// This is a slightly modified version of the original matomo-nodejs-tracker
// adapted to work with browser APIs.
const MATOMO_URL = process.env.REACT_APP_TRACKING_BASE_URI + 'matomo.php'
const MATOMO_SITE_ID = 1

/**
 * Executes the call to the Matomo tracking API
 * Source: https://github.com/matomo-org/matomo-nodejs-tracker/blob/968aa8b52f54e33febb4ffabfeb4d68d59a862a2/index.js
 *
 * For a list of tracking option parameters see
 * https://developer.matomo.org/api-reference/tracking-api
 *
 * @param {(String|Object)} options URL to track or options (must contain URL as well)
 */
function track(options) {
  if (typeof options === 'string') {
    options = {
      url: options,
    }
  }

  // Set mandatory options
  options = options || {}
  options.idsite = MATOMO_SITE_ID
  options.rec = 1

  if (!options.url) {
    console.warn('URL to be tracked must be specified.')
    return
  }

  var requestUrl = `${MATOMO_URL}?${new URLSearchParams(options).toString()}`
  fetch(requestUrl)
}

var UserPK
var UserName

export function SetLogin(_UserPK, _UserName) {
  UserPK = _UserPK
  UserName = _UserName
  TrackEvent('LoggedIn', 'SetLogin')
}

export function TrackEvent(theEvent, action) {
  console.log('index tracking ' + theEvent)
  console.log(window.isOptedOut)
  // eslint-disable-next-line
  if (window.isOptedOut != true) {
    track({
      url: process.env.REACT_APP_BASE_URI,
      action_name: theEvent,
      ua: 'Node.js v0.10.24',
      uid: UserPK,
      cvar: JSON.stringify({
        1: ['UserName', UserName],
        2: ['action', action],
      }),
    })
  }
}

export function loadMatomo() {
  var _paq = window._paq || []
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  // _paq.push(['requireConsent']);
  //_paq.push(['requireConsent']);
  var isOptedOut = false
  _paq.push([
    function () {
      isOptedOut = this.isUserOptedOut()
    },
  ])

  _paq.push(['trackPageView'])
  _paq.push(['enableLinkTracking'])
  ;(function () {
    var u = process.env.REACT_APP_TRACKING_BASE_URI //"//analytics.atomicdev.lcl/";
    _paq.push(['setTrackerUrl', u + 'matomo.php'])
    _paq.push(['setSiteId', '1'])
    var d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0]
    g.type = 'text/javascript'
    g.async = true
    g.defer = true
    g.src = u + 'matomo.js'
    s.parentNode.insertBefore(g, s)
  })()
  _paq.push(['setDocumentTitle', document.domain + '/' + document.title])
}
