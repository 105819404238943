import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { STEP_ICONS, STEP_STATES } from './constants'
import MediaQuery from 'react-responsive'

const Step = ({ state, title, value }) => {
  const isTruncated = value?.length > 17

  return (
    <div>
      <MediaQuery minDeviceWidth={769}>
        <div className="d-block step flex-wrap justify-content-center text-center">
          <FontAwesomeIcon size="lg" icon={STEP_ICONS[state]} className="mr-2 step-icon" />
          <div className={`mr-4 fw-6 text-light text-center ${state === STEP_STATES.none ? 'font-weight-normal' : ''}`}>
            {title}
          </div>
          <div className="step-value" tabIndex="0" id={`${title.split(' ')?.[0]}`}>
            {STEP_STATES.none !== state && value && (
              <>
                {isTruncated ? `${value.slice(0, 14)}...` : value}
                {isTruncated && (
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`${title.split(' ')?.[0]}`}
                    innerClassName="ezchoice-tooltip"
                    className="ezchoice-tooltip-wrapper"
                    arrowClassName="ezchoice-tooltip-arrow"
                  >
                    {value}
                  </UncontrolledTooltip>
                )}
              </>
            )}
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={768}>
        <div className="d-flex flex-row justify-content-center text-center">
          <FontAwesomeIcon size="lg" icon={STEP_ICONS[state]} className="mr-4 step-icon" />
          <div className={`mr-4 fw-6 text-light text-center ${state === STEP_STATES.none ? 'font-weight-normal' : ''}`}>
            {title}
          </div>
          <div className="step-value" tabIndex="0" id={`${title.split(' ')?.[0]}`}>
            {STEP_STATES.none !== state && value && (
              <>
                {isTruncated ? `${value.slice(0, 14)}...` : value}
                {isTruncated && (
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`${title.split(' ')?.[0]}`}
                    innerClassName="ezchoice-tooltip"
                    className="ezchoice-tooltip-wrapper"
                    arrowClassName="ezchoice-tooltip-arrow"
                  >
                    {value}
                  </UncontrolledTooltip>
                )}
              </>
            )}
          </div>
        </div>
      </MediaQuery>
    </div>
  )
}

export default Step
