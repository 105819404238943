import { map } from 'rxjs/operators'
import Axios from '../upstream/axios-observable/dist'
import { serviceConfig } from './ServiceConfig'

const serviceBaseUrl = serviceConfig.memberServiceUrl + serviceConfig.memberServiceVersion

export const claimService = {
  getClaimList: (subscriberPK, dependentPK, beginDate, endDate, claimType, includePending, pageSize) => {
    console.log('Getting Claims for: subscriberPK:' + subscriberPK + ' , dependentPK:' + dependentPK)
    return Axios.post(serviceBaseUrl + '/Account/GetClaimList', {
      filterOptions: {
        subscriberPK: subscriberPK,
        dependentPK: dependentPK,
        startDate: beginDate,
        endDate: endDate,
        claimType: claimType,
        includePending: includePending,
      },
      sortColumn: 'serviceDate',
      pageSize: pageSize,
      currentPage: 0,
      sortAscending: false,
      includePaging: true,
    }).pipe(
      map((x) => {
        let newResult = x.data.result.map((claim, index) => ({ ...claim, id: index })) // add unique id to each claim
        return { result: newResult }
      })
    )
  },

  getClaimDetails: (claimPK) => {
    return Axios.get(serviceBaseUrl + '/Account/GetClaimDetails', {
      params: {
        claimPK: claimPK,
      },
    }).pipe(map((x) => x.data))
  },

  getClaimListReportPDF: (subscriberPK, dependentPK, beginDate, endDate, claimType, pageSize) => {
    console.log('Getting Claim List Report PDF for: subscriberPK:' + subscriberPK + ' , dependentPK:' + dependentPK)
    return Axios.post(
      serviceBaseUrl + '/Report/ClaimListReportPDF',
      {
        filterOptions: {
          subscriberPK: subscriberPK,
          dependentPK: dependentPK,
          startDate: beginDate,
          endDate: endDate,
          claimType: claimType,
        },
        sortColumn: 'serviceDate',
        pageSize: pageSize,
        currentPage: 0,
        sortAscending: false,
        includePaging: true,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
        responseType: 'arraybuffer',
      }
    )
  },

  getClaimDetailsReportPDF: (claimPK, subscriberPK, dependentPK) => {
    return Axios.get(serviceBaseUrl + '/Report/ClaimDetailsReportPDF', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      params: { claimPK, subscriberPK, dependentPK },
      responseType: 'arraybuffer',
    })
  },
}
