import React, { PureComponent } from 'react'
import { memberService } from '../../services/MemberService'
import { Subscription } from 'rxjs'
import { LoadingCard } from './LoadingCard'
import { switchMap } from 'rxjs/operators'

const ResourcesRow = (props) => {
  var isPhone = props.memberExternalResource.resourceType.includes('Phone')
  return (
    // Is only card
    <div>
      <div>
        <h5>
          {isPhone ? (
            // eslint-disable-next-line
            <a target="_blank" className={'card-resource-link'} href={'tel:' + props.memberExternalResource.webShortUrl}>
              {props.memberExternalResource.resourceType} - {props.memberExternalResource.resourceName}{' '}
              <div className="linkleft"> {props.memberExternalResource.webShortUrl}</div>
            </a>
          ) : (
            // eslint-disable-next-line
            <a target="_blank" className={'card-resource-link'} href={'http://' + props.memberExternalResource.webShortUrl}>
              {props.memberExternalResource.resourceType} - {props.memberExternalResource.resourceName}
            </a>
          )}
        </h5>
      </div>
    </div>
  )
}

export class MyResources extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      subscriberPK: props.subscriberPK,
      patientPK: props.patientPK,
      externalResources: false,
    }
    this.subscription = new Subscription()
  }

  componentDidMount() {
    this.subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            this.setState({ subscriberPK: x.subscriberPK, patientPK: x.dependentPK })
            return memberService.fetchMemberResources(x.subscriberPK, x.dependentPK)
          })
        )
        .subscribe((x) => {
          this.setState({
            externalResources: x.data.result,
          })
          this.forceUpdate()
        })
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    if (this.state.externalResources) {
      if (this.state.externalResources.length > 0) {
        return (
          <div className="card-body text-light extra-rounded">
            <span className="h5 text-light fw-7">My Resources</span>
            {this.state.externalResources.map((x, ind) =>
              x.webShortUrl ? <ResourcesRow key={ind} memberExternalResource={x}></ResourcesRow> : ''
            )}
          </div>
        )
      } else {
        return (
          <div className="card-body text-light extra-rounded">
            <small className="fw-7 text-primary-dark">You have no resources available.</small>
          </div>
        )
      }
    } else {
      return <LoadingCard />
    }
  }
}
