import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function MyPlanBenefitsIcon() {
  return (
    <SvgIcon>
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="30" r="30" fill="#AA0061" />
        <path
          d="M22.7084 21.1771C22.7084 18.9896 24.4792 17.1667 26.6667 17.1667C27.7084 17.1667 28.75 17.6354 29.4792 18.3646L30 18.8333L30.4688 18.3646C31.1979 17.6354 32.2396 17.1667 33.2813 17.1667C35.4688 17.1667 37.2917 18.9896 37.2917 21.1771C37.2917 22.2187 36.8229 23.2604 36.0938 23.9896L30.7292 29.4062C30.3125 29.7708 29.6354 29.7708 29.2188 29.4062L23.8542 23.9896C23.125 23.2604 22.7084 22.2187 22.7084 21.1771ZM24.7396 35.5C24.3229 35.5 23.9063 35.6562 23.5938 35.8646L19.4271 38.625C19.2188 38.7812 18.9584 38.8333 18.75 38.8333H16.25C15.5209 38.8333 15 38.3125 15 37.5833C15 36.9062 15.5209 36.3333 16.25 36.3333H18.3334L22.1875 33.7812C22.9688 33.3125 23.8542 33 24.7396 33H32.9167C34.7396 33 36.25 34.5104 36.25 36.3333C36.25 36.3854 36.25 36.4375 36.25 36.4375L39.5834 34.3542C40.1563 34.0417 40.7813 33.8333 41.4584 33.8333H41.8229C43.5938 33.8333 45 35.2396 45 37.0104C45 38.0521 44.4271 39.0417 43.5417 39.6146L38.5938 42.8438C37.5521 43.5208 36.3542 43.8854 35.1563 43.8854H16.25C15.5209 43.8854 15 43.3125 15 42.6354C15 41.9063 15.5209 41.3854 16.25 41.3854H35.1563C35.8854 41.3854 36.6146 41.1771 37.1875 40.7604L42.1875 37.5312C42.3438 37.4271 42.5 37.2187 42.5 37.0104C42.5 36.6458 42.1875 36.3333 41.8229 36.3333H41.4584C41.25 36.3333 41.0938 36.3854 40.9375 36.4896L36.0417 39.5104C35.8334 39.6146 35.625 39.6667 35.3646 39.6667H32.9167H31.6667H28.3334C27.6042 39.6667 27.0834 39.1458 27.0834 38.4167C27.0834 37.7396 27.6042 37.1667 28.3334 37.1667H31.6667H32.9167C33.3334 37.1667 33.75 36.8021 33.75 36.3333C33.75 35.9167 33.3334 35.5 32.9167 35.5H24.7396Z"
          fill="#5F0638"
        />
      </svg>
    </SvgIcon>
  )
}
