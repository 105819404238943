// Takes a string and formats
export const FormatCapitalizedText = (text) => {
  if (text) {
    if (text.split(' ').length > 1) {
      var splitText = text.split(' ')
      var newText = ''
      splitText.forEach((i) => {
        newText += i.charAt().toUpperCase() + i.slice(1).toLowerCase() + ' '
      })
      return newText
    } else {
      return text.charAt().toUpperCase() + text.slice(1).toLowerCase()
    }
  }
}

// Takes and object with string properties and formats them
export const FormatCapitalizedTextInObject = (obj) => {
  for (var prop in obj) {
    obj[prop] = FormatCapitalizedText(obj[prop])
  }
  return obj
}

// Takes and object with string properties and formats them
export const FormatCurrency = (text) => {
  var newText = Math.round(text).toLocaleString()
  return newText
}

export const formatMiles = (distance) => {
  return distance > 1 ? Math.round(distance) : 1
}

export const dateFormatter = (input, format = 'en-US') => {
  console.log(input)
  let output
  try {
    if (format === 'iso8601') {
      output = new Date(input).toISOString().substring(0, 10)
    } else {
      output = new Intl.DateTimeFormat(format).format(new Date(input))
    }
  } catch {
    output = 'Invalid value, expected something date-like.'
  }
  return input == null ? '' : output
}

export const FormatMaxDeductible = (deductible) => {
  let maxDeductible = ''
  if (deductible > 900000) {
    maxDeductible = 'No Max'
  } else {
    maxDeductible = FormatCurrency(deductible)
  }
  return maxDeductible
}

export const ValidLettersRegex = /^[A-Za-z]+$/i
export const ValidNumbersRegex = /^[0-9]+$/
export const ValidSpecialCharactersRegex = /\W|_/g

export const StatesStringArray = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MP',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UM',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
]
