import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

export class PayMyBillCard extends PureComponent {
  render() {
    return (
      <div className="d-flex justify-content-between card-body bg-primary-light extra-rounded">
        <span className="card-section-header">Pay My Bill</span>
        <Link to="/bill/0">
          <button type="button" className="btn btn-outline-primary btn-sm ml-3 float-right fw-7">
            Pay Now
          </button>
        </Link>
      </div>
    )
  }
}
