import React from 'react'
import { cardConnectService } from '../../services/EZPayService.cardconnect'
import { memberService } from '../../services/MemberService'
import ProfileCardF from '../cards/ProfileCardF'
import { AuthContext } from '../../auth/AuthProvider'
import { LoadingCard } from '../cards/LoadingCard'
import { Subscription } from 'rxjs'
import { Component } from 'react'
import PlanSummaryCard from '../cards/PlanSummaryCard'
import TileButtonsCard from '../cards/TileButtonsCard'
import GetTheMostCard from '../cards/GetTheMostCard'
import { Stack, Container } from '@mui/material'
import Background1200 from '../../Background1200.png'
import KnowBeforeSection from '../_newDesign/pages/Dashboard/components/KnowBeforeSection'

export class DashboardPage extends Component {
  static contextType = AuthContext
  constructor(props) {
    super(props)
    //console.log('trackevent0');
    //console.log(this.props.TrackEvent);
    this.state = {
      claims: '',
      isDependent: false,
      modal: false,
    }
  }

  componentDidMount() {
    //console.log('trackevent0');
    //console.log(this.props.TrackEvent);
    //  const auth = useContext(AuthContext);
    const { getPatientPK, getSubscriberPK, isDependent, getClaims } = this.context
    const patientPK = getPatientPK()
    const subscriberPK = getSubscriberPK()

    this.subscription = new Subscription()

    this.setState(
      {
        claims: getClaims(),
        isDependent: isDependent(),
        modal: false,
        subscriberPK: subscriberPK,
        dependentPK: patientPK,
      },
      () => {
        this.checkEZStatus()
      }
    )
  }

  checkEZStatus() {
    this.subscription.add(
      cardConnectService.fetchEZPayList(this.state.subscriberPK, this.state.dependentPK).subscribe((x) => {
        // eslint-disable-next-line
        if (x.status == 200) {
          this.setState({
            isEZEnrolled: x.data.length > 0,
          })
        }
      })
    )

    this.subscription.add(
      memberService.fetchAppConfigs(this.state.dependentPK).subscribe((x) => {
        // eslint-disable-next-line
        if (x.status == 200) {
          let config = x.data.result.find((y) => (y.ConfigKey = 'EZPayPopup'))
          if (config) {
            this.setState({ ezpayPopup: config.configValue })
          } else {
            console.log('no config')
          }
        }
      })
    )
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  render() {
    /*
      let isDependent = this.state.isDependent;
      let displayPopup = this.state.ezpayPopup === "True" && (this.state.isEZEnrolled === false);
      */
    if (this.state.claims) {
      return (
        <>
          <Stack justifyContent="center" display="flex">
            <Stack
              sx={() => ({
                background: `url(${Background1200})`,
                width: '100%',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              })}
            >
              <Container
                maxWidth="lg"
                sx={(theme) => ({
                  paddingBottom: '40px',
                  [theme.breakpoints.down('lg')]: {
                    paddingTop: '20px',
                  },
                })}
              >
                <Stack direction="column" gap={2}>
                  <Stack
                    sx={(theme) => ({
                      flexDirection: 'row',
                      gap: '16px',

                      '>*:first-child': {
                        flexGrow: 1,
                      },

                      '>*:last-child': {
                        width: '340px',
                      },

                      [theme.breakpoints.down('md')]: {
                        flexDirection: 'column',
                        '>*:first-child': {
                          width: '100%',
                        },

                        '>*:last-child': {
                          width: '100%',
                        },
                      },
                    })}
                  >
                    <ProfileCardF />
                    <PlanSummaryCard />
                  </Stack>
                  <TileButtonsCard />
                </Stack>
              </Container>
            </Stack>

            <KnowBeforeSection />
            <GetTheMostCard />
          </Stack>
        </>
      )
    } else {
      return <LoadingCard />
    }
  }
}
