import { Stack, styled } from '@mui/material'
import React from 'react'
import BackgroundImage from './assets/Background.png'
import DoctorImage from './assets/DoctorImage.png'
import CostBreakdownImage from './assets/CostBreakdown.png'
import ContactImage from './assets/Contact.png'

const Img = styled('img')()

function DoctorImageComponent() {
  return (
    <Stack
      sx={(theme) => ({
        height: '100%',
        flexGrow: 1,
        maxWidth: '70%',
        position: 'relative',
        transform: 'translateX(5vw)',

        [theme.breakpoints.down('md')]: {
          width: '100%',
          minWidth: '100%',
          height: '590px',
          margin: '0 0 30px',
          transform: 'none',
        },
      })}
    >
      <Img
        src={BackgroundImage}
        alt="background"
        sx={() => ({
          position: 'absolute',
          bottom: '0',
          zIndex: 1,
        })}
      />
      <Img
        src={DoctorImage}
        alt="background"
        sx={(theme) => ({
          position: 'absolute',
          bottom: '0',
          left: '0',
          maxHeight: '95%',
          zIndex: '2',
          aspectRatio: '567 / 394',

          [theme.breakpoints.down('md')]: {
            height: '100%',
            left: '30%',
            transform: 'translate(-30%, 0)',
          },
        })}
      />
      <Img
        src={CostBreakdownImage}
        alt="background"
        sx={() => ({
          position: 'absolute',
          top: '50%',
          left: '0',
          zIndex: '3',
          aspectRatio: '112 / 280',
        })}
      />
      <Img
        src={ContactImage}
        alt="background"
        sx={(theme) => ({
          position: 'absolute',
          top: '67%',
          right: '10%',
          zIndex: '3',
          aspectRatio: '138 / 308',

          [theme.breakpoints.down('md')]: {
            right: 0,
          },
        })}
      />
    </Stack>
  )
}

export default DoctorImageComponent

/*
    <MediaQuery minDeviceWidth={1218}>
      {(matches) => (
        <img
          src={matches ? DoctorImg : DoctorImgCondensed}
          alt={'DoctorImg'}
          sx={(theme) => ({
            width: 'auto',
            height: '95%',
            aspectRatio: 880 / 565,
            marginRight: '-5vw',
            [theme.breakpoints.down('xl')]: {
              marginRight: '-60px',
            },
            [theme.breakpoints.down('lg')]: {
              height: '85%',
              aspectRatio: 1.45,
            },
            [theme.breakpoints.down('md')]: {
              width: 'calc(100% + 80px)',
              transform: 'translateX(-56px)',
              margin: '50px auto 30px',
            },
          })}
        />
      )}
    </MediaQuery>
*/
