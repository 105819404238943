import React from 'react'
import { useHistory } from 'react-router-dom'
import SessionService from '../../services/SessionService'

const EZChoiceCard = () => {
  const { push } = useHistory()
  const sessionService = new SessionService()
  return (
    <div className="card-body bg-primary-light extra-rounded d-flex justify-content-between align-content-center flex-wrap">
      <div className="card-section-header d-flex align-items-center mb-0 flex-wrap">
        <span className="mr-2">EZchoice</span>
      </div>
      <a
        href="/ezchoice"
        onClick={() => {
          sessionService.clearData()
          sessionService.clearStepDetails()
        }}
        className="btn btn-outline-primary btn-sm fw-7 d-flex"
      >
        Start an Estimate
      </a>
    </div>
  )
}

export default EZChoiceCard
