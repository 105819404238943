import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { faChevronLeft, faChevronRight, faChevronsLeft, faChevronsRight } from '@fortawesome/pro-light-svg-icons'

const PaginationComponent = ({ activePage, noOfPages, setActivePage, className, isShort }) => {
  if (!noOfPages || noOfPages <= 1) {
    return null
  }

  const getPagesToRender = () => {
    const pageNumbers = [...Array(noOfPages).keys()]
    if (noOfPages <= 5) {
      return pageNumbers
    }
    if (activePage < 3) {
      return pageNumbers.slice(0, 5)
    }
    if (noOfPages - activePage <= 2) {
      return pageNumbers.slice(-5)
    }
    return pageNumbers.slice(activePage - 2, activePage + 3)
  }

  const formatNumbers = (pages) => {
    if (!isShort) {
      return pages
    }
    if (pages[4] > 999) {
      return activePage === pages[4] || activePage === pages[3] ? pages.slice(1, 5) : pages.slice(0, 4)
    }
    return pages
  }

  const pagesToRender = formatNumbers(getPagesToRender())

  return (
    <Pagination className={`mt-3 ${className}`}>
      <PaginationItem disabled={activePage === 0}>
        <PaginationLink onClick={() => setActivePage(0)}>
          <FontAwesomeIcon size="xs" icon={faChevronsLeft} />
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={activePage === 0}>
        <PaginationLink onClick={() => setActivePage(activePage - 1)}>
          <FontAwesomeIcon size="xs" icon={faChevronLeft} />
        </PaginationLink>
      </PaginationItem>
      {pagesToRender.map((page) => (
        <PaginationItem key={page} active={activePage === page}>
          <PaginationLink onClick={() => setActivePage(page)}>{page + 1}</PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={activePage === noOfPages - 1}>
        <PaginationLink onClick={() => setActivePage(activePage + 1)}>
          <FontAwesomeIcon size="xs" icon={faChevronRight} />
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={activePage === noOfPages - 1}>
        <PaginationLink onClick={() => setActivePage(noOfPages - 1)}>
          <FontAwesomeIcon size="xs" icon={faChevronsRight} />
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  )
}

export default PaginationComponent
