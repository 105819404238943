import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function MoreIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.9999 25.1667C19.0833 25.1667 21.9166 23.5417 23.4999 20.8333C25.0416 18.1667 25.0416 14.875 23.4999 12.1667C21.9166 9.5 19.0833 7.83333 15.9999 7.83333C12.8749 7.83333 10.0416 9.5 8.45825 12.1667C6.91659 14.875 6.91659 18.1667 8.45825 20.8333C10.0416 23.5417 12.8749 25.1667 15.9999 25.1667ZM15.9999 5.83333C19.7916 5.83333 23.2916 7.875 25.2083 11.1667C27.1249 14.5 27.1249 18.5417 25.2083 21.8333C23.2916 25.1667 19.7916 27.1667 15.9999 27.1667C12.1666 27.1667 8.66659 25.1667 6.74992 21.8333C4.83325 18.5417 4.83325 14.5 6.74992 11.1667C8.66659 7.875 12.1666 5.83333 15.9999 5.83333ZM17.3333 16.5C17.3333 17.25 16.7083 17.8333 15.9999 17.8333C15.2499 17.8333 14.6666 17.25 14.6666 16.5C14.6666 15.7917 15.2499 15.1667 15.9999 15.1667C16.7083 15.1667 17.3333 15.7917 17.3333 16.5ZM19.9999 15.1667C20.7083 15.1667 21.3333 15.7917 21.3333 16.5C21.3333 17.25 20.7083 17.8333 19.9999 17.8333C19.2499 17.8333 18.6666 17.25 18.6666 16.5C18.6666 15.7917 19.2499 15.1667 19.9999 15.1667ZM13.3333 16.5C13.3333 17.25 12.7083 17.8333 11.9999 17.8333C11.2499 17.8333 10.6666 17.25 10.6666 16.5C10.6666 15.7917 11.2499 15.1667 11.9999 15.1667C12.7083 15.1667 13.3333 15.7917 13.3333 16.5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
