import React from 'react'
import { Button } from 'reactstrap'
import ReactMarkdown from 'react-markdown'
import _ from 'lodash'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SidebarOffcanvas from '../../../generic/SidebarOffcanvas'
import { useErrorToastDispatch } from '../../ToastContext'

const Disclaimer = ({ data, className, isOpen, setIsOpen, planEndText }) => {
  const toastErrorDispatch = useErrorToastDispatch()
  const sections = data?.sections.sort((s1, s2) => s1?.index - s2?.index)

  const onOpenClick = () => {
    if (_.isEmpty(data)) {
      toastErrorDispatch()
    } else {
      setIsOpen(true)
    }
  }
  return (
    <div className={`bg-light disclaimer ${className ? className : ''}`}>
      <div className="px-3 disclaimer__area" onClick={onOpenClick}>
        <div className="fs-11 fw-4 lh-16 text-dark">
          <span>The estimates are informational only and not a guarantee of coverage or total cost. </span>
          <br />
          <Button color="link" className="disclaimer__btn" onClick={onOpenClick}>
            Click here
          </Button>
          <span>to learn more about limitations you should consider before making any decision.</span>
        </div>
      </div>

      <SidebarOffcanvas isOpen={isOpen} toggle={setIsOpen}>
        <h5 className="mb-4 pb-2 fw-6">
          <ReactMarkdown>{data?.title}</ReactMarkdown>
        </h5>
        <div className="d-flex align-items-center mb-4 py-1 px-2 disclaimer__plan">
          <FontAwesomeIcon icon={faCircleInfo} className="ezchoice-icon-green mt-n1 mr-2" />
          <ReactMarkdown className="my-1">{planEndText || 'No plan year end date found.'}</ReactMarkdown>
        </div>
        <div className="mb-4">
          <ReactMarkdown>{data?.header}</ReactMarkdown>
        </div>
        {!_.isEmpty(sections) && (
          <div className="accordion mb-4" id="accordionExample">
            {sections.map((section, idx) => (
              <div className="ezchoice-accordion__header" key={idx}>
                <div className="px-3 py-3" id={`accordion${idx}`}>
                  <button
                    className="btn btn-link btn-block text-left"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#collapse${idx}`}
                    aria-expanded="false"
                    aria-controls={`#collapse${idx}`}
                  >
                    <ReactMarkdown className="fw-6">{section?.title}</ReactMarkdown>
                    <FontAwesomeIcon icon={faChevronDown} className="down" />
                  </button>
                </div>
                <div
                  id={`collapse${idx}`}
                  className="collapse ezchoice-accordion__body"
                  aria-labelledby={`accordion${idx}`}
                  data-parent="#accordionExample"
                >
                  <ReactMarkdown className="px-3 py-3">{section?.body}</ReactMarkdown>
                </div>
              </div>
            ))}
          </div>
        )}
        <div>
          <ReactMarkdown
            components={{
              // eslint-disable-next-line
              a: ({ ...props }) => <a target="_blank" {...props} />,
            }}
          >
            {data?.footer}
          </ReactMarkdown>
        </div>
      </SidebarOffcanvas>
    </div>
  )
}

export default Disclaimer
