import React, { useState } from 'react'
import { memberService } from '../../services/MemberService'
import { Subscription } from 'rxjs'
import { FormatCapitalizedText } from '../global/TextHelpers'
import { LoadingCard } from './LoadingCard'
import { switchMap } from 'rxjs/operators'

const DependentCard = (props) => {
  const [showSend, toggleShowSend] = useState(false)
  const [dependentEmail, setDependentEmail] = useState('')
  const skip = false
  if (props.dependent) {
    console.log('HasLogin - ' + props.dependent.hasLogin)
    console.log('Name - ' + props.dependent.firstName.trim())
    return (
      <div className="m-0 two-h flex-row">
        <div>
          <small className="text-light fw-7 ">
            {FormatCapitalizedText(props.dependent.firstName.trim())} {FormatCapitalizedText(props.dependent.lastName.trim())}
          </small>
        </div>
        {skip && !props.dependent.hasLogin && props.dependent.dependentPK !== props.subscriberDependentPK ? (
          showSend ? (
            <div className="w-75">
              <input
                type="email"
                className="d-inline w-75 email-input"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 3}$"
                placeholder="Recipient Email"
                name="dependentEmail"
                onChange={(e) => setDependentEmail(e.target.value)}
              />
              <button
                type="button"
                className="w-20 btn btn-outline-light btn-sm w-6"
                onClick={(e) => {
                  alert()
                  props.handleClick(props.dependent.subscriberPK, props.dependent.dependentPK, dependentEmail)
                }}
              >
                Send
              </button>
            </div>
          ) : (
            <div>
              <small className="text-light fw-7 card-link text-primary-orange" onClick={(e) => toggleShowSend(true)}>
                Send Login Invite
              </small>
            </div>
          )
        ) : (
          <div></div>
        )}
      </div>
    )
  }
  return ''
}

export class MyDependentsCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      subscriberInfo: false,
      show: false,
      isFSAOnly: false,
      subscriberPK: false,
      fsaPlans: false,
      allPlans: false,
    }

    this.subscription = new Subscription()
  }

  get subscriberInfo() {
    return this.state.subscriberInfo
  }

  componentDidMount() {
    console.log('mounted')
    this.subscription.add(
      memberService.subscriberInfo().subscribe((x) => {
        this.setState({ subscriberInfo: x })
        this.forceUpdate()
      })
    )

    this.subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            this.setState({ subscriberPK: x.subscriberPK })
            return memberService.fetchPlanList(x.subscriberPK)
          })
        )
        .subscribe((x) => {
          this.setState(
            {
              fsaPlans: x.data.result.filter((x) => x.coverage === 'FSA'),
              allPlans: x.data.result,
            },
            () => this.checkIfFSAOnly()
          )
          this.forceUpdate()
        })
    )
  }

  handleClose = () => {
    this.setState({ show: false })
  }
  handleShow = (e) => {
    this.setState({ show: true })
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  checkIfFSAOnly() {
    if (this.state.fsaPlans.length > 0 && this.state.fsaPlans.length === this.state.allPlans.length) {
      this.setState({
        isFSAOnly: true,
      })
    } else {
      this.setState({
        isFSAOnly: false,
      })
    }
  }

  sendDependentInvite(subscriberPK, dependentPK, email) {
    console.log('send invite to ' + dependentPK)
    console.log('send invite to ' + email)
    //parentSubscription.add(memberService.sendDependentLoginInvite(dependentPK, email).subscribe(x => {

    //}));
    memberService.sendDependentLoginInvite(subscriberPK, dependentPK, email).subscribe(
      (x) => {
        console.log(x)
        console.log('sendDependentLoginInvite COMPLETE')
        //if (x.status === 200) {
        //    this.setState({ sentToMobile: true });
        //} else {
        //    this.setState({ mobileError: true });
        //}
      },
      (error) => {}
    )
  }

  render() {
    if (this.subscriberInfo && this.subscriberInfo.dependents && !this.state.isFSAOnly) {
      const sortedDependents = [...this.subscriberInfo.dependents] // Create a copy of the dependents array
      sortedDependents.sort((a, b) => {
        if (a.relation === 'Insured') return -1 // Subscriber comes first
        if (a.relation === 'Spouse' && b.relation !== 'Insured') return -1 // Spouse comes before other dependents
        return 1 // Default case: b comes before a
      })

      return (
        <>
          <div className="m-2">
            <div className="text-white bg-transparent border-transparent">
              <h5 className="fw-7">Covered Members</h5>
              {sortedDependents.map((dependent, index) => (
                <DependentCard
                  dependent={dependent}
                  key={index}
                  handleClick={this.sendDependentInvite}
                  subscriberDependentPK={this.subscriberInfo.dependentPK}
                />
              ))}
            </div>
          </div>
        </>
      )
    } else if (this.state.isFSAOnly) {
      return <div></div>
    }

    return <LoadingCard />
  }
}
