import React from 'react'
import { faGlobe, faHouseMedical, faLocationDot, faPhone, faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { getPrice } from '../../helpers'
import { getShortAddress, joinStrings } from '../ElementsList/helpers'
import PricesBlock from '../PricesBlock'
import { formatMiles } from '../../../../../../global/TextHelpers'
import { buildGoogleMapsLink, formatUrl, isValidUrl } from './helpers'
import Disclaimer from '../Disclaimer/Disclaimer'

const ExtendedProvider = ({ provider, inNetworkDisclaimer, isInOpen, setIsInOpen, planEndText, isFacility }) => {
  if (!provider) {
    return <></>
  }
  const {
    totalCost,
    planCover,
    patientCost,
    facility: {
      name: facilityName,
      address: { addressLine1, addressLine2, city, state, zip },
      phoneNumbers,
      websiteUrl,
    },
    distance: { distanceInMiles },
  } = provider
  const shortAddress = getShortAddress(city, state, zip)
  const firstAddressLine = joinStrings(addressLine1, addressLine2, ', ')
  const specialtyName = isFacility
    ? provider.facility.specialtyName?.trim() || 'No specialty provided'
    : provider?.provider?.specialtyName
  return (
    <div className="pt-3 pl-3 extended-view h-100">
      <div className="fw-6 mb-2">
        {isFacility ? facilityName : joinStrings(provider.provider.firstName, provider.provider.lastName)}
      </div>
      {specialtyName?.trim() && <div className="mb-2">{specialtyName}</div>}
      <div className="d-responsive mr-4 mb-3">
        <PricesBlock
          totalCost={getPrice(totalCost, 'Not provided')}
          planCovers={getPrice(totalCost === patientCost && _.isNumber(totalCost) ? 0 : planCover)}
          myCost={getPrice(patientCost)}
          isUnableToCalculate={!_.isNumber(planCover) || !_.isNumber(patientCost)}
          isGreen
          isFacility={isFacility}
        />
        <Disclaimer data={inNetworkDisclaimer} isOpen={isInOpen} setIsOpen={setIsInOpen} planEndText={planEndText} />
      </div>

      <div className="extended-view__details mt-3">
        {!isFacility && (
          <>
            <div className="d-flex justify-content-center">
              <FontAwesomeIcon icon={faHouseMedical} className="ezchoice-icon-green" />
            </div>
            <div>{facilityName || 'Not available'}</div>
          </>
        )}
        <div className="d-flex justify-content-center">
          <FontAwesomeIcon icon={faLocationDot} className="ezchoice-icon-green" />
        </div>
        <div>
          {firstAddressLine && (
            <>
              {firstAddressLine}
              <br />
            </>
          )}
          <span>
            {shortAddress}{' '}
            <a
              className="ml-1"
              href={buildGoogleMapsLink(firstAddressLine, shortAddress)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ezchoice-icon-green" />
            </a>
          </span>
          <br />
          {`~${formatMiles(distanceInMiles)} miles`}
        </div>
        <div className="d-flex justify-content-center">
          <FontAwesomeIcon icon={faPhone} className="ezchoice-icon-green" />
        </div>
        <div>{phoneNumbers?.[0]?.trim() ? phoneNumbers?.[0] : 'Not available'}</div>
        <div className="d-flex justify-content-center">
          <FontAwesomeIcon icon={faGlobe} className="ezchoice-icon-green" />
        </div>
        <div>
          {websiteUrl?.trim() ? (
            <>
              {isValidUrl(websiteUrl) ? (
                <a href={formatUrl(websiteUrl)} rel="noopener noreferrer" target="_blank">
                  {websiteUrl}
                </a>
              ) : (
                websiteUrl
              )}
            </>
          ) : (
            'Not available'
          )}
        </div>
      </div>
    </div>
  )
}

export default ExtendedProvider
