import { faMagnifyingGlass, faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button, Form, Input, InputGroup, InputGroupAddon } from 'reactstrap'

const InputBar = ({ isDisabled, onSearch, searchValue, onChange, placeholder, maxCharacterInput }) => {
  return (
    <div className="d-flex mb-4">
      <Form
        onSubmit={(e) => {
          onSearch()
          e.preventDefault()
          e.stopPropagation()
        }}
        inline
        className="w-100"
      >
        <InputGroup className="mr-3 flex-grow-1">
          <InputGroupAddon addonType="prepend" className="input-group-text border-right-0 bg-transparent input-bar__prepend">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </InputGroupAddon>
          <Input
            name="searchValue"
            placeholder={placeholder}
            value={searchValue}
            onChange={({ target: { value } }) => onChange(value?.trimStart())}
            disabled={isDisabled}
            maxLength={maxCharacterInput}
            className="pt-2 pl-1 border-right-0 border-left-0 input-bar__input bg-transparent"
          />
          <InputGroupAddon addonType="prepend" className="input-group-text border-left-0 bg-transparent input-bar__prepend">
            {searchValue ? (
              <FontAwesomeIcon size="xs" icon={faXmark} onClick={() => onChange('')} role="button" />
            ) : (
              <span></span>
            )}
          </InputGroupAddon>
        </InputGroup>
        <Button
          color="primary"
          outline
          onClick={onSearch}
          disabled={isDisabled || !searchValue}
          className="ez-choice-btn"
          type="submit"
        >
          Search
        </Button>
      </Form>
    </div>
  )
}

export default InputBar
