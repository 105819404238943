import React from 'react'
import { HomeIcon, ClaimCenterIcon, FindCareIcon, MyPlanIcon } from '../../../icons'
import { serviceConfig } from '../../../../../services/ServiceConfig'

export const getMainLinkPages = ({ findCareLink }) => [
  {
    text: 'Home',
    path: '/',
    icon: <HomeIcon />,
  },
  {
    text: 'My Plan',
    path: '/myplan',
    icon: <MyPlanIcon />,
  },
  ...(findCareLink
    ? [
        {
          text: 'Find Care',
          path: findCareLink,
          icon: <FindCareIcon />,
          isAbsolutePath: true,
        },
      ]
    : []),
  {
    text: 'Claim Center',
    path: '/claim-search',
    icon: <ClaimCenterIcon />,
  },
]

export const getMoreMenuLinkPages = ({ isDependent, isCostEstimatorVisible, fsaFlag }) => [
  ...(!isDependent
    ? [
        {
          text: 'EZpay Bills',
          path: '/billlist',
        },
      ]
    : []),
  ...(!isDependent
    ? [
        {
          text: 'EZpay Accounts',
          path: '/ezpayaccounts',
        },
      ]
    : []),
  ...(!isDependent && fsaFlag
    ? [
        {
          text: 'Flexible Spending Account',
          path: 'flex-spend',
        },
      ]
    : []),
  ...(!isDependent && fsaFlag
    ? [
        {
          text: 'Flex Reimbursment Requests',
          path: '/flexrequests',
        },
      ]
    : []),
  {
    text: 'Deductibles',
    path: '/deductible',
  },
  ...(!isDependent
    ? [
        {
          text: 'Monthly Statements',
          path: '/statements',
        },
      ]
    : []),
  ...(isCostEstimatorVisible
    ? [
        {
          text: 'EZchoice',
          path: '/ezchoice',
        },
      ]
    : []),
  {
    text: 'Contact Us',
    path: '/contactus',
  },
  {
    text: 'Change Password',
    path: serviceConfig.identityServiceUrl + '/Manage/ChangePassword?returnURL=' + serviceConfig.returnUrl,
    isAbsolutePath: true,
  },
  {
    text: 'Logout',
    path: '/logout',
  },
]
