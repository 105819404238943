import React, { useEffect, useMemo, useState } from 'react'
import { memberService } from '../../services/MemberService'
import { Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { Typography } from '@mui/material'
import { Button, styled, Grid } from '@mui/material'
import FindProviderIcon from '../_newDesign/icons/svg/FindProviderIcon.js'
import MyPlanBenefitsIcon from '../_newDesign/icons/svg/MyPlanBenefitsIcon.js'
import EstimateVisitIcon from '../_newDesign/icons/svg/EstimateVisitIcon.js'
import MostRecentClaimsIcon from '../_newDesign/icons/svg/MostRecentClaimsIcon.js'

const TileButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  borderRadius: '16px',
  gap: '16px',
  '& svg': {
    fontSize: '60px',
  },
  padding: '24px',
  minHeight: '136px',
  minWidth: '100%',
  '&:hover': {
    color: theme.palette.common.white,
  },

  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    maxHeight: '136px',
    gap: '0px',
    padding: '16px',
    '& svg': {
      fontSize: '32px',
    },
  },
}))

export default function TileButtonsCard() {
  const subscription = useMemo(() => new Subscription(), [])
  const [state, setState] = useState({})

  useEffect(() => {
    subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            setState({
              subscriberPK: x.subscriberPK,
              patientPK: x.dependentPK,
              externalResources: state.externalResources,
            })
            return memberService.fetchMemberResources(x.subscriberPK, x.dependentPK)
          })
        )
        .subscribe((x) => {
          setState((prevState) => ({
            ...prevState,
            externalResources: x.data.result,
          }))
        })
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [state.externalResources, subscription])

  const findAProviderLink = state.externalResources?.find((resource) => resource.resourceType === 'Medical Network')?.webShortUrl
  const seeAllBenefitsLink = state.externalResources?.find(
    (resource) => resource.resourceType === 'Benefits Website'
  )?.webShortUrl

  const renderButton = (key, text, bgColor, icon, link, isAbsolutePath = false) => (
    <Grid item xs={6} sm={6} md={6} lg={3}>
      <TileButton
        variant="text"
        disableRipple
        disableElevation
        key={key}
        justifyContent="center"
        sx={{
          bgcolor: bgColor,
          // This is a workaround for a bug that happens on mobile devices when a
          // user opens a link in a new tab and then goes back to our app.
          // Mobile browsers keep the link in the :hover state until a new user
          // interaction occurs, resulting in the user seeing the :hover state even
          // if they are no longer hovering on the button.
          '@media not (hover: hover)': {
            // Disables the hover state on mobile devices.
            ':hover': {
              bgcolor: bgColor,
            },
          },
        }}
        target={isAbsolutePath ? '_blank' : '_self'}
        href={link}
      >
        {icon}
        <Typography
          sx={(theme) => ({
            fontWeight: '700',
            fontSize: '24px',
            lineHeight: '29px',
            textAlign: 'left',
            [theme.breakpoints.down('sm')]: {
              alignItems: 'flex-start',
              flexDirection: 'column',
              padding: '16px',
              fontSize: '20px',
              maxHeight: '136px',
              lineHeight: '24px',
              '& svg': {
                fontSize: '32px',
              },
            },
          })}
        >
          {text}
        </Typography>
      </TileButton>
    </Grid>
  )

  return (
    <Grid container spacing={2}>
      {renderButton('find a care', 'Find a Provider', '#6E3B04', <FindProviderIcon />, 'http://' + findAProviderLink, true)}
      {renderButton(
        'my plan benefits',
        'My Plan Benefits',
        '#5F0638',
        <MyPlanBenefitsIcon />,
        'http://' + seeAllBenefitsLink,
        true
      )}
      {renderButton('estimate a visit', 'Estimate a Visit', '#4F407F', <EstimateVisitIcon />, '/ezchoice')}
      {renderButton('fmost recent claims', 'Most Recent Claims', '#6F6604', <MostRecentClaimsIcon />, '/claim-search')}
    </Grid>
  )
}
