import React, { useState, useEffect } from 'react'
import { DISTANCE_OPTIONS, SORTING_OPTIONS, TABS } from '../../constants'
import { faBuildingCircleArrowRight, faDownload, faUserDoctor, faHouseMedical } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledTooltip } from 'reactstrap'
import { STEPS } from '../../../../constants'
import Filters from '../Filters'
import { getTotalCost } from '../../helpers'
import saveEstimatesToPdf from '../../../Estimates/pdfUtils'

const Header = ({
  activeTab,
  isLoading,
  setActiveTab,
  filteredProviders,
  filteredFacilities,
  savedValues,
  isPrecert,
  outOfNetworkPrices,
  inNetworkDisclaimer,
  planEndText,
  isFirstLoad,
  onFiltersChange,
  getProviders,
  providers,
  facilities,
  setIsLoading,
  isError,
}) => {
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(true)
  const [data, filteredData] = activeTab === TABS.facilities ? [facilities, filteredFacilities] : [providers, filteredProviders]
  const totalCost = getTotalCost(isError ? [] : filteredData)
  const [appliedFilters, setAppliedFilters] = useState({})

  useEffect(() => {
    setIsDownloadDisabled((!filteredProviders?.length && !filteredFacilities?.length) || isLoading)
  }, [filteredProviders, filteredFacilities, isLoading])

  return (
    <div className="search-results__header px-2 mx-n2">
      <div className="border-bottom px-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div
            className={`cet-tab d-responsive pt-3 pb-2 ${activeTab === TABS.inNetwork ? 'fw-6 active' : 'mb-1'} mr-3 ${
              isLoading ? 'disabled' : ''
            }`}
            onClick={() => !isLoading && setActiveTab(TABS.inNetwork)}
          >
            <FontAwesomeIcon icon={faUserDoctor} className="mr-2" />
            <span>{`In-network Providers ${
              filteredProviders?.length > 0 && !isLoading && !isFirstLoad && !isError ? filteredProviders?.length : ''
            }`}</span>
          </div>
          <div
            className={`cet-tab d-responsive pt-3 pb-2 ${activeTab === TABS.facilities ? 'fw-6 active' : 'mb-1'} mr-3 ${
              isLoading ? 'disabled' : ''
            }`}
            onClick={() => !isLoading && setActiveTab(TABS.facilities)}
          >
            <FontAwesomeIcon icon={faHouseMedical} className="mr-2" />
            <span>{`In-network Facilities ${
              filteredFacilities?.length > 0 && !isLoading && !isFirstLoad && !isError ? filteredFacilities?.length : ''
            }`}</span>
          </div>
          <div
            className={`cet-tab d-responsive pt-3 pb-2 ${activeTab === TABS.outOfNetwork ? 'fw-6 active' : 'mb-1'} ${
              isLoading ? 'disabled' : ''
            }`}
            onClick={() => !isLoading && setActiveTab(TABS.outOfNetwork)}
          >
            <FontAwesomeIcon icon={faBuildingCircleArrowRight} className="mr-2" />
            <span>Out-of-network Rates</span>
          </div>
        </div>
        <FontAwesomeIcon
          icon={faDownload}
          className={`mr-2 download-icon ${isDownloadDisabled ? 'download-disabled' : 'download-enabled'}`}
          id="download-icon"
          onClick={() => {
            if (isDownloadDisabled) {
              return
            }
            setIsDownloadDisabled(true)
            setIsLoading(true)
            const showReducedResult = filteredProviders?.length > 20
            const showFacilitiesReducedResult = filteredFacilities?.length > 20
            setTimeout(() => {
              saveEstimatesToPdf({
                inNetworkProviders: showReducedResult ? [...filteredProviders.slice(0, 20)] : [...filteredProviders],
                facilities: showFacilitiesReducedResult ? [...filteredFacilities.slice(0, 20)] : [...filteredFacilities],
                careType: savedValues?.[STEPS.careType]?.name,
                procedure: savedValues?.[STEPS.procedure].name,
                procedureCode: savedValues?.[STEPS.procedure].code,
                isPrecert: isPrecert,
                zip: savedValues?.[STEPS.location],
                outOfNetworkProviders: {
                  totalCost: outOfNetworkPrices?.averagePrice,
                  planCover: outOfNetworkPrices?.planCover,
                  patientCost: outOfNetworkPrices?.patientCost,
                },
                showReducedResult: showReducedResult,
                showFacilitiesReducedResult,
                disclaimer: inNetworkDisclaimer,
                planEndText: planEndText,
                appliedFilters: {
                  ...appliedFilters,
                  sortValue: SORTING_OPTIONS.find(({ value }) => value === appliedFilters.sortValue)?.label,
                  distance: DISTANCE_OPTIONS.find(({ value }) => value === appliedFilters.distance)?.label,
                },
              }).then(() => {
                setIsDownloadDisabled(false)
                setIsLoading(false)
              })
            }, 100)
          }}
        />
        {!isDownloadDisabled && !isLoading && (
          <UncontrolledTooltip
            placement="bottom"
            target="download-icon"
            innerClassName="ezchoice-tooltip"
            className="ezchoice-tooltip-wrapper"
            arrowClassName="ezchoice-tooltip-arrow"
          >
            Download results
          </UncontrolledTooltip>
        )}
      </div>
      <div className="d-responsive fpy-2 px-3">
        {activeTab === TABS.inNetwork || activeTab === TABS.facilities ? (
          <>
            <div className="d-responsive">
              {totalCost !== 'No costs provided' && <span className="mr-2">Total cost</span>}
              <span className="pl-1 mr-2">{totalCost}</span>
            </div>
          </>
        ) : (
          <div className="h-34"></div>
        )}
        <Filters
          onChange={onFiltersChange}
          onDistanceChange={(distance, name, facilityName, sorting) => {
            getProviders({ distance, name, facilityName, sorting })
          }}
          isDisabled={isLoading}
          noResults={!data || !data?.length || data?.length <= 1}
          filteredNoResults={!filteredData || !filteredData?.length || filteredData?.length <= 1}
          searchPlaceholder={activeTab === TABS.facilities ? 'e.g. Hospital' : 'e.g. Adam Smith'}
          searchLabel={activeTab === TABS.facilities ? 'Facility Name:' : 'Provider Name:'}
          isHidden={activeTab === TABS.outOfNetwork}
          isFacilityTab={activeTab === TABS.facilities}
          setAppliedFilters={setAppliedFilters}
        />
      </div>
    </div>
  )
}

export default Header
