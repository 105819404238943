import React from 'react'
import { Button, Stack, Typography, Box, styled } from '@mui/material'
import EZchoiceLogo from '../../../../../../EZchoiceLogo.png'
import Container from '@mui/material/Container'
import { useHistory } from 'react-router-dom'
import DoctorImage from './DoctorImage'

const Img = styled('img')()

function KnowBeforeSection() {
  const history = useHistory()

  return (
    <Box
      sx={{
        width: '100%',
        color: '#FFFFFF',
        bgcolor: '#121629',
        margin: '0px',
        borderRadius: '0',
        overflow: 'hidden',
      }}
    >
      <Container
        maxWidth="lg"
        sx={(theme) => ({
          overflow: 'visible',
          [theme.breakpoints.down('lg')]: {
            paddingLeft: '57px',
          },
          [theme.breakpoints.down('md')]: {
            paddingLeft: '20px',
          },
        })}
      >
        <Stack
          direction={{ xs: 'column-reverse', md: 'row' }}
          justifyContent="space-between"
          alignItems="flex-end"
          sx={(theme) => ({
            height: '600px',
            overflow: 'visible',
            [theme.breakpoints.down('md')]: {
              height: 'auto',
              paddingBottom: '65px',
            },
          })}
        >
          <Stack
            sx={(theme) => ({
              direction: 'column',
              justifyContent: 'center',
              gap: '24px',
              marginRight: '47px',
              maxWidth: '350px',
              alignSelf: 'center',
              [theme.breakpoints.down('lg')]: {
                marginRight: '-100px',
                maxWidth: '235px',
              },
              [theme.breakpoints.down('md')]: {
                width: '100%',
                maxWidth: 'unset',
                minHeight: 'unset',
                marginRight: 0,
              },
            })}
          >
            <Typography
              sx={(theme) => ({
                fontWeight: '700',
                fontSize: '50px',
                lineHeight: '60px',
                textAlign: 'left',
                marginBottom: '-8px',
                [theme.breakpoints.only('md')]: {
                  fontSize: '32px',
                  lineHeight: '38px',
                },
              })}
            >
              Know before you go with
            </Typography>
            <Img
              src={EZchoiceLogo}
              alt={'EZchoiceLogo'}
              sx={(theme) => ({
                width: '222px',
                height: '59px',
                [theme.breakpoints.only('md')]: {
                  width: '139px',
                  height: 'auto',
                  marginLeft: '0',
                  marginRight: 'auto',
                },
              })}
            />
            <Typography
              sx={(theme) => ({
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '19.6px',
                textAlign: 'left',
                color: '#CCCCCC',
                [theme.breakpoints.up('lg')]: {
                  fontSize: '16px',
                  lineHeight: '22px',
                },
              })}
            >
              Take control of your healthcare costs and eliminate surprise medical bills by comparing prices prior to your visit.
            </Typography>
            <Box>
              <Button
                variant="contained"
                disableElevation
                disableRipple
                onClick={() => history.push('/ezchoice')}
                sx={(theme) => ({
                  textTransform: 'none',
                  bgcolor: '#66D9A9',
                  color: '#1C2033',
                  fontSize: '13px',
                  fontWeight: '600',
                  borderRadius: '8px',
                  '&:hover': {
                    backgroundColor: '#00BF6F',
                  },
                  p: '12px',
                })}
              >
                Get Started
              </Button>
            </Box>
          </Stack>
          <DoctorImage />
        </Stack>
      </Container>
    </Box>
  )
}

export default KnowBeforeSection
