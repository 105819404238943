import { UserManager, WebStorageStateStore } from 'oidc-client'
import { IDENTITY_CONFIG, METADATA_OIDC } from './AuthSettings'
//import { Axios } from '../upstream/axios-observable/dist';

export default class AuthService {
  UserManager
  Props
  constructor(props) {
    this.Props = props

    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      metadata: {
        ...METADATA_OIDC,
      },
    })
    // LoggerJumbotron
    // Log.logger = console;
    //Log.level = Log.DEBUG;

    this.UserManager.events.addUserLoaded((user) => {
      console.log(user)
      sessionStorage.setItem('access_token', user.access_token)
      sessionStorage.setItem('id_token', user.id_token)
      console.log('parsing token: ' + user.access_token)
      const data = this.parseJwt(user.access_token)
      props.SetLogin(data.subscriberpk, data.subscriberid)
      sessionStorage.setItem('role', data.role)
      sessionStorage.setItem('userId', data.sub)
      let p = new URLSearchParams(window.location.search)
      if (p.get('subscriberpk')) {
        console.log('I get override?' + p.get('subscriberpk'))
        sessionStorage.setItem('subscriberpk', p.get('subscriberpk'))
      } else {
        console.log('loading sub ' + data.subscriberpk + ' and dep ' + data.patientpk)
        sessionStorage.setItem('subscriberpk', data.subscriberpk)
        sessionStorage.setItem('patientpk', data.patientpk)
        sessionStorage.setItem('subscriberid', data.subscriberid)
      }

      var myHealthEZGroups = []
      if (Array.isArray(data['myhealthez.group'])) {
        myHealthEZGroups = data['myhealthez.group']
      } else {
        myHealthEZGroups.push(data['myhealthez.group'])
      }
      var myPatientPkArray = []
      var patientPKCount = 0
      for (let i = 0; i < myHealthEZGroups.length; i++) {
        if (myHealthEZGroups[i]) {
          var myObj = JSON.parse(myHealthEZGroups[i])
          myPatientPkArray.push(myObj.GroupPK + '.' + myObj.RolePK + '.' + myObj.AssocPK + '.' + myObj.PatientPK)
          if (patientPKCount === 0) {
            sessionStorage.setItem('subscriberpk', myObj.AssocPK)
            sessionStorage.setItem('patientpk', myObj.PatientPK)
            sessionStorage.setItem('memberRole', myObj.Role)
            sessionStorage.setItem('subscriberid', myObj.AssocID)
          }
          patientPKCount++
        }
      }
      console.log('finished loading for ' + sessionStorage.getItem('memberRole'))
      sessionStorage.setItem('patientpks', myPatientPkArray)

      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen()
      }
    })
    this.UserManager.events.addSilentRenewError((e) => {
      props.TrackEvent('Silent Renew Error', this.getSubscriberID())
      console.log('silent renew error', e.message)
      this.UserManager.clearStaleState()
      this.signinRedirect()
    })

    this.UserManager.events.addAccessTokenExpired(() => {
      props.TrackEvent('token expired', this.getSubscriberID())
      console.log('token expired')
      this.signinSilent()
    })
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => {})
  }

  getUser = async () => {
    const user = await this.UserManager.getUser()
    if (!user) {
      return await this.UserManager.signinRedirectCallback()
    }
    return user
  }

  parseJwt = (token) => {
    if (!!token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      return JSON.parse(window.atob(base64))
    }
    return {}
  }

  getClaims = () => {
    const token = sessionStorage.getItem('access_token')
    const data = this.parseJwt(token)
    return data
  }
  getPatientList = () => {
    console.log(sessionStorage)
    const pk = sessionStorage.getItem('patientpks')
    return pk
  }
  getPatientPK = () => {
    console.log(sessionStorage)
    const pk = sessionStorage.getItem('patientpk')
    return pk
  }
  getSubscriberPK = () => {
    const pk = sessionStorage.getItem('subscriberpk')
    return pk
  }
  getSubscriberID = () => {
    const id = sessionStorage.getItem('subscriberid')
    return id
  }
  getRole = () => {
    return sessionStorage.getItem('role')
  }
  getMemberRole = () => {
    return sessionStorage.getItem('memberRole')
  }
  isSubscriber = () => {
    return sessionStorage.getItem('memberRole') === 'Subscriber'
  }
  isDependent = () => {
    return sessionStorage.getItem('memberRole') === 'Dependent'
  }
  isCS = () => {
    return sessionStorage.getItem('role') === 'admin'
  }

  getToken = () => {
    const token = sessionStorage.getItem('access_token')
    return token
  }
  switchMember = (newMember) => {
    var snp = newMember.split('.')
    sessionStorage.setItem('subscriberpk', snp[0])
    sessionStorage.setItem('patientpk', snp[1])
    sessionStorage.setItem('memberRole', snp[2] === 2 ? 'Subscriber' : 'Dependent')
  }
  signinRedirect = () => {
    sessionStorage.setItem('redirectUri', window.location.pathname)
    this.UserManager.signinRedirect({})
  }

  setUser = (data) => {
    sessionStorage.setItem('userId', data.sub)
    let p = new URLSearchParams(window.location.search)
    if (p.get('subscriberpk')) {
      console.log('I get override?' + p.get('subscriberpk'))
      sessionStorage.setItem('subscriberpk', p.get('subscriberpk'))
    } else {
      console.log('loading sub ' + data.subscriberpk)
      sessionStorage.setItem('subscriberpk', data.subscriberpk)
    }
    sessionStorage.setItem('subscriberid', data.subscriberid)
    sessionStorage.setItem('role', data.role)
    //localStorage.setItem("user", data);
    //console.log("ident= " + data);
  }

  navigateToScreen = () => {
    const redirectUri = !!sessionStorage.getItem('redirectUri') ? sessionStorage.getItem('redirectUri') : '/'
    window.location.replace(redirectUri)
  }

  setSessionInfo(authResult) {
    sessionStorage.setItem('access_token', authResult.access_token)
    sessionStorage.setItem('id_token', authResult.idToken)
    //const data = this.parseJwt(authResult.accessToken);
    //console.log(data);
  }

  isAuthenticated = () => {
    const access_token = sessionStorage.getItem('access_token')
    return !!access_token
  }

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log('signed in', user)
      })
      .catch((err) => {
        console.log(err)
        this.UserManager.clearStaleState()
        this.signinRedirect()
      })
  }
  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback()
  }

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest()
  }

  logout = async () => {
    const signoutRedirectObj = {
      id_token_hint: sessionStorage.getItem('id_token'),
    }
    window.sessionStorage.clear()

    await this.UserManager.clearStaleState()

    this.UserManager.signoutRedirect(signoutRedirectObj)
  }

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      sessionStorage.clear()
      window.location.replace(process.env.REACT_APP_BASE_URI)
    })
    this.UserManager.clearStaleState()
  }
}
