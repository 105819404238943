import React from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

const ModalComponent = ({ isOpen, className, title, children, footerChildren, hasError, ...restProps }) => {
  return (
    <Modal isOpen={isOpen} className={`ezchoice-modal ${className || ''}`} {...restProps}>
      <ModalBody className="p-0">
        <h5 className="mb-4 fw-6 text-black">{title}</h5>
        {children}
      </ModalBody>
      <ModalFooter className="mt-3 p-0">{footerChildren}</ModalFooter>
      {hasError && <div className="text-danger h6 mt-4">An error occured. Please try to submit again or refresh the page.</div>}
    </Modal>
  )
}

export default ModalComponent
