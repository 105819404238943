//import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import AppContainer from './AppContainer'
import { AuthProvider } from './auth/AuthProvider'
import AccessibilityWidgetScript from './components/AccessibilityWidgetScript'
import './scss/custom.scss'
import { loadMatomo, SetLogin, TrackEvent } from './utilities/matomo'

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

loadMatomo()

root.render(
  <AuthProvider TrackEvent={TrackEvent} SetLogin={SetLogin}>
    <AccessibilityWidgetScript />
    <BrowserRouter basename={baseUrl}>
      <AppContainer TrackEvent={TrackEvent} SetLogin={SetLogin} />
    </BrowserRouter>
  </AuthProvider>
)
