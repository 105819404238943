import React from 'react'
import { MediaQuery } from 'react-responsive'
import { FormatCapitalizedText, dateFormatter } from '../global/TextHelpers'
import { LoadingCard } from '../cards/LoadingCard'
import BootstrapTable from 'fad-react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { options } from '../global/TableOptions'
import 'fad-react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { claimService } from '../../services/ClaimService'
import { saveAs } from 'file-saver'
import { Subscription } from 'rxjs'
import { Button } from 'reactstrap'

export class ClaimList extends React.Component {
  constructor(props) {
    super(props)

    this.downloadPDF = this.downloadPDF.bind(this)

    this.subscription = new Subscription()

    this.state = {
      error: null,
    }
  }

  componentDidMount() {
    if (this.props.preSelectAll && this.props.preSelectAll === true) {
      console.log('preselecting all')
    }
  }

  downloadLink(cell, row) {
    return (
      // eslint-disable-next-line
      <a
        // eslint-disable-next-line
        href="javascript:void(0);"
        className="a-underline"
        onClick={() => {
          this.downloadPDF(row)
        }}
      >
        {cell}
      </a>
    )
  }
  /* /Account/GetClaimList
          "result": [
              {
                  "claimPK": "string",
                  "claimNumber": "string",
                  "serviceDate": "2019-07-27T13:15:15.969Z",
                  "providerName": "string",
                  "billedAmount": 0,
                  "claimType": "string"
              }
      */

  columns = [
    {
      dataField: 'claimNumber',
      text: 'Claim Number',
      formatter: (cell, row, rowIndex, extraData) => this.downloadLink(cell, row),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    {
      dataField: 'description',
      text: 'Description',
      formatter: (cell, row, rowIndex, extraData) => this.downloadLink(cell, row),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
      hidden: true,
    },
    {
      dataField: 'claimType',
      text: 'Claim Type',
      formatter: (cell, row, rowIndex, extraData) => this.downloadLink(cell, row),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    {
      dataField: 'billedAmount',
      text: 'Billed Amount',
      formatter: (cell, row, rowIndex, extraData) =>
        this.downloadLink(
          cell ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell) : '$0.00',
          row
        ),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    {
      dataField: 'patientOwesAmount',
      text: 'You Owe',
      formatter: (cell, row, rowIndex, extraData) =>
        this.downloadLink(
          cell ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell) : '$0.00',
          row
        ),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    {
      dataField: 'serviceDate',
      text: 'Date of Service',
      formatter: (cell, row, rowIndex, extraData) => this.downloadLink(new Date(cell).toLocaleDateString(), row),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    {
      dataField: 'providerName',
      text: 'Provider Name',
      formatter: (cell, row, rowIndex, extraData) => this.downloadLink(FormatCapitalizedText(cell), row),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row, rowIndex, extraData) => this.downloadLink(FormatCapitalizedText(cell), row),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
  ]

  minColumns = [
    {
      dataField: 'claimNumber',
      text: 'Claim Num',
      formatter: (cell, row, rowIndex, extraData) => this.downloadLink(cell, row),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },

    {
      dataField: 'patientOwesAmount',
      text: 'You Owe',
      formatter: (cell, row, rowIndex, extraData) =>
        this.downloadLink(cell ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell) : '', row),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    {
      dataField: 'serviceDate',
      text: 'Service Date',
      formatter: (cell, row, rowIndex, extraData) => this.downloadLink(new Date(cell).toLocaleDateString(), row),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    //,
    //{
    //    dataField: 'status',
    //    text: 'Status',
    //    formatter: (cell, row, rowIndex, extraData) => (this.downloadLink(FormatCapitalizedText(cell), row)),
    //    headerAlign: 'center',
    //    headerClasses: 'table-header',
    //    classes: 'table-cell',
    //    sort: true
    //}
  ]

  rxcolumns = [
    {
      dataField: 'drugName',
      text: 'DrugName',
      formatter: (cell, row, rowIndex, extraData) => <span className="text-seconday fw-7">{cell}</span>,
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    {
      dataField: 'claimType',
      text: 'Claim Type',
      formatter: (cell, row, rowIndex, extraData) => <span className="text-secondary fw-7">{cell}</span>,
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    {
      dataField: 'serviceDate',
      text: 'Service Date',
      formatter: (cell, row, rowIndex, extraData) => (
        <span className="text-secondary fw-7">{new Date(cell).toLocaleDateString()}</span>
      ),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    {
      dataField: 'providerName',
      text: 'Provider Name',
      formatter: (cell, row, rowIndex, extraData) => <span className="text-secondary fw-7">{FormatCapitalizedText(cell)}</span>,
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    {
      dataField: 'patientOwesAmount',
      text: 'Patient Owes Amount',
      formatter: (cell, row, rowIndex, extraData) => (
        <span className="text-secondary fw-7">
          {cell ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell) : cell}
        </span>
      ),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
  ]
  rxMinColumns = [
    {
      dataField: 'drugName',
      text: 'Drug Name',
      formatter: (cell, row, rowIndex, extraData) => <span className="text-seconday fw-7">{cell}</span>,
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    {
      dataField: 'serviceDate',
      text: 'Date',
      formatter: (cell, row, rowIndex, extraData) => (
        <span className="text-secondary fw-7">{new Date(cell).toLocaleDateString()}</span>
      ),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    {
      dataField: 'patientOwesAmount',
      text: 'Patient Owes',
      formatter: (cell, row, rowIndex, extraData) => (
        <span className="text-secondary fw-7">
          {cell ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell) : cell}
        </span>
      ),
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
  ]

  downloadPDF(row) {
    let claimPK = row.claimPrimaryKey
    if (row.claimPK) {
      claimPK = row.claimPK
    }

    // Do not generate a report for claims with a claimPK of 0.
    if (claimPK !== '0') {
      this.subscription.add(
        claimService.getClaimDetailsReportPDF(claimPK, this.props.subscriberPK, this.props.dependentPK).subscribe((pdf) => {
          const blob = new Blob([pdf.data], { type: 'application/pdf' })
          const fileName = dateFormatter(row.serviceDate, 'iso8601') + '_claim_' + row.claimNumber + '.pdf'

          // Save the PDF file using the saveAs function
          saveAs(blob, fileName)

          // Open the PDF in a new browser tab
          const url = URL.createObjectURL(blob)
          const newTab = window.open(url, '_blank')

          // Release the object URL
          URL.revokeObjectURL(url)

          // Check if the new tab has been blocked
          if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
            // Opening the new tab was blocked, display a message to the user
            alert('The PDF file has been saved, but the new tab was blocked. Please check your browser settings.')
          }
        })
      )
    } else {
      alert('Cannot generate a report for the selected claim.')
    }
  }

  render() {
    //if (this.state.error) {
    //    return (<div className="alert alert-danger">Can not generate report for the selected claim. </div>);
    //}

    const selectRow = this.props.displayCheckbox
      ? {
          mode: 'checkbox',
          selected: this.props.selectedClaims,
          clickToSelect: false,
          onSelect: this.props.handleOnSelect,
          onSelectAll: this.props.handleOnSelectAll,
        }
      : {
          mode: 'checkbox',
          hideSelectColumn: true,
        }

    var columns
    var minColumns
    if (this.props.claims.length > 0 && this.props.claims[0].claimType === 'Prescription') {
      columns = this.rxcolumns
      minColumns = this.rxMinColumns
    } else {
      columns = this.columns
      minColumns = this.minColumns
    }

    const ExportCSVButton = (props) => {
      const handleClick = () => {
        props.onExport()
      }
      return (
        <div>
          <Button outline color="primary" onClick={handleClick} className="float-right">
            Export to CSV{' '}
          </Button>
        </div>
      )
    }

    if (this.props.isPagination) {
      return (
        <>
          {this.props.claims ? (
            <div>
              <MediaQuery maxDeviceWidth={475}>
                <ToolkitProvider keyField="claimNumber" data={this.props.claims} columns={minColumns} exportCSV>
                  {(props) => (
                    <div className="card-body bg-primary-dark extra-rounded p-4 m-4">
                      <ExportCSVButton {...props.csvProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        bordered={false}
                        bootstrap4noDataIndication={<p className="text-center text-light">There is no data to display.</p>}
                        pagination={paginationFactory(options)}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </MediaQuery>

              <MediaQuery minDeviceWidth={476}>
                <ToolkitProvider keyField="claimNumber" data={this.props.claims} columns={columns} exportCSV>
                  {(props) => (
                    <div className="card-body bg-primary-dark extra-rounded p-4 m-4">
                      <ExportCSVButton {...props.csvProps} />
                      <BootstrapTable
                        {...props.baseProps}
                        bordered={false}
                        bootstrap4
                        noDataIndication={<p className="text-center text-light">There is no data to display.</p>}
                        pagination={paginationFactory(options)}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </MediaQuery>
            </div>
          ) : (
            <LoadingCard />
          )}
        </>
      )
    } else {
      return (
        <>
          {this.props.claims ? (
            <div className="card-body bg-primary-dark extra-rounded p-4 m-4">
              <MediaQuery maxDeviceWidth={475}>
                <BootstrapTable
                  className="statement-table"
                  keyField="id"
                  selectRow={selectRow}
                  data={this.props.claims}
                  bootstrap4
                  columns={minColumns}
                  bordered={false}
                  noDataIndication={<p className="text-center text-light">There is no data to display.</p>}
                />
              </MediaQuery>

              <MediaQuery minDeviceWidth={476}>
                <BootstrapTable
                  className="statement-table"
                  keyField="id"
                  selectRow={selectRow}
                  data={this.props.claims}
                  bootstrap4
                  columns={columns}
                  bordered={false}
                  noDataIndication={<p className="text-center text-light">There is no data to display.</p>}
                />
              </MediaQuery>
            </div>
          ) : (
            <LoadingCard />
          )}
        </>
      )
    }
  }
}
