import React from 'react'
import { Card, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import { MedicalExpenseForm } from './MedicalExpenseForm'
import { DaycareExpenseForm } from './DaycareExpenseForm'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

export class ExpenseCard extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.handleDelete = this.handleDelete.bind(this)

    let expense = props.expense
    expense.activeTab = 'medical'

    this.state = {
      expense: props.expense,
      dependents: props.dependents,
      deleteCallBack: props.deleteCallBack,
      index: props.index,
    }
  }

  toggle(tab) {
    if (this.state.expense.activeTab !== tab) {
      let expense = this.state.expense
      expense.activeTab = tab
      this.setState({
        expense: expense,
      })
      this.forceUpdate()
    }
  }

  handleDelete() {
    this.state.deleteCallBack(this.state.index)
  }

  render() {
    return (
      <Card className="card-light bg-primary-light">
        <Nav tabs className="d-flex">
          <NavItem>
            <NavLink
              active={this.state.expense.activeTab === 'medical'}
              className={classnames(
                'fw-7',
                'tab',
                { 'tab-active': this.state.expense.activeTab === 'medical' },
                { 'tab-inactive': this.state.expense.activeTab !== 'medical' }
              )}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.toggle('medical')
              }}
            >
              Medical Expense
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={this.state.expense.activeTab === 'daycare'}
              className={classnames(
                'fw-7',
                'tab',
                { 'tab-active': this.state.expense.activeTab === 'daycare' },
                { 'tab-inactive': this.state.expense.activeTab !== 'daycare' }
              )}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.toggle('daycare')
              }}
            >
              Daycare Expense
            </NavLink>
          </NavItem>
          {this.state.index !== 0 && (
            <NavItem className="ml-auto">
              <NavLink className="bg-danger text-white" style={{ cursor: 'pointer' }} onClick={this.handleDelete}>
                <FontAwesomeIcon icon={faTrash} />
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={this.state.expense.activeTab}>
          <TabPane tabId="medical">
            <MedicalExpenseForm expense={this.state.expense} dependents={this.state.dependents} index={this.state.index} />
          </TabPane>
          <TabPane tabId="daycare">
            <DaycareExpenseForm expense={this.state.expense} dependents={this.state.dependents} index={this.state.index} />
          </TabPane>
        </TabContent>
      </Card>
    )
  }
}
