import React from 'react'
import { AuthConsumer } from './AuthProvider'
import { LoadingCard } from '../components/cards/LoadingCard'

export const LoginCallback = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback()
      return <LoadingCard />
    }}
  </AuthConsumer>
)
