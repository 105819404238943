/* eslint-disable */
import React from 'react'

// Override Paginator
// https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/pagination-props.html
export const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
  <a
    key={text}
    className="dropdown-item"
    href="javascript:undefined(0);"
    tabIndex="-1"
    role="menuitem"
    data-page={page}
    onMouseDown={(e) => {
      e.preventDefault()
      onSizePerPageChange(page)
    }}
  >
    {text}
  </a>
)

export const options = {
  sizePerPageOptionRenderer,
  hidePageListOnlyOnePage: true,
}
