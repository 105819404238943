import React, { PureComponent } from 'react'
import { Card, Row, Col } from 'reactstrap'
import BootstrapTable from 'fad-react-bootstrap-table-next'
import { memberService } from '../../services/MemberService'
import { Subscription } from 'rxjs'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { FormatCapitalizedText } from '../global/TextHelpers'
import { options } from '../global/TableOptions'
import { LoadingCard } from './LoadingCard'
import 'fad-react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { MediaQuery } from 'react-responsive'

// Override Paginator
// https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/pagination-props.html
export default class FlexSpendCard extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      subscriberPK: props.subscriberPK,
      planDescription: props.planDescription,
      fsaName: props.fsaName,
      fsaType: props.fsaType,
      fsaYear: props.fsaYear,
      fsaBalance: props.fsaBalance,
      fsaElection: props.fsaElection,
      fsaSource: props.fsaSource,
      flexSpendItems: [],
      isLoading: true,
    }

    this.dateFormatter = this.dateFormatter.bind(this)
    this.currencyFormatter = this.currencyFormatter.bind(this)
    this.subscription = new Subscription()
  }

  /**
   * This function formats a string representing a date into the given format, defaulting to 'en-US', tolerates null/undefined parameters,
   * and displays a validation message for everything else.
   * @param {string|null|undefined} input Value to convert to date with given format.
   * @param {string|null|undefined} format Locale value to format date with, defaults to 'en-US'.
   * @return {string} Date in given format, empty string, or validation message.
   */
  dateFormatter = (input, format = 'en-US') => {
    let output
    try {
      if (format === 'iso8601') {
        output = new Date(input).toISOString().substring(0, 10)
      } else {
        output = new Intl.DateTimeFormat(format).format(new Date(input))
      }
    } catch {
      output = 'Invalid value, expected something date-like.'
    }
    return input == null ? '' : output
  }
  currencyFormatter = (input) =>
    input == null
      ? ''
      : typeof input === 'number'
      ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(input)
      : 'Invalid value, expected a number.'

  componentDidMount() {
    this.subscription.add(
      memberService
        .fetchFSAAccountDetail(
          this.state.subscriberPK,
          this.state.fsaName,
          this.state.fsaType,
          this.state.fsaYear,
          this.state.fsaSource,
          this.state.planDescription
        )
        .subscribe((z) => {
          this.setState({
            flexSpendItems: z.data.result.transactions,
            isLoading: false,
          })
        })
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  // "result": {
  //     "fsaType": "string",
  //     "planYear": "string",
  //     "transactions": [
  //       {
  //         "transactionType": "string",
  //         "description": "string",
  //         "requestedAmount": 0,
  //         "paidAmount": 0,
  //         "notPaidAmount": 0,
  //         "initialDate": "2019-07-24T17:34:03.264Z",
  //         "processedDate": "2019-07-24T17:34:03.264Z"
  //       }
  //     ]
  //   }

  // Table Columns
  columns = [
    {
      dataField: 'initialDate',
      text: 'Date Requested',
      formatter: (cell, row, rowIndex, extraData) => <span className="text-light fw-7">{this.dateFormatter(cell)}</span>,
      headerAlign: 'center',
      headerClasses: 'text-light fw-7',
      classes: 'text-dark fw-7 small',
      sort: true,
    },
    {
      dataField: 'processedDate',
      text: 'Date Processed',
      formatter: (cell, row, rowIndex, extraData) => <span className="text-light fw-7">{this.dateFormatter(cell)}</span>,
      headerAlign: 'center',
      headerClasses: 'text-light fw-7',
      classes: 'text-dark fw-7 small',
      sort: true,
    },
    {
      dataField: 'description',
      text: 'Description',
      formatter: (cell, row, rowIndex, extraData) => <span className="text-light fw-7">{FormatCapitalizedText(cell)}</span>,
      headerAlign: 'center',
      headerClasses: 'text-light fw-7',
      classes: 'text-dark fw-7 small',
      sort: true,
    },
    {
      dataField: 'paidAmount',
      text: 'Amount',
      formatter: (cell, row, rowIndex, extraData) =>
        // eslint-disable-next-line
        row.transactionType == 'Payment' ? (
          <span className="text-danger fw-7">-{cell}</span>
        ) : (
          <span className="text-success fw-7">+{cell}</span>
        ), //ADd green and + for CONTRIBUTION, red and - for PAY
      headerAlign: 'center',
      headerClasses: 'text-light fw-7',
      classes: 'text-dark fw-7 small',
      sort: true,
    },
    {
      dataField: 'notPaidAmount',
      text: 'Remaining Owed',
      formatter: (cell, row, rowIndex, extraData) =>
        // eslint-disable-next-line
        row.transactionType == 'Payment' ? <span className="text-success fw-7">{cell}</span> : <span></span>, // ONLY DISPLAY IF transactionType:PAYMENT
      headerAlign: 'center',
      headerClasses: 'text-light fw-7',
      classes: 'text-dark fw-7 small',
      sort: true,
    },
  ]
  minColumns = [
    {
      dataField: 'initialDate',
      text: 'Date',
      formatter: (cell, row, rowIndex, extraData) => <span className="text-light fw-7">{this.dateFormatter(cell)}</span>,
      headerAlign: 'center',
      headerClasses: 'text-light fw-7',
      classes: 'text-dark fw-7 small',
      sort: true,
    },
    {
      dataField: 'description',
      text: 'Descr',
      formatter: (cell, row, rowIndex, extraData) => <span className="text-light fw-7">{FormatCapitalizedText(cell)}</span>,
      headerAlign: 'center',
      headerClasses: 'text-light fw-7',
      classes: 'text-dark fw-7 small',
      sort: true,
    },
    {
      dataField: 'paidAmount',
      text: 'Amount',
      formatter: (cell, row, rowIndex, extraData) =>
        // eslint-disable-next-line
        row.transactionType == 'Payment' ? (
          <span className="text-danger fw-7">-{cell}</span>
        ) : (
          <span className="text-success fw-7">+{cell}</span>
        ), //ADd green and + for CONTRIBUTION, red and - for PAY
      headerAlign: 'center',
      headerClasses: 'text-light fw-7',
      classes: 'text-dark fw-7 small',
      sort: true,
    },
  ]

  render() {
    if (!this.state.isLoading) {
      if (this.state.flexSpendItems && this.state.flexSpendItems.length > 0) {
        // Show FSA
        return (
          <Card className="m-4 p-4 card-body bg-primary-dark extra-rounded">
            <Row>
              <Col xs={12} className="text-light fw-7 h3">
                {FormatCapitalizedText(this.state.planDescription)}
              </Col>
              <Col xs={6} sm={3} className="text-light fw-7 ">
                Balance: {this.currencyFormatter(this.state.fsaBalance)}
              </Col>
              <Col xs={6} sm={3} className="text-light fw-7 ">
                Election: {this.currencyFormatter(this.state.fsaElection)}
              </Col>
              <Col xs={6} sm={3} className="text-light fw-7 ">
                Year: {this.state.fsaYear}
              </Col>
              <Col xs={6} sm={3} className="text-light fw-7 ">
                FSA Type:{this.state.fsaType}
              </Col>
            </Row>
            <p className="text-light-light-blue fw-7">Transactions</p>
            <div className="centered-table">
              <MediaQuery minDeviceWidth={476}>
                <BootstrapTable
                  bootstrap4
                  condensed={true}
                  className="statement-table"
                  keyField="dateProcessed"
                  data={this.state.flexSpendItems}
                  columns={this.columns}
                  bordered={false}
                  options={{ noDataText: <p className="text-center">There is no data to display.</p> }}
                  pagination={paginationFactory(options)}
                />
              </MediaQuery>
              <MediaQuery maxDeviceWidth={475}>
                <BootstrapTable
                  bootstrap4
                  condensed={true}
                  className="statement-table"
                  keyField="dateProcessed"
                  data={this.state.flexSpendItems}
                  columns={this.minColumns}
                  bordered={false}
                  options={{ noDataText: <p className="text-center">There is no data to display.</p> }}
                  pagination={paginationFactory(options)}
                />
              </MediaQuery>
            </div>
          </Card>
        )
      } else {
        return (
          <Card className="m-4 p-4 card-body bg-primary-light extra-rounded text-center">
            <Row>
              <Col xs={12} className="text-light fw-7 h3">
                {FormatCapitalizedText(this.state.fsaName)}
              </Col>
              <Col xs={6} sm={3} className="text-light fw-7 ">
                Balance: {this.currencyFormatter(this.state.fsaBalance)}
              </Col>
              <Col xs={6} sm={3} className="text-light fw-7 ">
                Election: {this.currencyFormatter(this.state.fsaElection)}
              </Col>
              <Col xs={6} sm={3} className="text-light fw-7 ">
                Year: {this.state.fsaYear}
              </Col>
              <Col xs={6} sm={3} className="text-light fw-7 ">
                FSA Type:{this.state.fsaType}
              </Col>
            </Row>
            <span className="text-light fw-7">No Transactions Available</span>
          </Card>
        )
      }
    } else {
      return <LoadingCard />
    }
  }
}
