import React from 'react'
import { Link } from 'react-router-dom'
import HelpIcon from './HelpIcon.png'
import { styled } from '@mui/material'

const Img = styled('img')()

function HelpButton() {
  return (
    <Link to="/contactus">
      <Img
        src={HelpIcon}
        alt="help-icon"
        sx={(theme) => ({
          cursor: 'pointer',
          position: 'fixed',
          bottom: '24px',
          right: '24px',
          width: '60px',
          height: '60px',
          zIndex: 500,

          [theme.breakpoints.down('lg')]: {
            bottom: '105px',
            right: '10px',
          },
        })}
      />
    </Link>
  )
}

export default HelpButton
