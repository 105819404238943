import React, { Component } from 'react'
import { FormGroup, FormFeedback, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap'
import DatePicker from 'react-datepicker'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-datepicker/dist/react-datepicker.css'

export default class DateInput extends Component {
  render() {
    const { name, label, value, onChange, onBlur, touched, valid, validationRules, validationMessage } = this.props
    return (
      <div className="d-flex align-items-center">
        <DatePicker
          name={name}
          label={label}
          onBlur={onBlur}
          touched={touched}
          valid={valid}
          validationMessage={validationMessage}
          selected={value}
          onChange={onChange}
          customInput={<CustomInput />}
        />
        {validationRules.isRequired && <strong className="text-primary-orange ml-2">*Required</strong>}
      </div>
    )
  }
}

class CustomInput extends Component {
  render() {
    const { name, label, value, onChange, onBlur, touched, valid, validationMessage } = this.props
    return (
      <FormGroup>
        <Label for={name} className="text-primary fw-7">
          {label}
        </Label>
        <InputGroup onClick={this.props.onClick}>
          <Input
            type="text"
            id={name}
            name={name}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            valid={valid}
            invalid={touched && !valid}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText style={{ backgroundColor: '#1fb486' }}>
              <FontAwesomeIcon icon={faCalendarDay} color="#106681" />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        <FormFeedback>{validationMessage}</FormFeedback>
      </FormGroup>
    )
  }
}
