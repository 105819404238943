//replace literals with references to env variables set from .env-cmdrc.json

export const serviceConfig = {
  memberServiceUrl: process.env.REACT_APP_SERVICE_MEMBER_BASE_URI,
  memberServiceVersion: 'v2',

  enrollmentServiceUrl: process.env.REACT_APP_SERVICE_ENROLLMENT_BASE_URI,
  enrollmentServiceVersion: 'v1',

  ezPayServiceUrl: process.env.REACT_APP_SERVICE_EZPAY_BASE_URI,
  ezPayServiceVersion: 'v2',

  ezChoiceServiceUrl: process.env.REACT_APP_SERVICE_EZCHOICE_BASE_URI,
  ezChoiceServiceVersion: 'v1',

  surveyServiceUrl: process.env.REACT_APP_SERVICE_SURVEY_BASE_URI,
  surveyServiceVersion: 'v1',

  identityServiceUrl: process.env.REACT_APP_AUTH_URI,
  returnUrl: process.env.REACT_APP_BASE_URI,

  cardConnectServiceUrl: process.env.REACT_APP_SERVICE_CARDCONNECT_BASE_URI,
  cardConnectServiceMerchantId: process.env.REACT_APP_SERVICE_CARDCONNECT_MERCHANT_ID,
  cardConnectServiceUser: process.env.REACT_APP_SERVICE_CARDCONNECT_USER,
  cardConnectServicePassword: process.env.REACT_APP_SERVICE_CARDCONNECT_PASSWORD,
}

//Axios.defaults.baseURL = process.env.REACT_APP_AUTH_ISSUER
