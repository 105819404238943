import React, { PureComponent } from 'react'
import { memberService } from '../../services/MemberService'
import { Container, Card, Row, Col, Progress } from 'reactstrap'
import { Subscription } from 'rxjs'
import { LoadingCard } from './LoadingCard'
import { FormatMaxDeductible, FormatCurrency, FormatCapitalizedText } from '../global/TextHelpers'

/**
 * Current users name and insured state.
 */
const TitleComponent = (props) => {
  return (
    <div className="mb-1">
      <h3 className="card-section-header">
        {props.firstName} {props.lastName}
      </h3>
      <div className="text-primary-light-blue fw-7">{FormatCapitalizedText(props.relation)}</div>
    </div>
  )
}

/**
 * Current users dental information.
 */
const DentalComponent = (props) => {
  var dentUsed = props.dentalUsed
  var dentAvail = props.dentalMax
  if (dentAvail !== 0) {
    return (
      <Container style={{ marginLeft: '0' }}>
        <br />
        <Row>
          <h4 className="text-primary fw-7">Dental Benefits</h4>
        </Row>
        {dentAvail > 0 ? (
          <div>
            <Row>
              <Col sm="4">
                <h4 className="text-primary-orange fw-7">Used</h4>
              </Col>
              <Col>
                <h4 className="text-primary-orange fw-7">${dentUsed ? FormatCurrency(dentUsed) : '0'}</h4>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <h4 className="text-primary-orange fw-7">Max</h4>
              </Col>
              <Col>
                <h4 className="text-primary-orange fw-7">${FormatMaxDeductible(dentAvail)}</h4>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            {props.includeFamily ? (
              <p className="text-secondary fw-7">None found.</p>
            ) : (
              <p className="text-secondary fw-7 mb-2">None found.</p>
            )}
          </div>
        )}
      </Container>
    )
  } else return <Container></Container>
}

const ProgressBar = (props) => {
  let currentSpent = props.max > props.spent ? props.spent : props.max
  let maxDeductible = props.max
  let remainingSpend = maxDeductible > currentSpent ? maxDeductible - currentSpent : 0
  let headerText = props.headerText

  let deductiblePercentage = maxDeductible > currentSpent ? (currentSpent / maxDeductible) * 100 : 100

  return (
    <div id="deductible" className="mb-2">
      <Row>
        <Col>
          <h4 className="text-light float-left fw-7">{headerText}</h4>
        </Col>
      </Row>
      <Row>
        <Col className="text-left">
          <span className="text-secondary fw-7">Spent</span>
          <p className="text-primary-orange fw-7 mb-0">${FormatCurrency(currentSpent)}</p>
        </Col>
        <Col className="text-right">
          <span className="text-secondary fw-7">Remaining/Max</span>
          <p className="text-success fw-7 mb-0">
            ${FormatMaxDeductible(remainingSpend)}/{FormatMaxDeductible(maxDeductible)}
          </p>
        </Col>
      </Row>

      <Progress
        value={currentSpent}
        max={maxDeductible}
        className="rounded-left-5 rounded-right-5 deductible-bar deductible-progress"
      >
        <div>
          <span className="fw-7 float-left ml-2 text-dark">{Math.round(deductiblePercentage)}%</span>
        </div>
      </Progress>
    </div>
  )
}

export class DeductibleGraphCard extends PureComponent {
  constructor(props) {
    super(props)
    console.log(props.dependentInfo)

    this.state = {
      dependentInfo: props.dependentInfo,
      deductibleInfo: false,
      planList: false,
      hasDentalPlan: false,
      includeFamily: props.includeFamily,
    }

    this.subscription = new Subscription()
  }

  componentDidMount() {
    if (this.state && this.state.dependentInfo && this.state.dependentInfo.dependentPK) {
      //this.subscription.add(memberService.fetchPlanList(this.state.dependentInfo.dependentPK).subscribe(list => {
      //	this.setState({planList: list });
      //}));

      // Need fetchPlanDetails to check for dental plans*

      this.subscription.add(
        memberService.fetchDeductibleInfo(this.state.dependentInfo.dependentPK).subscribe((x) => {
          this.setState({ deductibleInfo: x.result })
        })
      )
      this.forceUpdate()
    }
    console.log(this.state)
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    if (this.state.deductibleInfo) {
      console.log(this.state.deductibleInfo)
      return (
        <Card className="card bg-primary-light p-3 mb-3">
          {this.state.deductibleInfo.individualInNetworkDeductible > 0 ? (
            <TitleComponent
              firstName={this.state.includeFamily ? '' : this.state.dependentInfo.firstName}
              lastName={this.state.includeFamily ? 'Family' : this.state.dependentInfo.lastName}
              relation={this.state.includeFamily ? 'Totals' : 'member'}
            />
          ) : (
            <span></span>
          )}
          {this.state.deductibleInfo.is3TierPlan ? (
            <ProgressBar //YTD
              headerText="Tier 1 Deductible"
              spent={
                this.state.includeFamily ? this.state.deductibleInfo.tier1FamInnDed : this.state.deductibleInfo.tier1MemInnDed
              }
              max={
                this.state.includeFamily
                  ? this.state.deductibleInfo.tier1FamInnDedMax
                  : this.state.deductibleInfo.tier1MemInnDedMax
              }
            />
          ) : (
            <span></span>
          )}
          {this.state.deductibleInfo.is3TierPlan ? (
            <ProgressBar //YTD
              headerText="Tier 1 Out Of Pocket"
              spent={
                this.state.includeFamily ? this.state.deductibleInfo.tier1FamInnOop : this.state.deductibleInfo.tier1MemInnOop
              }
              max={
                this.state.includeFamily
                  ? this.state.deductibleInfo.tier1FamInnOopMax
                  : this.state.deductibleInfo.tier1MemInnOopMax
              }
            />
          ) : (
            <span></span>
          )}
          {this.state.deductibleInfo.individualInNetworkDeductible > 0 ? (
            <ProgressBar //YTD
              headerText="In-network Deductible"
              spent={
                this.state.includeFamily
                  ? this.state.deductibleInfo.familyMedicalDeductible
                  : this.state.deductibleInfo.inNetworkDeductible
              }
              max={
                this.state.includeFamily
                  ? this.state.deductibleInfo.familyInNetworkDeductible
                  : this.state.deductibleInfo.individualInNetworkDeductible
              }
            />
          ) : (
            <span></span>
          )}
          {this.state.deductibleInfo.individualInNetworkOutOfPocket > 0 ? (
            <ProgressBar
              headerText="In-Network Out-Of-Pocket"
              spent={
                this.state.includeFamily
                  ? this.state.deductibleInfo.familyMedicalOutOfPocket
                  : this.state.deductibleInfo.inNetworkOutOfPocket
              }
              max={
                this.state.includeFamily
                  ? this.state.deductibleInfo.familyInNetworkOutOfPocket
                  : this.state.deductibleInfo.individualInNetworkOutOfPocket
              }
            />
          ) : (
            <span></span>
          )}
          {this.state.includeFamily ? (
            <></>
          ) : (
            <DentalComponent
              includeFamily={this.state.includeFamily}
              dentalUsed={this.state.deductibleInfo.dentalBenefitUsed}
              dentalMax={
                this.state.includeFamily
                  ? this.state.deductibleInfo.dentalPlanFamilyDeductible
                  : this.state.deductibleInfo.dentalIndividualBenefitMax
              }
            />
          )}
        </Card>
      )
    } else {
      return (
        <Col>
          <LoadingCard />
        </Col>
      )
    }
  }
}
