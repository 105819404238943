import React from 'react'

export class Privacy extends React.Component {
  render() {
    return (
      <div>
        {/* eslint-disable-next-line */}
        <iframe
          // eslint-disable-next-line
          style={({ border: 0 }, { height: '200px' }, { width: '100%' })}
          src={
            'https:' +
            process.env.REACT_APP_TRACKING_BASE_URI +
            'index.php?module=CoreAdminHome&action=optOut&language=en&backgroundColor=&fontColor=&fontSize=&fontFamily='
          }
        ></iframe>
      </div>
    )
  }
}
