import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function MyPlanIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
        <path d="M4 16.0004H5.8504" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M26.25 16.0004H27.9996" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M7.51367 24.4857L8.82287 23.1777"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M23.248 8.75283L24.4852 7.51562"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.0004 27.9988V26.1484"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M16.0004 5.7496V4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M24.4857 24.4856L23.1777 23.1776"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.75283 8.75283L7.51562 7.51562"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.4204 13.1944L15.9992 13.642L15.5792 13.1932C14.6552 12.2116 13.1228 12.1312 12.1004 13.0096C11.0564 13.9084 10.9316 15.4648 11.7908 16.5412C13.0196 18.082 14.4272 19.6036 15.9992 20.8C17.612 19.5736 19.0508 18.0064 20.3012 16.426C21.1124 15.4 20.9456 13.9108 19.9544 13.0576L19.9088 13.0192C18.878 12.1312 17.3456 12.2116 16.4204 13.1944Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
