import React from 'react'
import { Avatar } from '@mui/material'
import { useHistory } from 'react-router-dom'

const getUserInitials = (firstName, lastName) => [firstName?.[0], lastName?.[0]].filter(Boolean).join('')

function UserAvatar({ userInfo }) {
  const history = useHistory()

  return (
    <Avatar
      alt=""
      src="/static/images/avatar/2.jpg"
      sx={{ backgroundColor: '#FFFFFF', '&:hover': { cursor: 'pointer' } }}
      onClick={() => history.push('/profile')}
    >
      {getUserInitials(userInfo.firstName, userInfo.lastName)}
    </Avatar>
  )
}

export default UserAvatar
