import React from 'react'

const PricesBlock = ({ totalCost, planCovers, myCost, className, isUnableToCalculate, isGreen = false }) => (
  <div className={`price-cost ${className}`}>
    <div className={`px-4 py-3 ${isGreen ? 'price-cost--green' : 'bg-light'}`}>
      <div className="d-flex justify-content-between align-items-center">
        <span>Total Cost of Care</span>
        <span className="fw-6">{totalCost}</span>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-1">
        <span>Plan Covers</span>
        <span className="fw-6">{planCovers}</span>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-1 fw-6">
        <span>My Cost</span>
        <span className="page-title text-light">{myCost}</span>
      </div>
    </div>
    {isUnableToCalculate && <div className="pt-2 price-cost__unable">Unable to calculate costs.</div>}
  </div>
)

export default PricesBlock
