import React, { PureComponent } from 'react'
import { memberService } from '../../services/MemberService'
import { switchMap } from 'rxjs/operators'
import { Subscription } from 'rxjs'
import { FormatCapitalizedText, FormatCurrency } from '../global/TextHelpers'
import { LoadingCard } from './LoadingCard'
import { Link } from 'react-router-dom'

const FSARow = (props) => {
  var fsaType = FormatCapitalizedText(props.fsa.fsaType)
  var year = props.fsa.fsaYear
  return (
    // Is only card
    <div>
      <small className="text-light fw-7">
        {fsaType} {year}
      </small>
      <div>
        <h5>
          <strong className="text-primary-orange">Available Balance</strong>
          <strong className="text-primary-orange float-right">${FormatCurrency(props.fsa.availableBalance)}</strong>
        </h5>
      </div>
    </div>
  )
}

export class FSACard extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      subscriberPK: false,
      fsaInformation: false,
    }

    this.subscription = new Subscription()
  }

  componentDidMount() {
    this.subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            this.setState({ subscriberPK: x.subscriberPK, fsaFlag: x.employerFSADetails })
            return memberService.fetchFSAInformation(x.subscriberPK)
          })
        )
        .subscribe((x) => {
          this.setState({
            fsaInformation: x.data.result,
          })
          this.forceUpdate()
        })
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    if (this.state.fsaInformation) {
      if (this.state.fsaInformation.length > 0) {
        return (
          // Is only card
          <div className=" text-white bg-transparent border-transparent">
            {this.state.fsaFlag ? (
              <Link to="/flex-spend">
                <h5 className="text-white fw-7">Flexible Spending Account</h5>
              </Link>
            ) : (
              <h5 className="text-white fw-7">Flexible Spending Account</h5>
            )}
            {this.state.fsaInformation.map((x, ind) => (
              <FSARow key={ind} fsa={x}></FSARow>
            ))}
          </div>
        )
      } else {
        return (
          // No data returned
          <div className=" text-white bg-transparent border-transparent"></div>
        )
      }
    } else {
      return <LoadingCard />
    }
  }
}
