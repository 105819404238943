import React, { PureComponent } from 'react'
import { AuthContext } from '../../auth/AuthProvider'
import { memberService } from '../../services/MemberService'
import { Subscription } from 'rxjs'
import { StatementsTableCard } from '../cards/StatementsTableCard'
import { LoadingCard } from './LoadingCard'
import { switchMap } from 'rxjs/operators'

export class RecentStatementsCard extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      statementList: false,
      subscriberPK: false,
    }
    this.subscription = new Subscription()

    this.pageSize = 6
  }

  componentDidMount() {
    this.subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            this.setState({ subscriberPK: x.subscriberPK })
            return memberService.fetchStatementList(x.subscriberPK, this.pageSize)
          })
        )
        .subscribe((y) => {
          if (y) {
            this.setState({ statementList: y })
            this.forceUpdate()
          }
        })
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    return (
      <div className="card-body bg-primary-light extra-rounded">
        <h5 className="card-section-header">Recent Statements</h5>
        {(() => {
          if (this.state.statementList) {
            return (
              <div className="container-fluid p-0">
                <StatementsTableCard data={this.state} />
              </div>
            )
          } else {
            return <LoadingCard />
          }
        })()}
        <a href="/statements">
          <small className="text-primary fw-7">View More</small>
        </a>
      </div>
    )
  }
}

RecentStatementsCard.contextType = AuthContext
