import React, { Component } from 'react'
import FlexSpendCard from '../cards/FlexSpendCard'
import { memberService } from '../../services/MemberService'
import { switchMap, map } from 'rxjs/operators'
import { Subscription } from 'rxjs'
import { LoadingCard } from '../cards/LoadingCard'

export class FlexSpendPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subscriberPK: false,
      fsaInformation: false,
    }

    this.subscription = new Subscription()
  }

  componentDidMount() {
    this.subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            this.setState({ subscriberPK: x.subscriberPK })
            return memberService.fetchFSAInformation(x.subscriberPK)
          })
        )
        .pipe(map((y) => y.data.result))
        .subscribe((x) => {
          this.setState({
            fsaInformation: x,
          })
          this.forceUpdate()
        })
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    if (this.state.fsaInformation) {
      if (this.state.fsaInformation.length > 0) {
        return (
          // Is only card
          <div className="mt-4">
            <h3 className="page-header fw-7 text-light">Flexible Spending Account</h3>
            {this.state.fsaInformation.map((x, ind) => (
              <FlexSpendCard
                key={ind}
                subscriberPK={this.state.subscriberPK}
                fsaName={x.fsaName}
                fsaType={x.fsaType}
                fsaYear={x.fsaYear}
                fsaSource={x.dataSourcePK}
                fsaBalance={x.availableBalance}
                fsaElection={x.electionAmount}
                planDescription={x.planDescription}
              ></FlexSpendCard>
            ))}
          </div>
        )
      } else {
        return (
          // No data returned
          <div className="mt-4">
            <h3 className="page-header text-light fw-7">Flexible Spending Account</h3>
            <small>You do not have any Flex Spending Accounts.</small>
          </div>
        )
      }
    }
    return <LoadingCard />
  }
}
