import React, { PureComponent } from 'react'
import { Card } from 'reactstrap'
import { ClaimList } from './ClaimList'
import { FormatCapitalizedText } from '../global/TextHelpers'
import { claimService } from '../../services/ClaimService'
import { Subscription } from 'rxjs'
import { LoadingCard } from '../cards/LoadingCard'

export class DependentClaimsCard extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      subscriberPK: props.subscriberPK,
      dependent: props.dependent,
      dateStart: props.dateStart,
      dateEnd: props.dateEnd,
      claims: false,
      isLoading: true,
    }
    this.subscription = new Subscription()
  }

  componentDidMount() {
    this.subscription.add(
      claimService
        .getClaimList(
          this.state.subscriberPK,
          this.state.dependent.dependentPK,
          this.state.dateStart,
          this.state.dateEnd,
          'Medical',
          this.pageSize
        )
        .subscribe((data) => {
          this.setState({
            claims: data.result,
            isLoading: false,
          })
          this.forceUpdate()
        })
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingCard />
    } else if (this.state.claims) {
      return (
        <Card className="card bg-primary-light p-4 mb-4">
          <h4 className="card-section-header">
            {FormatCapitalizedText(this.state.dependent.firstName)} {FormatCapitalizedText(this.state.dependent.lastName)}
          </h4>
          <p className="no-h">
            <strong className="text-primary-light-blue fw-7">{FormatCapitalizedText('member')}</strong>
          </p>
          <ClaimList
            claims={this.state.claims}
            pagination={false}
            subscriberPK={this.state.subscriberPK}
            dependentPK={this.state.dependent.dependentPK}
          ></ClaimList>
        </Card>
      )
    } else {
      return (
        <Card className="card-body bg-light extra-rounded m-3 text-center">
          <h3 className="text-primary fw-7">There were no claims found: </h3>
          <p className="text-secondary fw-7">From: {this.state.dateStart} </p>
          <p className="text-secondary fw-7">To: {this.state.dateEnd}</p>
        </Card>
      )
    }
  }
}
