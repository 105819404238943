import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/pro-light-svg-icons'

const SidebarOffcanvas = ({ isOpen, toggle, children }) => {
  return (
    <div
      className={`off-canvas ${isOpen ? 'open' : ''}`}
      onClick={(ev) => {
        if (ev.target !== ev.currentTarget) return
        toggle(false)
      }}
    >
      <div className="off-canvas__content px-3 py-3">
        {isOpen && (
          <>
            <FontAwesomeIcon className="off-canvas__icon" icon={faX} onClick={() => toggle(false)} />
            {children}
          </>
        )}
      </div>
    </div>
  )
}

export default SidebarOffcanvas
