import React from 'react'
import { Subscription } from 'rxjs'
import { BillList } from '../bills/BillList'

export class PayMyBillsPage extends React.Component {
  constructor(props) {
    super(props)
    this.subscription = new Subscription()
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    return <BillList></BillList>
  }
}
