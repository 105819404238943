import React, { Component } from 'react'
import { Container, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import { MediaQuery } from 'react-responsive'
import { contactService } from '../../services/ContactService'
import { ValidIndicator, InvalidIndicator } from '../global/FormHelpers'
import { Subscription } from 'rxjs'
import { memberService } from '../../services/MemberService'
import { LoadingCard } from '../cards/LoadingCard'

function updateIsSending(isSending) {
  this.setState({ isSending })
}

function updateIsSent(isSent) {
  this.setState({ isSent })
}

function updateSendErrorMessage(errorMessage) {
  this.setState({ errorMessage })
}

export class EmailForm extends React.Component {
  constructor(props) {
    super(props)

    this.submitForm = this.submitForm.bind(this)
    // this.handleChange = this.handleChange.bind(this);

    this.state = {
      alternateEmail: '',
      subject: '',
      message: '',
      validate: {
        alternateEmail: true,
        subject: true,
        message: true,
      },
      submitCallback: props.submitCallback,
      subscriberInfo: false,
      isSending: false,
      isSent: false,
      errorMessage: false,
    }
    this.subscription = new Subscription()
    // eslint-disable-next-line
    updateIsSending = updateIsSending.bind(this)
    // eslint-disable-next-line
    updateIsSent = updateIsSent.bind(this)
    // eslint-disable-next-line
    updateSendErrorMessage = updateSendErrorMessage.bind(this)
  }

  componentDidMount() {
    this.subscription.add(
      memberService.subscriberInfo().subscribe((x) => {
        console.log(x)
        this.setState({
          subscriberInfo: x,
        })
      })
    )
    this.forceUpdate()
  }
  componentWillUnmount() {
    this.subscription.unsubscribe()
  }
  handleChange = async (event) => {
    const { target } = event
    const value = target.type === 'checkbox' ? target.checked : target.value
    const { name } = target
    await this.setState({
      [name]: value,
    })
  }

  validateField = async (event) => {
    const name = event.target.name
    let isValid = false

    const { validate } = this.state
    console.log(name)
    switch (name) {
      case 'alternateEmail':
        if (event.target.value) {
          isValid = true
        }
        break
      case 'subject':
        if (event.target.value) {
          isValid = true
        }
        break
      case 'message':
        if (event.target.value) {
          isValid = true
        }
        break
      default:
        isValid = true
    }

    validate[name] = isValid
    this.setState({ validate })
  }

  submitForm = async (e) => {
    e.preventDefault()
    this.state.submitCallback({
      alternateEmail: this.state.alternateEmail,
      subject: this.state.subject,
      message: this.state.message,
      isSending: true,
    })
    //this.state.isSending = true;
  }

  render() {
    // style ASTORISKS
    const { subject, message } = this.state
    if (this.state.subscriberInfo) {
      return (
        <Form onSubmit={this.submitForm}>
          <FormGroup>
            <Label className="text-grey fw-7" for="alternateEmail">
              Your email address <span className="text-danger">*</span>
            </Label>
            <Input
              type="email"
              name="alternateEmail"
              id="alternateEmail"
              placeholder="user@domain.com"
              required
              defaultValue={this.state.subscriberInfo.email}
              valid={this.state.validate.alternateEmail}
              invalid={!this.state.validate.alternateEmail}
              onChange={(e) => {
                this.validateField(e)
                this.handleChange(e)
              }}
            />
            <FormFeedback valid>
              <ValidIndicator />
            </FormFeedback>
            <FormFeedback>
              <InvalidIndicator />
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label className="text-grey fw-7" for="subject">
              Subject <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              name="subject"
              id="subject"
              placeholder="Subject..."
              required
              defaultValue={subject}
              valid={this.state.validate.subject}
              invalid={!this.state.validate.subject}
              onChange={(e) => {
                this.validateField(e)
                this.handleChange(e)
              }}
            />
            <FormFeedback valid>
              <ValidIndicator />
            </FormFeedback>
            <FormFeedback>
              <InvalidIndicator />
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label className="text-grey fw-7" for="message">
              Message <span className="text-danger">*</span>
            </Label>
            <Input
              type="textarea"
              name="message"
              id="message"
              placeholder="Write a message..."
              required
              defaultValue={message}
              valid={this.state.validate.message}
              invalid={!this.state.validate.message}
              onChange={(e) => {
                this.validateField(e)
                this.handleChange(e)
              }}
            />
            <FormFeedback valid>
              <ValidIndicator />
            </FormFeedback>
            <FormFeedback>
              <InvalidIndicator />
            </FormFeedback>
          </FormGroup>
          <MediaQuery minDeviceWidth={1224}>
            <Button color="link" className="btn profile-button btn-sm w-3 fw-7" disabled={this.state.isSending}>
              {this.state.isSending ? <LoadingCard tinyLoad={true} /> : 'Submit'}
            </Button>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={1224}>
            <Button color="link" className="btn profile-button btn-sm fw-7" disabled={this.state.isSending}>
              {this.state.isSending ? <LoadingCard tinyLoad={true} /> : 'Submit'}
            </Button>
          </MediaQuery>
          {this.state.isSent ? <small className="text-success fw-7 ml-2">Email Sent Successfully!</small> : ''}
          {this.state.errorMessage ? (
            <small className="text-danger fw-7 ml-2">
              Something went wrong sending the email! Please call customer service us at 1.800.948.9450.
            </small>
          ) : (
            ''
          )}
        </Form>
      )
    } else {
      return <LoadingCard />
    }
  }
}

export const ContactPageTab = (props) => {
  return (
    <div className="profile-content pt-2">
      <h5 className="card-section-header">{props.header}</h5>
      <p className="no-h">
        <small className="text-grey fw-7">{props.message1}</small>
      </p>
      <p>
        <small className="text-grey fw-7">{props.message2}</small>
      </p>
      <br />
    </div>
  )
}

export class ContactUsPage extends Component {
  constructor(props) {
    super(props)
    this.toggleNurseCard = this.toggleNurseCard.bind(this)
    this.toggleCaseCard = this.toggleCaseCard.bind(this)
    this.toggleCustomerCard = this.toggleCustomerCard.bind(this)
    this.sendContactCallback = this.sendContactCallback.bind(this)
    this.state = {
      showNurseCard: false,
      showCaseCard: false,
      showCustomerCard: true,
      currentCard: 'Customer Service',
      subscriberInfo: false,
    }

    this.subscription = new Subscription()
  }

  componentDidMount() {
    this.subscription.add(
      memberService.subscriberInfo().subscribe((x) => {
        this.setState({ subscriberInfo: x })
      })
    )
    this.toggleCustomerCard()
  }

  updateIsSending(isSending) {
    updateIsSending(isSending)
  }
  updateIsSent(isSent) {
    updateIsSent(isSent)
  }
  updateSendErrorMessage(errorMessage) {
    updateSendErrorMessage(errorMessage)
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
    clearTimeout(this.timeoutID)
  }

  toggleNurseCard() {
    const currentState = this.state.showNurseCard
    this.setState({
      showNurseCard: !currentState,
      showCaseCard: false,
      showCustomerCard: false,
      currentCard: 'Help Line',
      detailHeader: 'Email Help Line',
      detailMessage1: 'Send a secure and confidential e-mail to a nurse at HealthEZ.',
      detailMessage2: 'You can also reach the help line by dialing 1.800.948.9450.',
    })
  }

  toggleCaseCard() {
    const currentState = this.state.showCaseCard
    this.setState({
      showNurseCard: false,
      showCaseCard: !currentState,
      showCustomerCard: false,
      currentCard: 'Case Management',
      detailHeader: 'Email Case Management',
      detailMessage1: 'Send a secure and confidential e-mail to a HealthEZ case manager.',
      detailMessage2: 'You can also reach a case manager by dialing 1.800.948.9450.',
    })
  }

  toggleCustomerCard() {
    const currentState = this.state.showCustomerCard
    this.setState({
      showNurseCard: false,
      showCaseCard: false,
      showCustomerCard: !currentState,
      currentCard: 'Customer Service',
      detailHeader: 'Email Customer Service',
      detailMessage1: 'Send a secure and confidential e-mail to HealthEZ Customer Service.',
      detailMessage2: 'You can also call customer service by dialing 1.800.948.9450.',
    })
  }

  sendContactCallback(data) {
    let observable = false
    this.updateIsSending(true)
    this.updateIsSent(false)
    this.updateSendErrorMessage(false)
    if (this.state.showNurseCard) {
      observable = contactService.emailNurse(data, this.state.subscriberInfo.subscriberPK)
    } else if (this.state.showCaseCard) {
      observable = contactService.emailCaseManagement(data, this.state.subscriberInfo.subscriberPK)
    } else {
      observable = contactService.emailCustomerService(data, this.state.subscriberInfo.subscriberPK)
    }
    observable.subscribe(
      (x) => {
        console.log(x)
        if (x.isSuccessful) {
          // fire successful message
          this.updateIsSending(false)
          this.updateIsSent(true)
          this.updateSendErrorMessage(false)
          this.timeoutID = setTimeout(() => {
            this.updateIsSent(false)
          }, 10000)
        }
      },
      (error) => {
        console.log(error)
        this.updateIsSending(false)
        this.updateIsSent(false)
        this.updateSendErrorMessage(true)
        this.timeoutID = setTimeout(() => {
          this.updateSendErrorMessage(false)
        }, 20000)
      }
    )
  }

  render() {
    return (
      <Container className="p-4">
        <div className="card bg-primary-light">
          <div className="card-body">
            <h3 className="card-section-header">Contact Us</h3>
            <div className="dropdown">
              <button
                onClick={this.toggleCustomerCard}
                className={'btn profile-button btn-sm fw-7 w-3 mr-2' + (this.state.showCustomerCard ? ' active' : '')}
              >
                Customer Service
              </button>
              <button
                onClick={this.toggleNurseCard}
                className={'btn profile-button btn-sm fw-7 w-3 mr-2' + (this.state.showNurseCard ? ' active' : '')}
              >
                Help Line
              </button>
              <button
                onClick={this.toggleCaseCard}
                className={'btn profile-button btn-sm fw-7 w-3 mr-2' + (this.state.showCaseCard ? ' active' : '')}
              >
                Case Manager
              </button>
            </div>
            <br />
            <ContactPageTab
              header={this.state.detailHeader}
              message1={this.state.detailMessage1}
              message2={this.state.detailMessage2}
            />
            <EmailForm submitCallback={this.sendContactCallback} />
          </div>
        </div>
      </Container>
    )
  }
}
