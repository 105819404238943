import React from 'react'
import { memberService } from '../../services/MemberService'
import { DeductibleGraphCard } from '../cards/DeductibleGraphCard'
import { Subscription } from 'rxjs'
import { LoadingCard } from '../cards/LoadingCard'
/**
 * Deductible Class.
 */
export class DeductiblePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dependents: false,
    }

    this.subscription = new Subscription()
  }

  // getAccumulatorData = dependent => memberService.fetchDeductibleInfo(dependent.dependentPK).pipe(map(x => x.result));

  componentDidMount() {
    this.subscription.add(
      memberService.subscriberInfo().subscribe((subscriber) => {
        console.log(subscriber.dependents)
        this.setState({ dependents: subscriber.dependents })
        console.log(this.state.dependents)
        if (this.state.dependents == null) {
          this.setState({
            dependents: [
              {
                dependentPK: subscriber.dependentPK,
                firstName: subscriber.firstName,
                lastName: subscriber.lastName,
                relation: 'Dependent',
              },
            ],
          })
        }
        console.log(this.state.dependents)
        this.forceUpdate()
      })
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    if (this.state.dependents) {
      let sortedDependents = [...this.state.dependents] // Create a copy of the dependents array
      sortedDependents.sort((a, b) => {
        if (a.relation === 'Insured') return -1 // Insured comes first
        if (a.relation === 'Spouse' && b.relation !== 'Insured') return -1 // Spouse comes before other dependents
        if (a.relation === 'Child' && b.relation === 'Child') {
          // Sort child dependents by age/birthday
          const aBirthday = new Date(a.DOB)
          const bBirthday = new Date(b.DOB)
          return aBirthday - bBirthday
        }
        return 1 // Default case: b comes before a
      })

      let familyDeductibles
      if (sortedDependents.length > 1) {
        familyDeductibles = (
          <DeductibleGraphCard key={'0'} dependentInfo={sortedDependents[0]} includeFamily={true}></DeductibleGraphCard>
        )
      } else {
        familyDeductibles = <></>
      }

      return (
        <div className="mt-4">
          <h3 className="text-light fw-7">View Deductibles</h3>
          <div className="stacked-dependents">
            {' '}
            {/* Add a CSS class for stacked dependents */}
            {sortedDependents.map((dependent) => (
              <div key={dependent.dependentPK.toString()} className="dependent-card">
                {' '}
                {/* Add a CSS class for dependent card */}
                <DeductibleGraphCard dependentInfo={dependent} includeFamily={false}></DeductibleGraphCard>
              </div>
            ))}
            {familyDeductibles}
          </div>
        </div>
      )
    }

    return <LoadingCard />
  }
}
