import { memberService } from '../../services/MemberService'
import { Subscription, forkJoin, switchMap } from 'rxjs'
import React, { useState, useEffect, useMemo, useContext } from 'react'
import Box from '@mui/material/Box'
import { Skeleton, Typography, styled } from '@mui/material'
import WelcomeIcon from './WelcomeIcon.js'
import Stack from '@mui/material/Stack'
import HEZLogoGreenWhite from '../../HEZLogoGreenWhite.png'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthProvider.js'

const Img = styled('img')(() => ({
  marginLeft: 0,
  marginRight: 30,
  marginTop: 10,
  marginBottom: 20,
}))

export const AddressCard = (props) => {
  if (props.address) {
    return (
      <div className="profile-content pt-2 profile-section">
        <span className="no-h card-profile-detail"> {props.address.address1}</span>
        {props.address.address2 && <span className="no-h card-profile-detail"> {props.address.address2}</span>}
        <span className="no-h card-profile-detail">
          {props.address.city}, {props.address.state} {props.address.zipCode}
        </span>
      </div>
    )
  } else {
    return ''
  }
}

function capitalizeFirstLetter(string) {
  if (!string) {
    return
  }

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

function ProfileCardF() {
  const authContext = useContext(AuthContext)
  const subscription = useMemo(() => new Subscription(), [])
  const [userInfo, setUserInfo] = useState()
  const [planInformation, setPlanInformation] = useState()
  const [primaryNetwork, setPrimaryNetwork] = useState('')

  const history = useHistory()

  useEffect(() => {
    subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            setUserInfo(x)
            return forkJoin({
              subscriberPlans: memberService.fetchPlanList(x.subscriberPK),
              dependentPlans: memberService.fetchPlanList(x.subscriberPK, x.dependentPK),
              resources: memberService.fetchMemberResources(x.subscriberPK, x.dependentPK),
            })
          })
        )
        .subscribe((x) => {
          const subscriberPlans = x.subscriberPlans?.data?.result
          const now = new Date()
          const subscriberMedicalPlans = subscriberPlans?.filter((plan) => {
            const coverageEndDate = new Date(plan.coverageEndDate)
            const coverageBeginDate = new Date(plan.coverageBeginDate)
            return (
              plan.planType === 'Medical' &&
              now >= coverageBeginDate &&
              now < coverageEndDate &&
              coverageEndDate > coverageBeginDate
            )
          })

          const dependentPlans = x.dependentPlans?.data?.result

          if (subscriberMedicalPlans?.length > 0) {
            setPlanInformation(dependentPlans)
          } else {
            setPlanInformation(dependentPlans?.filter((plan) => plan.planType !== 'Medical'))
          }

          setPrimaryNetwork(
            x.resources?.data?.result?.find((resource) => resource.resourceType === 'Medical Network')?.resourceName ?? ''
          )
        })
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [subscription])

  if (userInfo) {
    return (
      <Box
        sx={(theme) => ({
          width: { xs: 423, lg: 807 },
          color: '#FFFFFF',
          bgcolor: '#121629',
          borderRadius: '15px',
          padding: '40px 20px 20px 40px',
          font: '#FFFFFF',
          fontSize: '.7rem',
          [theme.breakpoints.down('lg')]: {
            padding: '20px',
          },
          [theme.breakpoints.down('lg')]: {
            padding: '20px',
          },
        })}
      >
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-end" width={'100%'} maxWidth={'400px'}>
          <Typography sx={{ fontWeight: '700', fontSize: '62px', lineHeight: '75px' }}>
            Welcome, {capitalizeFirstLetter(userInfo.firstName)}
            <WelcomeIcon sx={{ fontSize: '70px' }} />
          </Typography>
        </Stack>

        <Stack direction={{ xs: 'column', lg: 'row' }} spacing={2} sx={{ mt: '5px' }}>
          <Box
            sx={(theme) => ({
              maxWidth: '341px',
              minHeight: { sm: 187 },
              bgcolor: '#1C2033',
              borderRadius: '15px',
              padding: '20px',
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },
            })}
          >
            <Img src={HEZLogoGreenWhite} alt={'logo'} width="103" height="27" sx={{ display: { xs: 'flex', sm: 'none' } }}></Img>
            <Img src={HEZLogoGreenWhite} alt={'logo'} width="130" height="31" sx={{ display: { xs: 'none', sm: 'flex' } }}></Img>
            <Stack
              direction="row"
              sx={(theme) => ({
                gap: '12px',
                [theme.breakpoints.up('md')]: {
                  gap: '24px',
                },
              })}
            >
              <Stack direction="column" alignItems="flex-start">
                <Typography
                  sx={(theme) => ({
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '26px',
                    mb: 1,
                    flexGrow: '1',
                    [theme.breakpoints.up('md')]: {
                      fontWeight: '700',
                      fontSize: '18px',
                      lineHeight: '21.6px',
                    },
                  })}
                >
                  {[capitalizeFirstLetter(userInfo.firstName), capitalizeFirstLetter(userInfo.lastName)]
                    .filter(Boolean)
                    .join(' ')}
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '19.6px',
                    mb: 1,
                    [theme.breakpoints.up('md')]: {
                      fontSize: '11px',
                      lineHeight: '16px',
                    },
                  })}
                >
                  {`Group ID: ${userInfo.employerGroupGroupId}`}
                </Typography>
                <Typography
                  sx={(theme) => ({
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '19.6px',
                    mb: 1,
                    [theme.breakpoints.up('md')]: {
                      fontSize: '11px',
                      lineHeight: '16px',
                    },
                  })}
                >
                  {`Member ID: ${userInfo.subscriberId}`}
                </Typography>

                {planInformation
                  ?.filter((plan) => plan.planType)
                  .map((plan) => (
                    <Typography
                      key={plan.planPK}
                      sx={(theme) => ({
                        fontWeight: '500',
                        fontSize: '12px',
                        marginRight: '-50px',
                      })}
                    >
                      {plan.planType}: Employee {plan.isFamily ? 'plus Family' : 'Only'}
                    </Typography>
                  ))}
              </Stack>

              {/* // primary network Box - right column */}
              <Box
                sx={(theme) => ({
                  width: '75px',
                  [theme.breakpoints.up('md')]: {
                    width: '95px',
                  },
                })}
              >
                <Stack direction="column" alignItems="flex-start">
                  <Typography
                    sx={(theme) => ({
                      fontWeight: '500',
                      fontSize: '7px',
                      lineHeight: '16px',
                      marginTop: '8px',
                      [theme.breakpoints.up('md')]: {
                        fontSize: '10px',
                        lineHeight: '30px',
                        marginTop: '0',
                      },
                    })}
                  >
                    PRIMARY NETWORK
                  </Typography>
                  <Typography
                    sx={(theme) => ({
                      fontWeight: '500',
                      fontSize: '10px',
                      lineHeight: '16px',
                      marginTop: '8px',
                      [theme.breakpoints.up('md')]: {
                        fontSize: '12px',
                        lineHeight: '12px',
                        marginTop: '0',
                      },
                    })}
                  >
                    {primaryNetwork}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>

          {authContext.isCostEstimatorVisible && !authContext.isDependent() ? (
            <Box
              sx={(theme) => ({
                p: 3,
                minHeight: { xs: 162, sm: 187 },
                width: { sm: 375 },
                bgcolor: '#1C2033',
                borderRadius: '15px',
                padding: '29px',
                paddingTop: '22px',
                font: '#FFFFFF',
                [theme.breakpoints.down('md')]: {
                  width: '100%',
                },
              })}
            >
              <Stack direction={{ xs: 'column', sm: 'row', width: '100%' }} justifyContent="space-between" spacing={1}>
                <Typography sx={{ fontWeight: '700', fontSize: '20px', mt: '0' }}>EZpay</Typography>
                <Button
                  variant="outlined"
                  disableElevation
                  disableRipple
                  onClick={() => window.open('https://healthez.com/ezpay', '_blank')}
                  sx={{
                    height: '30px',
                    textTransform: 'none',
                    outlineColor: '#66D9A9',
                    color: '#FFFFFF',
                    fontSize: '12px',
                    fontWeight: '600',
                    borderRadius: '8px',
                    px: '13px',
                  }}
                >
                  See how EZpay works
                </Button>
              </Stack>

              <Typography
                sx={(theme) => ({
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '19.6px',
                  textAlign: 'left',
                  marginTop: '4px',
                  marginBottom: '16px',
                  [theme.breakpoints.up('md')]: {
                    marginBottom: '10px',
                  },
                })}
              >
                EZpay is the smart, simple way to view your medical bills, see exactly what your insurance will cover, and pay
                what you owe with just a few clicks.
              </Typography>
              <Stack
                direction={{ xs: 'column', sm: 'row', width: '100%' }}
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
              >
                <Button
                  variant="contained"
                  disableElevation
                  disableRipple
                  onClick={() => history.push('/ezpayaccounts')}
                  sx={{
                    height: '30px',
                    textTransform: 'none',
                    bgcolor: '#66D9A9',
                    color: '#1C2033',
                    fontSize: '12px',
                    fontWeight: '600',
                    borderRadius: '8px',
                    px: '13px',
                    '&:hover': {
                      backgroundColor: '#00BF6F',
                    },
                  }}
                >
                  Get started today
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  disableRipple
                  onClick={() => history.push('/billlist')}
                  sx={{
                    height: '30px',
                    textTransform: 'none',
                    bgcolor: '#66D9A9',
                    color: '#1C2033',
                    fontSize: '12px',
                    fontWeight: '600',
                    borderRadius: '8px',
                    px: '13px',
                    '&:hover': {
                      backgroundColor: '#00BF6F',
                    },
                  }}
                >
                  EZpay Bills
                </Button>
              </Stack>
            </Box>
          ) : (
            <></>
          )}
        </Stack>
      </Box>
    )
  } else {
    return <Skeleton height="600px" />
  }
}

export default ProfileCardF
