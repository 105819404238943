import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function MostRecentClaimsIcon() {
  return (
    <SvgIcon>
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="30" r="30" fill="#E1CD00" />
        <path
          d="M19.1666 22.7396C21.5625 19.4062 25.5208 17.1667 30 17.1667C37.3437 17.1667 43.3333 23.1562 43.3333 30.5C43.3333 37.8958 37.3437 43.8333 30 43.8333C27.2395 43.8333 24.7395 43.0521 22.6041 41.6458C22.0312 41.2813 21.875 40.5 22.2916 39.9271C22.6562 39.3542 23.4375 39.1979 24.0104 39.5625C25.7291 40.7083 27.7604 41.3333 30 41.3333C35.9375 41.3333 40.8333 36.4896 40.8333 30.5C40.8333 24.5625 35.9375 19.6667 30 19.6667C26.1458 19.6667 22.7604 21.6979 20.8333 24.6667H23.75C24.427 24.6667 25 25.2396 25 25.9167C25 26.6458 24.427 27.1667 23.75 27.1667H17.9166C17.1875 27.1667 16.6666 26.6458 16.6666 25.9167V20.0833C16.6666 19.4062 17.1875 18.8333 17.9166 18.8333C18.5937 18.8333 19.1666 19.4062 19.1666 20.0833V22.7396ZM30 23.8333H29.9479C30.677 23.8333 31.1979 24.4062 31.1979 25.0833V30.0312L34.5833 33.4167C35.1041 33.8854 35.1041 34.6667 34.5833 35.1354C34.1145 35.6562 33.3333 35.6562 32.8645 35.1354L29.1145 31.3854C28.8541 31.1771 28.75 30.8646 28.75 30.5V25.0833C28.75 24.4062 29.2708 23.8333 30 23.8333Z"
          fill="#6F6604"
        />
      </svg>
    </SvgIcon>
  )
}
