import { map } from 'rxjs/operators'
import Axios from '../upstream/axios-observable/dist'
import { serviceConfig } from './ServiceConfig'

const serviceBaseUrl = serviceConfig.surveyServiceUrl + serviceConfig.surveyServiceVersion

export const surveyService = {
  getSurvey: (surveyName) => Axios.get(serviceBaseUrl + '/survey/GetSurvey?SurveyName=' + surveyName),
  sendSurvey: (data, patientPk) =>
    Axios.post(serviceBaseUrl + '/survey/StoreSurvey', data, { params: { patientPk: patientPk } }).pipe(map((x) => x.data)),
  NeedSurvey: (surveyName, patientPk) =>
    Axios.get(serviceBaseUrl + '/survey/NeedSurvey?surveyName=' + surveyName + '&patientPk=' + patientPk),
}
