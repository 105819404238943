import React, { PureComponent } from 'react'

export class LoadingCard extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      loadingFailed: false,
      tinyLoad: props.tinyLoad,
    }
  }

  componentDidMount() {
    this.timeoutID = setTimeout(() => {
      this.setState({ loadingFailed: true })
    }, 30000)
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID)
  }

  render() {
    if (!this.state.loadingFailed) {
      if (this.state.tinyLoad) {
        return (
          // tiny load
          <div className="spinner tiny-load"></div>
        )
      } else {
        return (
          // reg size load
          <div className="spinner"></div>
        )
      }
    } else {
      return (
        <>
          <hr className="border border-white" />
          <span className="text-danger fw-7 h6">Loading Failed... please refresh your page!</span>
        </>
      )
    }
  }
}
