import React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faRotateLeft } from '@fortawesome/pro-light-svg-icons'
import { STEPS_CONFIG } from '../../constants'
import Step from './Step'
import { getStepState } from './helpers'
import MediaQuery from 'react-responsive'
import { Stack } from '@mui/material'

const NavigationPanel = ({
  values,
  activeStepIndex,
  onNextClick,
  onBackClick,
  onRefreshClick,
  isNextDisabled,
  isRestartDisabled,
}) => {
  return (
    <div className="border-bottom px-3 pt-3 pb-3">
      <h3 className="ezchoice-title fw-6 mb-0 pt-2 text-light">EZchoice</h3>
      <MediaQuery minDeviceWidth={769}>
        <div className="d-flex justify-content-between align-items-top">
          <div className="d-flex step-wrapper align-items-baseline pt-2">
            {STEPS_CONFIG.map((step, idx) => {
              const value = step.displayValueProp ? values?.[step.name]?.[step.displayValueProp] : values?.[step.name]
              return (
                <React.Fragment key={`navigation-step-${idx}`}>
                  {idx !== 0 && <div className="mx-2 bg-dark step-separator" />}
                  <Step title={step.title} state={getStepState(idx, activeStepIndex)} value={value} />
                </React.Fragment>
              )
            })}
          </div>
          <div className="d-flex navigation-panel-buttons">
            <Button color="link" onClick={onRefreshClick} className="d-flex mr-3" disabled={isRestartDisabled}>
              <FontAwesomeIcon size="lg" icon={faRotateLeft} className="mr-2" />
              Restart
            </Button>
            {activeStepIndex !== 0 && (
              <Button color="link" onClick={onBackClick} className="d-flex mr-3" disabled={isRestartDisabled}>
                <FontAwesomeIcon size="lg" icon={faAngleLeft} className="mr-2" />
                Back
              </Button>
            )}
            {activeStepIndex !== STEPS_CONFIG.length - 1 && (
              <Button color="primary" onClick={onNextClick} disabled={isNextDisabled} className="text-black ez-choice-btn">
                Next
              </Button>
            )}
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={768}>
        <div className="d-flex flex-column justify-content-between align-items-top">
          <div className="d-flex flex-column align-items-start step-wrapper py-3">
            {STEPS_CONFIG.map((step, idx) => {
              const value = step.displayValueProp ? values?.[step.name]?.[step.displayValueProp] : values?.[step.name]
              return (
                <React.Fragment key={`navigation-step-${idx}`}>
                  {idx !== 0 && <div className="mx-2 bg-dark step-separator" />}
                  <Step title={step.title} state={getStepState(idx, activeStepIndex)} value={value} />
                </React.Fragment>
              )
            })}
          </div>
          <div className="d-flex justify-content-end navigation-panel-buttons">
            <Button color="link" onClick={onRefreshClick} className="d-flex mr-3" disabled={isRestartDisabled}>
              <FontAwesomeIcon size="lg" icon={faRotateLeft} className="mr-2" />
              Restart
            </Button>
            {activeStepIndex !== 0 && (
              <Button color="link" onClick={onBackClick} className="d-flex mr-3" disabled={isRestartDisabled}>
                <FontAwesomeIcon size="lg" icon={faAngleLeft} className="mr-2" />
                Back
              </Button>
            )}
            {activeStepIndex !== STEPS_CONFIG.length - 1 && (
              <Button color="primary" onClick={onNextClick} disabled={isNextDisabled} className="text-black ez-choice-btn">
                Next
              </Button>
            )}
          </div>
        </div>
      </MediaQuery>
    </div>
  )
}

export default NavigationPanel
