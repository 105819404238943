/* /src/routes/privateRoute.jsx */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthConsumer } from './AuthProvider'

export const PrivateRoute = ({ component, roles, isCostEstimatorPage, ...rest }) => {
  const renderFn = (Component) => (props) => (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect, getMemberRole, isCostEstimatorVisible }) => {
        if (isCostEstimatorPage && isCostEstimatorVisible === null && isAuthenticated()) {
          return <span>loading</span>
        }
        // eslint-disable-next-line
        if ((roles && getMemberRole() != 'Subscriber') || (isCostEstimatorPage && !isCostEstimatorVisible && isAuthenticated())) {
          //member is not autherized for this page
          return <Redirect to={{ pathname: '/' }} />
        }
        if (!!Component && isAuthenticated()) {
          return <Component {...props} />
        } else {
          signinRedirect()
          return <span>loading</span>
        }
      }}
    </AuthConsumer>
  )

  return <Route {...rest} render={renderFn(component)} />
}
