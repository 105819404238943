import React, { PureComponent } from 'react'
import { memberService } from '../../services/MemberService'
import { Subscription } from 'rxjs'
import BootstrapTable from 'fad-react-bootstrap-table-next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownToBracket } from '@fortawesome/pro-solid-svg-icons'
import { saveAs } from 'file-saver'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { options } from '../global/TableOptions'
import 'fad-react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { dateFormatter } from '../global/TextHelpers'

export class StatementsTableCard extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      statementList: props.data.statementList,
      subscriberPK: props.data.subscriberPK,
      isPage: props.isPage,
    }
    this.downloadStatementPDF = this.downloadStatementPDF.bind(this)
    this.downloadEOBPDF = this.downloadEOBPDF.bind(this)
    this.subscription = new Subscription()
  }

  cardColumns = [
    {
      dataField: 'statementIdentifier',
      text: 'Statements',
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
    },
    {
      dataField: 'summary',
      text: 'Summary',
      formatter: (cell, row, rowIndex, extraData) => (
        <button
          className="icon_blue"
          onClick={() => {
            this.downloadStatementPDF(row)
          }}
        >
          {' '}
          <FontAwesomeIcon icon={faArrowDownToBracket} className="icon_blue" />
        </button>
      ),
      headerAlign: 'center',
      headerClasses: 'table-header',
    },
    {
      dataField: 'detailed',
      text: 'Detailed',
      formatter: (cell, row, rowIndex, extraData) =>
        row.isEob ? (
          <button
            className="icon_blue"
            onClick={() => {
              this.downloadEOBPDF(row)
            }}
          >
            {' '}
            <FontAwesomeIcon icon={faArrowDownToBracket} className="icon_blue" />
          </button>
        ) : (
          <p className="text-primary-orange fw-7">RX Only</p>
        ),
      headerAlign: 'center',
      headerClasses: 'table-header',
    },
  ]

  pageColumns = [
    {
      dataField: 'statementIdentifier',
      text: 'Statements',
      headerAlign: 'center',
      headerClasses: 'table-header',
      classes: 'table-cell',
      sort: true,
    },
    {
      dataField: 'summary',
      text: 'Summary',
      formatter: (cell, row, rowIndex, extraData) => (
        <button
          className="icon_blue"
          onClick={() => {
            this.downloadStatementPDF(row)
          }}
        >
          {' '}
          <FontAwesomeIcon icon={faArrowDownToBracket} className="icon_blue" />
        </button>
      ),
      headerAlign: 'center',
      headerClasses: 'table-header',
    },
    {
      dataField: 'detailed',
      text: 'Detailed',
      formatter: (cell, row, rowIndex, extraData) =>
        row.isEob ? (
          <button
            className="icon_blue"
            onClick={() => {
              this.downloadEOBPDF(row)
            }}
          >
            {' '}
            <FontAwesomeIcon icon={faArrowDownToBracket} className="icon_blue" />
          </button>
        ) : (
          <p className="text-primary-orange fw-7">RX Only</p>
        ),
      headerAlign: 'center',
      headerClasses: 'table-header',
    },
  ]

  downloadStatementPDF(row) {
    this.subscription.add(
      memberService.fetchStatement(row.statementDate, this.state.subscriberPK).subscribe((statement) => {
        const blob = new Blob([statement.data], { type: 'application/pdf' })
        saveAs(blob, dateFormatter(row.statementDate, 'iso8601') + '_statement_' + this.state.subscriberPK + '.pdf', true)

        // Takes Blob, File, or MediaSource object ot create an object URL:
        const url = URL.createObjectURL(blob)
        window.open(url)
      })
    )
  }
  downloadEOBPDF(row) {
    this.subscription.add(
      memberService.fetchEOB(row.statementDate, this.state.subscriberPK).subscribe((statement) => {
        const blob = new Blob([statement.data], { type: 'application/pdf' })
        saveAs(blob, dateFormatter(row.statementDate, 'iso8601') + '_eob_' + this.state.subscriberPK + '.pdf', true)
        const url = URL.createObjectURL(blob)
        window.open(url)
      })
    )
  }
  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    if (this.state.isPage) {
      return (
        // Page
        <div className="centered-table">
          <BootstrapTable
            bootstrap4
            keyField="statementDate"
            data={this.state.statementList}
            columns={this.pageColumns}
            bordered={false}
            pagination={paginationFactory(options)}
          />
        </div>
      )
    } else {
      return (
        // Card
        <div className="centered-table">
          <BootstrapTable
            bootstrap4
            keyField="statementDate"
            data={this.state.statementList}
            columns={this.cardColumns}
            bordered={false}
          />
        </div>
      )
    }
  }
}
