import React, { useEffect, useMemo } from 'react'
import Box from '@mui/material/Box'
import { CircularProgress, MenuItem, Stack, Typography } from '@mui/material'
import Select from '@mui/material/Select'
import { styled } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { Subscription, switchMap } from 'rxjs'
import { memberService } from '../../services/MemberService'
import { colors } from '../design-lib/Colors'

const CustomMenuItem = styled(MenuItem)(() => ({
  fontColor: '#0000FF',
  backgroundColor: '#31354B',
  '& .Mui-selected': { backgroundColor: '#0000FF' },
}))

const getPlanInfoValues = (maxDeductible, spent) => {
  if (!maxDeductible || !spent) {
    return {
      remaining: 100,
      spent: 0,
    }
  }
  const currentSpent = maxDeductible > spent ? spent : maxDeductible
  let spentPercentage = Math.round((currentSpent / maxDeductible) * 100)

  return {
    remaining: 100 - spentPercentage,
    spent: spentPercentage,
  }
}

const PIE_COLORS = [
  { remainingColor: colors.green600, spentColor: colors.green400 },
  { remainingColor: colors.cranberry600, spentColor: colors.cranberry400 },
  { remainingColor: colors.pink600, spentColor: colors.pink300 },
  { remainingColor: colors.purple600, spentColor: colors.purple400 },
]

export default function PlanSummaryCard() {
  const subscription = useMemo(() => new Subscription(), [])
  const [activePlanSelected, setActivePlan] = React.useState(0)
  const [userPlanInformation, setUserPlanInformation] = React.useState({})

  const handleSelectDeductible = (event) => {
    setActivePlan(event.target.value)
  }

  useEffect(() => {
    subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            setUserPlanInformation((userPlanInformation) => ({
              ...userPlanInformation,
              insuredCount: x.dependents ? x.dependents.length : 0,
            }))

            return memberService.fetchDeductibleInfo(x.dependentPK)
          })
        )
        .subscribe((y) => {
          setUserPlanInformation((userPlanInformation) => ({
            ...userPlanInformation,
            deductibleInfo: y.result,
            callSucceeded: y.isSuccessful,
            isFSAOnly: false,
          }))
        })
    )

    subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            setUserPlanInformation((userPlanInformation) => ({
              ...userPlanInformation,
              subscriberPK: x.subscriberPK,
            }))
            return memberService.fetchPlanList(x.subscriberPK)
          })
        )
        .subscribe((x) => {
          const fsaPlans = x.data.result.filter((x) => x.coverage === 'FSA')
          setUserPlanInformation((userPlanInformation) => ({
            ...userPlanInformation,
            fsaPlans: fsaPlans,
            allPlans: x.data.result,
            isFSAOnly: fsaPlans?.length === x.data.result?.length,
          }))
        })
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [subscription])

  const planOptions = useMemo(() => {
    let options = []

    if (!userPlanInformation.deductibleInfo) {
      return options
    }

    if (userPlanInformation.deductibleInfo.is3TierPlan) {
      options.push({
        value: 0,
        label: 'In Network Tier 1 Deductible',
        infos: getPlanInfoValues(
          userPlanInformation.deductibleInfo.tier1MemInnDedMax,
          userPlanInformation.deductibleInfo.tier1MemInnDed
        ),
      })
      options.push({
        value: 1,
        label: 'In Network Tier 1 Out of Pocket',
        infos: getPlanInfoValues(
          userPlanInformation.deductibleInfo.tier1MemInnOopMax,
          userPlanInformation.deductibleInfo.tier1MemInnOop
        ),
      })
    }
    if (userPlanInformation.deductibleInfo.individualInNetworkDeductible > 0) {
      options.push({
        value: 2,
        label: userPlanInformation.deductibleInfo.is3TierPlan ? 'In Network Tier 2 Deductible' : 'In Network Deductible',
        infos: getPlanInfoValues(
          userPlanInformation.deductibleInfo.individualInNetworkDeductible,
          userPlanInformation.deductibleInfo.inNetworkDeductible
        ),
      })
    }
    if (userPlanInformation.deductibleInfo.individualInNetworkOutOfPocket > 0) {
      options.push({
        value: 3,
        label: userPlanInformation.deductibleInfo.is3TierPlan ? 'In Network Tier 2 Out of Pocket' : 'In Network Out of Pocket',
        infos: getPlanInfoValues(
          userPlanInformation.deductibleInfo.individualInNetworkOutOfPocket,
          userPlanInformation.deductibleInfo.inNetworkOutOfPocket
        ),
      })
    }

    return options
  }, [userPlanInformation.deductibleInfo])

  useEffect(() => {
    if (planOptions.length) {
      setActivePlan(planOptions[0].value)
    }
  }, [planOptions])

  const activePlanIndex = planOptions.findIndex((option) => option.value === activePlanSelected)
  const activePlanWithInfo = planOptions[activePlanIndex] ?? {}
  const { remainingColor, spentColor } = PIE_COLORS[activePlanIndex] ?? {}

  return !userPlanInformation.isFSAOnly && planOptions.length ? (
    <Box
      sx={{
        height: 434,
        width: 334,
        flexShrink: 0,
        color: '#FFFFFF',
        bgcolor: '#121629',
        borderRadius: '15px',
        padding: '30px',
      }}
    >
      <Typography sx={{ fontWeight: '600', fontSize: '21px', lineHeight: '29px', mb: '20px' }}>Plan Summary</Typography>

      {userPlanInformation.deductibleInfo ? (
        <>
          <FormControl fullWidth>
            <Select
              variant="outlined"
              labelId="deductible-select"
              id="deductible-select"
              value={activePlanSelected}
              onChange={handleSelectDeductible}
              autoWidth={true}
              sx={{ borderRadius: '10px', height: '41px', border: '1px solid #FFF' }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: '#31354B',
                    '& .MuiPaper-elevation': { elevation: '0' },
                  },
                },
              }}
            >
              {planOptions.map((option) => (
                <CustomMenuItem value={option.value}>{option.label}</CustomMenuItem>
              ))}
            </Select>
          </FormControl>

          <Stack justifyContent="center" alignItems="center" position="relative" sx={{ my: 5 }}>
            <CircularProgress variant="determinate" value={100} sx={{ color: remainingColor }} thickness={5} size={150} />
            <CircularProgress
              variant="determinate"
              size={150}
              thickness={5}
              sx={{
                position: 'absolute',
                top: 0,
                color: spentColor,
                svg: {
                  strokeLinecap: 'round',
                },
              }}
              value={activePlanWithInfo?.infos?.spent ?? 0}
            />
          </Stack>

          <Stack direction="row" gap={1} alignItems="center" width="100%">
            <Box sx={{ borderRadius: '50%', height: '12px', width: '12px', backgroundColor: spentColor }} />
            <Typography color="#FFFFFF" sx={{ fontWeight: '400', fontSize: '18px', lineHeight: '25px', my: '5px', flexGrow: 1 }}>
              Deductible Met
            </Typography>
            <Typography color="#FFFFFF" sx={{ fontWeight: '700', fontSize: '18px', lineHeight: '22px', my: '5px' }}>
              {activePlanWithInfo?.infos?.spent ?? 0}%
            </Typography>
          </Stack>
          <Stack direction="row" gap={1} alignItems="center" width="100%">
            <Box sx={{ borderRadius: '50%', height: '12px', width: '12px', backgroundColor: remainingColor }} />
            <Typography color="#FFFFFF" sx={{ fontWeight: '400', fontSize: '18px', lineHeight: '25px', my: '5px', flexGrow: 1 }}>
              Remaining
            </Typography>
            <Typography color="#FFFFFF" sx={{ fontWeight: '700', fontSize: '18px', lineHeight: '22px', my: '5px' }}>
              {activePlanWithInfo?.infos?.remaining ?? 0}%
            </Typography>
          </Stack>
        </>
      ) : (
        <Typography
          variant="subtitle2"
          color="#FFFFFF"
          fontFamily="Poppins"
          sx={{ fontWeight: '400', fontSize: '18px', lineHeight: '25px', my: '5px' }}
        >
          Deductible Information Not Found
        </Typography>
      )}
    </Box>
  ) : (
    <></>
  )
}
