import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Privacy } from '../Privacy'

export class PrivacyModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      crop: {
        aspect: 1,
        width: 211,
        height: 211,
      },
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  render() {
    return (
      <Modal id="PrivacyModal" isOpen={this.state.modal} toggle={this.toggle} centered={true} className={this.props.className}>
        <ModalHeader toggle={this.toggle}>Tracking Opt-Out</ModalHeader>
        <ModalBody>
          <Privacy />
        </ModalBody>
      </Modal>
    )
  }
}
