import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function ClaimCenterIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
        <path
          d="M14.8 20.48H6.28C5.0212 20.48 4 19.46 4 18.2V7.28C4 6.02 5.0212 5 6.28 5H24.5212C25.78 5 26.8 6.02 26.8 7.28V13.4"
          stroke="currentColor"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.4153 10.965C18.5277 12.0774 18.5277 13.8822 17.4153 14.9958C16.3017 16.1082 14.4969 16.1082 13.3845 14.9958C12.2721 13.8834 12.2721 12.0786 13.3845 10.965C14.4969 9.85136 16.3017 9.85136 17.4153 10.965"
          stroke="currentColor"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8.80034 17.0002H7.60034"
          stroke="currentColor"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M23.2 8.60009H22" stroke="currentColor" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M23.1997 26.6001C20.5489 26.6001 18.3997 24.4521 18.3997 21.8013C18.3997 19.2045 20.6077 16.9989 23.2045 17.0001C25.8529 17.0025 27.9997 19.1505 27.9997 21.8001C27.9997 24.4509 25.8517 26.6001 23.1997 26.6001"
          stroke="currentColor"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24.6403 21.8002H22.7203V19.8802"
          stroke="currentColor"
          stroke-width="1.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
