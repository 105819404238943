import React, { useEffect } from 'react'
import _ from 'lodash'
import { faHouseMedical } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormatCurrency, formatMiles } from '../../../../../../global/TextHelpers'
import Pagination from '../../../generic/Pagination'
import ResultItem from '../../../generic/ResultItem'
import { getShortAddress, joinStrings } from './helpers'
import { EZCHOICE_PAGE_SIZE } from '../../../../constants'

const ElementsList = ({ data, activeProvider, onProviderClick, isPrecert, activePage, setActivePage, isFacility }) => {
  const noOfResults = data.length
  const pageSize = EZCHOICE_PAGE_SIZE
  const noOfPages = Math.ceil(noOfResults / pageSize)

  const scrollTop = () => {
    const scrollDiv = document.getElementById('scroll-top-div')
    if (scrollDiv) {
      scrollDiv.scrollTop = 0
    }
  }

  const onPageChange = (page) => {
    setActivePage(page)
    scrollTop()
  }

  useEffect(() => {
    scrollTop()
  }, [isFacility, data])

  const renderProvider = (
    {
      provider: { firstName, lastName },
      totalCost,
      distance: { distanceInMiles },
      facility: {
        name: facilityName,
        address: { city, state, zip },
      },
    },
    idx
  ) => (
    <ResultItem
      className="border-left-0 border-right"
      isActive={activePage * pageSize + idx === activeProvider}
      onClick={() => onProviderClick(activePage * pageSize + idx)}
      key={`provider-${idx}`}
    >
      <div className="fw-6 mb-2 lh-1">{joinStrings(firstName, lastName)}</div>
      <div className="d-flex mb-1">
        <FontAwesomeIcon icon={faHouseMedical} className="ezchoice-icon-green mr-2" size="sm" />
        <div className="text-truncate">{facilityName || 'Not available'}</div>
      </div>
      <div className="mb-2 lh-1">{getShortAddress(city, state, zip)}</div>
      <div className="d-flex justify-content-between align-items-center lh-1">
        <span className="provider-summary__bottom">{`~${formatMiles(distanceInMiles)} miles`}</span>
        <div className="d-flex justify-content-between align-items-center">
          <span className="provider-summary__bottom mr-2">Total cost</span>
          <span className="page-title lh-1 text-light">${FormatCurrency(totalCost)}</span>
        </div>
      </div>
    </ResultItem>
  )

  const renderFacility = (
    {
      totalCost,
      distance: { distanceInMiles },
      facility: {
        name: facilityName,
        address: { city, state, zip },
      },
    },
    idx
  ) => (
    <ResultItem
      className="border-left-0 border-right"
      isActive={activePage * pageSize + idx === activeProvider}
      onClick={() => onProviderClick(activePage * pageSize + idx)}
      key={`provider-${idx}`}
    >
      <div className="fw-6 mb-2 lh-1 text-truncate">{facilityName}</div>
      <div className="mb-2 lh-1">{getShortAddress(city, state, zip)}</div>
      <div className="d-flex justify-content-between align-items-center lh-1">
        <span className="provider-summary__bottom">{`~${formatMiles(distanceInMiles)} miles`}</span>
        <div className="d-block justify-content-between align-items-center mr-1">
          {!_.isNumber(totalCost) ? (
            <span className="provider-summary__bottom">No cost provided</span>
          ) : (
            <>
              <span className="provider-summary__bottom">Total cost</span>
              <span className="page-title lh-1 text-light">${FormatCurrency(totalCost)}</span>
            </>
          )}
        </div>
      </div>
    </ResultItem>
  )

  return (
    <div className={`scrollable-results search-results__left ${isPrecert ? '' : 'no-precert'} pr-2`} id="scroll-top-div">
      {data
        ?.slice(activePage * pageSize, (activePage + 1) * pageSize)
        ?.map((item, idx) => (isFacility ? renderFacility(item, idx) : renderProvider(item, idx)))}
      <Pagination
        className="ml-3"
        activePage={activePage}
        noOfPages={noOfPages}
        setActivePage={(nextPage) => onPageChange(nextPage)}
        isShort
      />
    </div>
  )
}

export default ElementsList
