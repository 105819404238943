import React, { useState, useMemo, useEffect } from 'react'
import _ from 'lodash'
import { FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon } from 'reactstrap'
import { Subscription } from 'rxjs'
import { ezChoiceService } from '../../../../../services/EZChoiceService'
import Spinner from '../generic/Spinner'
import SessionService from '../../../../../services/SessionService'
import { useErrorToastDispatch } from '../SearchResults/ToastContext'

const Location = ({ onChange, value, isFromBack }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [validation, setValidation] = useState(isFromBack ? true : null)
  const [isLoading, setIsLoading] = useState(false)
  const [zipCode, setZipCode] = useState(value)
  const subscription = useMemo(() => new Subscription(), [])
  const sessionService = useMemo(() => new SessionService(), [])
  const toastErrorDispatch = useErrorToastDispatch()

  useEffect(() => {
    const sessionData = sessionService.getStepData()
    if (!_.isEmpty(sessionData)) {
      const { validation, isLoading, zipCode } = sessionData
      setValidation(validation)
      setIsLoading(isLoading)
      setZipCode(zipCode)
      if (zipCode.length === 5 && isLoading) {
        validateZipCodeDb(zipCode)
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isFirstLoad) {
      sessionService.saveStepData({
        validation,
        isLoading,
        zipCode,
      })
    }

    setIsFirstLoad(false)
    // eslint-disable-next-line
  }, [validation, isLoading, zipCode])

  const validateZipCodeDb = (zip) => {
    setIsLoading(true)
    subscription.add(
      ezChoiceService
        .validateZip(zip)
        .toPromise()
        .then((res) => {
          setIsLoading(false)
          if (res?.data?.code) {
            onChange(zip)
            setValidation(true)
          } else {
            onChange('')
            setValidation(false)
          }
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
          onChange('')
          toastErrorDispatch()
        })
    )
  }

  const validateZipCode = (inputVal) => {
    const zip = parseInt(inputVal, 10)
    const validZip = zip > 0 && zip <= 99950
    if (inputVal?.length !== 5) {
      setValidation(null)
      onChange('')
      return
    }
    if (validZip && inputVal?.length === 5) {
      setValidation(null)
      validateZipCodeDb(inputVal)
    } else {
      setValidation(false)
      onChange('')
    }
  }

  const onZipCodeChange = (inputVal) => {
    setZipCode(inputVal)
    validateZipCode(inputVal)
  }

  return (
    <div className="p-4 page-wrapper">
      <div className="location">
        <h5 className="page-title mb-3 text-light">Set the location to find a provider</h5>
        <div className="page-description mb-3 text-light">
          Default search distance is 10 miles. You can customize it on the next step.
        </div>
        <FormGroup className="zip-code-wrapper px-4 py-3">
          <div className="zip-code-description mb-3">Enter 5 digit ZIP code.</div>
          <InputGroup className="zip-code-input">
            <Input
              type="number"
              name="zipCode"
              placeholder="ZIP"
              value={zipCode}
              onKeyDown={(event) => {
                if (
                  !(
                    ([
                      8, 13, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104,
                      105,
                    ].includes(event.keyCode) &&
                      !event.shiftKey) ||
                    ([65, 67, 86, 88].includes(event.keyCode) && (event.ctrlKey || event.metaKey))
                  )
                ) {
                  event.preventDefault()
                }
              }}
              onChange={({ target: { value: inputValue } }) => {
                const val = inputValue === '-1' ? '0' : inputValue
                if (val?.length > 5) {
                  if (zipCode !== val.slice(0, 5)) {
                    onZipCodeChange(val.slice(0, 5))
                  }
                  return
                }
                onZipCodeChange(val)
              }}
              valid={validation === true && !isLoading ? true : null}
              invalid={validation === false && !isLoading ? true : null}
              className={`mb-2 ${isLoading ? 'small-width' : 'full-width'}`}
              disabled={isLoading}
            />
            {isLoading && (
              <InputGroupAddon addonType="prepend" className="input-group-text bg-transparent">
                <Spinner />
              </InputGroupAddon>
            )}
            <FormFeedback>
              <span>Provided ZIP code is not supported. Please try another ZIP code.</span>
            </FormFeedback>
          </InputGroup>
        </FormGroup>
      </div>
    </div>
  )
}

export default Location
