import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function FindCareIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.25004 6.5C9.45837 7 9.16671 7.58333 8.66671 7.79167L6.87504 8.45833C6.75004 8.5 6.66671 8.625 6.66671 8.79167V14.1667C6.66671 16.5833 8.58337 18.5 11 18.5H11.3334H11.6667C14.0417 18.5 16 16.5833 16 14.1667V8.79167C16 8.625 15.875 8.5 15.75 8.45833L13.9584 7.79167C13.4584 7.58333 13.1667 7 13.375 6.5C13.5834 6 14.1667 5.70833 14.6667 5.91667L16.4584 6.58333C17.375 6.91667 18 7.79167 18 8.79167V14.1667C18 17.4583 15.5 20.1667 12.3334 20.5C12.4584 23.125 14.6667 25.1667 17.3334 25.1667C20.0834 25.1667 22.3334 22.9583 22.3334 20.1667V17.0417C20.9584 16.625 20 15.3333 20 13.8333C20 12 21.4584 10.5 23.3334 10.5C25.1667 10.5 26.6667 12 26.6667 13.8333C26.6667 15.3333 25.6667 16.625 24.3334 17.0417V20.1667C24.3334 24.0417 21.1667 27.1667 17.3334 27.1667C13.5417 27.1667 10.4584 24.2083 10.3334 20.5C7.12504 20.1667 4.66671 17.4583 4.66671 14.1667V8.79167C4.66671 7.79167 5.25004 6.91667 6.16671 6.58333L7.95837 5.91667C8.50004 5.70833 9.04171 6 9.25004 6.5ZM23.3334 12.5C22.5834 12.5 22 13.125 22 13.8333C22 14.5833 22.5834 15.1667 23.3334 15.1667C24.0417 15.1667 24.6667 14.5833 24.6667 13.8333C24.6667 13.125 24.0417 12.5 23.3334 12.5Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
