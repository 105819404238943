import { styled } from '@mui/material'
import { Link } from 'react-router-dom'

const MenuButton = styled(Link)(({ isActive, theme }) => ({
  fontSize: '14px',
  lineHeight: '14px',
  textDecoration: 'none',
  fontWeight: '500',
  color: '#CCCCCC',
  display: 'flex',
  gap: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '16px',
  maxHeight: '56px',
  minHeight: '56px',
  padding: '0 30px 0 20px',
  '&:hover': { backgroundColor: '#FFFFFF', color: '#31354B', textDecoration: 'none', '& .MuiSvgIcon-root': { color: '#66D9A9' } },

  ...(isActive
    ? { backgroundColor: '#FFFFFF', color: '#31354B', textDecoration: 'none', '& .MuiSvgIcon-root': { color: '#66D9A9' } }
    : {}),

  '&:focus': { textDecoration: 'none' },
  '& .MuiSvgIcon-root': { width: '32px', height: '32px' },

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    gap: '4px',
    padding: '4px 8px',
    justifyContent: 'center',
    textAlign: 'center',
    width: 'auto',
    flexGrow: 1,
  },
}))

export default MenuButton
