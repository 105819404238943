import React from 'react'
import Select, { components } from 'react-select'

const SelectInput = ({ options, value: selectValue, onChange, className, isDisabled, prefix }) => {
  const selectedValue = options.find((it) => it.value === selectValue)
  return (
    <Select
      value={selectedValue}
      onChange={({ value }) => onChange(value)}
      options={options}
      isDisabled={isDisabled}
      className={className}
      classNamePrefix={'ez-choice-select'}
      isSearchable={false}
      components={{
        SingleValue: ({ children, ...props }) => {
          return <components.SingleValue {...props}>{prefix ? `${prefix} ` + children : children}</components.SingleValue>
        },
      }}
    />
  )
}

export default SelectInput
