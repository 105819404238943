import React, { Component } from 'react'
import { Subscription, forkJoin } from 'rxjs'
import { memberService } from '../services/MemberService'
import AuthService from './AuthService'

export const AuthContext = React.createContext({
  signinRedirectCallback: () => ({}),
  logout: () => ({}),
  getUser: () => ({}),
  getClaims: () => ({}),
  getSubscriberPK: () => ({}),
  getPatientPK: () => ({}),
  getPatientList: () => ({}),
  getSubscriberID: () => ({}),
  getRole: () => ({}),
  isCS: () => ({}),
  getMemberRole: () => ({}),
  isSubscriber: () => ({}),
  isDependent: () => ({}),
  getToken: () => ({}),
  signoutRedirectCallback: () => ({}),
  isAuthenticated: () => ({}),
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  createSigninRequest: () => ({}),
  switchMember: () => ({}),
})

export const AuthConsumer = AuthContext.Consumer

export class AuthProvider extends Component {
  authService
  constructor(props) {
    super(props)
    this.state = {
      isCostEstimatorVisible: null,
    }

    this.checkCostEstimatorVisibility = this.checkCostEstimatorVisibility.bind(this)
    this.authService = new AuthService(props)
    this.subscription = new Subscription()
  }

  componentDidMount() {
    this.checkCostEstimatorVisibility()
  }

  checkCostEstimatorVisibility = () => {
    const subscriberPk = this.authService.getSubscriberPK()
    const dependentPK = this.authService.getPatientPK()
    if (subscriberPk && dependentPK) {
      this.subscription.add(
        forkJoin({
          subscriberPlanList: memberService.fetchPlanList(subscriberPk),
          dependentPlanList: memberService.fetchPlanList(subscriberPk, dependentPK),
          ezChoiceInfo: memberService.fetchEZChoiceInfo(subscriberPk, dependentPK),
        }).subscribe((x) => {
          const subscriberPlans = x.subscriberPlanList?.data?.result
          const memberPlans = x.dependentPlanList?.data?.result
          const now = new Date()
          const subscriberMedicalPlan = subscriberPlans?.filter((plan) => {
            const coverageEndDate = new Date(plan.coverageEndDate)
            const coverageBeginDate = new Date(plan.coverageBeginDate)
            return (
              plan.planType === 'Medical' &&
              now >= coverageBeginDate &&
              now < coverageEndDate &&
              coverageEndDate > coverageBeginDate
            )
          })
          const filteredPlans = memberPlans?.filter((plan) => {
            const coverageEndDate = new Date(plan.coverageEndDate)
            const coverageBeginDate = new Date(plan.coverageBeginDate)
            return (
              plan.planType === 'Medical' &&
              now >= coverageBeginDate &&
              now < coverageEndDate &&
              coverageEndDate > coverageBeginDate
            )
          })
          const hasMedicalPlans = subscriberMedicalPlan?.length > 0 && filteredPlans?.length > 0 ? true : false

          const ezChoice = x.ezChoiceInfo?.data?.result
          const hasEZChoiceAccess = ezChoice?.hasEZChoiceAccess

          this.setState({
            isCostEstimatorVisible: hasMedicalPlans && hasEZChoiceAccess,
          })
        })
      )
    }
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          ...this.authService,
          isCostEstimatorVisible: this.state.isCostEstimatorVisible,
          checkCostEstimatorVisibility: this.checkCostEstimatorVisibility,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}
