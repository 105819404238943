import React, { useState, useMemo, useEffect } from 'react'
import _ from 'lodash'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { forkJoin, Subscription } from 'rxjs'
import ReactMarkdown from 'react-markdown'
import { ezChoiceService } from '../../../../../services/EZChoiceService'
import { memberService } from '../../../../../services/MemberService'
import { STEPS } from '../../constants'
import ElementsList from './components/ElementsList'
import { SORTING, TABS } from './constants'
import { getPrice, getSortedFacilities, getSortedProviders } from './helpers'
import AuthService from '../../../../../auth/AuthService'
import PricesBlock from './components/PricesBlock'
import ExtendedProvider from './components/ExtendedProvider'
import Disclaimer from './components/Disclaimer/Disclaimer'
import SessionService from '../../../../../services/SessionService'
import { joinStrings } from './components/ElementsList/helpers'
import Header from './components/Header'
import { useErrorToastDispatch } from './ToastContext'
import MediaQuery from 'react-responsive'

const SearchResults = ({ savedValues }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isFromSession, setIsFromSession] = useState(false)
  const [activeProvidersPage, setActiveProvidersPage] = useState(0)
  const [activeFacilitiesPage, setActiveFacilitiesPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isOONLoading, setIsOONLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [data, setData] = useState(null)
  const [filteredProviders, setFilteredProviders] = useState(null)
  const [facilities, setFacilities] = useState(null)
  const [filteredFacilities, setFilteredFacilities] = useState(null)
  const [activeTab, setActiveTab] = useState(TABS.inNetwork)
  const [activeProvider, setActiveProvider] = useState()
  const [activeFacility, setActiveFacility] = useState()
  const [outOfNetworkPrices, setOutOfNetworkPrices] = useState(null)
  const [inNetworkDisclaimer, setInNetworkDisclaimer] = useState(null)
  const [outOfNetworkDisclaimer, setOutOfNetworkDisclaimer] = useState(null)
  const [outOfNetworkNotice, setOutOfNetworkNotice] = useState(null)
  const [planEndText, setPlanEndText] = useState(null)
  const [isInOpen, setIsInOpen] = useState(false)
  const [isOutOpen, setIsOutOpen] = useState(false)
  const subscription = useMemo(() => new Subscription(), [])
  const authService = new AuthService()
  const sessionService = useMemo(() => new SessionService(), [])
  const { activeProvider: sessionActiveProvider, activeFacility: sessionActiveFacility } = sessionService.getStepData()
  const [isReportDownloading, setIsReportDownloading] = useState(false)
  const toastErrorDispatch = useErrorToastDispatch()

  useEffect(() => {
    if (!isFirstLoad) {
      sessionService.saveStepData({
        activeTab,
        activeProvidersPage,
        activeFacilitiesPage,
        activeProvider,
        activeFacility,
        outOfNetworkPrices,
        inNetworkDisclaimer,
        outOfNetworkDisclaimer,
        isOutOpen,
        isInOpen,
      })
    }

    setIsFirstLoad(false)
    // eslint-disable-next-line
  }, [
    activeTab,
    activeProvidersPage,
    activeFacilitiesPage,
    activeProvider,
    activeFacility,
    outOfNetworkPrices,
    inNetworkDisclaimer,
    outOfNetworkDisclaimer,
    isOutOpen,
    isInOpen,
  ])

  const isPrecert = savedValues?.[STEPS.careType]?.preCertIsRequired || savedValues?.[STEPS.procedure]?.preCertIsRequired

  const getProviders = ({ distance, name, facilityName, sorting }) => {
    setIsLoading(true)
    const {
      location,
      careType: { id: careTypeId },
      procedure: { code: procedureCode },
    } = savedValues
    const subscriberPK = authService.getSubscriberPK()
    const dependentPK = authService.getPatientPK()
    subscription.add(
      forkJoin({
        inNetworks: ezChoiceService.searchProviders(location, careTypeId, procedureCode, distance, subscriberPK, dependentPK),
        res: ezChoiceService.getOutOfNetworkPrices(subscriberPK, dependentPK, careTypeId, procedureCode),
      }).subscribe(
        ({ inNetworks, res }) => {
          const providers = inNetworks?.data?.providers
          const facilities = inNetworks?.data?.facilities
          setData(providers)
          setFacilities(facilities)
          setOutOfNetworkPrices(res?.data)
          if (name || facilityName) {
            onFiltersChange({
              sortValue: sorting,
              name,
              facilityName,
              injectedData: {
                providers,
                facilities,
              },
            })
          } else {
            const sortedProviders = getSortedProviders(providers, sorting || SORTING.lowestCost)
            const sortedFacilities = getSortedFacilities(facilities, sorting || SORTING.lowestCost)
            setFilteredProviders(sortedProviders)
            setFilteredFacilities(sortedFacilities)
            if (!isFromSession && (!_.isNumber(sessionActiveProvider) || !_.isNumber(sessionActiveFacility))) {
              setActiveProvidersPage(0)
              setActiveFacilitiesPage(0)
              setActiveProvider(sortedProviders?.length ? 0 : null)
              setActiveFacility(sortedFacilities?.length ? 0 : null)
            } else {
              setIsFromSession(false)
            }
          }
          setIsLoading(false)
        },
        () => {
          setIsLoading(false)
          setIsError(true)
          toastErrorDispatch()
        }
      )
    )
  }

  const getInNetworkDisclaimer = () => {
    subscription.add(
      ezChoiceService
        .getInNetworkDisclaimer()
        .toPromise()
        .then((res) => {
          setInNetworkDisclaimer(res?.data)
        })
        .catch((err) => {
          console.error(err)
        })
    )
  }

  const getOutOfNetworkDisclaimer = () => {
    subscription.add(
      ezChoiceService
        .getOutOfNetworkDisclaimer()
        .toPromise()
        .then((res) => {
          setOutOfNetworkDisclaimer(res?.data)
        })
        .catch((err) => {
          console.error(err)
        })
    )
  }

  const getOutOfNetworkNoticeText = () => {
    setIsOONLoading(true)
    subscription.add(
      ezChoiceService
        .getOutOfNetworkNotice()
        .toPromise()
        .then((res) => {
          setOutOfNetworkNotice(res?.data)
          setIsOONLoading(false)
        })
        .catch((err) => {
          setIsOONLoading(false)
          console.error(err)
        })
    )
  }

  useEffect(() => {
    if (activeTab === TABS.outOfNetwork && !outOfNetworkNotice) {
      getOutOfNetworkNoticeText()
    }
    // eslint-disable-next-line
  }, [activeTab])

  useEffect(() => {
    const sessionData = sessionService.getStepData()
    if (!_.isEmpty(sessionData)) {
      setIsFromSession(true)
      const {
        activeTab,
        activeProvider,
        activeFacility,
        outOfNetworkPrices,
        inNetworkDisclaimer,
        outOfNetworkDisclaimer,
        activeProvidersPage,
        activeFacilitiesPage,
        isOutOpen,
        isInOpen,
      } = sessionData
      setActiveTab(activeTab)
      setActiveProvider(activeProvider)
      setActiveFacility(activeFacility)
      setOutOfNetworkPrices(outOfNetworkPrices)
      setInNetworkDisclaimer(inNetworkDisclaimer)
      setOutOfNetworkDisclaimer(outOfNetworkDisclaimer)
      setActiveProvidersPage(activeProvidersPage)
      setActiveFacilitiesPage(activeFacilitiesPage)
      setIsOutOpen(isOutOpen)
      setIsInOpen(isInOpen)

      if (_.isEmpty(outOfNetworkDisclaimer)) {
        getOutOfNetworkDisclaimer()
      }
      if (_.isEmpty(inNetworkDisclaimer)) {
        getInNetworkDisclaimer()
      }
    } else {
      getInNetworkDisclaimer()
      getOutOfNetworkDisclaimer()
    }
    // eslint-disable-next-line
  }, [])

  const onFiltersChange = ({ sortValue, name, facilityName, injectedData }) => {
    const { providers, facilities: injectedFacilities } = injectedData || {}
    const providersToFilter = providers || data
    const nameToSearch = name?.toLowerCase()?.trim()
    const filteredProviders = providersToFilter?.filter(({ provider: { firstName, lastName } }) =>
      joinStrings(firstName, lastName).toLowerCase().includes(nameToSearch)
    )
    const newData = getSortedProviders(filteredProviders, sortValue)
    setFilteredProviders(newData)

    const facilitiesToFilter = injectedFacilities || facilities
    const facilityNameToSearch = facilityName?.toLowerCase()?.trim()
    const filteredFacilities = facilitiesToFilter?.filter(({ facility: { name } }) =>
      name.toLowerCase().includes(facilityNameToSearch)
    )
    const facilitiesNewData = getSortedFacilities(filteredFacilities, sortValue)
    setFilteredFacilities(facilitiesNewData)

    if (!isFromSession) {
      setActiveProvidersPage(0)
      setActiveProvider(newData?.length ? 0 : null)
      setActiveFacilitiesPage(0)
      setActiveFacility(facilitiesNewData?.length ? 0 : null)
    } else {
      setIsFromSession(false)
    }
  }

  useEffect(() => {
    const subscriberPK = authService.getSubscriberPK()
    const dependentPK = authService.getPatientPK()
    subscription.add(
      memberService
        .fetchPlanDuration(subscriberPK, dependentPK)
        .toPromise()
        .then((res) => {
          const planData = res?.data?.result
          setPlanEndText(planData.disclaimer)
        })
        .catch((err) => {
          console.error(err)
        })
    )
    // eslint-disable-next-line
  }, [])

  const filteredData = activeTab === TABS.inNetwork ? filteredProviders : filteredFacilities

  return (
    <>
      {isPrecert && (
        <div className="pl-3 py-2 d-flex align-items-center">
          <FontAwesomeIcon icon={faCircleInfo} className="precert-icon" />
          <span>Procedure requires preliminary certification</span>
        </div>
      )}
      <Header
        activeTab={activeTab}
        isLoading={isLoading}
        setActiveTab={setActiveTab}
        filteredProviders={filteredProviders}
        filteredFacilities={filteredFacilities}
        savedValues={savedValues}
        isPrecert={isPrecert}
        outOfNetworkPrices={outOfNetworkPrices}
        inNetworkDisclaimer={inNetworkDisclaimer}
        planEndText={planEndText}
        isFirstLoad={isFirstLoad}
        onFiltersChange={onFiltersChange}
        getProviders={getProviders}
        providers={data}
        facilities={facilities}
        setIsLoading={setIsReportDownloading}
        isError={isError}
      />
      {isLoading || isOONLoading || isFirstLoad || isReportDownloading ? (
        <>
          <div className={`spinner ${isReportDownloading ? 'mb-4' : ''}`}></div>
          {isReportDownloading && (
            <>
              <div className="text-center">Downloading results...</div>
              <div className="position-fixed vw-100 vh-100 fixed-top fixed-start" />
            </>
          )}
        </>
      ) : (
        <>
          {[TABS.inNetwork, TABS.facilities].includes(activeTab) && (
            <div>
              <MediaQuery minDeviceWidth={769}>
                <>
                  {filteredData?.length && filteredData?.length > 0 ? (
                    <div className="d-responsive search-results pt-1 mx-n2">
                      <ElementsList
                        isPrecert={isPrecert}
                        data={filteredData}
                        activeProvider={activeTab === TABS.facilities ? activeFacility : activeProvider}
                        onProviderClick={(idx) => {
                          if (activeTab === TABS.facilities) {
                            setActiveFacility(idx)
                          } else {
                            setActiveProvider(idx)
                          }
                        }}
                        activePage={activeTab === TABS.facilities ? activeFacilitiesPage : activeProvidersPage}
                        setActivePage={activeTab === TABS.facilities ? setActiveFacilitiesPage : setActiveProvidersPage}
                        isFacility={activeTab === TABS.facilities}
                      />
                      <div className="search-results__right pl-2 ml-1">
                        <ExtendedProvider
                          provider={filteredData?.[activeTab === TABS.facilities ? activeFacility : activeProvider]}
                          inNetworkDisclaimer={inNetworkDisclaimer}
                          isInOpen={isInOpen}
                          setIsInOpen={setIsInOpen}
                          planEndText={planEndText}
                          isFacility={activeTab === TABS.facilities}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4 pt-2 ml-3">
                      <div className="fw-6 mb-2">No results found in your area.</div>
                      <div>Expand search radius, change location, or review other filters.</div>
                    </div>
                  )}
                </>
              </MediaQuery>
              <MediaQuery maxDeviceWidth={768}>
                <>
                  {filteredData?.length && filteredData?.length > 0 ? (
                    <div className="d-responsive search-results pt-1 mx-n2">
                      <div className="search-results__right pl-2 ml-1">
                        <ExtendedProvider
                          provider={filteredData?.[activeTab === TABS.facilities ? activeFacility : activeProvider]}
                          inNetworkDisclaimer={inNetworkDisclaimer}
                          isInOpen={isInOpen}
                          setIsInOpen={setIsInOpen}
                          planEndText={planEndText}
                          isFacility={activeTab === TABS.facilities}
                        />
                      </div>
                      <ElementsList
                        isPrecert={isPrecert}
                        data={filteredData}
                        activeProvider={activeTab === TABS.facilities ? activeFacility : activeProvider}
                        onProviderClick={(idx) => {
                          if (activeTab === TABS.facilities) {
                            setActiveFacility(idx)
                          } else {
                            setActiveProvider(idx)
                          }
                        }}
                        activePage={activeTab === TABS.facilities ? activeFacilitiesPage : activeProvidersPage}
                        setActivePage={activeTab === TABS.facilities ? setActiveFacilitiesPage : setActiveProvidersPage}
                        isFacility={activeTab === TABS.facilities}
                      />
                    </div>
                  ) : (
                    <div className="mt-4 pt-2 ml-3">
                      <div className="fw-6 mb-2">No results found in your area.</div>
                      <div>Expand search radius, change location, or review other filters.</div>
                    </div>
                  )}
                </>
              </MediaQuery>
            </div>
          )}
          {activeTab === TABS.outOfNetwork && (
            <div className="pl-2">
              <div className="pt-3 d-flex mb-4">
                <PricesBlock
                  totalCost={getPrice(outOfNetworkPrices?.averagePrice)}
                  planCovers={getPrice(outOfNetworkPrices?.planCover)}
                  myCost={getPrice(outOfNetworkPrices?.patientCost)}
                  isUnableToCalculate={
                    !_.isNumber(outOfNetworkPrices?.averagePrice) ||
                    !_.isNumber(outOfNetworkPrices?.planCover) ||
                    !_.isNumber(outOfNetworkPrices?.patientCost)
                  }
                  className="mr-3 flex-grow-1"
                  isGreen
                />
                <Disclaimer
                  data={outOfNetworkDisclaimer}
                  className="flex-grow-1"
                  isOpen={isOutOpen}
                  setIsOpen={setIsOutOpen}
                  planEndText={planEndText}
                />
              </div>
              {outOfNetworkNotice?.sections?.[0]?.body && (
                <div className="d-flex mw-660">
                  <FontAwesomeIcon icon={faExclamationCircle} className="ezchoice-icon-green mr-2 mt-0" />
                  <ReactMarkdown className="fs-11">{outOfNetworkNotice?.sections?.[0]?.body}</ReactMarkdown>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default SearchResults
