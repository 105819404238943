import _ from 'lodash'

const SESSION_STORAGE_KEY = 'ezChoice'
const SESSION_STORAGE_KEY_DETAILS = 'ezChoiceDetails'

class SessionService {
  saveData = (data) => {
    const savedData = this.getData()
    sessionStorage.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify({
        ...savedData,
        ...data,
      })
    )
  }

  getData = () => {
    const data = sessionStorage.getItem(SESSION_STORAGE_KEY)
    if (_.isEmpty(data)) {
      return {}
    }
    return JSON.parse(data)
  }

  clearData = () => {
    sessionStorage.removeItem(SESSION_STORAGE_KEY)
  }

  saveStepData = (stepData) => {
    const data = this.getData()
    this.saveData({ ...data, stepData })
  }

  getStepData = () => {
    const data = this.getData()
    return data?.stepData
  }

  clearStepData = () => {
    this.saveStepData({})
  }

  getStepDetails = () => {
    const data = sessionStorage.getItem(SESSION_STORAGE_KEY_DETAILS)
    if (_.isEmpty(data)) {
      return {}
    }
    return JSON.parse(data)
  }

  saveStepDetails = (data) => {
    const savedData = this.getStepDetails()
    sessionStorage.setItem(
      SESSION_STORAGE_KEY_DETAILS,
      JSON.stringify({
        ...savedData,
        ...data,
      })
    )
  }

  clearStepDetails = () => {
    sessionStorage.removeItem(SESSION_STORAGE_KEY_DETAILS)
  }
}

export default SessionService
