import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

export class ContactUsCard extends PureComponent {
  render() {
    return (
      <div className="card-body d-flex justify-content-between bg-primary-light extra-rounded">
        <span className="card-section-header h5 fw-6 fs-18">24/7 Help Line</span>
        <Link to="/contactus">
          <button type="button" className="btn btn-outline-primary btn-sm ml-3 float-right fw-7">
            Contact Us
          </button>
        </Link>
      </div>
    )
  }
}
