import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import ReactRating from 'react-rating'
import { Link } from 'react-router-dom'
import {
  Button,
  Col,
  Collapse,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import { Subscription } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import { billService } from '../../services/BillService'
import { claimService } from '../../services/ClaimService'
import { memberService } from '../../services/MemberService'
import { surveyService } from '../../services/SurveyService'
import { EZPayAddUpdateCard } from '../cards/EZPayAddUpdateCard.cardconnect'
import { EZPayCardListCard } from '../cards/EZPayCardListCard.cardconnect'
import { LoadingCard } from '../cards/LoadingCard'
import { ClaimList } from '../claims/ClaimList'
import { FormatCapitalizedText } from '../global/TextHelpers'

export class BillDetailPage extends React.Component {
  constructor(props) {
    super(props)

    this.params = this.props
    if (this.props.match) {
      this.params = this.props.match.params
    }

    // eslint-disable-next-line
    if (this.params && (this.params.billPK || this.params.billPK == 0)) {
      this.state = {
        billPK: this.params.billPK,
        billDetails: false,
        error: false,
        selectedClaims: [],
        ezPayCardList: false,
        collapseCardList: false,
        collapseAddCard: false,
        page: 'list',
        accountId: 0,
        patientPK: 0,
        subscriberPK: 0,
        show: false,
        isEZPaySurvey: false,
        ratingValue: 0,
        survey: false,
      }
    }

    this.subscription = new Subscription()
    this.handleOnSelect = this.handleOnSelect.bind(this)
    this.handleOnSelectAll = this.handleOnSelectAll.bind(this)
    this.toggleCardList = this.toggleCardList.bind(this)
    this.toggleAddCard = this.toggleAddCard.bind(this)
    this.handlePay = this.handlePay.bind(this)
    this.totalAmount = this.totalAmount.bind(this)
    this.chosenClaims = this.chosenClaims.bind(this)
    this.nonChosenClaims = this.nonChosenClaims.bind(this)
    this.getSurvey = this.getSurvey.bind(this)
  }

  totalAmount() {
    return this.chosenClaims()
      .map((x) => x.patientOwesAmount)
      .reduce((prev, current, ind) => prev + current, 0) //initial value of 0
  }

  chosenClaims() {
    return this.state.billDetails.claims.filter((x) => this.state.selectedClaims.indexOf(x.id) > -1)
  }

  nonChosenClaims() {
    return this.state.billDetails.claims.filter((x) => this.state.selectedClaims.indexOf(x.id) === -1)
  }

  componentDidMount() {
    this.subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            this.setState({ patientPK: x.dependentPK })
            this.setState({ subscriberPK: x.subscriberPK })
            return billService.getBillDetails(this.state.billPK, x.subscriberPK)
          })
        )
        .subscribe(
          (response) => {
            if (response.status === 200) {
              this.setState({
                billDetails: response.data.result,
              })
            } else {
              this.setState({ error: true })
            }
          },
          (error) => {
            this.setState({ error: true })
          }
        )
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selectedClaims: [...this.state.selectedClaims, row.id],
      }))
    } else {
      this.setState(() => ({
        selectedClaims: this.state.selectedClaims.filter((x) => x !== row.id),
      }))
    }
  }

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id)
    if (isSelect) {
      this.setState(() => ({
        selectedClaims: ids,
      }))
    } else {
      this.setState(() => ({
        selectedClaims: [],
      }))
    }
  }

  handlePay() {
    this.setState({
      page: this.state.selectedClaims.length === 0 ? 'confirmation' : 'payment', // skip to confirmation screen if not paying any claims
      isEZPaySurvey: this.state.selectedClaims.length === 0 ? false : true,
    })
    let surveyName = this.state.selectedClaims.length === 0 ? 'NonEZPay' : 'EZPay'
    surveyService.NeedSurvey(surveyName, this.state.patientPK).subscribe((x) => {
      if (x.data) {
        surveyService.getSurvey(surveyName).subscribe(
          (x) => {
            this.setState({
              survey: x.data,
            })
          },
          (error) => {
            this.handleClose()
          }
        )
      }
    })
  }

  ezPayCallback = (accountId) => {
    this.setState({
      accountId: accountId,
      page: 'confirmation',
      isEZPaySurvey: true,
    })
  }
  newEZPayCallback = (accountId) => {
    this.setState({
      accountId: accountId,
      page: 'confirmation',
      isEZPaySurvey: true,
    })
  }
  handleClose = () => {
    this.setState({ show: false })
  }
  handleShow = (e) => {
    // If there is no survey, skip showing the modal
    if (!this.state.survey) return

    this.setState({ show: true })
  }

  toggleCardList() {
    this.setState((state) => ({ collapseCardList: !state.collapseCardList }))
  }

  toggleAddCard() {
    this.setState((state) => ({ collapseAddCard: !state.collapseAddCard }))
  }

  claimDetailHelper = (y) => claimService.getClaimDetails(y.claimPrimaryKey).pipe(map((b) => b.data.result))

  getSurvey = () => {
    let surveyName = this.state.isEZPaySurvey ? 'EZPay' : 'NonEZPay'
    surveyService.getSurvey(surveyName).subscribe((x) => {
      this.setState({
        survey: x.data,
      })
      this.handleShow()
    })
  }

  submitPayment = (e) => {
    let claims = this.chosenClaims()
    let excludedClaims = this.nonChosenClaims()
    const { billPK, accountId, patientPK } = this.state
    const { subscriberPK, billID, amount, billNumber, dateOfService, facility, providerName } = this.state.billDetails
    let obj = {
      billPK,
      subscriberPK,
      claims,
      excludedClaims,
      billID,
      amount,
      billNumber,
      dateOfService,
      facility,
      providerName,
    }
    this.subscription.add(
      billService.submitPayment(subscriberPK, patientPK, accountId, [obj]).subscribe(
        (response) => {
          // this.handleClose();
          // this.setState({ page: 'success' });
          // this.setState({ isEZPaySurvey: false });

          this.setState({ page: 'success' })
          this.handleShow()
        },
        (error) => {
          this.setState({ error: true })
        }
      )
    )
  }
  _handleChange = (event) => {
    var survey = this.state.survey
    if (event.currentTarget !== undefined && event.currentTarget.name !== undefined) {
      // eslint-disable-next-line
      var index = this.state.survey.surveyQuestion.findIndex((x) => x.surveyQuestionPk == event.currentTarget.name)
      survey.surveyQuestion[index].surveyResponse = event.currentTarget.value
    } else {
      this.setState({ ratingValue: event })
      // eslint-disable-next-line
      var index = this.state.survey.surveyQuestion.findIndex((x) => x.surveyQuestionType == 'Rating1to10')
      survey.surveyQuestion[index].surveyResponse = event
    }
    this.setState({ survey })
  }
  _handleRatingClick = (event) => {
    this.setState({ ratingValue: event })
  }

  submitSurvey = () => {
    surveyService.sendSurvey(this.state.survey, this.state.patientPK).subscribe((x) => {
      this.setState({ show: false })
    })
  }

  render() {
    const { billDetails, error, page, billPK, survey } = this.state
    // eslint-disable-next-line
    const displayButtons = billPK == 0
    switch (page) {
      case 'payment':
        return (
          <>
            <div className="card-body bg-primary-light extra-rounded">
              <h3 className="card-section-header">Bills</h3>

              <p className="text-white">
                Please enter payment information below. This information will only be used for the bills you selected on the
                previous page. You will need to re-enter this information the next time you pay your bill.
              </p>
              <h4 className="text-primary fw-7">Total Amount:</h4>
              <em className="text-secondary fw-7">
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.totalAmount())}
              </em>

              <Row>
                <Col sm="auto" md="6" className="my-3">
                  <h5 className="text-primary fw-7">Claims you are paying:</h5>
                  <ListGroup>
                    {this.chosenClaims().map((claim) => (
                      <ListGroupItem key={claim.id} className="text-black">
                        <strong>
                          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(claim.patientOwesAmount)}
                        </strong>
                        , {FormatCapitalizedText(claim.providerName)} <em>(Claim #: {claim.claimNumber})</em>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
                <Col sm="auto" md="6" className="my-3">
                  <h5 className="text-primary fw-7">Claims you are not paying:</h5>
                  <ListGroup>
                    {this.nonChosenClaims().map((claim) => (
                      <ListGroupItem key={claim.id} className="text-black">
                        <strong>
                          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(claim.patientOwesAmount)}
                        </strong>
                        , {FormatCapitalizedText(claim.providerName)} <em>(Claim #: {claim.claimNumber})</em>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
              </Row>
            </div>
            <div className="centered-table bg-primary-light extra-rounded">
              <div className="card-body bg-primary-light extra-rounded">
                <h3 className="text-primary fw-7">Select a Card</h3>
                <hr />
                <EZPayCardListCard callback={this.ezPayCallback} />
              </div>
            </div>
            <div className="centered-table bg-primary-light extra-rounded">
              <div className="card-body bg-primary-light extra-rounded">
                <span className="h3 text-primary fw-7 mr-2">Add a Card</span>
                <span>
                  {this.state.collapseAddCard ? (
                    <FontAwesomeIcon icon={faAngleDown} className="text-primary" onClick={this.toggleAddCard} size="2x" />
                  ) : (
                    <FontAwesomeIcon icon={faAngleUp} className="text-primary" onClick={this.toggleAddCard} size="2x" />
                  )}
                </span>
                <hr />
                <Collapse isOpen={this.state.collapseAddCard}>
                  <EZPayAddUpdateCard callback={this.newEZPayCallback} />
                </Collapse>
              </div>
              <button className="btn btn-outline-primary btn-sm w-3 fw-7 m-2" onClick={(e) => this.setState({ page: 'list' })}>
                Cancel
              </button>
            </div>
          </>
        )
      case 'confirmation':
        if (error) {
          return (
            <div className="card-body bg-primary-light extra-rounded">
              <h3 className="card-section-header">Bills</h3>
              <div className="alert alert-danger">Sorry, we are unable to submit your payment at this time. Please try again</div>
            </div>
          )
        } else {
          return (
            <>
              <div className="card-body bg-primary-light extra-rounded">
                <h3 className="card-section-header">Bills</h3>
                <p className="text-white">
                  You are about to submit a payment of{' '}
                  {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.totalAmount())}. Please
                  click "Confirm" to continue
                </p>
                <Row>
                  <Col sm="auto" md="6" className="my-3">
                    <h5 className="text-primary fw-7">Claims you are paying:</h5>
                    <ListGroup>
                      {this.chosenClaims().map((claim) => (
                        <ListGroupItem key={claim.id} className="text-black">
                          <strong>
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                              claim.patientOwesAmount
                            )}
                          </strong>
                          , {FormatCapitalizedText(claim.providerName)} <em>(Claim #: {claim.claimNumber})</em>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </Col>
                  <Col sm="auto" md="6" className="my-3">
                    <h5 className="text-primary fw-7">Claims you are not paying:</h5>
                    <ListGroup>
                      {this.nonChosenClaims().map((claim) => (
                        <ListGroupItem key={claim.id} className="text-black">
                          <strong>
                            {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                              claim.patientOwesAmount
                            )}
                          </strong>
                          , {FormatCapitalizedText(claim.providerName)} <em>(Claim #: {claim.claimNumber})</em>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </Col>
                </Row>
                <button className="btn btn-primary btn-sm w-3 fw-7 m-2" onClick={this.submitPayment}>
                  Confirm
                </button>
                <button className="btn btn-outline-primary btn-sm w-3 fw-7 m-2" onClick={(e) => this.setState({ page: 'list' })}>
                  Cancel
                </button>
              </div>
            </>
          )
        }
      case 'success':
        return (
          <div className="card-body bg-primary-light extra-rounded">
            <h3 className="card-section-header">Bills</h3>
            <p className="text-white">Your payment has been submitted successfully.</p>
            <Link to="/">
              <button className="btn btn-outline-primary btn-sm w-3 fw-7 m-2">Back to Home</button>
            </Link>

            <div>
              <Modal isOpen={this.state.show} onExit={this.handleClose} centered={true}>
                <ModalHeader>Would you like to take survey?</ModalHeader>
                <ModalBody>
                  <div>
                    <Form>
                      {survey?.surveyQuestion?.map((form) => {
                        // eslint-disable-next-line
                        if (form.surveyQuestionType == 'Rating1to10') {
                          return (
                            <div>
                              <FormGroup>
                                <Label className="text-primary fw-7">{form.surveyQuestionDescription}</Label>
                                <ReactRating stop={10} initialRating={this.state.ratingValue} onChange={this._handleChange} />
                              </FormGroup>
                            </div>
                          )
                        }
                        // eslint-disable-next-line
                        if (form.surveyQuestionType == 'textarea') {
                          return (
                            <div>
                              <FormGroup>
                                <Label className="text-primary fw-7" for="message">
                                  {form.surveyQuestionDescription}
                                </Label>
                                <Input
                                  type="textarea"
                                  name={form.surveyQuestionPk}
                                  id="message3"
                                  placeholder="Write a message..."
                                  onChange={this._handleChange}
                                />
                              </FormGroup>
                            </div>
                          )
                        }
                        // eslint-disable-next-line
                        if (form.surveyQuestionType == 'YESorNORadio') {
                          return (
                            <div>
                              <FormGroup tag="fieldset">
                                <Label className="text-primary fw-7" for="message">
                                  {form.surveyQuestionDescription}
                                </Label>
                                <br />
                                <FormGroup check>
                                  <Label className="text-black">
                                    <Input type="radio" value="Yes" name={form.surveyQuestionPk} onChange={this._handleChange} />{' '}
                                    Yes
                                  </Label>
                                </FormGroup>
                                <FormGroup check>
                                  <Label className="text-black">
                                    <Input type="radio" value="No" name={form.surveyQuestionPk} onChange={this._handleChange} />{' '}
                                    No
                                  </Label>
                                </FormGroup>
                              </FormGroup>
                            </div>
                          )
                        }
                        return <></>
                      })}
                    </Form>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button type="button" onClick={this.submitSurvey} className="profile-button">
                    Submit
                  </button>
                  <Button color="secondary" onClick={this.handleClose}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        )
      default:
      case 'list':
        if (error) {
          return (
            <div className="card-body bg-primary-light extra-rounded">
              <h3 className="card-section-header">Bill Details</h3>
              <div className="alert alert-danger">Sorry, we are unable to retrieve the bill details at this time.</div>
            </div>
          )
        } else {
          if (billDetails) {
            return (
              <div className="card-body bg-primary-light extra-rounded">
                {/* <div className="mt-4">*/}
                <h3 className="card-section-header">Bill Details</h3>
                <Container fluid>
                  {displayButtons ? (
                    <p className="text-white">
                      Please check the box for the claims that you would like to pay, and then click{' '}
                      <em>"Pay Selected Claims"</em> below. You may click the <em>"Claim Number"</em> link to view the claim
                      details.
                    </p>
                  ) : (
                    <></>
                  )}
                  <Row>
                    <Col>
                      {billDetails.claims ? (
                        <ClaimList
                          displayCheckbox={displayButtons}
                          claims={billDetails.claims}
                          subscriberPK={this.state.subscriberPK}
                          dependentPK={this.state.patientPK}
                          hideClaimType={true}
                          isPagination={false}
                          handleOnSelect={this.handleOnSelect}
                          handleOnSelectAll={this.handleOnSelectAll}
                        />
                      ) : (
                        <span>No claims associated</span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    {displayButtons ? (
                      <Col>
                        <button
                          className="btn btn-outline-primary btn-sm w-3 fw-7"
                          disabled={this.state.selectedClaims.length === 0}
                          onClick={this.handlePay}
                        >
                          Pay Selected Claims
                        </button>
                        <button
                          className="btn btn-outline-primary btn-sm w-3 fw-7"
                          disabled={this.state.selectedClaims.length > 0}
                          onClick={this.handlePay}
                        >
                          Don't Pay This Bill's Claims
                        </button>
                      </Col>
                    ) : (
                      <></>
                    )}

                    {/*<Col className="m-auto">*/}
                    {/*    <small className="text-primary fw-7"> Questions? Email or call HealthEZ Payment Services at 1 888 409 2273 </small>*/}
                    {/*</Col>*/}
                  </Row>
                </Container>
                {/*</div>*/}
              </div>
            )
          } else {
            return <LoadingCard />
          }
        }
    }
  }
}
