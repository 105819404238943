import React from 'react'
import { MediaQuery } from 'react-responsive'
import { Row, Col } from 'reactstrap'
import { PrivacyModal } from './PrivacyModal'

export default class Footer extends React.Component {
  constructor(props) {
    super(props)

    this.openPrivacyModal = this.openPrivacyModal.bind(this)
    this.privacyModalElement = React.createRef()
  }

  openPrivacyModal = () => {
    this.privacyModalElement.current.toggle()
  }

  render() {
    return (
      <div>
        <PrivacyModal ref={this.privacyModalElement} />
        <MediaQuery minDeviceWidth={1224}>
          <footer className="desktop-footer py-3 bg-primary-transparent fw-7 pt-2">
            <div className="container">
              <Row>
                <Col>
                  <img src={process.env.PUBLIC_URL + '/images/HEZ_LOGO_Green.png'} className="logo" alt="Health EZ" />
                  <div className="h-5">
                    <small className="fw-7">
                      <a href="mailto:service@healthez.com" className="footer-text fw-7">
                        SERVICE@HEALTHEZ.COM
                      </a>
                    </small>
                  </div>
                </Col>
                <Col md={{ span: 3, offset: 3 }}>
                  <button className="btn btn-outline-primary" onClick={this.openPrivacyModal}>
                    Privacy
                  </button>
                </Col>
              </Row>
              <small className="m-2 copyright">&copy; HEALTHEZ 2023. ALL RIGHTS RESERVED.</small>
              <x-small classname="copyright">v2.1.1</x-small>
            </div>
          </footer>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={1224}>
          <footer className="footer mt-2 py-3 bg-gradient fw-7 pt-2">
            <div className="container">
              <Row>
                <Col>
                  <img src={process.env.PUBLIC_URL + '/images/HealthEZ_logo_Green.png'} className="logo" alt="Health EZ" />
                  <h5 className="h-5">
                    <small className="fw-7">
                      <a href="tel:8448048118" className="footer-text fw-7">
                        844.804.8118
                      </a>
                    </small>
                  </h5>
                  <div className="h-5">
                    <small className="fw-7">
                      <a href="mailto:service@healthez.com" className="footer-text fw-7">
                        SERVICE@HEALTHEZ.COM
                      </a>
                    </small>
                  </div>
                </Col>
                <Col md={{ span: 3, offset: 3 }}>
                  <button className="btn btn-outline-primary" onClick={this.openPrivacyModal}>
                    Privacy
                  </button>
                </Col>
              </Row>
              <small classname="copyright">&copy; HEALTHEZ 2023. ALL RIGHTS RESERVED.</small>
              <x-small className="copyright">v2.1.1</x-small>
            </div>
          </footer>
        </MediaQuery>
      </div>
    )
  }
}
//export default React.memo(Footer);
