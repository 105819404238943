import React from 'react'
import { Button, Row, Card, Input, Col } from 'reactstrap'
import { ExpenseCard } from '../reimbursement/ExpenseCard'
import { memberService } from '../../services/MemberService'
import { Subscription, forkJoin } from 'rxjs'
import { LoadingCard } from '../cards/LoadingCard'
import { ReceiptUpload } from '../reimbursement/ReceiptUpload'

export const SubmissionNotification = (props) => {
  if (props.isSubmitting) {
    return <span className="text-info fw-7 ml-2">Submitting...</span>
  } else if (props.isSuccessful) {
    return <span className="text-success fw-7 ml-2">Submitted successfully!</span>
  } else if (props.hasFailed) {
    return <span className="text-danger fw-7 ml-2">{'Error submitting: ' + props.errorMessage}</span>
  } else if (props.clearNotification) {
    return <></>
  } else {
    return <></>
  }
  // <SubmissionNotification isSubmitting={this.state.isSubmitting} isSuccessful={this.state.isSuccessful} hasFailed={this.state.hasFailed} clearNotification={this.state.clearNotification} />
}

export class FlexRequestsPage extends React.Component {
  constructor(props) {
    super(props)

    this.addExpense = this.addExpense.bind(this)
    this.submit = this.submit.bind(this)
    this.removeExpense = this.removeExpense.bind(this)
    this.checkRemoved = this.checkRemoved.bind(this)
    this.checkAdded = this.checkAdded.bind(this)

    this.state = {
      expenses: [],
      wasSubmitted: false,
      subscriberInfo: false,
      termsAccepted: false,
      error: false,
      showCheck: false,
      checkFileId: false,
    }

    this.subscription = new Subscription()
  }

  componentDidMount() {
    this.addExpense()
    this.subscription.add(memberService.subscriberInfo().subscribe((x) => this.setState({ subscriberInfo: x })))
    console.log('Starting', this.state.props)
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  addExpense() {
    let expenses = this.state.expenses
    expenses.push({
      files: [],
    })
    this.setState({ expenses: expenses })
  }

  removeExpense(index) {
    let expenses = this.state.expenses
    if (expenses[index]) expenses.splice(index, 1)
    this.setState({ expenses: expenses })
  }

  submit = async () => {
    await this.setState({ isSubmitting: true })
    let medicals = this.state.expenses.filter((x) => x.activeTab === 'medical')
    let daycares = this.state.expenses.filter((x) => x.activeTab === 'daycare')
    let observables = []
    //for loop through medcals, adding files list direct deposit when set to true
    if (medicals.length) {
      let data = {
        directDeposit: this.state.directDeposit,
        termsAccepted: this.state.termsAccepted,
        directDepositFile: this.state.checkFileId,
        expenses: medicals,
      }

      observables.push(memberService.submitMedicalExpenseReport(this.state.subscriberInfo.subscriberPK, data))
    }

    if (daycares.length) {
      let data = {
        directDeposit: this.state.directDeposit,
        termsAccepted: this.state.termsAccepted,
        expenses: daycares,
      }
      observables.push(memberService.submitDaycareExpenseReport(this.state.subscriberInfo.subscriberPK, data))
    }

    this.subscription.add(
      forkJoin(observables).subscribe(
        (x) => {
          console.log(x)
          if (x[0].data.isSuccessful) {
            this.setState({
              isSubmitting: false,
              isSuccessful: true,
              hasFailed: true,
            })
            this.timeoutID = setTimeout(() => {
              this.setState({
                isSubmitting: false,
                isSuccessful: false,
                hasFailed: false,
                clearNotification: true,
              })
            }, 5000)
          } else {
            this.setState({
              isSubmitting: false,
              isSuccessful: false,
              hasFailed: true,
            })
          }
        },
        (error) => {
          this.setState({
            isSubmitting: false,
            isSuccessful: false,
            hasFailed: true,
            errorMessage: error.response.data[0].errorMessage,
          })
        }
      )
    )
  }

  handleChange = async (event) => {
    const { target } = event
    const value = target.type === 'checkbox' ? target.checked : target.value
    const { name } = target
    let expense = this.state.expense

    await this.setState({
      [name]: value,
      expense: expense,
    })
    console.log('handlechange:', this.state)
  }

  addFile() {
    let expense = this.state.expense
    expense.files.push({ id: '' })
    this.setState({ expense: expense })
    console.log('flex addfile:', this.state.expense)
    console.log('flex addfile files.push', expense.files)
  }

  forCheck = () => {
    let expense = this.state.expense
    //expense.files.push({ id: '' });

    // console.log('flex addfile:', this.state.expense);
    // console.log('flex addfile files.push', expense.files);
    this.setState({
      showCheck: !this.state.showCheck,
      expense: expense,
    })
  }
  checkAdded(id, index) {
    this.setState({ checkFileId: id })
  }

  checkRemoved(id, index) {
    this.setState({ checkFileId: false })
  }

  render() {
    if (this.state.subscriberInfo) {
      return (
        <Card className="card bg-primary-light p-md-4 p-xs-1 m-md-4 m-xs-1">
          <h4 className="page-header text-light">Flex Reimbursement Request Form</h4>
          {this.state.expenses.map((exp, ind) => (
            <ExpenseCard
              key={ind}
              index={ind}
              expense={exp}
              dependents={this.state.subscriberInfo.dependents}
              deleteCallBack={this.removeExpense}
            ></ExpenseCard>
          ))}

          <Row className="mt-2 mb-2">
            <Col>
              <Button color="link" onClick={this.addExpense} className="btn profile-button btn-sm fw-7">
                Add Expense
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="form-check mt-4">
                <label className="form-check-label">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    name="directDeposit"
                    id="directDeposit"
                    onChange={(e) => {
                      this.handleChange(e)
                    }}
                    onClick={this.forCheck}
                  />
                  <p className="text-grey fw-7">
                    Please check here if you would like Direct Deposit into your checking account, and then scan and attach a
                    voided check for that account.
                  </p>
                  {this.state.showCheck && (
                    <Col md={8}>
                      <div className="text-white">
                        Attach Voided Check
                        <ReceiptUpload
                          id="r1"
                          index="99"
                          expenseIndex="directDeposit"
                          callback={this.checkAdded}
                          deleteCallBack={this.checkRemoved}
                        ></ReceiptUpload>
                      </div>{' '}
                    </Col>
                  )}
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <p className="text-light fw-7">Terms and Conditions</p>
              <small className="text-grey fw-7">
                The undersigned participant in the plan certifies that all expenses for which reimbursement of payment is claimed
                by submission of this form, were incurred during a period while the undersigned was covered under the company’s
                flex plan. The undersigned fully understands that he/she alone is responsible for the suciency, accuracy and
                veracity of all information relating to this claim which is provided by the undersigned and that, unless an
                expense for which payment of reimbursement is claimed is a proper expense under the plan, the undersigned may be
                liable for payment of all related federal, state, or city income tax on amounts paid from the plan which relate to
                such expense.
              </small>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="form-check mt-4">
                <label className="form-check-label">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    name="termsAccepted"
                    id="termsAccepted"
                    onChange={(e) => {
                      this.handleChange(e)
                    }}
                  />
                  <p className="text-light fw-7">I understand and agree to the above Terms and Conditions.</p>
                </label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                color="link"
                disabled={!this.state.termsAccepted}
                onClick={this.submit}
                className="btn profile-button btn-sm fw-7"
              >
                Submit
              </Button>
              <SubmissionNotification
                isSubmitting={this.state.isSubmitting}
                isSuccessful={this.state.isSuccessful}
                hasFailed={this.state.hasFailed}
                clearNotification={this.state.clearNotification}
              />
            </Col>
          </Row>
        </Card>
      )
    } else {
      return <LoadingCard />
    }
  }
}
