import React from 'react'
import { Stack } from '@mui/material'
import { getMainLinkPages } from './MainMenu.utils'
import MenuButton from './MenuButton'
import MoreMenu from './MoreMenu'

function MainMenu({ externalResources, isGreenHeaderBackground, fsaFlag }) {
  const findCareLink = externalResources?.find((resource) => resource.resourceType === 'Medical Network')?.webShortUrl

  const mainLinkPages = getMainLinkPages({ findCareLink: findCareLink ? 'http://' + findCareLink : null })

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      sx={(theme) => ({
        [theme.breakpoints.down('lg')]: {
          position: 'fixed',
          bottom: 0,
          left: 0,
          height: '90px',
          backgroundColor: isGreenHeaderBackground ? '#004E42' : '#121629',
          width: '100vw',
          borderTopLeftRadius: '21px',
          borderTopRightRadius: '21px',
          zIndex: 900,
        },
      })}
    >
      {mainLinkPages.map((navItem) => (
        <MenuButton
          key={navItem.path}
          onClick={(event) => {
            if (navItem.isAbsolutePath) {
              event.preventDefault()
              window.open(navItem.path, '_blank')
            }
          }}
          to={!navItem.isAbsolutePath && navItem.path}
        >
          {navItem.icon}
          {navItem.text}
        </MenuButton>
      ))}
      <MoreMenu isGreenHeaderBackground={isGreenHeaderBackground} fsaFlag={fsaFlag} />
    </Stack>
  )
}

export default MainMenu
