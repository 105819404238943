export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_AUTH_URI,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_BASE_URI + process.env.REACT_APP_REDIRECT_PATH,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  silent_redirect_uri: process.env.REACT_APP_BASE_URI + process.env.REACT_APP_SILENT_REDIRECT_PATH,
  post_logout_redirect_uri: process.env.REACT_APP_BASE_URI + process.env.REACT_APP_LOGOFF_REDIRECT_PATH,
  response_type: 'code',
  scope: process.env.REACT_APP_SCOPE,
}

export const METADATA_OIDC = {
  issuer: process.env.REACT_APP_AUTH_ISSUER,
  jwks_uri: process.env.REACT_APP_AUTH_URI + '/.well-known/openid-configuration/jwks',
  authorization_endpoint: process.env.REACT_APP_AUTH_URI + '/connect/authorize',
  token_endpoint: process.env.REACT_APP_AUTH_URI + '/connect/token',
  userinfo_endpoint: process.env.REACT_APP_AUTH_URI + '/connect/userinfo',
  end_session_endpoint: process.env.REACT_APP_AUTH_URI + '/connect/endsession',
  check_session_iframe: process.env.REACT_APP_AUTH_URI + '/connect/checksession',
  revocation_endpoint: process.env.REACT_APP_AUTH_URI + '/connect/revocation',
  introspection_endpoint: process.env.REACT_APP_AUTH_URI + '/connect/introspect',
}
