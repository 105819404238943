import React, { PureComponent } from 'react'
import { Form, FormGroup, Label, Button, Card, Input, Col } from 'reactstrap'
import { claimService } from '../../services/ClaimService'
import DateInput from '../forms/DateInput'
import SelectInput from '../forms/SelectInput'
import validate from '../forms/validate'
import { ClaimList } from '../claims/ClaimList'
import { Subscription } from 'rxjs'
import { memberService } from '../../services/MemberService'
import { saveAs } from 'file-saver'
import { dateFormatter } from '../global/TextHelpers'

export class ClaimSearchCard extends PureComponent {
  constructor(props) {
    super(props)

    this.downloadPDF = this.downloadPDF.bind(this)

    this.subscription = new Subscription()

    this.state = {
      formIsValid: false,
      claims: false,
      subscriberInfo: false,
      formControls: {
        patientName: {
          label: 'Patient Name',
          value: null,
          placeholder: '',
          valid: false,
          validationRules: {
            isRequired: true,
          },
          touched: false,
          options: [],
          validationMessage: "Please select a patient's name.",
        },
        includePending: {
          label: 'Include Pending',
          value: false,
          placeholder: '',
          valid: true,
          validationRules: {
            isRequired: false,
          },
          touched: false,
          options: [],
          validationMessage: '',
        },
        startDate: {
          label: 'Begin Date',
          value: null,
          placeholder: 'MM/DD/YYYY',
          valid: false,
          validationRules: {
            isRequired: true,
            // TODO: add Date Validator
          },
          touched: false,
        },
        endDate: {
          label: 'End Date',
          value: null,
          placeholder: 'MM/DD/YYYY',
          valid: false,
          validationRules: {
            isRequired: true,
            // TODO: add Date Validator
          },
          touched: false,
        },
        claimType: {
          label: 'Claim Type',
          value: '',
          placeholder: '',
          valid: false,
          validationRules: {
            isRequired: true,
          },
          touched: false,
          options: [
            { value: 'medical', displayValue: 'Medical / Dental' },
            { value: 'prescription', displayValue: 'Prescription' },
          ],
          validationMessage: 'Please select a claim type.',
        },
      },
    }

    this.pageSize = 100
    this.startDateHandler = this.startDateHandler.bind(this)
    this.endDateHandler = this.endDateHandler.bind(this)
  }

  blurAndChangeHandler = (event) => {
    if (event.target) {
      const name = event.target.name
      const value = event.target.value

      const updatedControls = {
        ...this.state.formControls,
      }
      const updatedFormElement = {
        ...updatedControls[name],
      }
      updatedFormElement.value = value
      updatedFormElement.touched = true
      updatedFormElement.valid = validate(value, updatedFormElement.validationRules)

      updatedControls[name] = updatedFormElement

      let formIsValid = true
      for (let inputIdentifier in updatedControls) {
        formIsValid = updatedControls[inputIdentifier].valid && formIsValid
      }
      let prescSearch = value === 'prescription'

      this.setState({
        formControls: updatedControls,
        formIsValid: formIsValid,
        isPrescriptionSearch: prescSearch,
      })
    }
  }

  formSubmitHandler = async () => {
    this.setState({ claims: false })
    this.subscription.add(
      claimService
        .getClaimList(
          `${this.state.subscriberInfo.subscriberPK}`,
          this.state.formControls.patientName.value,
          this.state.formControls.startDate.value,
          this.state.formControls.endDate.value,
          this.state.formControls.claimType.value,
          this.state.formControls.includePending.value,
          this.pageSize
        )
        .subscribe((x) => {
          console.log(x)
          this.setState({
            claims: x.result,
          })
          this.forceUpdate()
        })
    )
  }

  startDateHandler(date) {
    const name = 'startDate'
    const updatedControls = {
      ...this.state.formControls,
    }
    const updatedFormElement = {
      ...updatedControls[name],
    }
    updatedFormElement.value = date
    updatedFormElement.touched = true
    updatedFormElement.valid = true

    updatedControls[name] = updatedFormElement

    let formIsValid = true
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid
    }

    this.setState({
      formControls: updatedControls,
      formIsValid: formIsValid,
    })
  }

  endDateHandler(date) {
    const name = 'endDate'
    const updatedControls = {
      ...this.state.formControls,
    }
    const updatedFormElement = {
      ...updatedControls[name],
    }
    updatedFormElement.value = date
    updatedFormElement.touched = true
    updatedFormElement.valid = true
    // validate(new Date(date).toLocaleString(), updatedFormElement.validationRules);

    updatedControls[name] = updatedFormElement

    let formIsValid = true
    for (let inputIdentifier in updatedControls) {
      formIsValid = updatedControls[inputIdentifier].valid && formIsValid
    }

    this.setState({
      formControls: updatedControls,
      formIsValid: formIsValid,
    })
  }

  downloadPDF() {
    this.subscription.add(
      claimService
        .getClaimListReportPDF(
          `${this.state.subscriberInfo.subscriberPK}`,
          `${this.state.subscriberInfo.dependentPK}`,
          this.state.formControls.startDate.value,
          this.state.formControls.endDate.value,
          this.state.formControls.claimType.value,
          this.pageSize
        )
        .subscribe((pdf) => {
          const blob = new Blob([pdf.data], { type: 'application/pdf' })
          saveAs(
            blob,
            dateFormatter(this.state.formControls.startDate.value, 'iso8601') +
              '_' +
              dateFormatter(this.state.formControls.endDate.value, 'iso8601') +
              '_claims_' +
              this.state.subscriberInfo.dependentPK +
              '.pdf',
            true
          )
        })
    )
  }

  componentDidMount() {
    this.subscription.add(
      memberService.subscriberInfo().subscribe((x) => {
        this.setState({ subscriberInfo: x })

        var formControls = this.state.formControls
        formControls.patientName.options = x.dependents.map((y) => {
          return { displayValue: y.firstName + ' ' + y.lastName, value: y.dependentPK }
        })

        var memberByDefaultIndex = formControls.patientName.options.findIndex((item) => item.value === x.dependentPK)
        var memberFound = memberByDefaultIndex !== -1
        if (memberFound) {
          formControls.patientName.options.splice(memberByDefaultIndex, 1)
        }
        formControls.patientName.options.unshift({ displayValue: x.firstName + ' ' + x.lastName, value: x.dependentPK })

        formControls.patientName.options.unshift({ displayValue: 'All Participants', value: 0 })
        formControls.patientName.touched = true
        formControls.patientName.valid = true
        const defaultDependentPK = x.dependentPK
        formControls.patientName.value = defaultDependentPK ?? 0

        formControls.claimType.value = 'medical'
        formControls.claimType.valid = true

        let pastThreeMonth = new Date()
        pastThreeMonth.setMonth(pastThreeMonth.getMonth() - 3)
        formControls.startDate.value = pastThreeMonth
        formControls.startDate.valid = true

        const today = new Date()
        formControls.endDate.value = today
        formControls.endDate.valid = true

        this.setState({ formControls: formControls })
        this.setState({ formIsValid: true })

        if (this.state.subscriberInfo.subscriberPK) {
          this.formSubmitHandler()
        }
      })
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  handleChange = async (event) => {
    const { target } = event

    const value = target.type === 'checkbox' ? target.checked : target.value
    const { name } = target

    const updatedControls = {
      ...this.state.formControls,
    }
    const updatedFormElement = {
      ...updatedControls[name],
    }

    updatedFormElement.value = value
    updatedFormElement.touched = true

    updatedControls[name] = updatedFormElement

    this.setState({
      formControls: updatedControls,
    })
    //this.forceUpdate();
  }

  forCheck(e) {
    console.log(e)
  }

  render() {
    const { patientName, startDate, endDate, claimType } = this.state.formControls

    return (
      <Col className="bg-primary-light p-6 h-150 colmin-2">
        <h4 className="card-section-header">Search Claims by Date of Service</h4>
        <Card className="card-body bg-primary-light extra-rounded p-4 m-4">
          <p className="card-section-header">Fill in the options below to search for specific claims by date range.</p>
          <Form className="form">
            <SelectInput
              name="patientName"
              label={<span className="text-light label-normal">{patientName.label}</span>}
              value={patientName.value}
              onChange={this.blurAndChangeHandler}
              onBlur={this.blurAndChangeHandler}
              options={patientName.options}
              touched={patientName.touched}
              valid={patientName.valid}
              validationRules={patientName.validationRules}
              validationMessage={patientName.validationMessage}
            />

            <DateInput
              name="startDate"
              label={startDate.label}
              value={startDate.value}
              onChange={this.startDateHandler}
              // onBlur={this.blurAndChangeHandler}
              touched={startDate.touched}
              valid={startDate.valid}
              validationRules={startDate.validationRules}
              validationMessage={startDate.validationMessage}
            />

            <DateInput
              name="endDate"
              label={endDate.label}
              value={endDate.value}
              onChange={this.endDateHandler}
              // onBlur={this.blurAndChangeHandler}
              touched={endDate.touched}
              valid={endDate.valid}
              validationRules={endDate.validationRules}
              validationMessage={endDate.validationMessage}
            />

            <SelectInput
              name="claimType"
              label={<span className="text-light label-normal">{claimType.label}</span>}
              value={claimType.value}
              onChange={this.blurAndChangeHandler}
              onBlur={this.blurAndChangeHandler}
              options={claimType.options}
              touched={claimType.touched}
              valid={claimType.valid}
              validationRules={claimType.validationRules}
              validationMessage={claimType.validationMessage}
            />
            <FormGroup>
              <Input
                style={{ marginLeft: 0 }}
                type="checkbox"
                className="test"
                //className="form-check-input"
                name="includePending"
                id="includePending"
                onChange={(e) => {
                  this.handleChange(e)
                }}
                onClick={this.forCheck}
              />
              <Label for="includePending" className="text-primary fw-7" style={{ paddingLeft: 1 + 'em' }}>
                Include Pending
              </Label>
            </FormGroup>
            <br />
            <p className="text-primary-light-blue fw-7">
              *Please note, not all benefits may be administered by HealthEZ. Check with your HR representative for more
              information.
            </p>
            <Button outline color="primary" onClick={this.formSubmitHandler} disabled={!this.state.formIsValid} className="fw-7">
              Search
            </Button>
            <Button
              outline
              color="primary"
              onClick={this.downloadPDF}
              disabled={!this.state.formIsValid || this.state.isPrescriptionSearch}
              className="ml-4 fw-7"
            >
              Get Report
            </Button>
          </Form>
        </Card>

        {this.state.claims ? (
          <ClaimList
            isPagination={true}
            claims={this.state.claims}
            subscriberPK={this.state.subscriberInfo.subscriberPK}
            dependentPK={this.state.subscriberInfo.dependentPK}
          />
        ) : (
          ''
        )}
      </Col>
    )
  }
}
