// TODO: add the rest of the colors
export const colors = {
  neutral0: '#FFFFFF',
  neutral50: '#EBEBEB',
  neutral100: '#CCCCCC',
  neutral200: '#A4A6AD',
  neutral300: '#777985',
  neutral400: '#494D5C',
  neutral500: '#1C2033',
  neutral600: '#121629',
  neutral700: '#06060A',

  green50: '#CCF2E2',
  green100: '#99E5C5',
  green200: '#66D9A9',
  green300: '#33CC8C',
  green400: '#00BF6F',
  green500: '#009959',
  green600: '#004E42',
  green700: '#002F28',

  pink50: '#FCE6EE',
  pink100: '#F9CDDD',
  pink200: '#F5B4CD',
  pink300: '#F29BBC',
  pink400: '#EF82AB',
  pink500: '#BF6889',
  pink600: '#8F4E67',
  pink700: '#603444',

  cranberry50: '#EECCDF',
  cranberry100: '#DD99C0',
  cranberry200: '#CC66A0',
  cranberry300: '#BB3381',
  cranberry400: '#AA0061',
  cranberry500: '#88004E',
  cranberry600: '#66003A',
  cranberry700: '#440027',

  purple50: '#EAE6F9',
  purple100: '#D5CCF3',
  purple200: '#C1B3ED',
  purple300: '#AC99E7',
  purple400: '#9780E1',
  purple500: '#7966B4',
  purple600: '#5B4D87',
  purple700: '#3C335A',

  lightblue50: '#EDF8FA',
  lightblue100: '#DBF1F6',
  lightblue200: '#C8E9F1',
  lightblue300: '#B6E2ED',
  lightblue400: '#A4DBE8',
  lightblue500: '#83AFBA',
  lightblue600: '#62838B',
  lightblue700: '#42585D',

  blue50: '#CCD8ED',
  blue100: '#99B1DB',
  blue200: '#668BC9',
  blue300: '#3364B7',
  blue400: '#003DA5',
  blue500: '#003184',
  blue600: '#002563',
  blue700: '#001842',

  chartreuse50: '#F9F5CC',
  chartreuse100: '#F3EB99',
  chartreuse200: '#EDE166',
  chartreuse300: '#E7D733',
  chartreuse400: '#E1CD00',
  chartreuse500: '#B4A400',
  chartreuse600: '#877B00',
  chartreuse700: '#5A5200',

  orange50: '#FBE4CC',
  orange100: '#F7C899',
  orange200: '#F2AD66',
  orange300: '#EE9133',
  orange400: '#EA7600',
  orange500: '#BB5E00',
  orange600: '#8C4700',
  orange700: '#5E2F00',

  red50: '#FCE4E4',
  red100: '#FAC9C9',
  red200: '#F59292',
  red300: '#F27777',
  red400: '#C45050',
  red500: '#B52424',
  red600: '#911D1D',
  red700: '#6D1616',
}

export const namedColors = {
  surface1Dark: colors.neutral600,
  surface2Dark: colors.neutral700,
  surface3Dark: colors.neutral500,
  surface1Light: colors.neutral0,
  surface2Light: colors.neutral50,
  surface3Light: colors.neutral100,

  validState: colors.green200,
  invalidState: colors.red300,
}

export const brandColors = {
  dark: {
    green1: colors.green400,
    cranberry1: colors.cranberry400,
    pink1: colors.pink300,
    purple1: colors.purple400,
    orange1: colors.orange300,
    chartreuse1: colors.chartreuse300,
    lightblue1: colors.lightblue300,
    blue1: colors.blue200,

    green2: colors.green600,
    cranberry2: colors.cranberry600,
    pink2: colors.pink600,
    purple2: colors.purple600,
    orange2: colors.orange600,
    chartreuse2: colors.chartreuse600,
    lightblue2: colors.lightblue600,
    blue2: colors.blue400,
  },
  light: {
    green1: colors.green400,
    cranberry1: colors.cranberry400,
    pink1: colors.pink400,
    purple1: colors.purple400,
    orange1: colors.orange400,
    chartreuse1: colors.chartreuse400,
    lightblue1: colors.lightblue400,
    blue1: colors.blue400,

    green2: colors.green50,
    cranberry2: colors.cranberry50,
    pink2: colors.pink50,
    purple2: colors.purple50,
    orange2: colors.orange50,
    chartreuse2: colors.chartreuse50,
    lightblue2: colors.lightblue50,
    blue2: colors.blue50,
  },
}
