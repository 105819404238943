import React, { PureComponent } from 'react'
import { Row } from 'reactstrap'

const EZPayPromoSection = (props) => {
  return (
    <div className="card-body bg-primary-light extra-rounded m-2 w-4 ezpay-card">
      <span className="p-2">
        {/* eslint-disable-next-line */}
        <img src={process.env.PUBLIC_URL + '/images/icons/' + props.icon} className="img img-thumbnial" />
      </span>
      <span className="card-section-header">{props.header}</span>
      <span className="card-detail text-light">{props.message1}</span>
      <span className="card-detail text-light">{props.message2}</span>
    </div>
  )
}

export class EZPayPromoCard extends PureComponent {
  render() {
    let moneyIcon = 'money-clock.svg'
    let clipboardIcon = 'clipboard.svg'
    let message1a = `Once you’ve added a payment option, you can set up automated payments to cover any monthly balances due on your account.`

    let message1b = `If you’d rather pay each bill manually, EZpay also lets you schedule payments ahead of time so the charge only comes out of your account when it works for your budget.`

    let message2a = `Paying medical bills can be a real chore. You may get multiple bills for different services, Explanation of Benefits statements, duplicate bills—so much to sort through and figure out.`

    let message2b = `EZpay collects it all into one statement and clearly shows you what you owe, without all the headaches.`

    return (
      <Row className="m-4 ezpay-card">
        <EZPayPromoSection
          header="EZpay helps you automate your payments."
          message1={message1a}
          message2={message1b}
          icon={moneyIcon}
        />
        <EZPayPromoSection
          header="EZpay puts all your bills all in one place."
          message1={message2a}
          message2={message2b}
          icon={clipboardIcon}
        />
      </Row>
    )
  }
}
