import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'

const ResultItem = ({ children, isActive, onClick, isFeatured, featuredText, featureId, className }) => {
  return (
    <div
      className={`
                py-3 pr-4 pl-4 result__item
                ${isActive ? ' result__item--active ' : ''}
                ${isFeatured ? ' pr-5 ' : ''}
                ${className}
            `}
      onClick={onClick}
    >
      {children}
      {isFeatured && (
        <>
          <FontAwesomeIcon icon={faCircleInfo} className="result__item-info" id={featureId} />
          <UncontrolledTooltip
            placement="right-start"
            target={featureId}
            innerClassName="ezchoice-tooltip is-right"
            className="ezchoice-tooltip-wrapper"
            arrowClassName="ezchoice-tooltip-arrow is-right"
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                escapeWithReference: true,
              },
            }}
          >
            {featuredText}
          </UncontrolledTooltip>
        </>
      )}
    </div>
  )
}

export default ResultItem
