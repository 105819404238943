export function getToken() {
  const token = sessionStorage.getItem('access_token')
  return token
}

export function getSubscriberPK() {
  const pk = sessionStorage.getItem('subscriberpk')
  return pk
}

export function getSubscriberID() {
  const id = sessionStorage.getItem('subscriberid')
  return id
}

export function getPatientPK() {
  const pk = sessionStorage.getItem('patientpk')
  return pk
}

export function isDependent() {
  return sessionStorage.getItem('memberRole') === 'Dependent'
}

export function isCS() {
  return sessionStorage.getItem('role') === 'admin'
}
