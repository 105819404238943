import { createTheme, ThemeProvider } from '@mui/material/styles'
import React, { Component } from 'react'
import { map } from 'rxjs/operators'
import { AuthConsumer } from './auth/AuthProvider'
import { Layout } from './components/global/Layout'
import { Routes } from './Routes'
import { memberService } from './services/MemberService'
import Axios from './upstream/axios-observable/dist/index'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1218,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    mode: 'dark',
    newPrimaryButtons: {
      main: '#66D9A9',
      light: '#E9DB5D',
      dark: '#A29415',
      contrastText: '#242105',
    },
    newMenuButtons: {
      bg: '#FFFFFF',
      light: '#E9DB5D',
      dark: '#A29415',
      contrastText: '#242105',
    },
    backgrounds: {
      main: '#121629',
    },
    text: {
      primary: '#FFFFFF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { minWidth: 0 },
      },
    },
  },
  typography: {
    fontFamily: ['Poppins-Aligned'].join(','),
  },
})
// attempt for typograyphy responsivness for 'Welcome' in ProfileCardF - doesn't work
// theme.typography.h1 = {
//   '@media (min-width:300px)': {
//     fontSize: '50px',
//     fontWeight: '700',
//     lineHeight: '66px',
//   },
//   '@media (min-width:600px)': {
//     fontSize: '62px',
//     fontWeight: '700',
//     lineHeight: '70px',
//   },
//   [theme.breakpoints.up('md')]: {
//     fontSize: '5rem',
//   },
// }
// sm: {fontWeight: '700', fontSize: '50px', lineHeight: '66px', mt:'15px',}
// lg: {fontWeight: '700', fontSize: '62px', lineHeight: '70px', mt:'25px',}

export default class App extends Component {
  static displayName = App.name
  constructor(props) {
    super(props)
    this.state = {
      isNewDesign: this.props.history.location.pathname === '/',
    }
    this.props.history.listen((location, action) => {
      this.props.TrackEvent(location.pathname, action)

      // TO ADD HERE NEW PAGES PATH IN ORDER TO DISPLAY CORRECT BACKGROUND
      this.setState({ isNewDesign: location.pathname === '/' })
    })
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <AuthConsumer TrackEvent={this.props.TrackEvent} SetLogin={this.props.SetLogin}>
          {({ getToken, getSubscriberPK, getPatientPK, checkCostEstimatorVisibility }) => {
            //Axios.defaults.withCredentials = true;
            Axios.interceptors.request.use(
              (config) => {
                config.headers.Authorization = 'Bearer ' + getToken()
                return config
              },
              (error) => {
                this.props.TrackEvent('ERROR', error.response)
              }
            )

            const claim = getSubscriberPK()
            const patientPK = getPatientPK()
            memberService
              .fetchSubscriberInfo(claim, patientPK)
              .pipe(map((y) => y.data))
              .subscribe((x) => {
                if (x.isSuccessful) {
                  memberService.setSubscriberInfo(x.result)
                } else {
                  memberService.setSubscriberInfo(false)
                }
              })

            return (
              <Layout
                children={Routes}
                checkCostEstimatorVisibility={checkCostEstimatorVisibility}
                isNewDesign={this.state.isNewDesign}
              ></Layout>
            )
          }}
        </AuthConsumer>
      </ThemeProvider>
    )
  }
}
