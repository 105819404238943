import Axios from '../upstream/axios-observable/dist'
import { serviceConfig } from './ServiceConfig'

const enrollmentServiceBaseUrl = serviceConfig.enrollmentServiceUrl + serviceConfig.enrollmentServiceVersion

export const enrollmentService = {
  getMemberEnrollmentDetails: (dependentPK) =>
    Axios.get(enrollmentServiceBaseUrl + '/Subscriber/Dependent', { params: { dependentPK } }),
  getSubscriberEnrollmentDetails: (pk) =>
    Axios.get(enrollmentServiceBaseUrl + '/Subscriber/SubscriberDetails', { params: { pk } }),
  getMaritalStatuses: () => Axios.get(enrollmentServiceBaseUrl + '/Values/MaritalStatusList'),
  getStates: () => Axios.get(enrollmentServiceBaseUrl + '/Values/StateList'),

  submitChangeRequest: (request, subscriberPK) =>
    Axios.post(enrollmentServiceBaseUrl + '/Subscriber/UpdateSubscriber', request, { params: { subscriberPK } }),
}
