import React, { Component } from 'react'
import { Row, Col, Input } from 'reactstrap'
import { memberService } from '../../services/MemberService'
import { enrollmentService } from '../../services/EnrollmentService'
import { Subscription } from 'rxjs'

export default class EditMemberComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      ...props.phoneNumber,
      ...props.alternatePhoneNumber,
      ...props.address,
      ...props.memberInfo,
      ...props.canEdit,
      isAddress1Valid: true,
      isAddress2Valid: true,
      isCityValid: true,
      isStateValid: true,
      isZipValid: true,
      isFirstNameValid: true,
      isLastNameValid: true,
      isPhoneValid: true,
      isPhone2Valid: true,
      isEmailValid: true,
      isEmail2Valid: true,
      canRequestEdit: true,
      ssn: null,
      patientPK: props.patientPK,
      subscriberPK: props.subscriberPK,
      maritalStatus: {
        options: [],
        validationMessage: 'Please select a marital status',
        value: props.memberInfo.state,
        placeholder: 'Marital Status',
        label: 'Marital Status',
        validationRules: {
          isRequired: true,
        },
        touched: false,
        valid: true,
      },
      states: {
        options: [],
        validationMessage: 'Please select a state',
        value: props.statepk,
        placeholder: 'State',
        label: 'State',
        validationRules: {
          isRequired: true,
        },
        touched: false,
        valid: true,
      },
      succeeded: false,
      failed: false,
      canRequest: true,
    }
    this.blurAndChangeHandler = this.blurAndChangeHandler.bind(this)
    this.subscription = new Subscription()
    //this.getEnrollmentMemberInfo();
    ///console.log(this.state);
    this.subscription.add(
      enrollmentService.getMaritalStatuses().subscribe((x) => {
        if (x.status === 200) {
          let statuses = [{ value: '', displayValue: 'UnKnown' }]
          let newStatuses = x.data.map((y) => {
            return {
              value: y.pk,
              displayValue: y.name,
            }
          })
          statuses.push(...newStatuses)

          var maritalStatus = { ...this.state.maritalStatus }
          maritalStatus.options = statuses
          this.setState({ maritalStatus })
        }
      })
    )

    this.subscription.add(
      enrollmentService.getStates().subscribe((x) => {
        if (x.status === 200) {
          let stateValues = x.data.map((y) => {
            return {
              value: y.pk,
              displayValue: y.name,
            }
          })
          var states = { ...this.state.states }
          states.options = stateValues
          this.setState({ states })
        }
      })
    )
  }

  toggleEditing() {
    if (this.state.canRequest) {
      return this.setState({
        isEditing: !this.state.isEditing,
      })
    }
  }

  handleSubmit = () => {
    var today = new Date()
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
    var yyyy = today.getFullYear()
    today = mm + '/' + dd + '/' + yyyy

    this.validator()
    //let request = {
    //  subscriberPK: this.state.subscriberPK,
    //  employerPK: this.state.memberInfo.employerPK,
    //  effectiveDate: today,
    //  subscriber: {
    //    address1: address.address1,
    //    address2: address.address2,
    //    city: address.city,
    //    stateID: this.state.states.value,
    //    statePK: this.state.states.value,
    //    zipCode: address.zipCode,
    //    ssn: address.ssn,
    //    firstName: dependent.firstName,
    //    lastName: dependent.lastName,
    //    middleInitial: dependent.middleInitial,
    //    genderPK: dependent.genderPK,
    //    phone1: address.phoneNumber,
    //      emailAddress: address.email,
    //      phone2: address.alternatePhoneNumber,
    //      emailAddress2: address.email2,
    //    dateOfBirth: dependent.dateOfBirth,
    //    maritalStatusPK: this.state.maritalStatus.value,
    //  },
    //  };

    //this.subscription.add(
    //  enrollmentService
    //    .submitChangeRequest(request, this.props.subscriberPK)
    //    .subscribe(
    //      (e) => {
    //        //console.log("this is a thing1");
    //        if (e.status === 200) {
    //          this.setState({
    //            succeeded: true,
    //            error: false,
    //          });
    //        } else {
    //          this.setErrorMessage();
    //        }
    //      },
    //      (error) => {
    //        //var message = error.

    //        //console.log(error.response);
    //        //console.log(error.response.data.Message);
    //        console.log(this.state);
    //        if (error && error.response && error.response.data.Message) {
    //          this.setState({ errorMessage: error.response.data.Message });
    //        } else {
    //          this.setErrorMessage();
    //        }
    //        this.setState({ error: true });
    //      }
    //    )
    //);

    this.subscription.add(
      memberService
        .submitMemberProfile({
          subscriberPK: this.state.subscriberPK,
          email: this.state.email,
          email2: this.state.email2,
          phone: this.state.phoneNumber,
          phone2: this.state.alternatePhoneNumber,
          IsPaperless: null,
        })
        .subscribe(
          (e) => {
            console.log(e)
            if (e.status === 200) {
              this.setState({
                succeeded: true,
                error: false,
                isEditing: !this.state.isEditing,
              })
            } else {
              this.setErrorMessage()
            }
          },
          (error) => {
            console.log(this.state)
            if (error && error.response && error.response.data.Message) {
              this.setState({ errorMessage: error.response.data.Message })
            } else {
              this.setErrorMessage()
            }
            this.setState({ error: true })
          }
        )
    )
  }

  setErrorMessage() {
    this.setState({ errorMessage: '' })
    this.setState({ error: true })

    if (!this.state.maritalStatusPK) {
      this.setState({ errorMessage: 'Please select a marital status' })
    } else if (!this.state.isEmailValid) {
      this.setState({ errorMessage: 'Please enter an email' })
    } else if (!this.state.isPhoneValid) {
      this.setState({ errorMessage: 'Please enter a valid phone number' })
    } else if (!this.state.isAddress1Valid || !this.state.isCityValid || !this.state.isZipValid || !this.state.isStateValid) {
      this.setState({
        errorMessage: 'Please make sure you entered a valid address',
      })
    }
  }

  getEnrollmentMemberInfo = async () => {
    this.subscription.add(
      enrollmentService.getSubscriberEnrollmentDetails(this.state.subscriberPK).subscribe((x) => {
        if (x.status === 200) {
          this.setState({ memberInfo: x.data })
          this.setState({ canRequest: !x.data.hasPendingRequest })
          let states = { ...this.state.states }
          states.value = x.data.address.statePK
          this.setState({ states })
          //console.log("Setting marital Status");
          let maritalStatus = { ...this.state.maritalStatus }
          maritalStatus.value = x.data.maritalStatus
          this.setState({ maritalStatus })
        }
      })
    )
  }

  validator() {
    let address = this.state
    if (!address.address1) {
      this.setState({ isAddress1Valid: false })
    } else {
      this.setState({ isAddress1Valid: true })
    }
    if (!address.city) {
      this.setState({ isCityValid: false })
    } else {
      this.setState({ isCityValid: true })
    }

    if (!address.state) {
      this.setState({ isStateValid: false })
    } else {
      this.setState({ isStateValid: true })
    }

    if (!address.zipCode) {
      this.setState({ isZipValid: false })
    } else {
      this.setState({ isZipValid: true })
    }

    if (!address.zipCode) {
      this.setState({ isZipValid: false })
    } else {
      this.setState({ isZipValid: true })
    }

    //if (this.state.phoneNumber) {
    //    this.setState({ isPhoneValid: true });
    //} else {
    //    this.setState({
    //        isPhoneValid: false,
    //    });
    //}
    //if (this.state.alternatePhoneNumber) {
    //    this.setState({ isPhone2Valid: true });
    //} else {
    //    this.setState({
    //        isPhone2Valid: false,
    //    });
    //}

    if (this.state.email) {
      this.setState({ isEmailValid: true })
    } else {
      this.setState({ isEmailValid: false })
    }
    if (this.state.email2) {
      this.setState({ isEmail2Valid: true })
    } else {
      this.setState({ isEmail2Valid: false })
    }

    this.forceUpdate()
    return this.state
  }

  blurAndChangeHandler = (event) => {
    console.log(event)
    if (event.target) {
      const name = event.target.name
      const value = event.target.value

      if (name === 'addressState') {
        let states = { ...this.state.states }
        states.value = value
        states.touched = true
        this.setState({
          statePK: value,
        })
        this.setState({ states })
      } else {
        let maritalStatus = { ...this.state.maritalStatus }
        maritalStatus.value = value
        maritalStatus.touched = true
        // console.log(maritalStatus);
        this.setState({
          maritalStatusPK: value,
        })
        this.setState({ maritalStatus })
      }
    }
    this.validator()
  }

  handleChange = async (event) => {
    const { target } = event
    const value = target.type === 'checkbox' ? target.checked : target.value
    const { name } = target

    this.setState({
      [name]: value,
    })
    this.validator()
    //this.forceUpdate();
  }

  render() {
    if (this.state.isEditing) {
      // Show Input field
      let sent = this.state.succeeded ? <div className="alert alert-success">Profile Successfully Updated.</div> : <></>
      let error = this.state.error ? (
        <div className="alert alert-danger">Error submitting request. {this.state.errorMessage}</div>
      ) : (
        <></>
      )

      return (
        <div className="card-body bg-primary-dark extra-rounded m-2">
          <Row>
            <h6 className="card-section-header">Contact Information</h6>
            <Col sm="3">
              <button className="btn profile-button btn-sm fw-7" onClick={(event) => this.handleSubmit(event)}>
                Submit
              </button>
            </Col>
            <Col sm="3">
              <button className="btn profile-button btn-sm fw-7" onClick={(event) => this.toggleEditing(event)}>
                Cancel
              </button>
            </Col>
          </Row>
          <Row>
            <Col sm="2">
              <p className="no-h">
                <small className="fw-7 text-primary">Address: </small>
              </p>
            </Col>
            <Col sm="10">
              <p className="no-h">
                <small className="fw-7 text-primary">{this.props.address.address1}</small>
              </p>
              {this.props.address.address2 && (
                <p className="no-h">
                  <small className="fw-7 text-primary"> {this.props.address.address2}</small>
                </p>
              )}
              <p className="no-h">
                <small className="fw-7 text-primary">
                  {this.props.address.city}, {this.props.address.state} {this.props.address.zipCode}
                </small>
              </p>
            </Col>
            <Col sm="2">
              <p className="no-h">
                <small className="fw-7 text-primary">Primary Email: </small>
              </p>
            </Col>
            <Col sm="10">
              <Input
                className={this.state.fieldClasses}
                type={this.state.fieldType}
                defaultValue={this.state.email}
                placeholder="email"
                onChange={this.handleChange}
                name="email"
                valid={this.state.isEmailValid}
                invalid={!this.state.isEmailValid}
              />
            </Col>

            <Col sm="2">
              <p className="no-h">
                <small className="fw-7 text-primary">Secondary Email: </small>
              </p>
            </Col>
            <Col sm="10">
              <Input
                className={this.state.fieldClasses}
                type={this.state.fieldType}
                defaultValue={this.state.email2}
                placeholder="Secondary Email"
                onChange={this.handleChange}
                name="email2"
                valid={this.state.isEmail2Valid}
                invalid={!this.state.isEmail2Valid}
              />
            </Col>
            <Col sm="2">
              <p className="no-h">
                <small className="fw-7 text-primary">Primary Phone Number: </small>
              </p>
            </Col>
            <Col sm="10">
              <Input
                className={this.state.fieldClasses}
                type={this.state.fieldType}
                defaultValue={this.state.phoneNumber}
                placeholder="phone number"
                name="phone"
                onChange={(e) => {
                  this.setState({ phoneNumber: e.target.value })
                }}
              />
            </Col>
            <Col sm="2">
              <p className="no-h">
                <small className="fw-7 text-primary">Secondary Phone Number: </small>
              </p>
            </Col>
            <Col sm="10">
              <Input
                className={this.state.fieldClasses}
                type={this.state.fieldType}
                defaultValue={this.state.alternatePhoneNumber}
                placeholder="Alternate PhoneNumber"
                name="phone2"
                onChange={(ev) => {
                  this.setState({ alternatePhoneNumber: ev.target.value })
                }}
              />
            </Col>
          </Row>

          {sent}
          {error}
        </div>
      )
    } else {
      // Show read-only
      let cantRequest = this.state.canRequest ? (
        <></>
      ) : (
        <div className="alert alert-warning">You already have a pending request, you may not edit your profile at this time.</div>
      )
      let address2 = this.props.address.city + ', ' + this.props.address.state + ', ' + this.props.address.zipCode
      let address1 = this.props.address.address1 + (this.props.address.address2 ? ' ' + this.props.address.address2 : '')
      console.log(this.props.address)
      return (
        <div className="card-body bg-primary-dark extra-rounded m-2">
          <Row>
            <Col>
              <h6 sm="10" className="float-left card-section-header">
                Contact Information
              </h6>
            </Col>
            {this.state.canRequestEdit ? (
              <Col>
                <button
                  sm="2"
                  className="float-right btn profile-button btn-sm fw-7"
                  onClick={(event) => this.toggleEditing(event)}
                >
                  Edit
                </button>
              </Col>
            ) : (
              <></>
            )}
          </Row>
          <div className="profile-content pt-2">
            <Row>
              <AddressField header="Address" text1={address1} text2={address2} />
              <AddressField header="Primary Phone Number" text1={this.state.phoneNumber} />
              <AddressField header="Primary Email" text1={this.state.email} />
              <AddressField header="Secondary Phone Number" text1={this.state.alternatePhoneNumber} />
              <AddressField header="Secondary Email" text1={this.state.email2} />
            </Row>
            {cantRequest}
          </div>
        </div>
      )
    }
  }
}

const AddressField = (props) => {
  let fieldHeader = props.header
  let text1 = props.text1
  let text2 = props.text2
  return (
    <>
      <Col sm="6">
        <p className="fw-5 fs-14 text-light">{fieldHeader}</p>
        <p className="fw-4 fs-14 text-light profile-detail">{text1}</p>
        {text2 ? <p className="fw-4 fs-14 text-light profile-detail">{text2}</p> : ''}
      </Col>
    </>
  )
}
