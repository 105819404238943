import React, { PureComponent } from 'react'
import { memberService } from '../../services/MemberService'
import { switchMap } from 'rxjs/operators'
import { forkJoin, Subscription } from 'rxjs'
import { LoadingCard } from './LoadingCard'

const PlanRow = (props) => {
  return (
    // Is only card
    <div>
      <small className="text-primary fw-7">
        {props.plan.planType} {props.plan.planYear}
      </small>
      <div>
        <h5>
          <strong className="text-primary fw-7">{props.plan.planName}</strong>
        </h5>
      </div>
    </div>
  )
}

export class MyPlanCard extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      subscriberPK: false,
      dependentPK: false,
      planInformation: false,
    }

    this.subscription = new Subscription()
  }

  componentDidMount() {
    this.subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            this.setState({ subscriberPK: x.subscriberPK, dependentPK: x.dependentPK })
            return forkJoin({
              subscriberPlans: memberService.fetchPlanList(x.subscriberPK),
              dependentPlans: memberService.fetchPlanList(x.subscriberPK, x.dependentPK),
            })
          })
        )
        .subscribe((x) => {
          const subscriberPlans = x.subscriberPlans?.data?.result
          const now = new Date()
          const subscriberMedicalPlans = subscriberPlans?.filter((plan) => {
            const coverageEndDate = new Date(plan.coverageEndDate)
            const coverageBeginDate = new Date(plan.coverageBeginDate)
            return (
              plan.planType === 'Medical' &&
              now >= coverageBeginDate &&
              now < coverageEndDate &&
              coverageEndDate > coverageBeginDate
            )
          })

          const dependentPlans = x.dependentPlans?.data?.result
          if (subscriberMedicalPlans?.length > 0) {
            this.setState({
              planInformation: dependentPlans,
            })
          } else {
            this.setState({
              planInformation: dependentPlans?.filter((plan) => plan.planType !== 'Medical'),
            })
          }

          this.forceUpdate()
        })
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    if (this.state.planInformation) {
      if (this.state.planInformation.length > 0) {
        return (
          // Is only card
          <div className="card-body text-light extra-rounded">
            <span className="h5 fw-7 text-light">My Plan</span>
            {this.state.planInformation.map((x, ind) => (
              <PlanRow key={ind} plan={x}></PlanRow>
            ))}
          </div>
        )
      } else {
        return (
          // No data returned
          <div className="card-body extra-rounded">
            <small className="fw-7 text-light">You have no current Plan Information.</small>
          </div>
        )
      }
    } else {
      return <LoadingCard />
    }
  }
}
