import React from 'react'
import { memberService } from '../../services/MemberService'
import { Subscription } from 'rxjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'reactstrap'

export class ReceiptUpload extends React.Component {
  constructor(props) {
    super(props)
    this.fileAdded = this.fileAdded.bind(this)
    this.handleDelete = this.handleDelete.bind(this)

    this.state = {
      file: false,
      subscriberInfo: false,
      fileId: props.id,
      index: props.index,
      expenseIndex: props.expenseIndex,
      callBack: props.callback,
      deleteCallBack: props.deleteCallBack,
      uploaded: false,
      error: false,
      fileSizeMax: 4194304, //bytes (4MB),
      tabType: props.tabType,
    }
    this.subscription = new Subscription()
  }

  componentDidMount() {
    this.subscription.add(
      memberService.subscriberInfo().subscribe((x) => {
        this.setState({ subscriberInfo: x })
      })
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  fileAdded = async (e) => {
    await this.setState({ file: e.target.files[0] })
    console.log(this.state.file)
    if (this.state.file.size <= this.state.fileSizeMax) {
      this.fileUpload(this.state.file)
    } else {
      this.setState({ error: 'File size is too large!' })
    }
  }

  fileUpload(file) {
    this.subscription.add(
      memberService.uploadReceipt(file, this.state.subscriberInfo.subscriberPK).subscribe(
        (x) => {
          if (x.status === 200) {
            this.setState({
              fileId: x.data,
              uploaded: true,
            })

            this.state.callBack(x.data, this.state.index)
            console.log(this.state)
          } else {
            this.setState({ error: true })
          }
        },
        (error) => {
          this.setState({ error: true })
        }
      )
    )
  }

  handleDelete() {
    this.setState({
      file: false,
      fileId: false,
      uploaded: false,
    })
    this.state.deleteCallBack(this.state.fileId, this.state.index)
  }

  render() {
    if (!this.state.uploaded) {
      let result = ''

      if (this.state.error) {
        result = <div className="alert alert-danger">An error occurred while uploading the file. </div>
      }
      let elementID = this.state.tabType + this.state.expenseIndex + 'customFile' + this.state.index
      result = (
        <div className="m-1 custom-file">
          <input type="file" className="form-control" id={elementID} onChange={this.fileAdded} />
          <label htmlFor={elementID} className="custom-file-label">
            Choose file
          </label>
        </div>
      )

      return result
    }

    return (
      <div className="m-1 custom-attachment border border-white rounded m-auto">
        <Row>
          <Col xs="6" className="m-auto">
            <span className="text-primary-light-blue fw-7 m-1">Filename:</span>{' '}
            <span className="text-secondary fw-7 ml-1">{this.state.file.name}</span>
          </Col>
          <Col xs="4" className="m-auto">
            <span className="text-primary-light-blue fw-7 m-1">Filesize:</span>
            <span className="fw-7 ml-1 text-secondary">{Math.floor(Math.log(this.state.file.size) / Math.log(1000))} MB</span>
          </Col>
          <Col xs="2" className="m-auto">
            <FontAwesomeIcon
              icon={faTrash}
              color="red"
              className="m-2 float-right"
              style={{ cursor: 'pointer' }}
              onClick={this.handleDelete}
            />
          </Col>
        </Row>
      </div>
    )
  }
}
