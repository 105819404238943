import { map } from 'rxjs/operators'
import Axios from '../upstream/axios-observable/dist'
import { serviceConfig } from './ServiceConfig'

const serviceBaseUrl = serviceConfig.memberServiceUrl + serviceConfig.memberServiceVersion

export const contactService = {
  emailNurse: (data, subscriberPK) =>
    Axios.post(serviceBaseUrl + '/Contact/EmailNurse', data, { params: { subscriberPK: subscriberPK } }).pipe(map((x) => x.data)),
  emailCaseManagement: (data, subscriberPK) =>
    Axios.post(serviceBaseUrl + '/Contact/EmailCaseManagement', data, { params: { subscriberPK: subscriberPK } }).pipe(
      map((x) => x.data)
    ),
  emailCustomerService: (data, subscriberPK) =>
    Axios.post(serviceBaseUrl + '/Contact/EmailCustomerService', data, { params: { subscriberPK: subscriberPK } }).pipe(
      map((x) => x.data)
    ),
}
