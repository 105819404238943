import React, { useState, useMemo, useEffect } from 'react'
import Box from '@mui/material/Box'
import { Container, Stack, Typography } from '@mui/material'
import { Button, styled } from '@mui/material'
import GetTheMostImg from '../../GetTheMostImg.png'
import SeeAllBenefitsIcon from '../_newDesign/icons/svg/SeeAllBenefitsIcon.js'
import { Subscription, switchMap } from 'rxjs'
import { memberService } from '../../services/MemberService'

const Img = styled('img')(() => ({}))

export default function GetTheMostCard() {
  const subscription = useMemo(() => new Subscription(), [])
  const [state, setState] = useState({})

  useEffect(() => {
    subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            setState({
              subscriberPK: x.subscriberPK,
              patientPK: x.dependentPK,
              externalResources: state.externalResources,
            })
            return memberService.fetchMemberResources(x.subscriberPK, x.dependentPK)
          })
        )
        .subscribe((x) => {
          setState((prevState) => ({
            ...prevState,
            externalResources: x.data.result,
          }))
        })
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [state.externalResources, subscription])

  const findAProviderLink = state.externalResources?.find((resource) => resource.resourceType === 'Medical Network')?.webShortUrl
  const seeAllBenefitsLink = state.externalResources?.find(
    (resource) => resource.resourceType === 'Benefits Website'
  )?.webShortUrl

  return (
    <Box
      sx={{
        width: '100%',
        color: '#FFFFFF',
        margin: '0px',
      }}
    >
      <Container maxWidth="lg">
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
            },
          })}
        >
          <Img
            src={GetTheMostImg}
            alt={'Image'}
            sx={(theme) => ({
              width: '40%',
              height: 'auto',
              aspectRatio: 469 / 479,
              [theme.breakpoints.down('md')]: {
                marginTop: '40px',
                width: '100%',
              },
            })}
          ></Img>
          <Stack
            sx={(theme) => ({
              direction: 'column',
              justifyContent: 'center',
              gap: '24px',
              minHeight: '600px',
              width: '50%',
              [theme.breakpoints.down('md')]: {
                padding: '30px 20px 60px',
                width: '100%',
                maxWidth: 'unset',
                minHeight: 'unset',
                marginRight: 0,
              },
            })}
          >
            <Typography
              sx={(theme) => ({
                fontWeight: '700',
                fontSize: '50px',
                lineHeight: '60px',
                textAlign: 'left',
                [theme.breakpoints.only('md')]: {
                  fontSize: '32px',
                  lineHeight: '38px',
                },
              })}
            >
              Get the most out of your plan
            </Typography>
            <Typography
              sx={(theme) => ({
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '19.6px',
                textAlign: 'left',
                color: '#CCCCCC',
                [theme.breakpoints.up('lg')]: {
                  fontSize: '16px',
                  lineHeight: '22px',
                },
              })}
            >
              Click below to find an in-network provider or find your plan documents and coverage, reimbursement forms, and
              compare plans offered by your employer.
            </Typography>
            <Stack direction={'row'} spacing={1.5}>
              {findAProviderLink ? (
                <Button
                  variant="contained"
                  disableElevation
                  disableRipple //memberExternalResource.webShortUrl
                  target="_blank"
                  LinkComponent="a"
                  href={'http://' + findAProviderLink}
                  sx={(theme) => ({
                    height: '50px',
                    width: '169px',
                    textTransform: 'none',
                    bgcolor: '#66D9A9',
                    color: '#1C2033',
                    fontSize: '17px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    borderRadius: '8px',
                    px: '13px',
                    mt: '28px',
                    '&:hover': {
                      backgroundColor: '#00BF6F',
                      color: '#1C2033',
                    },
                    [theme.breakpoints.down('lg')]: {
                      width: '123px',
                      height: '30px',
                      fontSize: '13px',
                    },
                  })}
                >
                  Find a Provider
                </Button>
              ) : (
                <></>
              )}
              {seeAllBenefitsLink ? (
                <Button
                  variant="outlined"
                  disableElevation
                  disableRipple
                  startIcon={<SeeAllBenefitsIcon />}
                  target="_blank"
                  LinkComponent="a"
                  href={'http://' + seeAllBenefitsLink}
                  sx={(theme) => ({
                    width: '200px',
                    height: '50px',
                    textTransform: 'none',
                    outlineColor: '#66D9A9',
                    color: '#FFFFFF',
                    fontSize: '16px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    borderRadius: '8px',
                    px: '13px',

                    [theme.breakpoints.down('lg')]: {
                      width: '157px',
                      height: '30px',
                      fontSize: '13px',
                    },
                  })}
                >
                  See All Benefits
                </Button>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}
