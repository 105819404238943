import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function SeeAllBenefitsIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <path d="M12.4286 0.964286C12.4286 0.441965 12.8304 2.38419e-07 13.3929 2.38419e-07H19.7812C20.3437 2.38419e-07 20.7455 0.441965 20.7455 0.964286V7.39286C20.7455 7.95536 20.3437 8.35714 19.7812 8.35714C19.2589 8.35714 18.817 7.95536 18.817 7.39286V3.29464L9.85714 12.2545C9.49554 12.6563 8.89286 12.6563 8.53125 12.2545C8.12946 11.8929 8.12946 11.2902 8.53125 10.8884L17.4911 1.92857H13.3929C12.8304 1.92857 12.4286 1.52679 12.4286 0.964286ZM3.10714 1.28571H8.25C8.77232 1.28571 9.21429 1.72768 9.21429 2.25C9.21429 2.8125 8.77232 3.21429 8.25 3.21429H3.10714C2.54464 3.21429 2.14286 3.65625 2.14286 4.17857V17.6786C2.14286 18.2411 2.54464 18.6429 3.10714 18.6429H16.6071C17.1295 18.6429 17.5714 18.2411 17.5714 17.6786V12.5357C17.5714 12.0134 17.9732 11.5714 18.5357 11.5714C19.058 11.5714 19.5 12.0134 19.5 12.5357V17.6786C19.5 19.2857 18.1741 20.5714 16.6071 20.5714H3.10714C1.5 20.5714 0.214286 19.2857 0.214286 17.6786V4.17857C0.214286 2.61161 1.5 1.28571 3.10714 1.28571Z" />
    </SvgIcon>
  )
}
