import React from 'react'
import { Font, Image, Page, Text, View, Document, StyleSheet, Link } from '@react-pdf/renderer'
import _ from 'lodash'
import ReactMarkdown from 'react-markdown'
import { formatMiles } from '../../../../global/TextHelpers'
import { buildUrl } from './pdfUtils'
import { getPrice } from '../SearchResults/helpers'

Font.register({
  family: 'Arial',
  fonts: [
    { src: 'https://fonts.cdnfonts.com/s/29105/ARIAL.woff' },
    { src: 'https://fonts.cdnfonts.com/s/29105/ARIALI.woff', fontStyle: 'italic' },
    { src: 'https://fonts.cdnfonts.com/s/29105/ARIALBD.woff', fontWeight: 700 },
  ],
})

const styles = StyleSheet.create({
  body: {
    paddingTop: 70,
    paddingBottom: 65,
    paddingHorizontal: 70,
    fontFamily: 'Arial',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  headerList: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: 100,
  },
  h1: {
    fontSize: 16,
    fontWeight: 700,
  },
  h2: {
    fontSize: 14,
    fontWeight: 700,
    margin: '12pt 0 6pt 0',
  },
  h3: {
    fontSize: 12,
    fontWeight: 700,
  },
  text12: {
    fontSize: 12,
    textAlign: 'justify',
  },
  text: {
    fontSize: 11,
    textAlign: 'justify',
  },
  italic: {
    fontStyle: 'italic',
  },
  bold: {
    fontWeight: 700,
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  underlined: {
    textDecoration: 'underline',
  },
  section: {
    flexDirection: 'row',
    alignItems: 'baseline',
    marginTop: 10,
  },
  marginTop0: {
    marginTop: 0,
  },
  marginTop5: {
    marginTop: 5,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop16: {
    marginTop: 16,
  },
  marginBottom10: {
    marginBottom: 10,
  },
  marginBottom16: {
    marginBottom: 16,
  },
  marginBottom0: {
    marginBottom: 0,
  },
  limit: {
    marginBottom: 5,
    padding: 0,
  },
  break: {
    borderTop: '1pt solid #000',
    borderBottom: '1pt solid #000',
    margin: '12pt 0 2pt',
    padding: '10pt 5pt',
  },
  conclusion: {
    borderTop: '1pt solid #000',
    margin: '24pt 0 0',
    padding: '8pt 0',
  },
  cell: {
    padding: '3pt 5pt',
  },
  addressCell: {
    paddingTop: 3,
    height: 39,
    lineHeight: 1.5,
  },
  columns: {
    margin: 0,
    padding: 0,
    flexDirection: 'column',
  },
  rows: {
    margin: 0,
    padding: 0,
    flexDirection: 'row',
  },
  grow: {
    flexGrow: 2,
  },
  textBlack: {
    color: '#000000',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 8,
    bottom: 60,
    right: 75,
    textAlign: 'right',
    color: '#CCC',
  },
  borderBlackTop: {
    borderTop: '1pt solid #000',
  },
  borderBlackRight: {
    borderRight: '1pt solid #000',
  },
  borderBlackBottom: {
    borderBottom: '1pt solid #000',
  },
  borderBlackLeft: {
    borderLeft: '1pt solid #000',
  },
  borderGrayTop: {
    borderTop: '1pt solid #CCC',
  },
  borderGrayRight: {
    borderRight: '1pt solid #CCC',
  },
  borderGrayBottom: {
    borderBottom: '1pt solid #CCC',
  },
  borderGrayLeft: {
    borderLeft: '1pt solid #CCC',
  },
  borderWhiteBottom: {
    borderBottom: '1pt solid #FFF',
  },
  flexStart: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  width75: {
    width: 75,
  },
  expandH: {
    flexGrow: 2,
  },
})

export const formatDate = (date) => `${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`

const formatTime = (date) => {
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? 'p.m.' : 'a.m.'
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes
  return `${hours}:${minutes} ${ampm}`
}

const EstimatesPdf = ({
  title,
  author,
  pageSize,
  date,
  image,
  domain,
  inNetworkProviders,
  careType,
  procedureCode,
  procedure,
  isPrecert,
  zip,
  outOfNetworkProviders,
  showReducedResult,
  disclaimer,
  planEndText,
  facilities,
  showFacilitiesReducedResult,
  appliedFilters,
}) => {
  const disclaimerSections = disclaimer?.sections.sort((s1, s2) => s1?.index - s2?.index)

  return (
    <Document title={title} author={author}>
      <Page size={pageSize} style={styles.body}>
        <View style={styles.header}>
          <Text style={styles.h1}>Cost Estimate Results</Text>
          <Image source={image} style={styles.logo} />
        </View>
        <View>
          <Text style={styles.text}>{`Date: ${formatDate(date)} | Time: ${formatTime(date)}`}</Text>
        </View>
        <View>
          <Text style={styles.h2}>Search Details</Text>
          <View style={[styles.section, styles.marginTop5]}>
            <Text style={styles.text12}>
              <Text style={styles.h3}>Care Type: </Text>
              {careType}
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.text12}>
              <Text style={styles.h3}>Procedure: </Text>
              <Text>{procedureCode}</Text> {procedure}
            </Text>
          </View>
          {isPrecert && (
            <View style={[styles.section, styles.marginTop5]}>
              <Text style={styles.text12}>
                <Text style={styles.h3}>NOTE: </Text>
                <Text style={styles.italic}>Procedure requires preliminary certification.</Text>
              </Text>
            </View>
          )}
          <View style={styles.section}>
            <Text style={styles.text12}>
              <Text style={styles.h3}>ZIP: </Text>
              {zip}
            </Text>
          </View>
          <View style={styles.break}>
            <Text style={[styles.text, styles.italic, styles.textAlignCenter]}>
              The estimates are informational only and not a guarantee of coverage or total cost. Learn more about limitations you
              should consider before making any decision.
            </Text>
          </View>
        </View>
        <View>
          <Text style={styles.h2}>Search Results</Text>
          <View style={[styles.section, styles.marginTop5]}>
            <Text style={styles.text12}>
              <Text style={styles.h3}>Filter by: </Text>
              {`Distance -- ${appliedFilters.distance || ''}`}
            </Text>
          </View>
          <View style={[styles.section, styles.marginTop5, styles.marginBottom10]}>
            <Text style={styles.text12}>
              <Text style={styles.h3}>Sort by: </Text>
              {appliedFilters.sortValue || ''}
            </Text>
          </View>
        </View>
        {inNetworkProviders?.length ? (
          <View style={styles.marginBottom16}>
            <View>
              <View style={[styles.headerList, styles.marginBottom0, styles.marginTop0]}>
                <Text style={styles.h2}>In-network Providers</Text>
                {showReducedResult && (
                  <Text style={[styles.text, styles.italic, styles.marginTop5]}>Only the first 20 items are listed.</Text>
                )}
              </View>
              {appliedFilters.name?.trim() ? (
                <View style={[styles.section, styles.marginBottom10, styles.marginTop5]}>
                  <Text style={styles.text12}>
                    <Text style={styles.h3}>Filter by Provider Name: </Text>
                    {appliedFilters.name || ''}
                  </Text>
                </View>
              ) : null}
            </View>
            {inNetworkProviders.map((inp, index) => (
              <View
                key={index}
                style={[styles.borderBlackBottom, styles.borderBlackTop, styles.borderBlackLeft, styles.borderBlackRight]}
                wrap={false}
              >
                <Text
                  style={[styles.text, styles.cell, styles.bold, styles.borderGrayBottom]}
                >{`${inp.provider.firstName} ${inp.provider.lastName}`}</Text>
                <Text style={[styles.text, styles.cell, styles.borderGrayBottom]}>{inp.provider.specialtyName}</Text>
                <Text style={[styles.text, styles.cell, styles.borderGrayBottom]}>{inp.facility.name}</Text>
                <View style={styles.rows}>
                  <View style={[styles.columns, styles.width75]}>
                    <Text style={[styles.text, styles.cell, styles.borderGrayRight, styles.borderGrayBottom]}>Total Cost</Text>
                    <Text style={[styles.text, styles.cell, styles.borderGrayRight, styles.borderGrayBottom]}>Plan Covers</Text>
                    <Text style={[styles.text, styles.cell, styles.borderGrayRight]}>My Cost</Text>
                  </View>
                  <View style={[styles.columns, styles.width75]}>
                    <Text
                      style={[styles.text, styles.textAlignRight, styles.cell, styles.borderGrayRight, styles.borderGrayBottom]}
                    >
                      {getPrice(inp.totalCost)}
                    </Text>
                    <Text
                      style={[styles.text, styles.textAlignRight, styles.cell, styles.borderGrayRight, styles.borderGrayBottom]}
                    >
                      {getPrice(inp.planCover)}
                    </Text>
                    <Text style={[styles.text, styles.textAlignRight, styles.cell, styles.borderGrayRight]}>
                      {getPrice(inp.patientCost)}
                    </Text>
                  </View>
                  <View style={styles.grow}>
                    <View style={styles.columns}>
                      <Text style={[styles.text, styles.cell, styles.addressCell]}>
                        {inp.facility.address.addressLine1 && inp.facility.address.addressLine1.trim() !== ''
                          ? `${inp.facility.address.addressLine1.trim()}, `
                          : ''}
                        {inp.facility.address.addressLine2 && inp.facility.address.addressLine2.trim() !== ''
                          ? `${inp.facility.address.addressLine2.trim()}, `
                          : ''}
                        {inp.facility.address.city && inp.facility.address.city.trim() !== ''
                          ? `${inp.facility.address.city.trim()}, `
                          : ''}
                        {inp.facility.address.state && inp.facility.address.state.trim() !== ''
                          ? `${inp.facility.address.state.trim()}, `
                          : ''}
                        {`${inp.facility.address.zip}`}
                      </Text>
                      <Text style={[styles.text, styles.cell]}>
                        {inp.distance ? `~ ${formatMiles(inp.distance.distanceInMiles)} miles` : ''}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.rows}>
                  <Text style={[styles.text, styles.cell, styles.borderGrayRight, styles.borderGrayTop]}>
                    {(!inp.facility.phoneNumbers || inp.facility.phoneNumbers.filter((it) => it?.trim()?.length).length === 0) &&
                      'tel. Not available'}
                    {inp.facility.phoneNumbers && inp.facility.phoneNumbers[0]?.trim() && `tel. ${inp.facility.phoneNumbers[0]}`}
                    {inp.facility.phoneNumbers && inp.facility.phoneNumbers[1]?.trim() && `, ${inp.facility.phoneNumbers[1]}`}
                    {inp.facility.phoneNumbers && inp.facility.phoneNumbers[2]?.trim() && `, ${inp.facility.phoneNumbers[2]}`}
                  </Text>
                  <Text style={[styles.text, styles.cell, styles.borderGrayTop, styles.expandH]}>
                    {inp.facility.websiteUrl && inp.facility.websiteUrl?.trim() !== ''
                      ? inp.facility.websiteUrl
                      : 'URL Not available'}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        ) : null}

        {facilities?.length ? (
          <View style={styles.marginBottom16}>
            <View>
              <View style={[styles.headerList, styles.marginBottom0, styles.marginTop0]}>
                <Text style={styles.h2}>In-network Facilities</Text>
                {showFacilitiesReducedResult && (
                  <Text style={[styles.text, styles.italic, styles.marginTop5]}>Only the first 20 items are listed.</Text>
                )}
              </View>
              {appliedFilters.facilityName?.trim() ? (
                <View style={[styles.section, styles.marginBottom10, styles.marginTop5]}>
                  <Text style={styles.text12}>
                    <Text style={styles.h3}>Filter by Facility Name: </Text>
                    {appliedFilters.facilityName || ''}
                  </Text>
                </View>
              ) : null}
            </View>

            {facilities.map((inp, index) => (
              <View
                key={index}
                style={[styles.borderBlackBottom, styles.borderBlackTop, styles.borderBlackLeft, styles.borderBlackRight]}
                wrap={false}
              >
                <Text style={[styles.text, styles.cell, styles.bold, styles.borderGrayBottom]}>{inp.facility.name}</Text>
                <Text style={[styles.text, styles.cell, styles.borderGrayBottom]}>
                  {inp.facility.specialtyName?.trim() || 'No specialty provided'}
                </Text>
                <View style={styles.rows}>
                  <View style={[styles.columns, styles.width75]}>
                    <Text style={[styles.text, styles.cell, styles.borderGrayRight, styles.borderGrayBottom]}>Total Cost</Text>
                    <Text style={[styles.text, styles.cell, styles.borderGrayRight, styles.borderGrayBottom]}>Plan Covers</Text>
                    <Text style={[styles.text, styles.cell, styles.borderGrayRight]}>My Cost</Text>
                  </View>
                  <View style={[styles.columns, styles.width75]}>
                    <Text
                      style={[styles.text, styles.textAlignRight, styles.cell, styles.borderGrayRight, styles.borderGrayBottom]}
                    >
                      {getPrice(inp.totalCost, 'Not provided')}
                    </Text>
                    <Text
                      style={[styles.text, styles.textAlignRight, styles.cell, styles.borderGrayRight, styles.borderGrayBottom]}
                    >
                      {getPrice(inp.planCover)}
                    </Text>
                    <Text style={[styles.text, styles.textAlignRight, styles.cell, styles.borderGrayRight]}>
                      {getPrice(inp.patientCost)}
                    </Text>
                  </View>
                  <View style={styles.grow}>
                    <View style={styles.columns}>
                      <Text style={[styles.text, styles.cell, styles.addressCell]}>
                        {inp.facility.address.addressLine1 && inp.facility.address.addressLine1.trim() !== ''
                          ? `${inp.facility.address.addressLine1.trim()}, `
                          : ''}
                        {inp.facility.address.addressLine2 && inp.facility.address.addressLine2.trim() !== ''
                          ? `${inp.facility.address.addressLine2.trim()}, `
                          : ''}
                        {inp.facility.address.city && inp.facility.address.city.trim() !== ''
                          ? `${inp.facility.address.city.trim()}, `
                          : ''}
                        {inp.facility.address.state && inp.facility.address.state.trim() !== ''
                          ? `${inp.facility.address.state.trim()}, `
                          : ''}
                        {`${inp.facility.address.zip}`}
                      </Text>
                      <Text style={[styles.text, styles.cell]}>
                        {inp.distance ? `~ ${formatMiles(inp.distance.distanceInMiles)} miles` : ''}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.rows}>
                  <Text style={[styles.text, styles.cell, styles.borderGrayRight, styles.borderGrayTop]}>
                    {(!inp.facility.phoneNumbers || inp.facility.phoneNumbers.filter((it) => it?.trim()?.length).length === 0) &&
                      'tel. Not available'}
                    {inp.facility.phoneNumbers && inp.facility.phoneNumbers[0]?.trim() && `tel. ${inp.facility.phoneNumbers[0]}`}
                    {inp.facility.phoneNumbers && inp.facility.phoneNumbers[1]?.trim() && `, ${inp.facility.phoneNumbers[1]}`}
                    {inp.facility.phoneNumbers && inp.facility.phoneNumbers[2]?.trim() && `, ${inp.facility.phoneNumbers[2]}`}
                  </Text>
                  <Text style={[styles.text, styles.cell, styles.borderGrayTop, styles.expandH]}>
                    {inp.facility.websiteUrl && inp.facility.websiteUrl?.trim() !== ''
                      ? inp.facility.websiteUrl
                      : 'URL Not available'}
                  </Text>
                </View>
              </View>
            ))}
          </View>
        ) : null}

        <View wrap={false} style={[styles.flexStart]}>
          <Text style={styles.h2}>Out-of-network Rates</Text>
          <View>
            <View style={[styles.borderBlackTop, styles.borderBlackLeft, styles.borderBlackBottom, styles.borderBlackRight]}>
              <View style={styles.rows}>
                <View style={[styles.columns, styles.width75]}>
                  <Text style={[styles.text, styles.cell, styles.borderGrayRight, styles.borderGrayBottom]}>Total Cost</Text>
                  <Text style={[styles.text, styles.cell, styles.borderGrayRight, styles.borderGrayBottom]}>Plan Covers</Text>
                  <Text style={[styles.text, styles.cell, styles.borderGrayRight]}>My Cost</Text>
                </View>
                <View style={[styles.columns, styles.width75]}>
                  <Text style={[styles.text, styles.textAlignRight, styles.cell, styles.borderGrayBottom]}>
                    {getPrice(outOfNetworkProviders.totalCost)}
                  </Text>
                  <Text style={[styles.text, styles.textAlignRight, styles.cell, styles.borderGrayBottom]}>
                    {getPrice(outOfNetworkProviders.planCover)}
                  </Text>
                  <Text style={[styles.text, styles.textAlignRight, styles.cell]}>
                    {getPrice(outOfNetworkProviders.patientCost)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        {disclaimer ? (
          <View wrap={false} style={[styles.flexStart]}>
            <Text style={styles.h2}>Estimate Disclaimer</Text>
            <View style={styles.marginTop10}>
              <Text style={styles.text12}>
                <Text style={styles.h3}>NOTE: </Text>
                <Text style={styles.italic}>
                  <ReactMarkdown>{planEndText || 'No plan year end date found.'}</ReactMarkdown>
                </Text>
              </Text>
            </View>
            <View style={[styles.section, styles.marginTop16]}>
              <Text style={styles.text12}>
                <ReactMarkdown>{disclaimer?.header}</ReactMarkdown>
              </Text>
            </View>
            <View style={[styles.marginTop5]}>
              {!_.isEmpty(disclaimerSections) && (
                <>
                  {disclaimerSections.map((section) => (
                    <>
                      <View style={styles.section}>
                        <Text style={styles.h3}>
                          <ReactMarkdown>{section?.title}</ReactMarkdown>
                        </Text>
                      </View>
                      <View style={styles.marginTop5}>
                        <Text style={styles.text12}>
                          <ReactMarkdown>{section?.body}</ReactMarkdown>
                        </Text>
                      </View>
                    </>
                  ))}
                </>
              )}
            </View>
            <View style={[styles.conclusion]}>
              <Text style={[styles.text12]}>
                <ReactMarkdown
                  components={{
                    a: ({ ...props }) => {
                      return (
                        <Link src={buildUrl(props.href)} style={styles.textBlack}>
                          {props.children}
                        </Link>
                      )
                    },
                  }}
                >
                  {disclaimer?.footer}
                </ReactMarkdown>
              </Text>
            </View>
          </View>
        ) : null}

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} fixed />
      </Page>
    </Document>
  )
}

export default EstimatesPdf
