import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { Button, ListGroup } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faRotateLeft } from '@fortawesome/pro-light-svg-icons'
import NavigationPanel from './components/NavigationPanel'
import { STEPS, STEPS_CONFIG } from './constants'
import MediaQuery from 'react-responsive'
import SessionService from '../../../services/SessionService'
import Modal from './components/generic/Modal'
import Checkbox from './components/generic/Checkbox'
import { useHistory } from 'react-router-dom'
import Spinner from './components/generic/Spinner'
import { ezChoiceService } from '../../../services/EZChoiceService'
import AuthService from '../../../auth/AuthService'
import { Subscription } from 'rxjs'
import { ToastProvider } from './components/SearchResults/ToastContext'

const EZChoice = () => {
  const { push } = useHistory()
  const [isDisclaimerLoaded, setIsDisclaimerLoaded] = useState(true)
  const [isProceeding, setIsProceeding] = useState(false)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [isAgreementDisclaimerVisible, setIsAgreementDisclaimerVisible] = useState(false)
  const [disclaimerData, setDisclaimerData] = useState(false)
  const [disclaimerLoadingError, setDisclaimerLoadingError] = useState(false)
  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const [isNextDisabled, setIsNextDisabled] = useState(true)
  const [savedValues, setSavedValues] = useState({})
  const [value, setValue] = useState('')
  const [isFromBack, setIsFromBack] = useState(false)
  const [isRestartClicked, setIsRestartClicked] = useState(false)
  const sessionService = useMemo(() => new SessionService(), [])
  const Component = STEPS_CONFIG[activeStepIndex].component
  const authService = new AuthService()
  const subscriberPK = authService.getSubscriberPK()
  const dependentPK = authService.getPatientPK()
  const subscription = useMemo(() => new Subscription(), [])

  const fetchAgreementDisclaimer = () => {
    subscription.add(
      ezChoiceService
        .getDisclaimerAgreementAcceptance(subscriberPK, dependentPK)
        .toPromise()
        .then((res) => {
          const isAgreedToLatest = res?.data?.isAgreedToLatest
          if (isAgreedToLatest) {
            setIsAgreementDisclaimerVisible(false)
            setIsDisclaimerLoaded(true)
          } else {
            setIsAgreementDisclaimerVisible(true)
          }
        })
        .catch(() => {
          push('')
        })
    )
  }

  const fetchDisclaimerData = () => {
    subscription.add(
      ezChoiceService
        .getDisclaimerAgreementData()
        .toPromise()
        .then((res) => {
          setDisclaimerData(res?.data)
        })
        .catch(() => {
          push('')
        })
    )
  }

  const saveDisclaimerAgreement = (isAgreed) => {
    setIsProceeding(true)
    setDisclaimerLoadingError(false)
    subscription.add(
      ezChoiceService
        .saveDisclaimerAgreementAcceptance(subscriberPK, dependentPK, {
          isAgreed,
          disclaimerPublishedOn: disclaimerData?.publishedOn,
        })
        .toPromise()
        .then(() => {
          setIsProceeding(false)
          setIsAgreementDisclaimerVisible(false)
          if (isAgreed) {
            setIsDisclaimerLoaded(true)
          } else {
            push('')
          }
        })
        .catch(() => {
          setIsProceeding(false)
          if (isAgreed) {
            setDisclaimerLoadingError(true)
          } else {
            setIsAgreementDisclaimerVisible(false)
            push('')
          }
        })
    )
  }

  useEffect(() => {
    if (!disclaimerData && isAgreementDisclaimerVisible) {
      fetchDisclaimerData()
    }
    // eslint-disable-next-line
  }, [isAgreementDisclaimerVisible])

  useEffect(() => {
    if (!isDisclaimerLoaded) {
      fetchAgreementDisclaimer()
    }
    // eslint-disable-next-line
  }, [isDisclaimerLoaded])

  useEffect(() => {
    const data = sessionService.getData()
    if (!_.isEmpty(data)) {
      const { activeStepIndex, isNextDisabled, savedValues, value, isFromBack } = data
      setActiveStepIndex(activeStepIndex)
      setIsNextDisabled(isNextDisabled)
      setSavedValues(savedValues)
      setValue(value)
      setIsFromBack(isFromBack)

      if (activeStepIndex === 0 && !isFromBack && _.isEmpty(savedValues) && !value) {
        setIsDisclaimerLoaded(false)
      }
    } else {
      setIsDisclaimerLoaded(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const activeStep = STEPS_CONFIG[activeStepIndex]
    if (activeStep.name === STEPS.careType || activeStep.name === STEPS.procedure) {
      setIsNextDisabled(value?.id === undefined)
    }
    if (activeStep.name === STEPS.location) {
      setIsNextDisabled(!value)
    }
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    if (!isDisclaimerLoaded) {
      return
    }
    const ezChoiceData = {
      activeStepIndex,
      isNextDisabled,
      savedValues,
      value,
      isFromBack,
    }
    sessionService.saveData(ezChoiceData)
    // eslint-disable-next-line
  }, [activeStepIndex, isNextDisabled, savedValues, value, isFromBack, isDisclaimerLoaded])

  const onNextClick = () => {
    const activeStep = STEPS_CONFIG[activeStepIndex]
    setSavedValues({
      ...savedValues,
      [activeStep.name]: value,
    })
    setValue('')
    setActiveStepIndex(activeStepIndex + 1)
    if (activeStepIndex === 1) {
      setIsNextDisabled(true)
    }
    setIsFromBack(false)
    sessionService.clearStepData()
  }

  const onBackClick = () => {
    sessionService.clearStepData()
    sessionService.clearStepDetails()
    const activeStep = STEPS_CONFIG[activeStepIndex]
    setSavedValues({
      ...savedValues,
      [activeStep.name]: '',
    })
    const nextStep = STEPS_CONFIG[activeStepIndex - 1]
    setValue(savedValues[nextStep.name])
    setActiveStepIndex(activeStepIndex - 1)
    setIsNextDisabled(false)
    setIsFromBack(true)
  }

  const onRefreshClick = () => {
    setIsRestartClicked(true)
    setActiveStepIndex(0)
    setSavedValues({})
    setValue('')
    setIsNextDisabled(true)
    setIsFromBack(false)
    sessionService.clearData()
    sessionService.clearStepDetails()
  }

  const onDisclaimerClose = () => {
    saveDisclaimerAgreement(false)
  }

  const onDisclaimerProceed = () => {
    if (isCheckboxChecked) {
      saveDisclaimerAgreement(true)
    }
  }

  return (
    <ToastProvider>
      <div className="d-flex flex-column flex-grow-1 ezchoice-container">
        <NavigationPanel
          values={savedValues}
          activeStepIndex={activeStepIndex}
          onBackClick={onBackClick}
          onNextClick={onNextClick}
          onRefreshClick={onRefreshClick}
          isNextDisabled={isNextDisabled}
          isRestartDisabled={!isDisclaimerLoaded}
        />
        {disclaimerLoadingError && !isProceeding ? (
          <div className="text-danger fw-7 h6 mt-4 ml-3">Loading Failed... please refresh your page!</div>
        ) : (
          <>
            {isDisclaimerLoaded ? (
              <div className="d-flex flex-column flex-grow-1">
                {Component && (
                  <Component
                    onChange={(value) => {
                      setValue(value)
                      setIsFromBack(false)
                    }}
                    value={value}
                    isFromBack={isFromBack}
                    savedValues={savedValues}
                    isRestartClicked={isRestartClicked}
                    setIsRestartClicked={setIsRestartClicked}
                  />
                )}
              </div>
            ) : (
              <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                <Spinner />
              </div>
            )}
          </>
        )}

        <Modal
          isOpen={isAgreementDisclaimerVisible && !_.isEmpty(disclaimerData)}
          centered
          title={disclaimerData?.title}
          hasError={disclaimerLoadingError}
          footerChildren={
            <>
              <Button color="link" onClick={() => onDisclaimerClose()} disabled={isProceeding} className="d-flex mr-3">
                Decline
              </Button>
              <Button
                color="primary"
                onClick={() => onDisclaimerProceed()}
                className="text-black ez-choice-btn"
                disabled={isProceeding || !isCheckboxChecked}
              >
                {isProceeding ? <Spinner className="text-light spinner-border-sm" /> : 'Proceed'}
              </Button>
            </>
          }
        >
          <div>{disclaimerData?.header}</div>
          <ListGroup className="mb-2 pb-1 text-black">
            {disclaimerData?.sections
              ?.sort((s1, s2) => s1?.index - s2?.index)
              ?.map((st, idx) => (
                <li className="ml-4" key={`section-${idx}`}>
                  {st?.body}
                </li>
              ))}
          </ListGroup>
          <Checkbox
            label="I understand and agree"
            onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
            checked={isCheckboxChecked}
            disabled={isProceeding}
          />
        </Modal>
      </div>
    </ToastProvider>
  )
}

export default EZChoice
