import React from 'react'
import { MediaQuery } from 'react-responsive'
import { Subscription } from 'rxjs'
import { Link } from 'react-router-dom'
import BootstrapTable from 'fad-react-bootstrap-table-next'
import { dateFormatter } from '../global/TextHelpers'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { options } from '../global/TableOptions'
import 'fad-react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { billService } from '../../services/BillService'
import { LoadingCard } from '../cards/LoadingCard'
import { memberService } from '../../services/MemberService'
import { switchMap } from 'rxjs/operators'
import { BillDetailPage } from '../pages/BillDetailPage.cardconnect'

export class BillList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      bills: false,
      error: false,
      subscriberPK: false,
    }

    this.columns = [
      {
        dataField: 'billNumber',
        text: 'Bill Number',
        formatter: (cell, row, rowIndex, extraData) => (
          <Link className="a-underline" to={`/bill/${row.billID}`}>
            {cell}
          </Link>
        ),
        headerAlign: 'center',
        headerClasses: 'table-header',
        classes: 'table-cell',
        sort: true,
      },
      {
        dataField: 'amount',
        text: 'Amount',
        formatter: (cell, row, rowIndex, extraData) => (
          <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell)}</span>
        ),
        headerAlign: 'center',
        headerClasses: 'table-header',
        classes: 'table-cell',
        sort: true,
      },
      {
        dataField: 'billDetailCount',
        text: '# of Claims',
        formatter: (cell, row, rowIndex, extraData) => <span>{cell}</span>,
        headerAlign: 'center',
        headerClasses: 'table-header',
        classes: 'table-cell',
        sort: true,
      },
      {
        dataField: 'billCreatedDate',
        text: 'Bill Date',
        formatter: (cell, row, rowIndex, extraData) => <span>{dateFormatter(cell, 'en-US')}</span>,
        headerAlign: 'center',
        headerClasses: 'table-header',
        classes: 'table-cell',
        sort: true,
      },
      {
        dataField: 'actionDate',
        text: 'Option to Pay Ends',
        formatter: (cell, row, rowIndex, extraData) => <span>{dateFormatter(cell, 'en-US')}</span>,
        headerAlign: 'center',
        headerClasses: 'table-header',
        classes: 'table-cell',
        sort: true,
      },
      {
        dataField: 'isPaid',
        text: 'Paid',
        formatter: (cell, row, rowIndex, extraData) => <span>{cell ? 'Yes' : 'No'}</span>,
        headerAlign: 'center',
        headerClasses: 'table-header',
        classes: 'table-cell',
        sort: true,
      },
      {
        dataField: 'paymentStatus',
        text: 'Payment Status',
        formatter: (cell, row, rowIndex, extraData) => <span>{cell}</span>,
        headerAlign: 'center',
        headerClasses: 'table-header',
        classes: 'table-cell',
        sort: true,
      },
      {
        dataField: 'paidDate',
        text: 'Paid Date',
        formatter: (cell, row, rowIndex, extraData) => <span>{dateFormatter(cell, 'en-US')}</span>,
        headerAlign: 'center',
        headerClasses: 'table-header',
        classes: 'table-cell',
        sort: true,
      },
    ]
    this.minColumns = [
      {
        dataField: 'billNumber',
        text: 'Bill Num',
        formatter: (cell, row, rowIndex, extraData) => (
          <Link className="a-underline" to={`/bill/${row.billID}`}>
            {cell}
          </Link>
        ),
        headerAlign: 'center',
        headerClasses: 'table-header',
        classes: 'table-cell',
        sort: true,
      },
      {
        dataField: 'amount',
        text: 'Amount',
        formatter: (cell, row, rowIndex, extraData) => (
          <span>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell)}</span>
        ),
        headerAlign: 'center',
        headerClasses: 'table-header',
        classes: 'table-cell',
        sort: true,
      },
      {
        dataField: 'billCreatedDate',
        text: 'Bill Date',
        formatter: (cell, row, rowIndex, extraData) => <span>{dateFormatter(cell, 'en-US')}</span>,
        headerAlign: 'center',
        headerClasses: 'table-header',
        classes: 'table-cell',
        sort: true,
      },
      {
        dataField: 'isPaid',
        text: 'Paid',
        formatter: (cell, row, rowIndex, extraData) => <span>{cell ? 'Yes' : 'No'}</span>,
        headerAlign: 'center',
        headerClasses: 'table-header',
        classes: 'table-cell',
        sort: true,
      },
    ]

    this.subscription = new Subscription()
  }

  componentDidMount() {
    this.subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            this.setState({ subscriberPK: x.subscriberPK })
            return billService.getBillList(x.subscriberPK)
          })
        )
        .subscribe(
          (response) => {
            console.log(response)
            if (response.status === 200) {
              this.setState({ bills: response.data.result })
            } else {
              this.setState({ error: true })
            }
          },
          (error) => {
            this.setState({ error: true })
          }
        )
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    const { bills, error } = this.state

    if (error) {
      return (
        <div className="mt-4 mb-4">
          <h3 className="text-primary fw-7">Bills</h3>
          <div className="alert alert-danger">
            Sorry, we are unable to pull your bill information at this time. Please try again
          </div>
        </div>
      )
    } else {
      if (bills) {
        return (
          <div className="bg-primary-light p-6 h-150">
            <BillDetailPage billPK={0} />
            <br />
            <div className="card-body bg-primary-dark extra-rounded">
              <h3 className="card-section-header ">Bills</h3>
              <p className="text-light">
                You may click the <em>"Bill Number"</em> link to view the bill details. On that page, you may select the claims
                associated with the bill that you would like to pay.
              </p>
              <div className="centered-table pb-2">
                <MediaQuery minDeviceWidth={476}>
                  <BootstrapTable
                    className="bills-table"
                    keyField="billID"
                    data={bills}
                    bootstrap4
                    columns={this.columns}
                    bordered={false}
                    noDataIndication={<p className="text-center text-light">There is no data to display.</p>}
                    pagination={paginationFactory(options)}
                  />
                </MediaQuery>
                <MediaQuery maxDeviceWidth={475}>
                  <BootstrapTable
                    className="bills-table"
                    keyField="billID"
                    data={bills}
                    bootstrap4
                    columns={this.minColumns}
                    bordered={false}
                    noDataIndication={<p className="text-center text-light">There is no data to display.</p>}
                    pagination={paginationFactory(options)}
                  />
                </MediaQuery>
              </div>
            </div>
          </div>
        )
      } else {
        return <LoadingCard />
      }
    }
  }
}
