import React from 'react'

const Checkbox = ({ label, checked, onChange, disabled }) => {
  return (
    <label className="ezchoice-checkbox">
      <span className="label text-black">{label}</span>
      <input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
      <span className="checkmark"></span>
    </label>
  )
}

export default Checkbox
