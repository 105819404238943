import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function HomeIcon({ ...props }) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.625 6.08333L27.625 15.4167C28.0417 15.7917 28.0834 16.4167 27.75 16.8333C27.375 17.25 26.75 17.2917 26.3334 16.9583L25.3334 16.0833V23.8333C25.3334 25.7083 23.8334 27.1667 22 27.1667H10C8.12504 27.1667 6.66671 25.7083 6.66671 23.8333V16.0833L5.62504 16.9583C5.20837 17.2917 4.58337 17.25 4.20837 16.8333C3.87504 16.4167 3.91671 15.7917 4.33337 15.4167L15.3334 6.08333C15.7084 5.79167 16.25 5.79167 16.625 6.08333ZM8.66671 23.8333C8.66671 24.5833 9.25004 25.1667 10 25.1667H12V18.8333C12 17.9167 12.7084 17.1667 13.6667 17.1667H18.3334C19.25 17.1667 20 17.9167 20 18.8333V25.1667H22C22.7084 25.1667 23.3334 24.5833 23.3334 23.8333V14.375L16 8.16667L8.66671 14.375V23.8333ZM14 25.1667H18V19.1667H14V25.1667Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  )
}
