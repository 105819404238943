import React, { PureComponent } from 'react'
import { switchMap } from 'rxjs/operators'
import { memberService } from '../../services/MemberService'
import { Progress } from 'reactstrap'
import { Subscription } from 'rxjs'
import { LoadingCard } from './LoadingCard'
import { Link } from 'react-router-dom'
import { FormatCurrency } from '../global/TextHelpers'

const Deductible = (props) => {
  let currentSpent = props.max > props.spent ? props.spent : props.max
  let maxDeductible = props.max
  let headerText = props.headerText
  let deductiblePercentage = Math.round((currentSpent / maxDeductible) * 100)

  const formatMaxDeductible = (deductible) => {
    let maxDeductible = ''
    if (deductible > 999999) {
      maxDeductible = 'No Max'
    } else {
      maxDeductible = FormatCurrency(deductible)
    }
    return maxDeductible
  }

  return (
    <div className="card-deductible">
      <div>
        <strong className="text-light fw-5 fs-14">{headerText}</strong>
        <strong className="text-light fw-6 fs-14 float-right">${deductiblePercentage}%</strong>
      </div>
      <div>
        <span className="text-light fw-4 fs-12">${FormatCurrency(currentSpent)}</span>
        <span className="text-light fw-4 fs-12 float-right">${formatMaxDeductible(maxDeductible)}</span>
      </div>

      <Progress value={currentSpent} max={maxDeductible} className="rounded-left-5 rounded-right-5 card-progress"></Progress>
    </div>
  )
}

export class MyDeductiblesCard extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      deductibleInfo: false,
      callSucceeded: false,
      insuredCount: false,
      isFSAOnly: false,
      subscriberPK: false,
      fsaPlans: false,
      allPlans: false,
    }

    this.subscription = new Subscription()
  }

  get deductibleInfo() {
    return this.state.deductibleInfo
  }

  formatMaxDeductible(deductible) {
    let maxDeductible = ''
    if (deductible > 999999) {
      maxDeductible = 'No Max'
    } else {
      maxDeductible = FormatCurrency(deductible)
    }
    return maxDeductible
  }

  componentDidMount() {
    this.subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            this.setState({ insuredCount: x.dependents ? x.dependents.length : 0 })
            return memberService.fetchDeductibleInfo(x.dependentPK)
          })
        )
        .subscribe((y) => {
          this.setState({
            deductibleInfo: y.result,
            callSucceeded: y.isSuccessful,
            isFSAOnly: false,
          })

          this.forceUpdate()
          console.log(this.state)
        })
    )

    this.subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            this.setState({ subscriberPK: x.subscriberPK })
            return memberService.fetchPlanList(x.subscriberPK)
          })
        )
        .subscribe((x) => {
          this.setState(
            {
              fsaPlans: x.data.result.filter((x) => x.coverage === 'FSA'),
              allPlans: x.data.result,
            },
            () => this.checkIfFSAOnly()
          )
          this.forceUpdate()
        })
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  checkIfFSAOnly() {
    if (this.state.fsaPlans.length === this.state.allPlans.length) {
      this.setState({
        isFSAOnly: true,
      })
    } else {
      this.setState({
        isFSAOnly: false,
      })
    }
  }

  render() {
    if (this.state.callSucceeded && !this.state.isFSAOnly) {
      if (this.deductibleInfo) {
        return (
          <>
            <hr className="border border-white" />
            <div className="m-2">
              <div className="text-white bg-transparent border-transparent">
                <Link to="/deductible">
                  <h5 className="text-white fw-7 card-deductible-header">My Deductibles</h5>
                </Link>
                <div id="individual" className="card-deduct-set">
                  {this.deductibleInfo.individualInNetworkDeductible > 0 ? (
                    <strong className="text-light card-deductible-header">Individual</strong>
                  ) : (
                    <span></span>
                  )}
                  {this.deductibleInfo.is3TierPlan ? (
                    <Deductible
                      max={this.deductibleInfo.tier1MemInnDedMax}
                      spent={this.deductibleInfo.tier1MemInnDed}
                      headerText="Tier 1 - Deductible"
                    />
                  ) : (
                    <span></span>
                  )}
                  {this.deductibleInfo.is3TierPlan ? (
                    <Deductible
                      max={this.deductibleInfo.tier1MemInnOopMax}
                      spent={this.deductibleInfo.tier1MemInnOop}
                      headerText="Tier 1 - Out of Pocket"
                    />
                  ) : (
                    <span></span>
                  )}
                  {this.deductibleInfo.individualInNetworkDeductible > 0 ? (
                    <Deductible
                      max={this.deductibleInfo.individualInNetworkDeductible}
                      spent={this.deductibleInfo.inNetworkDeductible}
                      headerText="In-Network Deductible"
                    />
                  ) : (
                    <span></span>
                  )}
                  {this.deductibleInfo.individualInNetworkOutOfPocket > 0 ? (
                    <Deductible
                      max={this.deductibleInfo.individualInNetworkOutOfPocket}
                      spent={this.deductibleInfo.inNetworkOutOfPocket}
                      headerText="In-Network Out Of Pocket"
                    />
                  ) : (
                    <span></span>
                  )}
                </div>
                {this.state.insuredCount > 1 ? (
                  <div id="family" className="card-deduct-set">
                    <strong className="text-light card-deductible-header">Family</strong>
                    {this.deductibleInfo.is3TierPlan ? (
                      <Deductible
                        max={this.deductibleInfo.tier1FamInnDedMax}
                        spent={this.deductibleInfo.tier1FamInnDed}
                        headerText="Tier 1 - Deductible"
                      />
                    ) : (
                      <span></span>
                    )}
                    {this.deductibleInfo.is3TierPlan ? (
                      <Deductible
                        max={this.deductibleInfo.tier1FamInnOopMax}
                        spent={this.deductibleInfo.tier1FamInnOop}
                        headerText="Tier 1 - Out of Pocket"
                      />
                    ) : (
                      <span></span>
                    )}
                    {this.deductibleInfo.familyInNetworkDeductible > 0 ? (
                      <Deductible
                        max={this.deductibleInfo.familyInNetworkDeductible}
                        spent={this.deductibleInfo.familyMedicalDeductible}
                        headerText="In-Network Deductible"
                      />
                    ) : (
                      <span></span>
                    )}
                    {this.deductibleInfo.familyInNetworkOutOfPocket > 0 ? (
                      <Deductible
                        max={this.deductibleInfo.familyInNetworkOutOfPocket}
                        spent={this.deductibleInfo.familyMedicalOutOfPocket}
                        headerText="In-Network Out Of Pocket"
                      />
                    ) : (
                      <span></span>
                    )}
                  </div>
                ) : (
                  <span id="no-family"></span>
                )}
              </div>
            </div>
          </>
        )
      } else {
        return (
          <>
            <hr className="border border-white" />
            <div className="card-body text-white bg-transparent border-transparent">
              <h5 className="fw-7">My Deductibles</h5>
              <strong className="text-primary-orange">Deductible Information Not Found</strong>
            </div>
          </>
        )
      }
    } else if (this.state.callSucceeded && this.state.isFSAOnly) {
      return <div></div>
    } else return <LoadingCard />
  }
}
