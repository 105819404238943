import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { FetchData } from './components/FetchData'
import { ContactUsPage } from './components/pages/ContactUsPage'
import { DashboardPage } from './components/pages/DashboardPage'
import { MyPlanPage } from './components/pages/MyPlanPage'
import { LoginCallback } from './auth/LoginCallback'
import { Logout } from './auth/Logout'
import { LogoutCallback } from './auth/LogoutCallback'
import { PrivateRoute } from './auth/PrivateRoute'
import { SilentRenew } from './auth/SilentRenew'
import { DeductiblePage } from './components/pages/DeductiblePage'
import { ClaimSearchPage } from './components/pages/ClaimSearchPage'
import { StatementsPage } from './components/pages/StatementsPage'
import { RecentClaimsPage } from './components/pages/RecentClaimsPage'
import { EZPayAccountsPage } from './components/pages/EZPayAccountsPage.cardconnect'
import { FlexRequestsPage } from './components/pages/FlexRequestsPage'
import { ProfilePage } from './components/pages/ProfilePage'
import { ClaimDetailPage } from './components/pages/ClaimDetailPage'
import { FlexSpendPage } from './components/pages/FlexSpendPage'
import { BillDetailPage } from './components/pages/BillDetailPage.cardconnect'
import { PayMyBillsPage } from './components/pages/PayMyBillsPage'
import EZChoice from './components/pages/EZChoice/EZChoice'

export const Role = {
  Subscriber: 'Subscriber',
  Dependent: 'Dependent',
  Admin: 'Admin',
}

export const Routes = (
  <Switch>
    <PrivateRoute exact path="/" component={DashboardPage} />
    <PrivateRoute exact path="/myplan" component={MyPlanPage} />
    <PrivateRoute exact path="/deductible" component={DeductiblePage} />
    <PrivateRoute exact path="/claim-search" component={ClaimSearchPage} />
    <PrivateRoute exact path="/statements" roles={[Role.Subscriber]} component={StatementsPage} />
    <PrivateRoute path="/recent-claims" component={RecentClaimsPage} />
    <PrivateRoute path="/ezpayaccounts" roles={[Role.Subscriber]} component={EZPayAccountsPage} />
    <PrivateRoute path="/ezchoice" isCostEstimatorPage component={EZChoice} />
    <PrivateRoute path="/flexrequests" roles={[Role.Subscriber]} component={FlexRequestsPage} />
    <PrivateRoute path="/fetch-data" component={FetchData} />
    <PrivateRoute path="/profile" component={ProfilePage} />
    <PrivateRoute exact path="/contactus" component={ContactUsPage} />
    <PrivateRoute path="/claim/:claimPK" component={ClaimDetailPage} />
    <PrivateRoute path="/bill/:billPK" roles={[Role.Subscriber]} component={BillDetailPage} />
    <PrivateRoute path="/bills/:billPK" roles={[Role.Subscriber]} component={BillDetailPage} />
    <PrivateRoute path="/billlist" component={PayMyBillsPage} />
    <PrivateRoute path="/flex-spend" roles={[Role.Subscriber]} component={FlexSpendPage} />
    <Route exact={true} path="/signin-oidc" component={LoginCallback} />
    <Route exact={true} path="/logout" component={Logout} />
    <Route exact={true} path="/logout/callback" component={LogoutCallback} />
    <Route exact={true} path="/silentrenew" component={SilentRenew} />
  </Switch>
)
