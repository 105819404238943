import React, { useEffect, useMemo, useState } from 'react'
import { styled, Stack } from '@mui/material'
import HEZLogoGreenWhite from '../../../../HEZLogoGreenWhite.png'
import MainMenu from './MainMenu'
import UserAvatar from './UserAvatar'
import { Subscription, switchMap } from 'rxjs'
import { memberService } from '../../../../services/MemberService'
import HelpButton from './HelpButton'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Img = styled('img')(() => ({
  marginRight: '16px',
}))

const AppBarCustom = styled(Stack)(() => ({
  height: 120,
  backgroundColor: 'transparent',
  marginBottom: 30,
  padding: '0 24px',
}))

function Header({ isGreenHeaderBackground }) {
  const subscription = useMemo(() => new Subscription(), [])
  const [userInfo, setUserInfo] = useState({})
  const [externalResources, setExternalResources] = useState([])

  useEffect(() => {
    subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            setUserInfo(x)
            return memberService.fetchMemberResources(x.subscriberPK, x.dependentPK)
          })
        )
        .subscribe((x) => {
          setExternalResources(x.data.result)
        })
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [subscription])

  return (
    <AppBarCustom
      width="100%"
      display="flex"
      justifyContent="center"
      sx={(theme) => ({
        [theme.breakpoints.down('lg')]: {
          height: '55px',
          marginBottom: '0',
        },
      })}
    >
      <Stack
        maxWidth="1440px"
        width="100%"
        alignSelf="center"
        paddingTop={2.3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            paddingTop: '0',
          },
        })}
      >
        {/* 'img Logo' on all sizes */}
        <Link to="/">
          <Img
            src={HEZLogoGreenWhite}
            alt={'logo'}
            sx={(theme) => ({
              height: '51px',
              width: '196px',
              [theme.breakpoints.down('lg')]: {
                height: '35px',
                width: '134px',
              },
            })}
          ></Img>
        </Link>

        <MainMenu
          externalResources={externalResources}
          isGreenHeaderBackground={isGreenHeaderBackground}
          fsaFlag={userInfo.employerFSADetails}
        />

        <UserAvatar userInfo={userInfo} />
      </Stack>

      <HelpButton />
    </AppBarCustom>
  )
}

export default Header
