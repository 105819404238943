import React, { useContext, useState } from 'react'
import { getMoreMenuLinkPages } from './MainMenu.utils'
import { Menu, MenuItem, Typography, styled } from '@mui/material'
import { useHistory } from 'react-router-dom'
import MenuButton from './MenuButton'
import { MoreIcon } from '../../../icons'
import { AuthContext } from '../../../../../auth/AuthProvider'

const MoreMenuStyled = styled(Menu)(({ isGreenHeaderBackground }) => ({
  '& .MuiMenu-list': {
    backgroundColor: isGreenHeaderBackground ? '#004E42' : '#31354B',
  },
  '& .MuiPaper-root': {
    borderRadius: 8,
    marginTop: 0,
  },
}))

function MoreMenu({ isGreenHeaderBackground, fsaFlag }) {
  const authContext = useContext(AuthContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const isOpen = Boolean(anchorEl)
  const history = useHistory()

  const handleOpenMoreMenu = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMoreMenu = () => {
    setAnchorEl(null)
  }

  const moreMenuLinkPages = getMoreMenuLinkPages({
    isDependent: authContext.isDependent(),
    isCostEstimatorVisible: authContext.isCostEstimatorVisible,
    fsaFlag,
  })

  return (
    <>
      <MenuButton onClick={handleOpenMoreMenu}>
        <MoreIcon />
        More
      </MenuButton>
      <MoreMenuStyled
        id="more-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onBackdropClick={handleCloseMoreMenu}
        open={isOpen}
        onClose={handleCloseMoreMenu}
        isGreenHeaderBackground={isGreenHeaderBackground}
        slotProps={{
          sx: {
            backgroundColor: isGreenHeaderBackground ? 'white' : 'initial',
          },
        }}
      >
        {moreMenuLinkPages.map((page) => (
          <MenuItem
            key={page.text}
            onClick={() => {
              if (page.isAbsolutePath) {
                window.open(page.path, '_blank')
              } else {
                history.push(page.path)
              }
              handleCloseMoreMenu()
            }}
            sx={{
              color: '#FFFFFF',
            }}
          >
            <Typography textAlign="center">{page.text}</Typography>
          </MenuItem>
        ))}
      </MoreMenuStyled>
    </>
  )
}

export default MoreMenu
