import React, { PureComponent } from 'react'
import { MediaQuery } from 'react-responsive'
// import BootstrapTable from 'react-bootstrap-table-next';
import BootstrapTable from 'fad-react-bootstrap-table-next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCaretUp, faCaretDown, faEdit } from '@fortawesome/pro-light-svg-icons'
import { Subscription } from 'rxjs'
import { memberService } from '../../services/MemberService'
import { switchMap } from 'rxjs/operators'
import { cardConnectService } from '../../services/EZPayService.cardconnect'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'
import { LoadingCard } from './LoadingCard'
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'fad-react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { Button } from 'reactstrap'
import autoBind from 'react-autobind'
import { Modal, ModalHeader, ModalFooter } from 'reactstrap'
import { EZPayAddUpdateCard } from './EZPayAddUpdateCard.cardconnect'

const UP = 'up'
const DOWN = 'down'

export class EZPayManageCard extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      subscriberPK: false,
      ezPayData: false,
      updatedCard: false,
      modal: false,
      accountId: 0,
      errorModal: false,
      errorMessage: '',
      profileId: 0,
    }
    autoBind(this)

    this.subscription = new Subscription()
  }

  componentDidMount() {
    this.subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            this.setState({ subscriberPK: x.subscriberPK, dependentPK: x.dependentPK })
            return cardConnectService.fetchEZPayList(x.subscriberPK, x.dependentPK)
          })
        )
        .subscribe((x) => {
          this.setState({ ezPayData: x.data })
        })
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  swapPriority = async (row, direction) => {
    const priority1 = row.priority
    let priority2 = row.priority - 1
    if (direction === DOWN) {
      priority2 = row.priority + 1
    }
    this.subscription.add(
      cardConnectService
        .swapCardPriority(this.state.subscriberPK, this.state.dependentPK, priority1, priority2)
        .pipe(
          switchMap((x) => {
            return cardConnectService.fetchEZPayList(this.state.subscriberPK, this.state.dependentPK)
          })
        )
        .subscribe((x) => {
          this.setState({ ezPayData: x.data })
        })
    )
  }

  priorityButtons(cell, row) {
    const isFirst = row.priority === this.state.ezPayData[0].priority
    let direction = UP
    let icon = faCaretUp
    if (isFirst) {
      direction = DOWN
      icon = faCaretDown
    }
    return (
      <div className="center-align-all">
        <span className="text-secondary fw-7">{cell}</span>
        <Button color="link" className="m-auto">
          <FontAwesomeIcon
            size="2x"
            icon={icon}
            className="text-primary-light-blue"
            onClick={() => this.swapPriority(row, direction)}
          />
        </Button>
      </div>
    )
  }

  enableSpinner(rowIndex) {
    const spinner = document.getElementById('card-status-' + rowIndex)
    if (spinner !== null) {
      console.log(spinner)
      window.spinner = spinner
      spinner.hidden = false
    }
  }

  disableSpinner(rowIndex) {
    const spinner = document.getElementById('card-status-' + rowIndex)
    console.log(spinner)
    if (spinner !== null) {
      window.spinner = spinner
      spinner.hidden = true
    }
  }

  deleteCard(row, rowIndex) {
    this.enableSpinner(rowIndex)
    this.subscription.add(
      cardConnectService
        .deleteCard(this.state.subscriberPK, this.state.dependentPK, row.accountId, row.profileId, 'User web update')
        .pipe(
          switchMap((x) => {
            return cardConnectService.fetchEZPayList(this.state.subscriberPK, this.state.dependentPK)
          })
        )
        .subscribe((x) => {
          this.setState({ ezPayData: x.data })
          this.disableSpinner(rowIndex)
        })
    )
  }

  editButton(cell, row, rowIndex) {
    return (
      <div className="center-align-all">
        <span className="text-secondary fw-7">{cell}</span>
        <Button color="link" className="m-auto" onClick={() => this.setState({ accountId: row.accountId, modal: true })}>
          <FontAwesomeIcon size="1x" key={rowIndex} icon={faEdit} className="text-icon-blue d-block" onClick={() => null} />
        </Button>
        <Button id={'delete-card-' + rowIndex} color="link" className="m-auto" onClick={() => this.deleteCard(row, rowIndex)}>
          <FontAwesomeIcon size="1x" key={rowIndex} icon={faTrashAlt} className="text-icon-red d-block" onClick={() => null} />
        </Button>
        <div id={'card-status-' + rowIndex} className="spinner-border" role="status" hidden={true}>
          <span className="sr-only"></span>
        </div>
      </div>
    )
  }

  // See
  // https://developer.cardconnect.com/hosted-iframe-tokenizer#about-cardConnect-tokens
  // for how the parsing was set up.
  maskTokenNumber(tokenizedAccountNumber) {
    if (tokenizedAccountNumber == null) {
      console.error('Tokenized account number is null.')
      return ''
    }

    let output = ''
    for (let i = 0; i < tokenizedAccountNumber.length; ++i) {
      // eslint-disable-next-line
      if (i == 1) {
        output += tokenizedAccountNumber[i]
        output += '*'
      } else if (i > 1 && i < tokenizedAccountNumber.length - 4) {
        output += '*'
      } else if (i >= tokenizedAccountNumber.length - 4) {
        output += tokenizedAccountNumber[i]
      }
    }
    return output
  }

  prettyPrintExpirationDate(row) {
    const today = new Date()
    if (row == null || !row.expiryMonth || !row.expiryYear) {
      console.log(row)
      return '**/****'
    }
    const expiryDate = new Date(row.expiryYear, row.expiryMonth - 1, 1)
    if (expiryDate < today) {
      return 'Invalid expiration date'
    }
    return row.expiryMonth + '/' + row.expiryYear
  }

  columns = [
    {
      dataField: '',
      text: '',
      formatter: (cell, row, rowIndex, extraData) => this.editButton(cell, row, rowIndex),
      headerAlign: 'center',
      headerClasses: 'text-primary-green fw-7',
      classes: 'text-dark fw-7 small',
    },
    {
      dataField: 'active',
      text: 'Active',
      formatter: (cell, row, rowIndex, extraData) =>
        cell ? (
          <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
        ),
      headerAlign: 'center',
      headerClasses: 'fw-7',
    },
    {
      dataField: 'fullName',
      text: 'Name',
      formatter: (cell, row, rowIndex, extraData) => <span className="text-secondary fw-7">{cell}</span>,
      headerAlign: 'center',
      headerClasses: 'fw-7',
    },
    {
      dataField: 'tokenizedAccountNumber',
      text: 'Card Number',
      formatter: (cell, row, rowIndex, extraData) => <span className="text-secondary fw-7">{this.maskTokenNumber(cell)}</span>,
      headerAlign: 'center',
      headerClasses: 'fw-7',
    },
    {
      dataField: 'expirationDate',
      text: 'Ex. Date',
      formatter: (cell, row, rowIndex, extraData) => (
        <span className="text-secondary fw-7">{this.prettyPrintExpirationDate(row)}</span>
      ),
      headerAlign: 'center',
      headerClasses: 'fw-7',
    },
    {
      dataField: 'priority',
      text: 'Priority',
      formatter: (cell, row, rowIndex, extraData) => this.priorityButtons(cell, row),
      headerAlign: 'center',
      headerClasses: 'fw-7',
      classes: 'text-dark fw-7 small',
    },
  ]

  minColumns = [
    {
      dataField: '',
      text: '',
      formatter: (cell, row, rowIndex, extraData) => this.editButton(cell, row, rowIndex),
      headerAlign: 'center',
      headerClasses: 'text-primary-green fw-7',
      classes: 'text-dark fw-7 small',
    },
    {
      dataField: 'active',
      text: 'Active',
      formatter: (cell, row, rowIndex, extraData) =>
        cell ? (
          <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
        ),
      headerAlign: 'center',
      headerClasses: 'text-primary-green fw-7',
    },
    {
      dataField: 'tokenizedAccountNumber',
      text: 'Card Number',
      formatter: (cell, row, rowIndex, extraData) => <span className="text-secondary fw-7">{this.maskTokenNumber(cell)}</span>,
      headerAlign: 'center',
      headerClasses: 'fw-7',
    },
    {
      dataField: 'priority',
      text: 'Priority',
      formatter: (cell, row, rowIndex, extraData) => this.priorityButtons(cell, row),
      headerAlign: 'center',
      headerClasses: 'fw-7',
      classes: 'text-dark fw-7 small',
    },
  ]

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  toggleErrorModal() {
    this.setState((prevState) => ({
      errorModal: !prevState.errorModal,
      errorMessage: '',
    }))
  }

  render() {
    if (this.state.ezPayData && this.state.ezPayData.length > 0) {
      return (
        <div className="centered-table bg-light extra-rounded">
          <MediaQuery minDeviceWidth={475}>
            <BootstrapTable
              bootstrap4
              className="statement-table"
              striped
              keyField="priority"
              data={this.state.ezPayData}
              columns={this.columns}
              bordered={false}
            ></BootstrapTable>

            <div>
              {/* eslint-disable-next-line */}
              <a href="javascript:undefined(0);" type="button">
                <small className="text-primary fw-7" onClick={this.toggle}>
                  {this.props.buttonLabel}
                </small>
              </a>
              <Modal
                id="UpdateCardModal"
                isOpen={this.state.modal}
                toggle={this.toggle}
                centered={true}
                className={this.props.className}
                size="xl"
              >
                <div className="bg-primary-light">
                  <ModalHeader className="text-secondary white-close-button" toggle={this.toggle}>
                    Update Card
                  </ModalHeader>
                  <EZPayAddUpdateCard accountId={this.state.accountId} onSubmitSuccessful={this.toggle} />
                  <ModalFooter className="no-border"></ModalFooter>
                </div>
              </Modal>
              <Modal
                id="ErrorModal"
                isOpen={this.state.errorModal}
                toggle={this.toggleErrorModal}
                centered={true}
                className={this.props.className}
              >
                <ModalHeader toggle={this.toggleErrorModal}>Update Card</ModalHeader>
                <div className="error">{this.state.errorMessage}</div>
                <ModalFooter></ModalFooter>
              </Modal>
            </div>
          </MediaQuery>

          <MediaQuery maxDeviceWidth={476}>
            <BootstrapTable
              bootstrap4
              className="statement-table"
              striped
              keyField="priority"
              data={this.state.ezPayData}
              columns={this.minColumns}
              bordered={false}
            ></BootstrapTable>
            <div>
              {/* eslint-disable-next-line */}
              <a href="javascript:undefined(0);" type="button">
                <small className="text-primary fw-7" onClick={this.toggle}>
                  {this.props.buttonLabel}
                </small>
              </a>
              <Modal
                id="UpdateCardModal"
                isOpen={this.state.modal}
                toggle={this.toggle}
                centered={true}
                className={this.props.className}
                size="xl"
              >
                <div className="bg-primary-light">
                  <ModalHeader className="text-secondary white-close-button" toggle={this.toggle}>
                    Update Card
                  </ModalHeader>
                  <EZPayAddUpdateCard accountId={this.state.accountId} onSubmitSuccessful={this.toggle} />
                  <ModalFooter className="no-border"></ModalFooter>
                </div>
              </Modal>
              <Modal
                id="ErrorModal"
                isOpen={this.state.errorModal}
                toggle={this.toggleErrorModal}
                centered={true}
                className={this.props.className}
              >
                <ModalHeader toggle={this.toggleErrorModal}>Update Card</ModalHeader>
                <div className="error">{this.state.errorMessage}</div>
                <ModalFooter></ModalFooter>
              </Modal>
            </div>
          </MediaQuery>
        </div>
      )
    } else if (this.state.ezPayData) {
      return <span className="text-danger fw-7 h6">No cards found. Please add a card!</span>
    } else {
      return <LoadingCard />
    }
  }
}
