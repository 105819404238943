import React, { PureComponent } from 'react'
import { memberService } from '../../services/MemberService'
import { Subscription } from 'rxjs'
import { Row, Col, Button, Modal, ModalBody, Card } from 'reactstrap'
import { DependentClaimsCard } from '../claims/DependentClaimsCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { LoadingCard } from '../cards/LoadingCard'

const now = new Date()
const day = ('0' + now.getDate()).slice(-2)
const month = ('0' + (now.getMonth() + 1)).slice(-2)
const today = now.getFullYear() + '-' + month + '-' + day

let pastThree = new Date()
pastThree.setMonth(pastThree.getMonth() - 3)

const pastThreeDay = ('0' + pastThree.getDate()).slice(-2)

const pastTheeMonth = ('0' + (pastThree.getMonth() + 1)).slice(-2)

const pastThreeMonths = pastThree.getFullYear() + '-' + pastTheeMonth + '-' + pastThreeDay

export class RecentClaimsPage extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      dependents: false,
      subscriberInfo: false,
      claimData: [],
      modal: false,
      isLoading: true,
    }

    this.subscription = new Subscription()
    this.toggle = this.toggle.bind(this)
    // this.getClaimData = this.getClaimData.bind(this);
    this.pageSize = 100
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }))
  }

  componentDidMount() {
    this.subscription.add(
      memberService.subscriberInfo().subscribe((subscriber) => {
        //console.log(subscriber.dependents);
        this.setState({
          dependents: subscriber.dependents,
          subscriberInfo: subscriber,
          isLoading: false,
        })
        if (this.state.dependents == null) {
          this.setState({
            dependents: [
              {
                dependentPK: subscriber.dependentPK,
                firstName: subscriber.firstName,
                lastName: subscriber.lastName,
                relation: 'Dependeent',
              },
            ],
          })
        }
        console.log(this.state.dependents)
        this.forceUpdate()
      })
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    console.log(this.state.claimData)
    if (!this.state.isLoading) {
      if (this.state.dependents != null && this.state.dependents.length > 0) {
        const sortedDependents = [...this.state.dependents] // Create a copy of the dependents array
        sortedDependents.sort((a, b) => {
          if (a.relation === 'Insured') return -1 // Subscriber comes first
          if (a.relation === 'Spouse' && b.relation !== 'Insured') return -1 // Spouse comes before other dependents
          return 1 // Default case: b comes before a
        })

        return (
          <div className="mt-4">
            <h3 className="page-header text-light">
              Recently Processed Claims{' '}
              <FontAwesomeIcon onClick={this.toggle} icon={faQuestionCircle} size="xs" style={{ cursor: 'pointer' }} />
            </h3>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-centered">
              <Button close onClick={this.toggle} className="text-right pr-3 pt-3" size="lg" />
              <ModalBody className="text-primary fw-7 px-4 pb-4">
                These claims have been processed by HealthEZ in the last 90 days, and are either paid or waiting to be paid. Once
                paid, claims will appear on your monthly statement. To find claims more than 90 days old use the Claims Search
                link in the navigation.
              </ModalBody>
            </Modal>

            <Row className="rowClaimData">
              {sortedDependents.map((dep, index) => (
                <Col lg={12} className="col-lg-6-rc" key={index}>
                  <DependentClaimsCard
                    dateStart={pastThreeMonths}
                    dateEnd={today}
                    subscriberPK={this.state.subscriberInfo.subscriberPK}
                    dependent={dep}
                    pagination={false}
                  />
                </Col>
              ))}
            </Row>
          </div>
        )
      } else {
        return (
          <Card className="card-body bg-light extra-rounded m-3 text-center">
            <h3 className="text-primary fw-7">There were no new claims found: </h3>
            <p className="text-secondary fw-7">From: {pastThreeMonths} </p>
            <p className="text-secondary fw-7">To: {today}</p>
          </Card>
        )
      }
    } else {
      return <LoadingCard />
    }
  }
}
