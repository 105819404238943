import React from 'react'
import { ClaimDetailCard } from '../claims/ClaimDetailCard'

export class ClaimDetailPage extends React.Component {
  constructor(props) {
    super(props)

    this.params = this.props.match.params

    if (this.params && this.params.claimPK) {
      this.state = {
        claimPK: this.params.claimPK,
      }
    }
  }

  render() {
    if (this.state.claimPK) {
      return <ClaimDetailCard claimPK={this.state.claimPK} navHistory={this.props.history}></ClaimDetailCard>
    }
    return <span> No claim found. </span>
  }
}
