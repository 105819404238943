import { joinStrings } from '../ElementsList/helpers'

export const isValidUrl = (urlString) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  return !!urlPattern.test(urlString)
}

export const formatUrl = (validUrl) => {
  if (!validUrl.includes('http')) {
    return 'https://' + validUrl
  }
  return validUrl
}

const formatAddressItem = (item) => {
  if (!item) {
    return ''
  }
  return item.replace(',', '').replace(' ', '+')
}

export const buildGoogleMapsLink = (firstAddressLine, shortAddress) => {
  const item1 = formatAddressItem(firstAddressLine)
  const item2 = formatAddressItem(shortAddress)
  return `https://www.google.com/maps?saddr=My+Location&daddr=${joinStrings(item1, item2, '+')}`
}
