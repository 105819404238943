import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { Fade } from 'reactstrap'
import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ToastContext = createContext(null)

const ToastDispatchContext = createContext(null)

export const ToastProvider = ({ children }) => {
  const [toasts, dispatch] = useReducer(toastReducer, initialToasts)

  useEffect(() => {
    let timeout
    if (toasts.length >= 1) {
      timeout = setTimeout(() => {
        dispatch({ type: ACTION_TYPES.remove })
      }, 8000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [toasts])

  return (
    <ToastContext.Provider value={toasts}>
      <ToastDispatchContext.Provider value={dispatch}>{children}</ToastDispatchContext.Provider>
      <div className="position-fixed fixed-top toast-container">
        {toasts.map((toast, idx) => (
          <Fade
            tag="div"
            className={`animated fadeInUp fadeOutDown alert alert-success d-flex align-items-center justify-content-between ${
              toast.variant === 'error' ? 'alert-danger' : 'alert-success'
            }`}
            role="alert"
            key={idx}
          >
            <div className="mr-3">{toast.message}</div>
            <FontAwesomeIcon
              size="xs"
              icon={faXmark}
              onClick={() =>
                dispatch({
                  type: ACTION_TYPES.remove,
                  index: idx,
                })
              }
              role="button"
            />
          </Fade>
        ))}
      </div>
    </ToastContext.Provider>
  )
}

export const useToasts = () => {
  return useContext(ToastContext)
}

export const useErrorToastDispatch = () => {
  const dispatch = useContext(ToastDispatchContext)
  return () => {
    dispatch({ type: ACTION_TYPES.remove })
    setTimeout(() => {
      dispatch({
        type: ACTION_TYPES.add,
        message: 'The request failed. Please contact Member Experience at the number on the back of your ID card.',
        variant: 'error',
      })
    }, 200)
  }
}

const toastReducer = (toasts, action) => {
  switch (action.type) {
    case ACTION_TYPES.add: {
      if (action.variant === 'error') {
        return [
          ...toasts.filter(({ variant }) => variant !== 'error'),
          {
            message: action.message,
            variant: action.variant,
          },
        ]
      }
      return [
        ...toasts,
        {
          message: action.message,
          variant: action.variant,
        },
      ]
    }
    case ACTION_TYPES.remove: {
      return []
    }
    default: {
      throw Error('Unknown action: ' + action.type)
    }
  }
}

const initialToasts = []

export const ACTION_TYPES = {
  add: 'add',
  remove: 'remove',
}
