import React from 'react'
import { EZPayPromoCard } from '../cards/EZPayPromoCard'
import { EZPayManageCard } from '../cards/EZPayManageCard.cardconnect'
import { EZPayAddUpdateCard } from '../cards/EZPayAddUpdateCard.cardconnect'

export const EZPayAccountsPage = (props) => {
  return (
    <div className="mt-4 card bg-primary-light p-6 mb-4" version="cardconnect">
      <img src={process.env.PUBLIC_URL + '/images/ezpay.png'} width={'15%'} alt="EZPay logo" className="m-4" />
      <EZPayPromoCard />
      <h4 className="page-header text-light">Manage Your EZPay Accounts</h4>
      <div>
        <small className="text-light fw-5 fs-12">
          Prioritize your on file cards/accounts in order of preference. To deactivate or if you have questions, please contact
          HealthEZ Payment Services at 888-409-2273
        </small>
        <p>
          <small>
            <b className="text-danger">*</b>
            <span className="text-light fw-5 fs-12">
              Please note that HRA, Direct Flex will always be the #1, #2 and #3 positions in the list below.
            </span>
            <br />
            <b className="text-danger">*</b>
            <span className="text-light fw-5 fs-12">
              Please note that after you set up EZPay, every time we process a bill of yours, we will send you an email asking you
              to approve the payment for the amount.
            </span>
            <br />
            <b className="text-danger">*</b>
            <span className="text-light fw-5 fs-12">
              Please note that EZPay will pay the bill by default with the Credit Card you have on file if you do not respond to
              the email in: 2 Business Days for bills under $250 and 5 business days for bills over $250
            </span>
          </small>
        </p>
      </div>

      <EZPayManageCard />
      <br />

      <EZPayAddUpdateCard />
    </div>
  )
}
