import React, { Component } from 'react'
import { Row, Col, Card } from 'reactstrap'
import { AuthContext } from '../../auth/AuthProvider'
import { memberService } from '../../services/MemberService'
import { Subscription } from 'rxjs'
import { saveAs } from 'file-saver'
import { StatementsTableCard } from '../cards/StatementsTableCard'
import { LoadingCard } from '../cards/LoadingCard'
import { switchMap } from 'rxjs/operators'

export class StatementsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      statementList: false,
      subscriberPK: false,
    }
    this.downloadStatementPDF = this.downloadStatementPDF.bind(this)
    this.downloadEOBPDF = this.downloadEOBPDF.bind(this)
    this.subscription = new Subscription()

    this.pageSize = 100
  }

  componentDidMount() {
    this.subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            this.setState({ subscriberPK: x.subscriberPK })
            return memberService.fetchStatementList(x.subscriberPK, this.pageSize)
          })
        )
        .subscribe((y) => {
          if (y) {
            this.setState({ statementList: y })
            this.forceUpdate()
          }
        })
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  downloadStatementPDF(row) {
    this.subscription.add(
      memberService.fetchStatement(row.statementDate, this.state.subscriberPK).subscribe((statement) => {
        const blob = new Blob([statement.data], { type: 'application/pdf' })
        saveAs(blob, row.statementDate + '_statement_' + this.state.subscriberPK + '.pdf', true)
      })
    )
  }
  downloadEOBPDF(row) {
    this.subscription.add(
      memberService.fetchEOB(row.statementDate, this.state.subscriberPK).subscribe((statement) => {
        const blob = new Blob([statement.data], { type: 'application/pdf' })
        saveAs(blob, row.statementDate + '_eob_' + this.state.subscriberPK + '.pdf', true)
      })
    )
  }

  render() {
    if (this.state.statementList) {
      return (
        <div className="mt-4">
          <Card className="card bg-primary-light p-4 mb-4">
            <h3 className="card-section-header">View Statements</h3>
            <p className="no-h">
              <small className="text-primary-light-blue fw-7">
                Statements are produced monthly. If you do not see a specific monthly statement, a claim has either not been
                received or processed for that statement period.
              </small>
            </p>
            <Row>
              {/* Statement Summary */}
              <Col>
                <StatementsTableCard data={this.state} isPage={true} />
              </Col>
            </Row>
          </Card>
        </div>
      )
    }
    return <LoadingCard />
  }
}

StatementsPage.contextType = AuthContext
