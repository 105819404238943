import React, { Component } from 'react'
import { MediaQuery } from 'react-responsive'
import { Col, Container, Row } from 'reactstrap'
import { Subscription } from 'rxjs'
import { AuthContext } from '../../auth/AuthProvider'
import { ContactUsCard } from '../cards/ContactUsCard'
import EZChoiceCard from '../cards/EZChoiceCard'
import { FSACard } from '../cards/FSACard'
import { LoadingCard } from '../cards/LoadingCard'
import { MyDeductiblesCard } from '../cards/MyDeductiblesCard'
import { MyDependentsCard } from '../cards/MyDependentsCard'
import { MyIDCard } from '../cards/MyIDCard'
import { MyPlanCard } from '../cards/MyPlanCard'
import { MyResources } from '../cards/MyResources'
import { PayMyBillCard } from '../cards/PayMyBillCard'
import { RecentStatementsCard } from '../cards/RecentStatementsCard'

export class MyPlanPage extends Component {
  static contextType = AuthContext
  constructor(props) {
    super(props)
    //console.log('trackevent0');
    //console.log(this.props.TrackEvent);
    this.state = {
      claims: '',
      isDependent: false,
      modal: false,
    }
  }

  componentDidMount() {
    //console.log('trackevent0');
    //console.log(this.props.TrackEvent);
    //  const auth = useContext(AuthContext);
    const { getPatientPK, getSubscriberPK, isDependent, getClaims } = this.context
    const patientPK = getPatientPK()
    const subscriberPK = getSubscriberPK()

    this.subscription = new Subscription()

    this.setState({
      claims: getClaims(),
      isDependent: isDependent(),
      modal: false,
      subscriberPK: subscriberPK,
      dependentPK: patientPK,
    })
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  render() {
    let isDependent = this.state.isDependent
    if (this.state.claims) {
      return (
        <>
          <div className="h-150">
            <Container fluid className="h-150">
              <MediaQuery maxDeviceWidth={475}>
                <Row className="h-150 bg-primary-dark">
                  <Col className="p-6 text-light colmin-3">
                    {!isDependent ? (
                      <div className="m-2">
                        <MyDependentsCard className="m-2" />
                      </div>
                    ) : (
                      ''
                    )}

                    <MyDeductiblesCard />
                    {!isDependent ? (
                      <>
                        <hr className="border border-white" />
                        <div className="m-2">
                          <FSACard className="m-2" />
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </Col>
                  {!isDependent ? (
                    <>
                      <div className="m-2">
                        <RecentStatementsCard />
                      </div>
                      <br />
                      <div className="m-2">
                        <PayMyBillCard />
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                  <br />
                  {this.context.isCostEstimatorVisible && (
                    <>
                      <div className="m-2">
                        <EZChoiceCard />
                      </div>
                      <br />
                    </>
                  )}
                  <div className="m-2">
                    <ContactUsCard />
                  </div>
                  <br />
                  {!isDependent ? (
                    <>
                      <div className="m-2">
                        <MyIDCard />
                      </div>
                      <br />
                    </>
                  ) : (
                    ''
                  )}

                  <div className="m-2">
                    <MyPlanCard />
                  </div>
                  <div className="m-2">
                    <MyResources />
                  </div>
                </Row>
              </MediaQuery>

              <MediaQuery minDeviceWidth={476}>
                <Row className="h-150 bg-primary-dark ">
                  <Col className="p-6 text-light colmin-3">
                    {!isDependent ? (
                      <div>
                        <div className="m-2">
                          <MyDependentsCard className="m-2" />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <MyDeductiblesCard />
                    {!isDependent ? (
                      <div>
                        <hr className="border border-white" />
                        <div className="m-2">
                          <FSACard className="m-2" />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </Col>
                  <Col className="p-6 h-150 colmin-3">
                    {!isDependent ? (
                      <div>
                        <div className="m-2">
                          <RecentStatementsCard />
                        </div>
                        <br />
                        <div className="m-2">
                          <MyIDCard />
                        </div>
                        <br />
                        <div className="m-2">
                          <PayMyBillCard />
                        </div>
                        <br />
                      </div>
                    ) : (
                      ''
                    )}
                    {this.context.isCostEstimatorVisible && (
                      <>
                        <div className="m-2">
                          <EZChoiceCard />
                        </div>
                        <br />
                      </>
                    )}
                    <div className="m-2">
                      <ContactUsCard />
                    </div>
                    <br />

                    <div className="m-2">
                      <MyPlanCard />
                    </div>
                    <div className="m-2">
                      <MyResources />
                    </div>
                  </Col>
                </Row>
              </MediaQuery>
            </Container>
          </div>
        </>
      )
    } else {
      return <LoadingCard />
    }
  }
}
