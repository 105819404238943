export const TABS = {
  inNetwork: 'inNetwork',
  outOfNetwork: 'outOfNetwork',
  facilities: 'facilities',
}

export const SORTING = {
  lowestCost: 'lowestCost',
  highestCost: 'highestCost',
  nearest: 'nearest',
  nameAZ: 'nameAZ',
  nameZA: 'nameZA',
}

export const SORTING_OPTIONS = [
  {
    value: SORTING.lowestCost,
    label: 'Lowest-cost first',
  },
  {
    value: SORTING.highestCost,
    label: 'Highest-cost first',
  },
  {
    value: SORTING.nearest,
    label: 'Nearest first',
  },
  {
    value: SORTING.nameAZ,
    label: 'Name A-Z',
  },
  {
    value: SORTING.nameZA,
    label: 'Name Z-A',
  },
]

export const DISTANCE = {
  10: '10.49',
  20: '20.49',
  30: '30.49',
  40: '40.49',
  50: '50.49',
}

export const DISTANCE_OPTIONS = [
  {
    value: DISTANCE[10],
    label: '10 mi',
  },
  {
    value: DISTANCE[20],
    label: '20 mi',
  },
  {
    value: DISTANCE[30],
    label: '30 mi',
  },
  {
    value: DISTANCE[40],
    label: '40 mi',
  },
  {
    value: DISTANCE[50],
    label: '50 mi',
  },
]
