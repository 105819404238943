import React, { PureComponent } from 'react'
import Dropdown from 'react-dropdown'
import { withRouter } from 'react-router-dom'
import { memberService } from '../../services/MemberService'
import { Subscription, lastValueFrom } from 'rxjs'
import { AuthConsumer } from '../../auth/AuthProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import SessionService from '../../services/SessionService'
import { Stack } from '@mui/material'
import { getPatientPK, getSubscriberPK, isCS, isDependent } from '../../utilities/sessionStorage'

class SwitchUser extends PureComponent {
  static displayName = SwitchUser.name

  constructor(props) {
    super(props)

    this.state = {
      collapsed: true,
      isOpen: false,
      dataSource: false,
      inputHasError: false,
    }

    this.handleSwitchUser = this.handleSwitchUser.bind(this)
    this.handleSwitchUserDDL = this.handleSwitchUserDDL.bind(this)
    this.subscription = new Subscription()
    this.sessionService = new SessionService()
  }

  componentDidMount() {
    this.subscription.add(
      memberService.fetchMemberList().subscribe((x) => {
        console.log(x)
        var res = x.data.result.map((y) => {
          return {
            label: y.memberName + '-' + y.assocID + '(' + y.groupName + ')',
            value: y.assocPK + '.' + y.patientPK + '.' + y.rolePK,
          }
        })
        this.setState({
          patientDDLList: res,
          hasDropdown: res.length > 1,
        })
        this.forceUpdate()
      })
    )
    this.subscription.add(
      memberService.subscriberInfo().subscribe((x) => {
        this.setState({
          dataSource: x.employerClaimSource,
          fsaFlag: x.employerFSADetails,
        })
        this.forceUpdate()
      })
    )
  }

  handleSwitchUserDDL(person) {
    this.switchUser(person.value)
  }

  async switchUser(newMember) {
    // Check if user is valid before switching
    try {
      const [subscriberPK, patientPK, rolePK] = newMember.split('.')

      const { data } = await lastValueFrom(memberService.fetchSubscriberInfo(subscriberPK, patientPK))

      const isValidUser =
        data.isSuccessful && data.result?.subscriberPK === +subscriberPK && data.result.dependentPK === +patientPK
      if (isValidUser) {
        sessionStorage.setItem('subscriberpk', subscriberPK)
        sessionStorage.setItem('patientpk', patientPK)
        sessionStorage.setItem('memberRole', rolePK === '2' ? 'Subscriber' : 'Dependent')

        this.props.checkCostEstimatorVisibility()
        this.props.history.push('/')
      }
    } catch (error) {
      this.setState({
        inputHasError: true,
      })
    }
  }

  handleSwitchUser(oldValue, newValue) {
    let id = newValue.target.value
    if (!!id && oldValue != id) {
      this.switchUser(id)
    }
  }

  render() {
    const subscriberPK = getSubscriberPK()
    const patientPK = getPatientPK()
    const rolePK = isDependent() ? 1 : 2
    const userIdentifier = subscriberPK + '.' + patientPK + '.' + rolePK

    return (
      <Stack direction="row" justifyContent="flex-end">
        {this.state.hasDropdown ? (
          <Stack width="100%" className="navbar-outline" sx={{ '.Dropdown-control': { justifyContent: 'space-between' } }}>
            <Dropdown
              options={this.state.patientDDLList}
              onChange={this.handleSwitchUserDDL}
              value={this.state.defaultOption}
              placeholder="Switch User"
              className={this.state.patientDDLList && this.state.patientDDLList.length > 1 ? 'contextDDL' : 'd-none'}
              menuClassName="contextDDL-menu"
              placeholderClassName="contextDDL-ph"
              arrowOpen={<FontAwesomeIcon size="2x" icon={faCaretDown} className="text-primary-light-blue mx-1" />}
              arrowClosed={<FontAwesomeIcon size="2x" icon={faCaretLeft} className="text-primary-light-blue mx-1" />}
            ></Dropdown>
          </Stack>
        ) : (
          <></>
        )}
        {isCS() ? (
          <input
            id="subid"
            name="subid"
            className={`form-control form-control-sm ${this.state.inputHasError ? 'is-invalid' : ''}`}
            type="text"
            placeholder={userIdentifier}
            onBlur={(evt) => this.handleSwitchUser(userIdentifier, evt)}
          />
        ) : (
          <b>&nbsp;</b>
        )}
      </Stack>
    )
  }
}

export default withRouter(SwitchUser)
