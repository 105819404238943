import { map } from 'rxjs/operators'
import Axios from '../upstream/axios-observable/dist'
import { serviceConfig } from './ServiceConfig'

const serviceBaseUrl = serviceConfig.memberServiceUrl + serviceConfig.memberServiceVersion

export const billService = {
  getBillList: (subscriberPK) =>
    Axios.post(
      serviceBaseUrl + '/Account/GetBillList',
      {
        filterOptions: {
          subscriberPK: subscriberPK,
          includePaid: true,
        },
        sortColumn: 'DateOfService',
        pageSize: 10,
        currentPage: 0,
        sortAscending: false,
        includePaging: true,
      },
      { params: { subscriberPK: subscriberPK } }
    ),
  getBillDetails: (billPK, subscriberPK) =>
    Axios.get(serviceBaseUrl + '/Account/GetBillDetails', { params: { billPK: billPK, subscriberPK: subscriberPK } }).pipe(
      map((x) => {
        x.data.result.claims = x.data.result.claims.map((claim, index) => ({ ...claim, id: index }))
        return x
      })
    ),

  submitPayment: (subscriberPK, patientPK, accountID, billDetails) =>
    Axios.post(serviceBaseUrl + '/Account/SubmitPayment', billDetails, {
      params: { subscriberPK: subscriberPK, patientPK: patientPK, accountID: accountID },
    }),
}
