import CareType from './components/CareType'
import Location from './components/Location'
import Procedures from './components/Procedures'
import SearchResults from './components/SearchResults'

export const STEPS = {
  careType: 'careType',
  procedure: 'procedure',
  location: 'location',
  searchResults: 'searchResults',
}

export const STEPS_CONFIG = [
  {
    name: STEPS.careType,
    title: 'Care Type',
    component: CareType,
    displayValueProp: 'name',
  },
  {
    name: STEPS.procedure,
    title: 'Procedure',
    component: Procedures,
    displayValueProp: 'description',
  },
  {
    name: STEPS.location,
    title: 'Location',
    component: Location,
  },
  {
    name: STEPS.searchResults,
    title: 'Search Results',
    component: SearchResults,
  },
]

export const EZCHOICE_PAGE_SIZE = 15
