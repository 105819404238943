import React from 'react'
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'
import { formatDate } from './EstimatesPdf'
import EstimatesPdf from './EstimatesPdf'
import { ESTIMATES_PDF_AUTHOR, ESTIMATES_PDF_BASE_NAME, ESTIMATES_PDF_PAGE_SIZE, LOGO_URI } from './constants'

export const buildUrl = (href) => {
  if (!href) {
    return ''
  }
  return `${window.location.origin}/${href.split('/')?.[1]}`
}

const saveEstimatesToPdf = async (documentData) => {
  let now = new Date()
  let title = `${ESTIMATES_PDF_BASE_NAME} ${formatDate(now)}`
  let props = { ...documentData }
  props.title = title
  props.author = ESTIMATES_PDF_AUTHOR
  props.pageSize = ESTIMATES_PDF_PAGE_SIZE
  props.date = now
  props.image = () => process.env.PUBLIC_URL + LOGO_URI
  props.domain = () => process.env.PUBLIC_URL

  const blob = await pdf(<EstimatesPdf {...props} />).toBlob()
  saveAs(blob, `${title}.pdf`)
}

export default saveEstimatesToPdf
