import { ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'
import Axios from '../upstream/axios-observable/dist'
import { serviceConfig } from './ServiceConfig'

//const accountIdSubject = new Subject();

const serviceBaseUrl = serviceConfig.memberServiceUrl + serviceConfig.memberServiceVersion
const enrollmentServiceBaseUrl = serviceConfig.enrollmentServiceUrl + serviceConfig.enrollmentServiceVersion
const ezPayServiceBaseUrl = serviceConfig.ezPayServiceUrl + serviceConfig.ezPayServiceVersion

const subscriberInfoSubject = new ReplaySubject()
const memberListSubject = new ReplaySubject()
//const deductibleInfoSubject = new BehaviorSubject(false);

export const memberService = {
  //Account
  // Get details on user claims
  fetchClaimDetails: (claimPK) => Axios.get(serviceBaseUrl + '/Account/GetClaimDetails', { claimPK }),
  // Get the list of ID Cards
  fetchIdCardList: (subscriberPK, date) => Axios.get(serviceBaseUrl + '/Account/GetIDCardList', { subscriberPK, date }),
  // Get a list of Statements
  fetchStatementList: (subscriberPK, numRows) =>
    Axios.post(serviceBaseUrl + '/Account/GetStatementList', {
      filterOptions: { subscriberPK: subscriberPK },
      pageSize: numRows,
      currentPage: 0,
    }).pipe(map((x) => x.data.result)),
  // .subscribe(x => statementListSubject.next(x.result));
  //return statementListSubject.asObservable();
  // Get individual Statment
  fetchStatement: (statementDate, subscriberPK) =>
    Axios.get(serviceBaseUrl + '/Report/ViewStatementPDF', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      params: {
        statementDate,
        subscriberPK,
      },
      responseType: 'arraybuffer',
    }),
  fetchFSAInformation: (subscriberPK) => Axios.get(serviceBaseUrl + '/Account/GetFSAInformation', { params: { subscriberPK } }),
  fetchFSAAccountDetail: (subscriberPK, fsaName, fsaType, year, dataSourcePK, planDescription) =>
    Axios.get(serviceBaseUrl + '/Account/GetFSAAccountDetail', {
      params: { subscriberPK, fsaName, fsaType, year, dataSourcePK, planDescription },
    }),
  fetchEOB: (statementDate, subscriberPK) =>
    Axios.get(serviceBaseUrl + '/Report/ViewEOBPDF', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      params: {
        statementDate,
        subscriberPK,
      },
      responseType: 'arraybuffer',
    }),

  // Get ID Card
  fetchIDCard: (subscriberPK) => Axios.get(enrollmentServiceBaseUrl + '/Report/ViewIDCard', { params: { subscriberPK } }),

  requestIDCard: (subscriberPK, dependentPK, requestType, toAddress) => {
    return Axios.post(
      serviceBaseUrl + '/Subscriber/RequestIDCard',
      { toAddress: toAddress },
      { params: { subscriberPK: subscriberPK, dependentPK: dependentPK, requestType: requestType } }
    )
  },

  sendIDCardEmail: (subscriberPK, dependentPK, toNumber) => {
    console.log('making email request')
    return Axios.post(
      serviceBaseUrl + '/Contact/SendIDCardEmail',
      { toAddress: toNumber },
      { params: { subscriberPK: subscriberPK, dependentPK: dependentPK } }
    )
  },

  fetchIDCardAsPDF: (subscriberPK, dependentPK) =>
    Axios.get(serviceBaseUrl + '/Report/ViewIDCardPDF', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
      params: { subscriberPK, dependentPK },
      responseType: 'arraybuffer',
    }),

  sendIDCardToMobile: (subscriberPK, dependentPK, phoneNumber) =>
    Axios.get(serviceBaseUrl + '/Contact/SendIDCardMMS', {
      params: { subscriberPK: subscriberPK, dependentPK: dependentPK, toNumber: phoneNumber },
    }),

  // Employer Data
  fetchEmployerDetails: (employerPK) =>
    Axios.get(enrollmentServiceBaseUrl + '/Employer/EmployerDetails', { params: { employerPK } }),

  // PlanList
  fetchPlanList: (subscriberPK, dependentPK = 0) =>
    Axios.post(serviceBaseUrl + '/Account/GetPlanList', {
      filterOptions: { subscriberPK: subscriberPK, dependentPK: dependentPK },
      pageSize: 100,
      currentPage: 0,
    }),

  // PlanDetails
  fetchPlanDetails: (subscriberPK) =>
    Axios.get(serviceBaseUrl + '/Account/GetPlanDetails', { params: { subscriberPK: subscriberPK } }),

  //Subscriber
  fetchAccumulatorInfo: (dependentPK, planType, date) =>
    Axios.get(serviceBaseUrl + '/Subscriber/GetAccumulatorInfo', { dependentPK, planType, date }),

  // Subscriber info. Fetch is the http. Get is the local subject cacher
  fetchSubscriberInfo: (subscriberPK, patientPK) => {
    return Axios.get(serviceBaseUrl + '/Subscriber/GetMemberInfo', {
      params: { subscriberPK: subscriberPK, dependentPK: patientPK },
    })
  },

  fetchMemberResources: (subscriberPK, patientPK) => {
    return Axios.get(serviceBaseUrl + '/Subscriber/GetMemberResources', {
      params: { subscriberPK: subscriberPK, dependentPK: patientPK },
    })
  },
  subscriberInfo: () => subscriberInfoSubject.asObservable(),
  setSubscriberInfo: (subscriberInfo) => subscriberInfoSubject.next(subscriberInfo),

  //member list info
  fetchMemberList: () => {
    return Axios.get(serviceBaseUrl + '/Subscriber/GetSecurityMemberList', {})
  },
  memberList: () => memberListSubject.asObservable(),
  setMemberList: (memberList) => memberListSubject.next(memberList),

  // Deductible info. Fetch is the http. Get is the local subject cacher
  fetchDeductibleInfo: (dependentPK) => {
    return Axios.get(serviceBaseUrl + '/Subscriber/GetAccumulatorInfo', {
      params: { dependentPK: dependentPK, planType: '' },
    }).pipe(map((x) => x.data)) //.subscribe(x => deductibleInfoSubject.next(x.result));
    //return deductibleInfoSubject.asObservable();
  },
  uploadReceipt: (file, subscriberPK) => {
    let formData = new FormData()
    formData.append('file', file)

    return Axios.post(serviceBaseUrl + '/Contact/StoreReceipt', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: { subscriberPK: subscriberPK },
    })
  },

  submitMedicalExpenseReport: (subscriberPK, data) => {
    return Axios.post(serviceBaseUrl + '/Contact/SubmitMedicalExpenseReport', data, { params: { subscriberPK: subscriberPK } })
  },

  submitDaycareExpenseReport: (subscriberPK, data) => {
    return Axios.post(serviceBaseUrl + '/Contact/SubmitDaycareExpenseReport', data, { params: { subscriberPK: subscriberPK } })
  },
  submitPhoneNumber: (data) => {
    return Axios.post(serviceBaseUrl + '/Subscriber/UpdateProfilePhoneNumber', data)
  },
  submitMemberProfile: (data) => {
    return Axios.post(serviceBaseUrl + '/Subscriber/UpdateMemberProfile', data)
  },
  submitEmailAddress: (data) => {
    return Axios.post(serviceBaseUrl + '/Subscriber/UpdateProfileEmail', data)
  },
  submitPaperlessOption: (data) => {
    return Axios.post(serviceBaseUrl + '/Subscriber/UpdateProfilePaperlessOption', data)
  },
  submitCroppedProfileImage: (croppedFile, subscriberPK, patientPK, coordinates) => {
    let formData = new FormData()
    console.log(croppedFile)
    formData.append('croppedImage', croppedFile)
    return Axios.post(serviceBaseUrl + '/Subscriber/UploadCroppedProfileImage', formData, {
      headers: {
        'Content-Type': 'multipart/form-adata',
      },
      params: {
        subscriberPK: subscriberPK,
        patientPK: patientPK,
        coordinates: coordinates,
      },
    })
  },
  submitProfileImage: (file, subscriberPK, patientPK) => {
    let formData = new FormData()
    formData.append('image', file)
    return Axios.post(serviceBaseUrl + '/Subscriber/UploadProfileImage', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: { subscriberPK: subscriberPK, patientPK: patientPK },
    })
  },
  fetchIDCardRequestTypes: () => {
    return Axios.get(serviceBaseUrl + '/Values/IDCardTypes')
  },
  fetchEZPayList: (subscriberPK, dependentPK) =>
    Axios.get(ezPayServiceBaseUrl + '/ezpay/ListCards?subscriberPk=' + subscriberPK + '&patientPk=' + dependentPK),
  fetchCroppedCoordinates: (subscriberPK) =>
    Axios.get(serviceBaseUrl + '/Subscriber/GetCroppedProfileImageCoordinates', { params: { subscriberPk: subscriberPK } }),
  fetchAppConfigs: (dependentPK) =>
    Axios.get(serviceBaseUrl + '/Notification/DependentAppConfiguration', { params: { dependentPK: dependentPK } }),
  fetchEZChoiceInfo: (subscriberPK, dependentPK) =>
    Axios.get(`${serviceBaseUrl}/Subscriber/${subscriberPK}/${dependentPK}/EZChoiceInfo`),
  fetchPlanDuration: (subscriberPK, dependentPK) =>
    Axios.get(`${serviceBaseUrl}/Account/${subscriberPK}/${dependentPK}/Plans/Medical/Duration`),
}
