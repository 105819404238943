import React from 'react'
import { Subscription } from 'rxjs'
// import BootstrapTable from 'react-bootstrap-table-next';
import BootstrapTable from 'fad-react-bootstrap-table-next'
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'fad-react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { LoadingCard } from '../cards/LoadingCard'
import { memberService } from '../../services/MemberService'
import { cardConnectService } from '../../services/EZPayService.cardconnect'
import { switchMap } from 'rxjs/operators'

export class EZPayCardListCard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ezPayCardList: false,
      selectedCard: [],
      cardSelectedCallback: props.callback,
    }

    this.columns = [
      {
        dataField: 'fullName',
        text: 'Name',
        formatter: (cell, row, rowIndex, extraData) => <span className="text-secondary fw-7">{cell}</span>,
        headerAlign: 'center',
        headerClasses: 'text-primary-light-blue bg-primary-light fw-7',
      },
      {
        dataField: 'tokenizedAccountNumber',
        text: 'Number',
        formatter: (cell, row, rowIndex, extraData) => <span className="text-secondary fw-7">{this.maskCardNumber(cell)}</span>,
        headerAlign: 'center',
        headerClasses: 'text-primary-light-blue bg-primary-light fw-7',
      },
      {
        dataField: 'expiry',
        text: 'Ex. Date',
        formatter: (cell, row, rowIndex, extraData) => (
          <span className="text-secondary fw-7">{this.formatExpiry(row.expiryMonth, row.expiryYear)}</span>
        ),
        headerAlign: 'center',
        headerClasses: 'text-primary-light-blue bg-primary-light fw-7',
      },
    ]

    this.handleOnSelect = this.handleOnSelect.bind(this)
    this.subscription = new Subscription()
  }

  maskCardNumber(cardNumber) {
    if (!cardNumber) {
      return ''
    }
    // Tokenized account number always has 16 characters.  Card numbers can
    // differ (like American Express), but the tokenized account number from
    // CardConnect is always consistent.
    return '************' + cardNumber.substring(12)
  }

  formatExpiry(month, year) {
    if (!month || !year) {
      return '**/****'
    }
    return month + '/' + year
  }

  componentDidMount() {
    this.subscription.add(
      memberService
        .subscriberInfo()
        .pipe(
          switchMap((x) => {
            this.setState({
              subscriberPK: x.subscriberPK,
            })
            return cardConnectService.fetchEZPayList(x.subscriberPK, x.dependentPK)
          })
        )
        .subscribe((y) => {
          this.setState({
            ezPayCardList: y.data,
          })
        })
    )
  }

  handleOnSelect = async (row, isSelect) => {
    if (isSelect) {
      await this.setState({
        selectedCard: [row.accountId],
      })
    } else {
      await this.setState({
        selectedCard: [],
      })
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    const selectRow = {
      mode: 'radio',
      selected: this.state.selectedCard,
      onSelect: this.handleOnSelect,
      clickToSelect: true,
    }

    if (this.state.ezPayCardList) {
      return (
        <div className="centered-table bg-light extra-rounded">
          <BootstrapTable
            bootstrap4
            className="statement-table"
            striped
            keyField="accountId"
            selectRow={selectRow}
            data={this.state.ezPayCardList}
            columns={this.columns}
            bordered={false}
          />
          <button
            type="button"
            className="btn btn-outline-primary btn-sm w-3 fw-7"
            onClick={(e) => this.state.cardSelectedCallback(this.state.selectedCard[0])}
            disabled={!this.state.selectedCard.length > 0}
          >
            Continue with selected card
          </button>
        </div>
      )
    } else {
      return <LoadingCard />
    }
  }
}
