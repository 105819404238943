import React from 'react'
import { Subscription } from 'rxjs'
import { claimService } from '../../services/ClaimService'
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Card, Button } from 'reactstrap'
import { FormatCapitalizedText } from '../global/TextHelpers'

export class ClaimDetailCard extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      claimPK: props.claimPK,
      claimDetails: false,
    }

    this.subscription = new Subscription()
  }

  componentDidMount() {
    if (this.state.claimPK) {
      this.subscription.add(
        claimService.getClaimDetails(this.state.claimPK).subscribe((x) => {
          this.setState({ claimDetails: x.result })
          this.forceUpdate()
        })
      )
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  get claimDetails() {
    return this.state.claimDetails
  }

  render() {
    if (this.state.claimPK && this.state.claimDetails) {
      return (
        <Card className="card-body bg-light extra-rounded m-3">
          <h5 className="text-primary fw-7">Claim Details: {this.state.claimDetails.claimNumber}</h5>

          <ListGroup>
            <ListGroupItem>
              <ListGroupItemHeading tag="h4" className="text-primary">
                Patient
              </ListGroupItemHeading>
              <ListGroupItemText tag="h5" className="text-primary-light-blue">
                {FormatCapitalizedText(this.claimDetails.patientFirstName)}{' '}
                {FormatCapitalizedText(this.claimDetails.patientLastName)}
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading tag="h4" className="text-primary">
                Date Received
              </ListGroupItemHeading>
              <ListGroupItemText>{new Date(this.claimDetails.dateReceived).toLocaleDateString()}</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading tag="h4" className="text-primary">
                Service Date
              </ListGroupItemHeading>
              <ListGroupItemText>{new Date(this.claimDetails.serviceDate).toLocaleDateString()}</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading tag="h4" className="text-primary">
                Provider
              </ListGroupItemHeading>
              <ListGroupItemText>{FormatCapitalizedText(this.claimDetails.providerName)}</ListGroupItemText>
            </ListGroupItem>
          </ListGroup>
          <Button outline className="mt-3" onClick={() => this.props.navHistory.goBack()}>
            Go back
          </Button>
        </Card>
      )
    }
    return (
      <Card className="card-body bg-light extra-rounded m-3">
        <h5 className="text-primary fw-7">Claim Details: {this.state.claimPK}</h5>
        <span>Could not find claim details...</span>
      </Card>
    )
  }
}
