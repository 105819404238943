import Axios from '../upstream/axios-observable/dist'
import { serviceConfig } from './ServiceConfig'

const serviceBaseUrl = serviceConfig.ezChoiceServiceUrl + serviceConfig.ezChoiceServiceVersion

export const ezChoiceService = {
  searchCareTypes: (text) => Axios.get(serviceBaseUrl + '/CareTypes/search', { params: { text } }),
  searchProcedures: (text) => Axios.get(serviceBaseUrl + '/ProcedureCodes/search', { params: { text } }),
  searchProviders: (zipCode, careTypeId, procedureCode, radiusInMiles, subscriberPK, dependentPK) =>
    Axios.get(serviceBaseUrl + `/InNetworks/subscribers/${subscriberPK}/${dependentPK}/search`, {
      params: {
        zipCode,
        careTypeId,
        procedureCode,
        radiusInMiles,
      },
    }),
  validateZip: (zipCode) => Axios.get(serviceBaseUrl + '/ZipCodes/' + zipCode),
  getOutOfNetworkPrices: (subscriberPK, dependentPK, careTypeId, procedureCode) =>
    Axios.get(serviceBaseUrl + `/Prices/subscribers/${subscriberPK}/${dependentPK}/providers/out-of-networks`, {
      params: { careTypeId, procedureCode },
    }),
  getInNetworkDisclaimer: () => Axios.get(serviceBaseUrl + '/Prices/in-network/disclaimer'),
  getOutOfNetworkDisclaimer: () => Axios.get(serviceBaseUrl + '/Prices/out-of-network/disclaimer'),
  getOutOfNetworkNotice: () => Axios.get(serviceBaseUrl + '/Prices/out-of-network/disclaimer/notice'),
  getDisclaimerAgreementAcceptance: (subscriberPK, dependentPK) =>
    Axios.get(serviceBaseUrl + `/Members/${subscriberPK}/${dependentPK}/disclaimerResponse/type/ezChoice`),
  getDisclaimerAgreementData: () => Axios.get(serviceBaseUrl + '/Prices/ez-choice/disclaimer'),
  saveDisclaimerAgreementAcceptance: (subscriberPK, dependentPK, data) =>
    Axios.post(serviceBaseUrl + `/Members/${subscriberPK}/${dependentPK}/disclaimerResponse/type/ezChoice`, data),
}
