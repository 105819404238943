import React from 'react'
import { AuthConsumer } from './AuthProvider'
import { LoadingCard } from '../components/cards/LoadingCard'
export const SilentRenew = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback()
      return <LoadingCard />
    }}
  </AuthConsumer>
)
