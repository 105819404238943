import React from 'react'
import { MediaQuery } from 'react-responsive'
import Header from '../_newDesign/layout/Header'
import { CssBaseline } from '@mui/material'
import Footer from './Footer'
import { Container } from 'reactstrap'

export function Layout({ checkCostEstimatorVisibility, children, isNewDesign }) {
  // const displayName = Layout.name;
  return (
    <div className={`d-flex flex-column min-vh-100`} style={{ backgroundColor: '#1C2033', paddingBottom: '90px' }}>
      <Header />
      <CssBaseline />
      {isNewDesign ? (
        <MediaQuery minDeviceWidth={200}>{children}</MediaQuery>
      ) : (
        <>
          <MediaQuery minDeviceWidth={1224}>
            <Container fluid className="flex-grow-1 d-flex flex-column">
              <div className="body-margin min-vh-90 page-body d-flex flex-column flex-grow-1">{children}</div>
            </Container>
          </MediaQuery>
          <MediaQuery minDeviceWidth={476} maxDeviceWidth={1223}>
            <Container fluid className="flex-grow-1 min-vh-90 page-body d-flex flex-column">
              <div className="d-flex flex-column flex-grow-1">{children}</div>
            </Container>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={475}>
            <Container fluid className="flex-grow-1 page-body d-flex flex-column">
              <div className="d-flex flex-column flex-grow-1">{children}</div>
            </Container>
          </MediaQuery>
        </>
      )}
    </div>
  )
}

export default Layout
