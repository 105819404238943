import * as React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

export default function FindProviderIcon() {
  return (
    <SvgIcon>
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="30" cy="30" r="30" fill="#EA7600" />
        <path
          d="M17.9998 42V42C17.9998 38.25 20.9998 35.25 24.7498 35.25H28.6498"
          stroke="#6E3B04"
          stroke-width="2.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M34.4998 19.8C37.0498 22.35 37.0498 26.4 34.4998 28.8C31.9498 31.2 27.8998 31.35 25.4998 28.8C23.0998 26.25 22.9498 22.2 25.4998 19.8C28.0498 17.4 31.9498 17.4 34.4998 19.8"
          stroke="#6E3B04"
          stroke-width="2.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M39.6 35.7C41.1 37.2 41.1 39.6 39.6 41.1C38.1 42.6 35.7 42.6 34.2 41.1C32.7 39.6 32.7 37.2 34.2 35.7C35.7 34.2 38.1 34.2 39.6 35.7"
          stroke="#6E3B04"
          stroke-width="2.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M41.9999 43.5L39.5999 41.1"
          stroke="#6E3B04"
          stroke-width="2.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
