import React, { useState, useEffect, useMemo } from 'react'
import _ from 'lodash'
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap'
import SessionService from '../../../../../../../services/SessionService'
import SelectInput from '../../../generic/SelectInput'
import { DISTANCE, DISTANCE_OPTIONS, SORTING, SORTING_OPTIONS } from '../../constants'
import { faXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Filters = ({
  onChange,
  onDistanceChange,
  isDisabled,
  noResults,
  filteredNoResults,
  isHidden,
  searchLabel,
  searchPlaceholder,
  isFacilityTab,
  setAppliedFilters,
}) => {
  const [isFromSession, setIsFromSession] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [sortValue, setSortValue] = useState(SORTING.lowestCost)
  const [distance, setDistance] = useState(DISTANCE[10])
  const [name, setName] = useState('')
  const [facilityName, setFacilityName] = useState('')
  const sessionService = useMemo(() => new SessionService(), [])

  useEffect(() => {
    if (!isFirstLoad && !isFromSession) {
      onChange({ sortValue, name, facilityName })
      sessionService.saveStepDetails({
        sortValue,
        name,
        distance,
        facilityName,
      })
    }
    setAppliedFilters({ sortValue, name, distance, facilityName })
    isFirstLoad && setIsFirstLoad(false)
    // eslint-disable-next-line
  }, [sortValue, name, distance, facilityName])

  useEffect(() => {
    const sessionData = sessionService.getStepDetails()
    if (!_.isEmpty(sessionData)) {
      setIsFromSession(true)
      const { sortValue, name, facilityName, distance } = sessionData
      setSortValue(sortValue)
      setDistance(distance)
      setName(name)
      setFacilityName(facilityName)
    } else {
      onDistanceChange(distance, name, facilityName, sortValue)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isFirstLoad && !isFromSession) {
      onDistanceChange(distance, name, facilityName, sortValue)
    }

    // eslint-disable-next-line
  }, [distance])

  useEffect(() => {
    if (isFromSession) {
      onDistanceChange(distance, name, facilityName, sortValue)
      setIsFromSession(false)
    }
    // eslint-disable-next-line
  }, [isFromSession])

  const isCrossVisible = isFacilityTab ? facilityName : name
  return isHidden ? null : (
    <div>
      <div className="d-responsive py-2 px-1">
        <div className="d-responsive align-items-center mr-3">
          <span className="mr-2">Sort by</span>
          <SelectInput
            options={SORTING_OPTIONS}
            value={sortValue}
            onChange={(value) => setSortValue(value)}
            isDisabled={isDisabled || filteredNoResults}
          />
        </div>
        <div className="d-responsive align-items-center mr-3">
          <span className="mr-2">Filter by</span>
          <SelectInput
            className="mr-2"
            options={DISTANCE_OPTIONS}
            value={distance}
            onChange={(value) => setDistance(value)}
            isDisabled={isDisabled}
            prefix="Distance:"
          />
          <InputGroup className="ez-choice-input w-">
            <InputGroupAddon
              addonType="prepend"
              className={`prepend p-0 border-0 ${isDisabled || noResults ? 'prepend-disabled' : ''}`}
            >
              {searchLabel}
            </InputGroupAddon>
            <Input
              name="searchValue"
              placeholder={searchPlaceholder}
              value={isFacilityTab ? facilityName : name}
              onChange={({ target: { value } }) => {
                if (isFacilityTab) {
                  setFacilityName(value?.trimStart())
                } else {
                  setName(value?.trimStart())
                }
              }}
              className="pt-2 pl-1 border-right-0 border-left-0 input"
              disabled={isDisabled || noResults}
              maxLength={55}
            />
            {isCrossVisible && (
              <InputGroupAddon
                addonType="prepend"
                className={`prepend prepend-end p-0 border-0 ${isDisabled || noResults ? 'prepend-disabled' : ''}`}
              >
                <FontAwesomeIcon
                  size="xs"
                  icon={faXmark}
                  className={isDisabled ? 'cross-disabled' : ''}
                  onClick={() => {
                    if (isDisabled) {
                      return
                    }
                    if (isFacilityTab) {
                      setFacilityName('')
                    } else {
                      setName('')
                    }
                  }}
                  role="button"
                />
              </InputGroupAddon>
            )}
          </InputGroup>
        </div>
        <Button
          color="link"
          onClick={() => {
            setDistance(DISTANCE[10])
            setSortValue(SORTING.lowestCost)
            setName('')
            setFacilityName('')
          }}
          className="px-0"
          disabled={isDisabled || (!name && sortValue === SORTING.lowestCost && distance === DISTANCE[10] && !facilityName)}
        >
          Reset
        </Button>
      </div>
    </div>
  )
}

export default Filters
