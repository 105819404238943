import React from 'react'
import { Button, Form, FormGroup, Label, Input, FormFeedback, Card, Row, Col } from 'reactstrap'
import { ValidIndicator, InvalidIndicator } from '../global/FormHelpers'
import { ReceiptUpload } from './ReceiptUpload'

export class MedicalExpenseForm extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
    this.addFile = this.addFile.bind(this)
    this.receiptAdded = this.receiptAdded.bind(this)
    this.receiptRemoved = this.receiptRemoved.bind(this)

    this.state = {
      expense: props.expense,
      dependents: props.dependents,
      index: props.index,
      validate: {
        expenseDate: false,
        personName: false,
        description: false,
        serviceProvider: false,
        netAmount: false,
      },
    }
  }

  handleChange = async (event) => {
    const { target } = event
    const value = target.type === 'checkbox' ? target.checked : target.value
    const { name } = target
    let expense = this.state.expense
    expense[name] = value
    await this.setState({
      expense: expense,
    })
  }

  validateField = async (event) => {
    const name = event.target.name
    let isValid = false

    const { validate } = this.state
    switch (name) {
      case 'message':
        if (event.target.value) {
          isValid = true
        }
        break
      default:
        isValid = true
    }

    validate[name] = isValid
    this.setState({ validate })
  }

  addFile() {
    let expense = this.state.expense
    expense.files.push('')
    this.setState({ expense: expense })
  }

  receiptAdded(id, index) {
    let expense = this.state.expense
    // eslint-disable-next-line
    let altIndex = expense.files.findIndex((e) => e == '')
    // eslint-disable-next-line
    if (altIndex == -1) {
      altIndex = expense.files.length
    }
    expense.files[altIndex] = id
    this.setState({ expense: expense })
  }

  receiptRemoved(id, index) {
    let expense = this.state.expense
    // eslint-disable-next-line
    let altIndex = expense.files.findIndex((e) => (e.id == id || e == id) && e)
    console.log(this.state.expense, id, index, altIndex)
    if (expense.files[altIndex] === id) expense.files.splice(altIndex, 1)
    this.setState({ expense: expense })
  }

  render() {
    const { expense } = this.state
    return (
      <Card className="card bg-primary-light p-4 m-0">
        <Form onSubmit={this.submitForm}>
          <Row>
            <FormGroup className="col">
              <Label className="text-secondary fw-7" for="expenseDate">
                Date expense incurred
              </Label>
              <Input
                type="date"
                name="expenseDate"
                id="expenseDate"
                placeholder="xx/xx/xxxx"
                defaultValue={expense.expenseDate}
                valid={this.state.validate.expenseDate}
                invalid={!this.state.validate.expenseDate}
                onChange={(e) => {
                  this.validateField(e)
                  this.handleChange(e)
                }}
              />
              <FormFeedback valid>
                <ValidIndicator />
              </FormFeedback>
              <FormFeedback>
                <InvalidIndicator />
              </FormFeedback>
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-grey fw-7" for="personName">
                Person for whom expense incurred
              </Label>
              {/*<Input
                                type="select"
                                id="dependentPK"
                                name="dependentPK"
                                defaultValue=""
                                onBlur={(e) => { this.validateField(e); this.handleChange(e) }}
                                onChange={(e) => { this.validateField(e); this.handleChange(e) }}
                                valid={this.state.validate.dependentPK} invalid={!this.state.validate.dependentPK}>
                                <option value=""></option>
                                {dependents.map(option => (
                                    <option key={option.dependentPK} value={option.dependentPK}>
                                        {option.firstName} {option.lastName}
                                    </option>
                                ))}
                            </Input>*/}
              <Input
                type="text"
                id="personName"
                name="personName"
                defaultValue=""
                onBlur={(e) => {
                  this.validateField(e)
                  this.handleChange(e)
                }}
                onChange={(e) => {
                  this.validateField(e)
                  this.handleChange(e)
                }}
                valid={this.state.validate.personName}
                invalid={!this.state.validate.personName}
              />
              <FormFeedback valid>
                <ValidIndicator />
              </FormFeedback>
              <FormFeedback>
                <InvalidIndicator />
              </FormFeedback>
            </FormGroup>
          </Row>
          <FormGroup>
            <Label className="text-secondary fw-7" for="description">
              Expense description
            </Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              placeholder=""
              required
              defaultValue={expense.description}
              valid={this.state.validate.description}
              invalid={!this.state.validate.description}
              onChange={(e) => {
                this.validateField(e)
                this.handleChange(e)
              }}
            />
            <FormFeedback valid>
              <ValidIndicator />
            </FormFeedback>
            <FormFeedback>
              <InvalidIndicator />
            </FormFeedback>
          </FormGroup>
          <Row>
            <FormGroup className="col">
              <Label className="text-secondary fw-7" for="serviceProvider">
                Name of service provider
              </Label>
              <Input
                type="text"
                name="serviceProvider"
                id="serviceProvider"
                placeholder=""
                defaultValue={expense.serviceProvider}
                valid={this.state.validate.serviceProvider}
                invalid={!this.state.validate.serviceProvider}
                onChange={(e) => {
                  this.validateField(e)
                  this.handleChange(e)
                }}
              />
              <FormFeedback valid>
                <ValidIndicator />
              </FormFeedback>
              <FormFeedback>
                <InvalidIndicator />
              </FormFeedback>
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-secondary fw-7" for="netAmount">
                Net amount
              </Label>
              <Input
                type="number"
                name="netAmount"
                id="netAmount"
                placeholder=""
                required
                defaultValue={expense.netAmount}
                valid={this.state.validate.netAmount}
                invalid={!this.state.validate.netAmount}
                onChange={(e) => {
                  this.validateField(e)
                  this.handleChange(e)
                }}
              />
              <FormFeedback valid>
                <ValidIndicator />
              </FormFeedback>
            </FormGroup>
            <FormGroup className="col">
              <Label className="text-secondary fw-7" for="cptDx">
                CPT/Dx code
              </Label>
              <Input
                type="text"
                name="cptDx"
                id="cptDx"
                placeholder=""
                defaultValue={expense.cptDx}
                onChange={(e) => {
                  this.handleChange(e)
                }}
              />
              <FormFeedback valid>
                <ValidIndicator />
              </FormFeedback>
              <FormFeedback>
                <InvalidIndicator />
              </FormFeedback>
            </FormGroup>
          </Row>
          <small className="text-secondary">
            *Net Amount is the amount of the claim not reimbursed to you through another plan i.e. health or dental insurance
          </small>

          <Row className="mt-4">
            <Col>
              <p className="text-white fw-7 mb-0">Attach Receipts</p>
              <small className="text-grey fw-7">
                Receipts should be jpeg, gif, bmp, doc or pdf files and no larger than 1 MB for each file. Alternatively, you can
                email your receipt to service@healthez.com.
              </small>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button color="link" onClick={this.addFile} className="btn profile-button btn-sm fw-7">
                Attach File
              </Button>
              {expense.files.length > 0 ? '' : <span className="text-grey fw-7 ml-2">No file currently uploaded</span>}
            </Col>
          </Row>

          {expense.files.map((val, ind) => {
            return (
              <Row key={ind} className="mt-4">
                <Col>
                  <p className="text-secondary fw-7 mb-0">Attached Files</p>
                  <ReceiptUpload
                    tabType="M"
                    id={val}
                    index={ind}
                    expenseIndex={this.state.index}
                    callback={this.receiptAdded}
                    deleteCallBack={this.receiptRemoved}
                  ></ReceiptUpload>
                </Col>
              </Row>
            )
          })}
        </Form>
      </Card>
    )
  }
}
