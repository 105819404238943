import Axios from '../upstream/axios-observable/dist'
import { serviceConfig } from './ServiceConfig'

const ezPayServiceBaseUrl = serviceConfig.ezPayServiceUrl + serviceConfig.ezPayServiceVersion

class PromiseMock {
  constructor(data) {
    if (data !== undefined) {
      console.log('PromiseMock: ' + data)
    }
  }
  then(f) {
    console.log('Then called.')
    return new PromiseMock()
  }

  catch(f) {
    console.log('Catch called.')
    return new PromiseMock()
  }

  finally(f) {
    console.log('Finally called.')
    return new PromiseMock()
  }
}

console.log('ezPayServiceBaseUrl: ' + ezPayServiceBaseUrl)

export const cardConnectService = {
  cardConnectServiceUrl: serviceConfig.cardConnectServiceUrl,
  //EZ Pay
  fetchEZPayList: (subscriberPK, dependentPK) =>
    Axios.get(ezPayServiceBaseUrl + '/ezpay/ListCards?subscriberPk=' + subscriberPK + '&patientPk=' + dependentPK),
  getCard: (subscriberPK, dependentPK, accountID) =>
    Axios.get(
      ezPayServiceBaseUrl +
        '/ezpay/GetCard?subscriberPk=' +
        subscriberPK +
        '&patientPk=' +
        dependentPK +
        '&accountId=' +
        accountID
    ),
  //: (employerPK) => Axios.get(enrollmentServiceBaseUrl + "/Employer/EmployerDetails", { params: { employerPK } }),
  switchEZPayPriority: (ezPayCardPK, isIncreasePriority, subscriberPK) =>
    new PromiseMock(
      'ezPayCardPK: ' + ezPayCardPK + ', isIncreasePriority: ' + isIncreasePriority + ', subscriberPK: ' + subscriberPK
    ),

  addCardToEZPay: (data) => Axios.post(ezPayServiceBaseUrl + '/ezpay/AddCard', data),
  updateCard: (accountId, data) => Axios.post(ezPayServiceBaseUrl + '/ezpay/UpdateCard?accountId=' + accountId, data),
  swapCardPriority: (subscriberPK, dependentPK, priority1, priority2) =>
    Axios.post(
      ezPayServiceBaseUrl +
        '/ezpay/SwapCardPriority?subscriberPk=' +
        subscriberPK +
        '&patientPK=' +
        dependentPK +
        '&priority1=' +
        priority1 +
        '&priority2=' +
        priority2
    ),
  deleteCard: (subscriberPK, dependentPK, accountID, profileID, reason) =>
    Axios.delete(
      ezPayServiceBaseUrl +
        '/ezpay/DeleteCard?subscriberPk=' +
        subscriberPK +
        '&patientPk=' +
        dependentPK +
        '&accountId=' +
        accountID +
        '&profileId=' +
        profileID +
        '&reason=' +
        encodeURIComponent(reason)
    ),
}
