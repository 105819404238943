import { faCircleCheck, faCircle } from '@fortawesome/pro-light-svg-icons'
import { faCircle as faSolidCircle } from '@fortawesome/pro-solid-svg-icons'

export const STEP_STATES = {
  active: 'active',
  completed: 'completed',
  none: 'none',
}

export const STEP_ICONS = {
  [STEP_STATES.active]: faSolidCircle,
  [STEP_STATES.completed]: faCircleCheck,
  [STEP_STATES.none]: faCircle,
}
