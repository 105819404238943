import React, { useState, useMemo, useEffect } from 'react'
import { Subscription } from 'rxjs'
import _ from 'lodash'
import classnames from 'classnames'
import { Nav, NavLink } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons'
import { ezChoiceService } from '../../../../../services/EZChoiceService'
import SessionService from '../../../../../services/SessionService'
import InputBar from '../generic/InputBar'
import Pagination from '../generic/Pagination'
import ResultItem from '../generic/ResultItem'
import SidebarOffcanvas from '../generic/SidebarOffcanvas'
import { TABS } from './constants'
import { EZCHOICE_PAGE_SIZE } from '../../constants'
import { useErrorToastDispatch } from '../SearchResults/ToastContext'

const CareType = ({ onChange, value, isFromBack, isRestartClicked, setIsRestartClicked }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const [isSearched, setIsSearched] = useState(false)
  const [data, setData] = useState([])
  const [popularProviders, setPopularProviders] = useState([])
  const [isLoading, setIsLoading] = useState(!isFromBack)
  const [activePage, setActivePage] = useState(0)
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(TABS.popular)
  const subscription = useMemo(() => new Subscription(), [])
  const sessionService = useMemo(() => new SessionService(), [])
  const isTabsVisible = !_.isEmpty(popularProviders) && !isSearched
  const pageSize = EZCHOICE_PAGE_SIZE
  const toastDispatch = useErrorToastDispatch()

  useEffect(() => {
    if (!isFirstLoad) {
      sessionService.saveStepData({
        searchValue,
        isSearched,
        data,
        isLoading,
        activePage,
        isDisclaimerOpen,
        activeTab,
      })
    }

    setIsFirstLoad(false)
    // eslint-disable-next-line
  }, [searchValue, isSearched, data, isLoading, activePage, isDisclaimerOpen, activeTab])

  const getCareTypes = (text) => {
    setIsLoading(true)
    setData([])
    setPopularProviders([])
    subscription.add(
      ezChoiceService
        .searchCareTypes(text)
        .toPromise()
        .then((res) => {
          setIsLoading(false)
          setData(res.data)
          if (text) {
            setPopularProviders([])
            setActiveTab(TABS.all)
          } else {
            const popular = res.data?.filter(({ popular }) => popular)
            setPopularProviders(popular)
          }
          setActivePage(0)
        })
        .catch(() => {
          setIsLoading(false)
          toastDispatch()
        })
    )
  }

  useEffect(() => {
    const sessionData = sessionService.getStepData()
    let searchTerm = searchValue
    if (!_.isEmpty(sessionData)) {
      const { searchValue: savedSearch, isSearched, data, isLoading, activePage, isDisclaimerOpen, activeTab } = sessionData
      setSearchValue(savedSearch)
      setIsSearched(isSearched)
      setIsLoading(isLoading)
      setData(data)
      setPopularProviders([])
      setActivePage(activePage)
      setIsDisclaimerOpen(isDisclaimerOpen)

      if (savedSearch && isSearched) {
        setActiveTab(TABS.all)
      } else {
        setActiveTab(activeTab)
        const popular = data?.filter(({ popular }) => popular)
        setPopularProviders(popular)
      }

      if (isLoading) {
        getCareTypes(savedSearch?.trim())
      }
    }
    if (!isFromBack) {
      getCareTypes(searchTerm?.trim())
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isRestartClicked) {
      setSearchValue('')
      getCareTypes('')
      setActiveTab(TABS.popular)
      setIsSearched(false)
      setIsRestartClicked(false)
    }
    // eslint-disable-next-line
  }, [isRestartClicked])

  const onPageChange = (page) => {
    const scrollDiv = document.getElementById('scroll-top-div')
    if (scrollDiv) {
      scrollDiv.scrollTop = 0
    }
    setActivePage(page)
  }

  const noOfResults = data.length
  const noOfPages = Math.ceil(noOfResults / pageSize)

  const onSearchClick = () => {
    getCareTypes(searchValue)
    setIsSearched(true)
    onChange({})
  }

  return (
    <>
      <div className="pt-4 pb-0 px-4 page-wrapper">
        <div className="search-page">
          <h5 className="page-title mb-3 text-light">
            <span>Search for care type</span>
            <FontAwesomeIcon
              fontSize={16}
              icon={faCircleQuestion}
              onClick={() => setIsDisclaimerOpen(true)}
              className="ml-2 ezchoice-icon-green ezchoice-help-icon"
            />
          </h5>
          <SidebarOffcanvas isOpen={isDisclaimerOpen} toggle={setIsDisclaimerOpen}>
            <h5 className="mb-5 pb-3 fw-6">Care Types Disclaimer</h5>
            <div className="mb-2">
              Care Type is used to narrow down the applicable benefit for your specific plan. The specialties listed below are
              used to determine the type of service that may be rendered and to help find appropriate services.
            </div>
            <div className="mb-2">
              IF YOU ARE HAVING YOUR SERVICE AT A SITE OF CARE OTHER THAN AN OFFICE VISIT, PLEASE SELECT THAT FROM THE LIST.
            </div>
            <div>Examples of that are Hospital, Ambulatory Surgical Center, Home Health, etc.</div>
          </SidebarOffcanvas>
          <div className="page-description mb-3 text-light">
            Enter a Place of Service (e.g. Hospital Outpatient, Urgent Care), Provider Specialty (e.g. Cardiology, Orthopedic),
            Routine Health Care (e.g. Preventive).
          </div>
          <InputBar
            isDisabled={isLoading}
            onSearch={onSearchClick}
            searchValue={searchValue}
            onChange={(value) => setSearchValue(value)}
            placeholder="Search Care Type"
            maxCharacterInput={55}
          />
          {isLoading ? (
            <div className="spinner"></div>
          ) : (
            <>
              {isSearched ? (
                <div className="mb-3">{noOfResults > 0 ? `${noOfResults} Care Types found` : 'No results found.'}</div>
              ) : isFromBack ? (
                <div className="mb-3 text-light">Selected Care Type</div>
              ) : (
                <>
                  {noOfResults > 0 ? (
                    <h5 className="page-title mb-3 text-light">Or select care type from the list</h5>
                  ) : (
                    <div className="mb-3">No results found.</div>
                  )}
                  {isTabsVisible && (
                    <Nav className="ezchoice__tabs mb-3">
                      <NavLink
                        className={classnames('px-3 py-1', {
                          active: activeTab === TABS.popular,
                        })}
                        onClick={() => {
                          setActiveTab(TABS.popular)
                          onChange({})
                        }}
                      >
                        Popular
                      </NavLink>
                      <NavLink
                        className={classnames('px-3 py-1', {
                          active: activeTab === TABS.all,
                        })}
                        onClick={() => {
                          setActiveTab(TABS.all)
                          onChange({})
                        }}
                      >
                        All
                      </NavLink>
                    </Nav>
                  )}
                </>
              )}

              {isFromBack ? (
                <div>
                  <ResultItem isActive>{value.name}</ResultItem>
                </div>
              ) : activeTab === TABS.all || !isTabsVisible ? (
                <>
                  <div
                    className={`scrollable-results ${
                      !isTabsVisible ? 'care-type__scrollable-results--no-tabs' : 'care-type__scrollable-results'
                    } pr-1`}
                    id="scroll-top-div"
                  >
                    {data.slice(activePage * pageSize, (activePage + 1) * pageSize).map((item, idx) => (
                      <ResultItem key={idx} isActive={value.id === item.id} onClick={() => onChange(item)}>
                        {item.name}
                      </ResultItem>
                    ))}
                    <Pagination
                      activePage={activePage}
                      noOfPages={noOfPages}
                      setActivePage={(nextPage) => {
                        onPageChange(nextPage)
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className="scrollable-results care-type__scrollable-results pr-1">
                  {popularProviders?.map((item, idx) => (
                    <ResultItem key={idx} isActive={value.id === item.id} onClick={() => onChange(item)}>
                      {item.name}
                    </ResultItem>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default CareType
