import React, { useState, useMemo, useRef, useEffect } from 'react'
import { Subscription } from 'rxjs'
import _ from 'lodash'
import { ezChoiceService } from '../../../../../services/EZChoiceService'
import SessionService from '../../../../../services/SessionService'
import { EZCHOICE_PAGE_SIZE, STEPS } from '../../constants'
import InputBar from '../generic/InputBar'
import Pagination from '../generic/Pagination'
import ResultItem from '../generic/ResultItem'
import { useErrorToastDispatch } from '../SearchResults/ToastContext'

const Procedures = ({ onChange, value, isFromBack, savedValues }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const [isSearched, setIsSearched] = useState(false)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [activePage, setActivePage] = useState(0)
  const subscription = useRef()
  if (subscription.current == null) {
    subscription.current = new Subscription()
  }
  const sessionService = useMemo(() => new SessionService(), [])
  const pageSize = EZCHOICE_PAGE_SIZE
  const toastDispatch = useErrorToastDispatch()

  useEffect(() => {
    if (!isFirstLoad) {
      sessionService.saveStepData({
        searchValue,
        isSearched,
        data,
        isLoading,
        activePage,
      })
    }

    setIsFirstLoad(false)
    // eslint-disable-next-line
  }, [searchValue, isSearched, data, isLoading, activePage])

  const getProcedures = () => {
    setData([])
    setIsLoading(true)
    subscription.current.add(
      ezChoiceService
        .searchProcedures(searchValue?.trim())
        .toPromise()
        .then((res) => {
          setIsLoading(false)
          setData(res.data)
          setActivePage(0)
        })
        .catch(() => {
          setIsLoading(false)
          toastDispatch()
        })
    )
  }

  useEffect(() => {
    const sessionData = sessionService.getStepData()
    if (!_.isEmpty(sessionData)) {
      const { searchValue, isSearched, data, isLoading, activePage } = sessionData
      setSearchValue(searchValue)
      setIsSearched(isSearched)
      setIsLoading(isLoading)
      setData(data)
      setActivePage(activePage)

      if (isLoading) {
        getProcedures()
      }
    }
    // eslint-disable-next-line
  }, [])

  const onPageChange = (page) => {
    const scrollDiv = document.getElementById('scroll-top-div')
    if (scrollDiv) {
      scrollDiv.scrollTop = 0
    }
    setActivePage(page)
  }

  const noOfResults = data.length
  const noOfPages = Math.ceil(noOfResults / pageSize)

  const onSearchClick = () => {
    getProcedures()
    setIsSearched(true)
    onChange({})
  }

  const renderProcedureContent = (procedure) => (
    <>
      <span className="font-weight-bolder mr-1">{`${procedure.code} `}</span>
      <span>{procedure.description}</span>
    </>
  )

  return (
    <>
      <div className="pt-4 pb-0 px-4 page-wrapper">
        <div className="search-page">
          <h5 className="page-title mb-3 text-light">Search for procedure</h5>
          <div className="page-description mb-3 text-light">
            Search for procedure by code or description, e.g. 99213 or Office visit.
          </div>
          <InputBar
            isDisabled={isLoading}
            onSearch={onSearchClick}
            searchValue={searchValue}
            onChange={(value) => setSearchValue(value)}
            placeholder="Search Procedure Code or Description"
            maxCharacterInput={260}
          />
          {isLoading ? (
            <div className="spinner"></div>
          ) : (
            <>
              {isSearched ? (
                <div className="mb-3">{noOfResults > 0 ? `${noOfResults} Procedures found` : 'No results found.'}</div>
              ) : isFromBack ? (
                <div className="mb-3">Selected Procedure</div>
              ) : null}

              {isFromBack ? (
                <div>
                  <ResultItem
                    isActive
                    featuredText="Preliminary certification is required"
                    isFeatured={value?.preCertIsRequired || savedValues?.[STEPS.careType]?.preCertIsRequired}
                    featureId="procedure"
                  >
                    {renderProcedureContent(value)}
                  </ResultItem>
                </div>
              ) : (
                <div className="scrollable-results pr-1" id="scroll-top-div">
                  {data.slice(activePage * pageSize, (activePage + 1) * pageSize).map((item, idx) => (
                    <ResultItem
                      key={idx}
                      isActive={value.id === item.id}
                      onClick={() => onChange(item)}
                      featuredText="Preliminary certification is required"
                      isFeatured={item.preCertIsRequired || savedValues?.[STEPS.careType]?.preCertIsRequired}
                      featureId={`procedure-${idx}`}
                    >
                      {renderProcedureContent(item)}
                    </ResultItem>
                  ))}
                  <Pagination
                    activePage={activePage}
                    noOfPages={noOfPages}
                    setActivePage={(nextPage) => {
                      onPageChange(nextPage)
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Procedures
