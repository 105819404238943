import React from 'react'
import { FormGroup, FormFeedback, CustomInput, Label } from 'reactstrap'
import { FormatCapitalizedText } from '../global/TextHelpers'

const SelectInput = (props) => (
  <FormGroup>
    <Label for={props.name} className="card-detail">
      {props.label}
    </Label>
    <CustomInput
      type="select"
      id={props.name}
      name={props.name}
      value={props.value}
      onBlur={props.onBlur}
      onChange={props.onChange}
      valid={props.valid}
      invalid={props.touched && !props.valid}
      defaultValue={props.defaultValue}
    >
      {props.options.map((option) => (
        <option key={option.value} value={option.value} disabled={option.disabled}>
          {FormatCapitalizedText(option.displayValue)}
        </option>
      ))}
    </CustomInput>
    <FormFeedback>{props.validationMessage}</FormFeedback>
  </FormGroup>
)

export default SelectInput
