import React from 'react'
import { Helmet } from 'react-helmet'

const AccessibilityWidgetScript = () => {
  const dataAccountCode = process.env.REACT_APP_ACCESSIBILITY_DATA_ACCOUNT

  return (
    <Helmet>{dataAccountCode && <script src="https://cdn.userway.org/widget.js" data-account={dataAccountCode}></script>}</Helmet>
  )
}

export default AccessibilityWidgetScript
