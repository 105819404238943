import React, { PureComponent } from 'react'
import { Form, Input, Row, Col, Label, Container } from 'reactstrap'
import { LoadingCard } from './LoadingCard'
import _ from 'lodash'
import { Subscription } from 'rxjs'
import { cardConnectService } from '../../services/EZPayService.cardconnect'
import { StatesStringArray, ValidNumbersRegex } from '../global/TextHelpers'
import { memberService } from '../../services/MemberService'
import autoBind from 'react-autobind'
import { MediaQuery } from 'react-responsive'
import reactToCSS from 'react-style-object-to-css'

//"https://fts.cardconnect.com:6443/itoke/ajax-tokenizer.html?useexpiry=true&usecvv=true"

class TokenizedInputElement extends React.Component {
  constructor(props) {
    super(props)
    //autoBind(this);
    this.state = { value: '' }
    this.handleMessageEvent = this.handleMessageEvent.bind(this)
  }

  componentDidMount() {
    window.addEventListener('message', this.handleMessageEvent, false)
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    window.removeEventListener('message', this.handleMessageEvent, false)
  }

  handleMessageEvent(event) {
    if (event.origin !== cardConnectService.cardConnectServiceUrl) {
      return
    }

    window.event_caught = event
    const data = JSON.parse(event.data)
    //document.getElementById("token-input").value = data.message;

    this.setState({ value: data.message })
    const newEvent = {
      target: {
        name: 'tokenizedAccountNumber',
        value: data.message,
      },
    }
    newEvent.target.name = 'tokenizedAccountNumber'
    newEvent.target.value = data.message
    this.props.onChange(newEvent)
  }

  render() {
    let renderFragment = null
    if (this.props.isUpdate) {
      renderFragment = (
        <div className="mt-4 row">
          <div className="col-8">
            <input
              id="token-input-disabled"
              tabIndex="-1"
              disabled={true}
              value={this.props.mask}
              className="ml-2 form-control"
              style={{ cursor: 'not-allowed' }}
              dataToggle="tooltip"
              dataPlacement="bottom"
              title="Changing card number is not allowed"
            ></input>
          </div>
          <div className="col-2 pl-0 pr-1">
            <input
              id="token-cvv-disabled"
              tabIndex="-1"
              disabled={true}
              value="***"
              className="form-control"
              style={{ cursor: 'not-allowed' }}
              dataToggle="tooltip"
              dataPlacement="bottom"
              title="Changing CVV is not allowed"
            ></input>
          </div>
        </div>
      )
    } else {
      renderFragment = (
        <>
          <MediaQuery maxDeviceWidth={475}>
            {/* eslint-disable-next-line */}
            <iframe
              id="tokenframe"
              name="tokenframe"
              tabIndex={this.props.tabIndex}
              src={
                cardConnectService.cardConnectServiceUrl +
                '/itoke/ajax-tokenizer.html?orientation=custom&useexpiry=false&usecvv=true&placeholdercvv=' +
                placeholdercvv +
                '&placeholdercard=test&placeholder=Card Number&css=' +
                encodeURIComponent(cardFieldMobileCss + formCss + labelCss + inputCss) +
                '&cardinputmaxlength=20'
              }
              frameBorder="0"
              scrolling="no"
              width="500vw"
              height="70px"
            />
          </MediaQuery>

          <MediaQuery minDeviceWidth={476} maxDeviceWidth={1224}>
            {/* eslint-disable-next-line */}
            <iframe
              id="tokenframe"
              name="tokenframe"
              tabIndex={this.props.tabIndex}
              src={
                cardConnectService.cardConnectServiceUrl +
                '/itoke/ajax-tokenizer.html?orientation=custom&useexpiry=false&usecvv=true&placeholdercvv=' +
                placeholdercvv +
                '&placeholdercard=test&placeholder=Card Number&css=' +
                encodeURIComponent(cardFieldMobileTabletCss + formCss + labelCss + inputCss) +
                '&cardinputmaxlength=20'
              }
              frameBorder="0"
              scrolling="no"
              width="500vw"
              height="70px"
            />
          </MediaQuery>

          <MediaQuery minDeviceWidth={1224}>
            {/* eslint-disable-next-line */}
            <iframe
              id="tokenframe"
              name="tokenframe"
              tabIndex={this.props.tabIndex}
              src={
                cardConnectService.cardConnectServiceUrl +
                '/itoke/ajax-tokenizer.html?orientation=custom&useexpiry=false&usecvv=true&placeholdercvv=' +
                placeholdercvv +
                '&placeholdercard=test&placeholder=Card Number&css=' +
                encodeURIComponent(cardFieldCss + formCss + labelCss + inputCss) +
                '&cardinputmaxlength=20'
              }
              frameBorder="0"
              scrolling="no"
              width="500vw"
              height="70px"
            />
          </MediaQuery>
          <input
            type="hidden"
            className="m-2"
            id="tokenizedAccountNumber"
            name="tokenizedAccountNumber"
            value={this.state.value}
            onChange={this.props.onChange}
          />
        </>
      )
    }

    return renderFragment
  }
}

//dirty, clean up
const placeholdercvv = 'CVV'
// Create CSS Objects so we can pass inline styles to react, but convert them
// to strings so we can pass them to the iframe URL as well for CardConnect.
// The duplication would be completely unnecessary if React allowed style
// properties to use strings, but for some reason it does not allow you to even
// force it.
const formCssObj = {
  fontFamily: 'brandon-grotesque, sans-serif',
}
const formCss = 'form{' + reactToCSS(formCssObj) + '}'
const inputCssObj = {
  fontFamily: 'brandon-grotesque',
  height: '24px',
  padding: '6px 0.75rem',
  fontSize: '1rem',
  fontWeight: '400',
  lineHeight: '1.5',
  color: '#495057',
  backgroundColor: '#fff',
  backgroundClip: 'padding-box',
  borderRadius: '0.25rem',
  border: '1px solid #28a745 !important',
  margin: '8px 0px',
}
const inputCss = 'input {' + reactToCSS(inputCssObj) + '}'
const labelCssObj = {
  display: 'none',
}
const labelCss = '#cccardlabel, #cccvvlabel{' + reactToCSS(labelCssObj) + '}'
const cardFieldCssObj = {
  width: '60%',
}
const cardFieldCss = '#ccnumfield{' + reactToCSS(cardFieldCssObj) + '}'
const cardFieldMobileCssObj = {
  width: '23%',
}
const cardFieldMobileCss = '#ccnumfield{' + reactToCSS(cardFieldMobileCssObj) + '}'
const cardFieldMobileTabletCssObj = {
  width: '34%',
}
const cardFieldMobileTabletCss = '#ccnumfield{' + reactToCSS(cardFieldMobileTabletCssObj) + '}'

export class EZPayAddUpdateCard extends PureComponent {
  constructor(props) {
    super(props)

    autoBind(this)

    let accountId = 0
    if (props.accountId) {
      accountId = props.accountId
    }

    this.state = {
      submitDisabled: false,
      shouldLoad: false,
      formValid: false,
      selectedCard: false,
      subscriberPK: false,
      dependentPK: 0,
      keepOnFile: true,
      parentCallback: props.callback,
      formData: {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        phoneNumber: '',
        zipCode: '',
        company: '',
        tokenizedAccountNumber: '',
        email: '',
        subscriberPK: 0,
        patientPK: 0,
        keepOnFile: true,
        update: false,
        expiryMonth: 0,
        expiryYear: 0,
      },
      // TODO: Rewrite validate.
      validate: {
        firstName: false,
        lastName: false,
        address: false,
        zipCode: false,
        city: false,
        state: false,
        expiryMonth: false,
        expiryYear: false,
        tokenizedAccountNumber: false,
      },
      isSubmitting: false,
      isSuccessful: false,
      hasFailed: false,
      errorMessage: false,
      accountId: accountId,
    }

    this.baseFormState = this.state.formData
    this.subscription = new Subscription()
    this.validateInput = this.validateInput.bind(this)
    this.handleTokenizedChange = this.handleTokenizedChange.bind(this)
    this.populateCardPlaceholder = this.populateCardPlaceholder.bind(this)
    this.handleAddCardSubmit = this.handleAddCardSubmit.bind(this)
  }

  resetForm = () => {
    this.setState({ formData: this.baseFormState })
  }

  /* POST /EZPay/AddCardToEZPay
  {
    "billingAddress": {
      "address1": "string",
      "address2": "string",
      "zipCode": "string",
      "city": "string",
      "state": "string"
    },
    "subscriberPK": 0,
    "fullName": "string",
    "cardTypePK": 0,
  }
  */

  getFullName() {
    return this.state.formData.name
  }

  getCardType() {
    var ccTokenValue = this.state.formData.tokenizedAccountNumber

    //Second number in token is first number of CC
    var res = parseInt(ccTokenValue.charAt(1))
    var cardType

    switch (res) {
      case 3:
        cardType = 4 //'AMERICAN EXPRESS'
        break
      case 4:
        cardType = 1 //'VISA'
        break
      case 5:
        cardType = 2 //'MasterCard'
        break
      case 6:
        cardType = 5 //'DISCOVER CARD'
        break
      default:
        cardType = 3 // 'CREDIT CARD'
    }

    return cardType
  }

  componentDidMount() {
    this.subscription.add(
      memberService.subscriberInfo().subscribe((x) => {
        this.setState({
          subscriberPK: x.subscriberPK,
          dependentPK: x.dependentPK,
        })
        if (this.state.accountId) {
          this.subscription.add(
            cardConnectService.getCard(x.subscriberPK, x.dependentPK, this.state.accountId).subscribe((x) => {
              x = x.data
              //formData: {
              //  firstName: '',
              //  lastName: '',
              //  address: '',
              //  city: '',
              //  state: '',
              //  phoneNumber: '',
              //  zipCode: '',
              //  company: '',
              //  tokenizedAccountNumber: '',
              //  email: '',
              //  subscriberPK: 0,
              //  patientPK: 0,
              //  keepOnFile: false,
              //  update: false,
              //  expiryMonth: '',
              //  expiryYear: ''
              //},
              const nameParts = this.kludgeNameSplit(x.fullName)
              const formData = this.state.formData
              formData.firstName = nameParts[0]
              formData.lastName = nameParts[1]
              formData.address = x.address
              formData.tokenizedAccountNumber = '************' + x.tokenizedAccountNumber.substring(12)
              formData.city = x.city
              formData.state = x.state
              formData.zipCode = x.zipCode
              formData.phoneNumber = ''
              formData.company = ''
              formData.update = true
              formData.expiryMonth = x.expiryMonth
              formData.expiryYear = x.expiryYear
              this.setState({
                formData: formData,
                validate: {
                  firstName: true,
                  lastName: true,
                  address: true,
                  zipCode: true,
                  city: true,
                  state: true,
                  expiryMonth: true,
                  expiryYear: true,
                  tokenizedAccountNumber: true,
                },
              })
              this.forceUpdate()
            })
          )
        }
      })
    )
    this.populateMonthOptions()
    this.populateYearOptions()
    this.populateStateptions()
  }

  populateCardPlaceholder() {
    document.getElementById('ccnumfield').placeholder = 'Card Number'
  }

  allTrue(obj) {
    let overallResult = true
    for (let o in obj) {
      if (!obj[o]) {
        overallResult = false
        //console.log(o + " is invalid.");
      }
    }

    return overallResult
  }

  async validateInput(event) {
    let isValid = false
    let name = event.target.name
    let value = event.target.value

    let onlyNumbers = value.length > 0 && value.match(ValidNumbersRegex) !== null

    // Exhaustively select all inputs, even if we do nothing with them and
    // they're optional.
    switch (name) {
      case 'expiryMonth':
      case 'expiryYear':
        // eslint-disable-next-line
        isValid = value != 0
        break
      case 'firstName':
        isValid = value.length > 0 && value.length <= 30
        break
      case 'lastName':
        isValid = value.length > 0 && value.length <= 30
        break
      case 'address':
        isValid = value.length > 0 && value.length <= 30
        break
      case 'city':
        isValid = value.length > 0 && value.length <= 30
        break
      case 'zipCode':
        // eslint-disable-next-line
        isValid = value.length == 5 && onlyNumbers
        break
      case 'state':
        isValid = value.length > 0
        break
      case 'phoneNumber':
        isValid = value.length > 0
        break
      case 'email':
        isValid = value.length > 0
        break
      case 'tokenizedAccountNumber':
        isValid = value.length > 0
        break
      default:
        // eslint-disable-next-line
        throw 'validateInput: Unexpected property: ' + name
    }

    let validate = this.state.validate
    validate[name] = isValid

    let formValid = this.allTrue(validate)

    await this.setState({
      validate: validate,
      formValid: formValid,
    })
    this.forceUpdate()
  }

  handleNameInput = async (event) => {
    this.setState({ formData: { name: event.target.value } })
  }

  handleTokenizedChange(tokenizedAccountNumber) {
    this.setState({
      formData: { tokenizedAccountNumber: tokenizedAccountNumber },
      // eslint-disable-next-line
      validate: { tokenizedAccountNumber: tokenizedAccountNumber != '' },
    })

    this.setState({
      formValid: this.allTrue(this.state.validate),
    })
  }

  handleAddressInput = async (event) => {
    this.setState({ formData: { [event.target.name]: event.target.value } })
    //this.state.formData[event.target.name] = event.target.value;
  }

  handleChange(event) {
    //console.log("Handling change for element named " + event.target.name + " with value=\"" + event.target.value + "\"");
    //event.persist();
    //window.handleChangeEvent = event;
    // TODO: Validate here.
    switch (event.target.name) {
      case 'firstName':
      case 'lastName':
      case 'address':
      case 'city':
      case 'zipCode':
      case 'state':
      case 'phoneNumber':
      case 'email':
      case 'company':
      case 'expiryMonth':
      case 'expiryYear':
        break
      case 'keepOnFile':
        event.target.value = event.target.value === 'true' ? 'false' : 'true'
        // eslint-disable-next-line
        this.state.keepOnFile = event.target.value === 'true'
        break
      case 'tokenizedAccountNumber':
        this.setState({
          validate: {
            ...this.state.validate,
            // eslint-disable-next-line
            tokenizedAccountNumber: event.target.value != '',
          },
        })

        this.setState({
          formValid: this.allTrue(this.state.validate),
        })
        break
      default:
        const message = 'handleChange: Unexpected property: ' + event.target.name
        throw message
    }

    // eslint-disable-next-line
    if (event.target.value == undefined) {
      // console.warn("Property value for " + event.target.name + " is undefined or something.");
      return false
    }

    const newState = {
      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.value,
      },
    }

    this.setState(newState)
    return false
  }

  // Submit new card
  handleAddCardSubmit = async (event) => {
    this.setState({ submitDisabled: true })
    await this.setState({ isSubmitting: true })
    let { formData } = this.state
    formData.subscriberPK = this.state.subscriberPK
    formData.patientPK = this.state.dependentPK

    const data = {
      name: this.state.formData.firstName + ' ' + this.state.formData.lastName,
      address: this.state.formData.address,
      city: this.state.formData.city,
      state: this.state.formData.state,
      phoneNumber: this.state.formData.phoneNumber,
      zipCode: this.state.formData.zipCode,
      company: this.state.formData.company,
      tokenizedAccountNumber: this.state.formData.tokenizedAccountNumber,
      email: this.state.formData.email,
      keepOnFile: this.state.formData.keepOnFile,
      subscriberPK: this.state.subscriberPK,
      patientPK: this.state.dependentPK,
      update: false,
      cardType: this.getCardType(),
      expiryYear: this.state.formData.expiryYear,
      expiryMonth: this.state.formData.expiryMonth,
      reason: 'User website update.',
    }

    if (this.state.accountId) {
      this.subscription.add(
        cardConnectService.updateCard(this.state.accountId, data).subscribe((res) => {
          if (res.status === 200) {
            this.setState({
              isSubmitting: false,
              isSuccessful: true,
              hasFailed: false,
              errorMessage: '',
              submitDisabled: true,
              formData: {
                firstName: '',
                lastName: '',
                address: '',
                city: '',
                state: '',
                phoneNumber: '',
                zipCode: '',
                company: '',
                tokenizedAccountNumber: '',
                email: '',
                subscriberPK: 0,
                patientPK: 0,
                keepOnFile: true,
                update: false,
                expiryMonth: 0,
                expiryYear: 0,
              },
            })
            if (this.props.onSubmitSuccessful) {
              this.props.onSubmitSuccessful()
            }
            window.location.reload()
          }
        })
      )
    } else {
      this.subscription.add(
        cardConnectService.addCardToEZPay(data).subscribe(
          (res) => {
            if (res.data.successful) {
              this.setState({
                isSubmitting: false,
                isSuccessful: true,
                hasFailed: false,
                errorMessage: '',
                submitDisabled: true, //,
                //formData: this.baseFormState
              })
              // console.log('Added Card' + res.data.profileId);
              //this.refreshTable();
              if (this.state.parentCallback) {
                this.state.parentCallback(res.data.accountId)
              } else {
                window.location.reload()
              }
            } else {
              this.setState({
                isSubmitting: false,
                isSuccessful: false,
                hasFailed: true,
                errorMessage: res.data.resptext,
                submitDisabled: false,
              })
            }
          },
          (error) => {
            window.errorGrabbed = error
            //There is a scenerio where the card doesn't even get submitted to CC because of some reasons.
            let errorMessage = 'Unknown error.'
            if (error.response && error.response.data.errors) {
              errorMessage = ''
              for (let key in error.response.data.errors) {
                errorMessage += error.response.data.errors[key][0]
                errorMessage += '\n'
              }
            } else if (error.response && error.response.data && error.response.data[0]) {
              errorMessage = error.response.data[0].errorMessage
            }
            this.setState({
              isSubmitting: false,
              isSuccessful: false,
              hasFailed: true,
              errorMessage: errorMessage,
            })
          }
        )
      )
    }
  }

  // Populates the <options>Months</options>
  populateMonthOptions = async () => {
    let monthOptions = []
    monthOptions.push(
      <option className="text-secondary" key={0} value={0} disabled>
        Ex. Month
      </option>
    )

    var i
    for (i = 0; i < 12; i++) {
      const monthNumber = i + 1
      const monthValue = monthNumber.toString().padStart(2, '0') // Add leading zeros to ensure a two-digit month
      const monthLabel = monthValue

      monthOptions.push(
        <option key={monthNumber} value={monthValue}>
          {monthLabel}
        </option>
      )
    }

    await this.setState({ monthOptions: monthOptions })
  }

  // Populates the <options>Years</options>
  populateYearOptions = async () => {
    const now = new Date()
    const currentYear = now.getFullYear()
    const maxYears = currentYear + 10

    let range = _.range(currentYear, maxYears)

    let yearOptions = []

    yearOptions.push(
      <option className="text-secondary" key={0} value={0} disabled>
        Ex. Year
      </option>
    )
    range.forEach(function (year) {
      yearOptions.push(
        <option key={year} value={year}>
          {year}
        </option>
      )
    })

    await this.setState({ yearOptions: yearOptions, shouldLoad: true })
  }

  // Populates the <options>States</options>
  populateStateptions = async () => {
    let stateOptions = []
    stateOptions.push(
      <option className="text-secondary" key={0} value="">
        State
      </option>
    )

    StatesStringArray.forEach(function (state) {
      stateOptions.push(
        <option key={state} value={state}>
          {state}
        </option>
      )
    })

    await this.setState({ stateOptions: stateOptions })
  }

  kludgeNameSplit(name) {
    const lastNamePart = name.match(' .*')[0].trim()
    const firstNamePart = name.split(lastNamePart)[0].trim()
    return [firstNamePart, lastNamePart]
  }

  submissionNotification() {
    if (this.state.isSubmitting) {
      return <span className="text-info fw-7 ml-2">Submitting...</span>
    } else if (this.state.isSuccessful) {
      return <span className="text-success fw-7 ml-2">Submitted successfully!</span>
    } else if (this.state.hasFailed) {
      return <span className="text-danger fw-7 ml-2">{'Error submitting: ' + this.state.errorMessage}</span>
    } else {
      return <></>
    }
  }

  render() {
    let { monthOptions, yearOptions } = this.state
    if (this.state.shouldLoad) {
      return (
        <div id="ezpaycard" className="card-body bg-primary-light extra-rounded">
          <Form>
            <p className="text-secondary fw-7 m-0">Card Information</p>

            <MediaQuery maxDeviceWidth={475}>
              <Container fluid={true}>
                <Row>
                  <Col md={6}>
                    <Input
                      type="text"
                      placeholder="First Name"
                      className="m-2"
                      name="firstName"
                      onChange={(e) => {
                        this.validateInput(e)
                        this.handleChange(e)
                      }}
                      valid={this.state.validate.firstName}
                      invalid={!this.state.validate.firstName}
                      tabIndex={this.state.accountId * 30 + 1}
                      value={this.state.formData.firstName}
                    />
                  </Col>
                  <Col me={6}>
                    <Input
                      type="text"
                      placeholder="Last Name"
                      className="m-2"
                      name="lastName"
                      onChange={(e) => {
                        this.validateInput(e)
                        this.handleChange(e)
                      }}
                      valid={this.state.validate.lastName}
                      invalid={!this.state.validate.lastName}
                      tabIndex={this.state.accountId * 30 + 2}
                      value={this.state.formData.lastName}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TokenizedInputElement
                      onChange={this.handleChange}
                      tabIndex={this.state.accountId * 30 + 3}
                      isUpdate={this.state.formData.update}
                      mask={this.state.formData.tokenizedAccountNumber}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <Input
                      type="select"
                      name="expiryMonth"
                      id="expMonth"
                      placeholder="Ex. Month"
                      required={true}
                      className="m-2"
                      onChange={(e) => {
                        this.handleChange(e)
                        this.validateInput(e)
                      }}
                      valid={this.state.validate.expirationMonth}
                      invalid={!this.state.validate.expirationMonth}
                      tabIndex={this.state.accountId * 30 + 4}
                      value={this.state.formData.expiryMonth}
                    >
                      {monthOptions}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      type="select"
                      name="expiryYear"
                      id="expYear"
                      placeholder="Ex. Year"
                      required={true}
                      className="m-2"
                      onChange={(e) => {
                        this.handleChange(e)
                        this.validateInput(e)
                      }}
                      valid={this.state.validate.expirationYear}
                      invalid={!this.state.validate.expirationYear}
                      tabIndex={this.state.accountId * 30 + 5}
                      value={this.state.formData.expiryYear}
                    >
                      {yearOptions}
                    </Input>
                  </Col>
                </Row>
              </Container>
            </MediaQuery>

            <MediaQuery minDeviceWidth={476}>
              <Container fluid={true}>
                <Row>
                  <Col md={6}>
                    <Input
                      type="text"
                      placeholder="First Name"
                      className="m-2"
                      name="firstName"
                      onChange={(e) => {
                        this.validateInput(e)
                        this.handleChange(e)
                      }}
                      valid={this.state.validate.firstName}
                      invalid={!this.state.validate.firstName}
                      tabIndex={this.state.accountId * 30 + 1}
                      value={this.state.formData.firstName}
                    />
                  </Col>
                  <Col md={6}>
                    <Input
                      type="text"
                      placeholder="Last Name"
                      className="m-2"
                      name="lastName"
                      onChange={(e) => {
                        this.handleChange(e)
                        this.validateInput(e)
                      }}
                      valid={this.state.validate.lastName}
                      invalid={!this.state.validate.lastName}
                      tabIndex={this.state.accountId * 30 + 2}
                      value={this.state.formData.lastName}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <TokenizedInputElement
                      onChange={this.handleChange}
                      tabIndex={this.state.accountId * 30 + 3}
                      isUpdate={this.state.formData.update}
                      mask={this.state.formData.tokenizedAccountNumber}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <Input
                      type="select"
                      name="expiryMonth"
                      id="expMonth"
                      placeholder="Ex. Month"
                      required={true}
                      className="m-2"
                      onChange={(e) => {
                        this.handleChange(e)
                        this.validateInput(e)
                      }}
                      valid={this.state.validate.expirationMonth}
                      invalid={!this.state.validate.expirationMonth}
                      tabIndex={this.state.accountId * 30 + 4}
                      value={this.state.formData.expiryMonth}
                    >
                      {monthOptions}
                    </Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      type="select"
                      name="expiryYear"
                      id="expYear"
                      placeholder="Ex. Year"
                      required={true}
                      className="m-2"
                      onChange={(e) => {
                        this.handleChange(e)
                        this.validateInput(e)
                      }}
                      valid={this.state.validate.expirationYear}
                      invalid={!this.state.validate.expirationYear}
                      tabIndex={this.state.accountId * 30 + 5}
                      value={this.state.formData.expiryYear}
                    >
                      {yearOptions}
                    </Input>
                  </Col>
                </Row>
              </Container>
            </MediaQuery>

            <p className="text-secondary fw-7">Billing Address</p>
            <Container fluid={true}>
              <Row>
                <Col md={6}>
                  <Input
                    type="text"
                    placeholder="Street Address"
                    className="m-2"
                    name="address"
                    onChange={(e) => {
                      this.handleChange(e)
                      this.validateInput(e)
                    }}
                    valid={this.state.validate.address}
                    invalid={!this.state.validate.address}
                    tabIndex={this.state.accountId * 30 + 6}
                    value={this.state.formData.address}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    type="text"
                    placeholder="City"
                    className="mt-2 ml-2 mr-2 mb-0"
                    name="city"
                    onChange={(e) => {
                      this.handleChange(e)
                      this.validateInput(e)
                    }}
                    valid={this.state.validate.city}
                    invalid={!this.state.validate.city}
                    tabIndex={this.state.accountId * 30 + 7}
                    value={this.state.formData.city}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Input
                    type="select"
                    name="state"
                    id="state"
                    placeholder="State"
                    required={true}
                    className="m-2"
                    onChange={(e) => {
                      this.handleChange(e)
                      this.validateInput(e)
                    }}
                    valid={this.state.validate.state}
                    invalid={!this.state.validate.state}
                    tabIndex={this.state.accountId * 30 + 8}
                    value={this.state.formData.state}
                  >
                    {this.state.stateOptions}
                  </Input>
                </Col>
                <Col md={3}>
                  <Input
                    type="text"
                    placeholder="Zip Code"
                    className="m-2"
                    maxLength="5"
                    name="zipCode"
                    onChange={(e) => {
                      this.handleChange(e)
                      this.validateInput(e)
                    }}
                    valid={this.state.validate.zipCode}
                    invalid={!this.state.validate.zipCode}
                    tabIndex={this.state.accountId * 30 + 9}
                    value={this.state.formData.zipCode}
                  />
                </Col>
              </Row>
              <Row>
                <Label className="ml-5">
                  <Input
                    type="checkbox"
                    name="keepOnFile"
                    onChange={(e) => this.handleChange(e)}
                    tabIndex={this.state.accountId * 30 + 10}
                    checked={this.state.keepOnFile === true}
                  />{' '}
                  <p className="text-secondary fw-7">Keep On File</p>
                </Label>
              </Row>
              <button
                type="button"
                className="btn btn-outline-primary btn-sm w-3 fw-7"
                onClick={this.handleAddCardSubmit}
                disabled={!this.state.formValid}
                tabIndex={this.state.accountId * 30 + 11}
              >
                Submit
              </button>{' '}
              {this.submissionNotification()}
            </Container>
          </Form>
        </div>
      )
    } else {
      return <LoadingCard />
    }
  }
}

/* [dbo].[Insured_Financial_acct_cardtype]
// IFAT_Insured_Financial_acct_cardtype_PK,	IFAT_CardName
// 1	VISA
// 2	MasterCard
// 11	FLEX Debit - VISA
// 21	FLEX Debit - MasterCard
// 30	HSA Debit - Visa or Master
// 4	AMERICAN EXPRESS
// 5	DISCOVER CARD
// 6	HSA Direct Account
// 7	HRA Direct Account
// 8	FLEX Direct Account
*/
