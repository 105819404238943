import _ from 'lodash'
import { FormatCurrency, formatMiles } from '../../../../global/TextHelpers'
import { SORTING } from './constants'

export const compareCostAndReturnLowestFirst = (cost1, cost2) => {
  if (!_.isNumber(cost1)) {
    return 1
  }
  if (!_.isNumber(cost2)) {
    return -1
  }
  return cost1 > cost2 ? 1 : -1
}

export const getSortedProviders = (providers, sortValue, isFacility = false) => {
  const sortedProviders = providers?.sort((p1, p2) => {
    const name1 = isFacility
      ? p1?.facility?.name?.trim()?.toLowerCase()
      : [p1.provider.firstName, p1.provider.lastName]
          .filter((it) => it)
          .join('')
          .trim()
          ?.toLowerCase()
    const name2 = isFacility
      ? p2?.facility?.name?.trim()?.toLowerCase()
      : [p2.provider.firstName, p2.provider.lastName]
          .filter((it) => it)
          .join('')
          .trim()
          ?.toLowerCase()
    const distance1 = formatMiles(p1?.distance?.distanceInMiles)
    const distance2 = formatMiles(p2?.distance?.distanceInMiles)
    if (SORTING.lowestCost === sortValue) {
      if (p1?.totalCost === p2?.totalCost) {
        if (distance1 === distance2) {
          return name1 > name2 ? 1 : -1
        }
        return distance1 > distance2 ? 1 : -1
      }
      return compareCostAndReturnLowestFirst(p1?.totalCost, p2?.totalCost)
    }
    if (SORTING.highestCost === sortValue) {
      if (p1?.totalCost === p2?.totalCost) {
        if (distance1 === distance2) {
          return name1 > name2 ? 1 : -1
        }
        return distance1 > distance2 ? 1 : -1
      }
      return p1?.totalCost > p2?.totalCost ? -1 : 1
    }
    if (SORTING.nearest === sortValue) {
      if (distance1 === distance2) {
        if (p1?.totalCost === p2?.totalCost) {
          return name1 > name2 ? 1 : -1
        }
        return compareCostAndReturnLowestFirst(p1?.totalCost, p2?.totalCost)
      }
      return distance1 > distance2 ? 1 : -1
    }
    if (SORTING.nameAZ === sortValue) {
      if (name1 === name2) {
        if (p1?.totalCost === p2?.totalCost) {
          return distance1 > distance2 ? 1 : -1
        }
        return compareCostAndReturnLowestFirst(p1?.totalCost, p2?.totalCost)
      }
      return name1 > name2 ? 1 : -1
    }
    if (SORTING.nameZA === sortValue) {
      if (name1 === name2) {
        if (p1?.totalCost === p2?.totalCost) {
          return distance1 > distance2 ? 1 : -1
        }
        return compareCostAndReturnLowestFirst(p1?.totalCost, p2?.totalCost)
      }
      return name1 > name2 ? -1 : 1
    }

    return 1
  })

  return sortedProviders
}

export const getSortedFacilities = (facilities, sortValue) => {
  if (sortValue === SORTING.highestCost) {
    const noCostFacilities = facilities?.filter(({ totalCost }) => !totalCost) || []
    const withCostFacilities = facilities?.filter(({ totalCost }) => totalCost) || []

    return [...getSortedProviders(withCostFacilities, sortValue, true), ...getSortedProviders(noCostFacilities, sortValue, true)]
  } else {
    return getSortedProviders(facilities, sortValue, true)
  }
}

export const getPrice = (price, placeholder = '--') => {
  if (!_.isNumber(price)) return placeholder
  return `$${price.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
}

export const getTotalCost = (filteredData) => {
  if (!filteredData?.length) {
    return '--'
  }
  const dataWithTotalCost = filteredData.map((item) => item.totalCost).filter((item) => !_.isNull(item))
  const maxCost = FormatCurrency(Math.max.apply(null, dataWithTotalCost))
  const minCost = FormatCurrency(Math.min.apply(null, dataWithTotalCost))
  if (!dataWithTotalCost.length) {
    return 'No costs provided'
  }
  return maxCost === minCost ? `$${maxCost}` : `$${minCost}-$${maxCost}`
}
