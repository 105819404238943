import React, { PureComponent } from 'react'
import { memberService } from '../../services/MemberService'
import { Subscription } from 'rxjs'
import { saveAs } from 'file-saver'
import SelectInput from '../forms/SelectInput'
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'

const Email = 'email'
const Sms = 'text'
const Pdf = 'digital'

export class MyIDCard extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      subscriberInfo: false,
      idCard: false,
      mobileNumber: false,
      sentToMobile: false,
      mobileError: false,
      downloadError: false,
      hasIDCard: true,
      idCardOptions: {
        options: [],
        valiationMessage: 'Please select an option',
        value: '',
        placeholder: 'Delivery Method',
        label: 'Delivery Method',
        validationRules: {
          isRequired: true,
        },
        touched: false,
        valid: false,
      },
      show: false,
      displayInput: false,
      inputType: '',
      requestIDCardInput: '',
      isDownloading: false,
    }

    this.subscription = new Subscription()
    this.downloadPDF = this.downloadPDF.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.sendToMobile = this.sendToMobile.bind(this)
    this.requestIDCard = this.requestIDCard.bind(this)
    this.selectIDCardTypeHandler = this.selectIDCardTypeHandler.bind(this)
  }

  handleClose = () => {
    this.setState({ show: false })
  }
  handleShow = (e) => {
    // We need to remove the focus from the button, otherwise it remains focused
    // after closing the modal.
    e.target.blur()
    this.setState({ show: true })
  }

  componentDidMount() {
    this.subscription.add(
      memberService.subscriberInfo().subscribe((x) => {
        this.setState({
          subscriberInfo: x,
        })
      })
    )

    this.subscription.add(
      memberService.fetchIDCardRequestTypes().subscribe((x) => {
        let idCardOptions = { ...this.state.idCardOptions }
        idCardOptions.options = x.data.result.map((y) => {
          return {
            value: y,
            displayValue: y,
          }
        })
        console.log(x)
        this.setState({ idCardOptions })
        console.log(this.state)
      })
    )
  }
  downloadPDF() {
    this.setState({ isDownloading: true })
    this.subscription.add(
      memberService.fetchIDCardAsPDF(this.state.subscriberInfo.subscriberPK, this.state.subscriberInfo.dependentPK).subscribe(
        (x) => {
          this.setState({ isDownloading: false })
          if (x.status === 200) {
            const blob = new Blob([x.data], { type: 'application/pdf' })
            saveAs(blob, this.state.subscriberInfo.subscriberPK + '_idcard.pdf', true)
          } else if (x.status === 404 || x.status === 406) {
            this.setState({
              hasIDCard: false,
            })
          } else {
            this.setState({ downloadError: true })
          }
        },
        (error) => {
          this.setState({
            isDownloading: false,
            downloadError: true,
            hasIDCard: false,
          })
        }
      )
    )
  }

  selectIDCardTypeHandler = (event) => {
    console.log(event)
    const value = event.target.value

    let idCardOptions = { ...this.state.idCardOptions }
    idCardOptions.value = value
    this.setState({ idCardOptions })

    var option
    option = value.toLowerCase()
    if (event.target.value && option.includes(Sms)) {
      this.setState({
        displayInput: true,
        inputType: 'phone number',
      })
    } else if (event.target.value && option.includes(Email)) {
      this.setState({
        displayInput: true,
        inputType: 'email address',
      })
    } else {
      this.setState({
        displayInput: false,
      })
    }
  }

  requestIDCard() {
    console.log(this.state.subscriberInfo)
    this.subscription.add(
      memberService
        .requestIDCard(
          this.state.subscriberInfo.subscriberPK,
          this.state.subscriberInfo.dependentPK,
          this.state.idCardOptions.value,
          this.state.requestIDCardInput
        )
        .subscribe((x) => {
          if (x.status === 200) {
            //console.log(x);
            this.setState({ IDCardRequested: true })
            if (this.state.idCardOptions.value.toLowerCase().includes(Pdf)) {
              this.downloadPDF()
            } else if (this.state.idCardOptions.value.toLowerCase().includes(Sms)) {
              //console.log("Requesting mobile send");
              this.sendToMobile()
            } else if (this.state.idCardOptions.value.toLowerCase().includes(Email)) {
              //console.log("requesting email send");
              this.subscription
                .add(
                  memberService.sendIDCardEmail(
                    this.state.subscriberInfo.subscriberPK,
                    this.state.subscriberInfo.dependentPK,
                    this.state.requestIDCardInput
                  )
                )
                .subscribe((x) => {
                  //console.log("subscribed");
                })
            }
          }
        })
    )
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  sendToMobile() {
    this.subscription.add(
      memberService
        .sendIDCardToMobile(
          this.state.subscriberInfo.subscriberPK,
          this.state.subscriberInfo.dependentPK,
          this.state.requestIDCardInput
        )
        .subscribe(
          (x) => {
            if (x.status === 200) {
              this.setState({ sentToMobile: true })
            } else {
              this.setState({ mobileError: true })
            }
          },
          (error) => {
            this.setState({ mobileError: true })
          }
        )
    )
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  render() {
    /*
    let sent = this.state.sentToMobile ? (<div className="alert alert-success">Message sent. Please check your mobile phone for your ID card!</div>) : (<></>);
    let mobileError = this.state.mobileError ? (<div className="alert alert-danger">Sorry, we were unable to send the cards to your mobile device.
            This may be because you do not have a valid ID card. You can request one by clicking below.</div>) : (<></>);
    let downloadError = this.state.downloadError ? (<div className="alert alert-danger">Sorry, we were unable to retrieve your id cards at this time.
            This may be because you do not have a valid ID card. You can request one by clicking below.</div>) : (<></>);
            */
    let requested = this.state.IDCardRequested ? <div className="alert alert-success">ID Card requested.</div> : <></>
    let pleaseWait = this.state.isDownloading ? (
      <div className="alert alert-secondary">Please wait while your ID card downloads</div>
    ) : (
      <></>
    )
    let input = this.state.displayInput ? (
      <span>
        <input
          type="tel"
          className="d-inline w-6"
          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
          placeholder={'Recipient ' + this.state.inputType}
          name="requestIDCardInput"
          onChange={this.handleChange}
        />
      </span>
    ) : (
      <></>
    )

    let result = (
      <div className="card-body bg-primary-light extra-rounded">
        <div className="d-flex justify-content-between">
          <span className="card-section-header">My ID Card</span>
          <button className="btn btn-outline-primary btn-sm ml-3 fw-7 card-section-button" onClick={this.handleShow}>
            View/Request ID Card
          </button>
        </div>

        <div>
          <Modal isOpen={this.state.show} onExit={this.handleClose} centered={true}>
            <ModalHeader>Request ID Card</ModalHeader>
            <ModalBody>
              <div>
                {requested}
                {pleaseWait}
                <span>
                  <small className="text-primary fw-7">Request ID Card</small>
                </span>
                <SelectInput
                  className="w-3"
                  name="idCardRequestType"
                  label={this.state.idCardOptions.label}
                  value={this.state.idCardOptions.value}
                  onChange={this.selectIDCardTypeHandler}
                  onBlur={this.selectIDCardTypeHandler}
                  options={this.state.idCardOptions.options}
                  touched={this.state.idCardOptions.touched}
                  valid={this.state.idCardOptions.valid}
                  validationRules={this.state.idCardOptions.validationRules}
                  validationMessage={this.state.idCardOptions.validationMessage}
                />
                <div>{input}</div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <button type="button" onClick={this.requestIDCard} className="btn btn-outline-primary btn-sm w-4 float-right fw-7">
                View/Request ID Card
              </button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )

    return result
  }
}
